import React, { FC, useMemo, useState } from 'react';

import TaskCardSvg from 'ant/components/svg/task-card.svg';
import WidgetsSvg from 'ant/components/svg/widgets.svg';
import { UiBreadcrumb, UiRoute } from 'ant/components/ui/breadcrumb';
import { UiFileDirection } from 'ant/components/ui/file';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon, UiIconLabelProps } from 'ant/components/ui/icon';
import { UiMentionClearInputSearchHandler, UiMentionsInputValue } from 'ant/components/ui/mentions';
import { UiSelect } from 'ant/components/ui/select';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { TagMentionSearch } from 'ant/components/widgets/TagMentionSearch';
import { useLocalStorage } from 'ant/helpers/hooks/use-local-storage';

type Props = {
  routes: UiRoute[];
  onSearch: (value: UiMentionsInputValue) => void;
  onClear?: UiMentionClearInputSearchHandler;
};

export const FileListManagerNavigation: FC<Props> = (props) => {
  const { routes, onSearch, onClear } = props;
  const [searchText, setSearchText] = useState('');
  const [fileListManagerDirection, setFileStorageDirection] = useLocalStorage<UiFileDirection>(
    'fileListManagerDirection',
    UiFileDirection.Horizontal,
  );

  const onClearHandler = () => {
    setSearchText('');
    onClear?.();
  };

  const DirectionSelectOptionLabel: FC<UiIconLabelProps> = (labelProps) => {
    return <UiIcon.Label type={UiTypographyTextTypes.Secondary} width={20} height={20} {...labelProps} />;
  };

  const directionOptions = useMemo(
    () => [
      {
        value: UiFileDirection.Horizontal,
        label: <DirectionSelectOptionLabel component={TaskCardSvg} label="Таблица" />,
      },
      {
        value: UiFileDirection.Vertical,
        label: <DirectionSelectOptionLabel component={WidgetsSvg} label="Плитка" />,
      },
    ],
    [],
  );

  return (
    <UiRow align="middle" justify="space-between" style={{ padding: '24px' }}>
      <UiCol span={8} xxl={14}>
        {routes.length === 1 && (
          <UiTypography.Title style={{ margin: 0 }} level={2}>
            Файловое хранилище
          </UiTypography.Title>
        )}
        {routes.length > 1 && (
          <UiBreadcrumb emptyMargin emptyPadding breadcrumbRoutes={routes} maxItemsAfterOverflow={2} />
        )}
      </UiCol>
      <UiCol span={16} xxl={10}>
        <UiRow wrap={false} gutter={16}>
          <UiCol>
            <UiSelect
              style={{ width: 136 }}
              defaultValue={fileListManagerDirection}
              onChange={setFileStorageDirection}
              optionLabelProp="label"
              options={directionOptions}
            />
          </UiCol>
          <UiCol flex="1 1 100%">
            <TagMentionSearch
              size="middle"
              value={searchText}
              onSearch={onSearch}
              onChange={setSearchText}
              onClear={onClearHandler}
            />
          </UiCol>
        </UiRow>
      </UiCol>
    </UiRow>
  );
};
