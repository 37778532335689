import { useStore } from 'effector-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Header as LayoutHeader } from 'ant/components/layout/header';
import { UiRow } from 'ant/components/ui/grid';
import { UiImage } from 'ant/components/ui/image';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { headerLogoImageStorage } from 'ant/theme/header-logo-image/store';
import { WidgetModule } from 'core-components/types';

import { HeaderButtonsDefault } from './buttons/HeaderButtons@default';
import { HeaderSearch } from './search/HeaderSearch';

const Header: React.FC<WidgetModule> = () => {
  const {
    data: { fileStorageObject },
  } = useStore(headerLogoImageStorage.storage.store);

  const Logo = (
    <UiRow justify="center" align="middle" style={{ width: 260 }} wrap={false}>
      <Link to={getRoutePath(RouteNames.Main)}>
        <UiImage
          placeholder={<UiRow style={{ width: 260, height: 70 }} />}
          src={fileStorageObject?.file}
          style={{ maxWidth: 199, maxHeight: 70 }}
        />
      </Link>
    </UiRow>
  );

  const Buttons = (
    <UiRow style={{ paddingRight: 20 }} wrap={false}>
      <HeaderButtonsDefault />
    </UiRow>
  );

  return (
    <LayoutHeader before={Logo} after={Buttons} contentStyle={{ padding: '0 20px' }}>
      <HeaderSearch />
    </LayoutHeader>
  );
};

export { Header };
