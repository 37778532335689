import { NotificationTypes } from 'ant/types/models/notification.model';

const commentCreatedDefaultDescription = 'Оставил(а) комментарий';
const commentReactionDefaultDescription = 'Отреагировал(а) на ваш комментарий';
const commentUserMentionedDefaultDescription = 'Упомянул(а) вас в комментарии';
const commentReplyDefaultDescription = 'Ответил(а)';

export const notificationDescriptionValues: Partial<Record<NotificationTypes, string>> = {
  [NotificationTypes.AlbumImageCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.AlbumImageCommentReaction]: commentReactionDefaultDescription,
  [NotificationTypes.AlbumImageCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.AlbumImageCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.BlogEntryCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.BlogEntryCommentReaction]: commentReactionDefaultDescription,
  [NotificationTypes.BlogEntryCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.BlogEntryCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.BlogEntryCreated]: 'Создан пост',
  [NotificationTypes.BlogEntryUpdated]: 'Обновлен пост',
  [NotificationTypes.BlogEntryRequireModeration]: 'Предложен пост',
  [NotificationTypes.BlogUserInviteRequestClosedBlog]: 'Подал(а) заявку на вступление в группу',
  [NotificationTypes.BlogUserApproveRequestClosedBlog]: 'Подтвердил(а) заявку на вступление в группу',
  [NotificationTypes.BlogUserRejectRequestClosedBlog]: 'Отклонил(а) заявку на вступление в группу',
  [NotificationTypes.CalendarGroupEventAttendeeApproveDecision]: 'Пользователь принял приглашение',
  [NotificationTypes.CalendarGroupEventAttendeeRejectDecision]: 'Пользователь отклонил приглашение',
  [NotificationTypes.CalendarGroupEventAttendeeMaybeDecision]: 'Пользователь возможно пойдет',
  [NotificationTypes.CalendarGroupEventInvite]: 'Приглашение на встречу',
  [NotificationTypes.CalendarGroupEventUpdated]: 'Изменил(а) встречу',
  [NotificationTypes.CalendarGroupEventDeleteAttendee]: 'Исключил(а) вас из встречи',
  [NotificationTypes.CmsPageCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.CmsPageCommentReaction]: commentReactionDefaultDescription,
  [NotificationTypes.CmsPageCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.CmsPageCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.CmsPageUserMentioned]: 'Упоминание вас на странице',
  [NotificationTypes.EventsDelete]: 'Мероприятие отменено',
  [NotificationTypes.EventsInvite]: 'Приглашение на мероприятие',
  [NotificationTypes.FileVersionCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.FileVersionCommentReaction]: commentReactionDefaultDescription,
  [NotificationTypes.FileVersionCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.FileVersionCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.GamificationEventsManualGiveBadges]:
    'Вами получена новая награда, вы можете посмотреть ее в профиле',
  [NotificationTypes.MicropostCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.MicropostCommentReaction]: commentReactionDefaultDescription,
  [NotificationTypes.MicropostCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.MicropostCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.TimelinesSignerMicropostCreated]: 'Опубликовал(а) пост в персональном блоге',
  [NotificationTypes.NewsCommentCreated]: commentCreatedDefaultDescription,
  [NotificationTypes.NewsCommentReaction]: commentReactionDefaultDescription,
  [NotificationTypes.NewsCommentReply]: commentReplyDefaultDescription,
  [NotificationTypes.NewsCommentUserMentioned]: commentUserMentionedDefaultDescription,
  [NotificationTypes.NewsUserMentioned]: 'Упомянул(а) вас в новости',
  [NotificationTypes.FileStorageFileShared]: 'Отправил(а) ссылку на файл',
};
