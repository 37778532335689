import React from 'react';

import {
  UiDragDropContext,
  UiDroppable,
  ResponderProvidedType,
  DropResultType,
} from 'ant/components/ui/drag-drop-context';
import { UiForm, UiFormListItemMoveActionType } from 'ant/components/ui/form';

import { NavigationFavoritesActions } from './actions/NavigationFavoritesActions';
import { NavigationFavoritesHeader } from './header/NavigationFavoritesHeader';
import { NavigationFavoritesItem, CheckboxChangeEventHandlerCustom } from './item/NavigationFavoritesItem';

type Props = {
  selectedKeys: string[];
  isFavoritesEdit: boolean;
  isFavoritesEmpty: boolean;
  isSuccessEditLoading: boolean;
  isSuccessEditDisabled: boolean;
  onClickEditHandler: React.MouseEventHandler<HTMLElement>;
  onCancelEditHandler: React.MouseEventHandler<HTMLElement>;
  onSuccessEditHandler: React.MouseEventHandler<HTMLElement>;
  onCheckboxFavoritesChange: CheckboxChangeEventHandlerCustom;
};

export const NavigationFavorites: React.FC<Props> = (props) => {
  const {
    selectedKeys,
    isFavoritesEdit,
    isFavoritesEmpty,
    isSuccessEditLoading,
    isSuccessEditDisabled,
    onClickEditHandler,
    onCancelEditHandler,
    onSuccessEditHandler,
    onCheckboxFavoritesChange,
  } = props;

  const onDragEndMove = (
    result: DropResultType,
    _: ResponderProvidedType,
    onMove: UiFormListItemMoveActionType,
  ) => {
    if (result.destination) {
      onMove(result.source.index, result.destination.index);
    }
  };

  return (
    <div>
      <NavigationFavoritesHeader
        isFavoritesEdit={isFavoritesEdit}
        isFavoritesEmpty={isFavoritesEmpty}
        onClickEditHandler={onClickEditHandler}
      />

      <UiForm.List name="favorites">
        {(items, { move: onMove }) => (
          <UiDragDropContext onDragEnd={(result, provided) => onDragEndMove(result, provided, onMove)}>
            <UiDroppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((field, index) => (
                    <UiForm.Item noStyle {...field}>
                      <NavigationFavoritesItem
                        index={index}
                        selectedKeys={selectedKeys}
                        isFavoritesEdit={isFavoritesEdit}
                        isSuccessEditLoading={isSuccessEditLoading}
                        onCheckboxFavoritesChange={onCheckboxFavoritesChange}
                      />
                    </UiForm.Item>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </UiDroppable>
          </UiDragDropContext>
        )}
      </UiForm.List>

      <NavigationFavoritesActions
        isFavoritesEdit={isFavoritesEdit}
        isFavoritesEmpty={isFavoritesEmpty}
        isSuccessEditLoading={isSuccessEditLoading}
        isSuccessEditDisabled={isSuccessEditDisabled}
        onClickEditHandler={onClickEditHandler}
        onCancelEditHandler={onCancelEditHandler}
        onSuccessEditHandler={onSuccessEditHandler}
      />
    </div>
  );
};
