import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { FileStorageEndpoints } from 'ant/store/filestorage/endpoints';
import { DictPaginated } from 'ant/types/api';
import { FileStorageListEntryModel } from 'ant/types/models/file.model';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchFilesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageListEntryModel>,
    FileStorageListEntryModel[],
    FileStorageListEntryModel[],
    SearchParams
  >({
    endpointBuilder: ({ searchString, ...restParams }) =>
      buildEndpointWithQueryParams(FileStorageEndpoints.filesSearch(), {
        query: searchString,
        ...restParams,
      }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};
