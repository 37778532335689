import isUndefined from 'lodash/isUndefined';
import React, { FC, MouseEventHandler, ReactNode } from 'react';

import { UiAvatar } from 'ant/components/ui/avatar';
import { BadgeColors, UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip, UiTooltipPlacement } from 'ant/components/ui/tooltip';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { useTheme } from 'ant/theme/provider';

export type GamificationItemProps = {
  tooltipTitle?: string;
  image: string;
  icon: SvgrComponent;
  badge?: ReactNode;
  info?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  size?: number;
  tooltipPlacement?: UiTooltipPlacement;
  isActive?: boolean;
  isSelected?: boolean;
  avatarIconSize?: number;
  badgeColor?: BadgeColors;
};

export const GamificationItem: FC<GamificationItemProps> = (props) => {
  const {
    tooltipTitle,
    image,
    badge,
    onClick,
    size = 72,
    avatarIconSize = 48,
    tooltipPlacement,
    icon: Icon,
    isActive = true,
    isSelected = false,
    info,
    badgeColor = BadgeColors.Primary,
  } = props;

  const [{ variables: themeVariables }] = useTheme();

  return (
    <UiTooltip placement={tooltipPlacement} title={tooltipTitle}>
      <UiButton.Decorator onClick={onClick}>
        <UiSpace flex size={0} align="center" direction="vertical" wrap={false} style={{ width: size }}>
          <UiAvatar
            size={size}
            src={image}
            style={{
              opacity: isActive ? 1 : 0.4,
              boxShadow: isSelected ? `0 0 0 4px ${themeVariables.colorBrand}` : 'none',
            }}
            icon={<UiIcon component={Icon} height={avatarIconSize} width={avatarIconSize} />}
          />
          {!isUndefined(badge) && <UiBadge showZero count={badge} color={badgeColor} offset={[0, -12]} />}

          {info && (
            <UiTypography.Text type="secondary" strong style={{ marginTop: 4, textAlign: 'center' }}>
              <UiTruncateMarkup truncate lines={2}>
                {info}
              </UiTruncateMarkup>
            </UiTypography.Text>
          )}
        </UiSpace>
      </UiButton.Decorator>
    </UiTooltip>
  );
};
