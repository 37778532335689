import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { CurrencyRates } from 'components-frontend/typings/currency-rates';

import { CurrencyEndpoints } from './endpoints';

export const getCurrencyRatesStorage = () => {
  const storage = abstractStorageFactory<CurrencyRates.Currency[], CurrencyRates.Currency, null>({
    endpointBuilder: () => buildEndpointWithQueryParams(CurrencyEndpoints.currencyRates()),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data[0],
  });

  return { storage };
};

export type CurrencyRatesStorage = ReturnType<typeof getCurrencyRatesStorage>;
