import React, { FC } from 'react';

import { Layout } from 'ant/components/layout/layout';
import { UiSpace } from 'ant/components/ui/space';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { GetFileStorageEntryInfoStorage } from 'ant/store/filestorage';
import { FileStorageEntryId } from 'ant/types/models/file.model';
import { FileActions } from 'components-frontend/components/file/actions';
import { FileHistory } from 'components-frontend/components/file/history';
import { FileInfoCard } from 'components-frontend/components/file/info-card';
import { FileInfoCardGeneral } from 'components-frontend/components/file/info-card/general';
import { FileVersions } from 'components-frontend/components/file/versions';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  fileId: FileStorageEntryId;
  fileStorageRootId?: FileStorageEntryId;
};

export const FileView: FC<Props> = (props) => {
  const { fileInfoStorage, fileId, fileStorageRootId } = props;

  useAbstractStorage(fileInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: fileId,
    },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnChangeParams: { id: fileId },
  });

  return (
    <Layout.Content justifyCenter>
      <Layout.Content column middle>
        <UiSpace size={20} direction="vertical">
          <FileInfoCard fileInfoStorage={fileInfoStorage} fileStorageRootId={fileStorageRootId} />
          <FileVersions id={fileId} fileInfoStorage={fileInfoStorage} />
        </UiSpace>
      </Layout.Content>

      <Layout.Sidebar maxColumn={2} shiftTop={false}>
        <FileInfoCardGeneral fileInfoStorage={fileInfoStorage} id={fileId} />
        <FileActions fileInfoStorage={fileInfoStorage} />
        <FileHistory id={fileId} />
      </Layout.Sidebar>
    </Layout.Content>
  );
};
