import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowFrontSvg from 'ant/components/svg/arrow-front.svg';
import HintSvg from 'ant/components/svg/hint.svg';
import PollSvg from 'ant/components/svg/poll.svg';
import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { ContentSurveyModel, NotificationModel } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<ContentSurveyModel>;
}

export const NotificationSurvey: FC<Props> = (props) => {
  const { notification } = props;
  const {
    content,
    image,
    createdAt,
    initiator: { fullName, keycloakId },
    service: serviceTitle,
  } = notification;
  const { title, surveyType, questionsCount, link } = content;

  const navigate = useNavigate();

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const onOpenLink = () => navigate(link);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />

      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          {/* TODO: Разобраться куда должна вести ссылка */}
          <UiTypography.Link strong>
            <UiTruncateMarkup truncate lines={2}>
              {title}
            </UiTruncateMarkup>
          </UiTypography.Link>
          <UiSpace size={16} full>
            <UiIcon.Label component={PollSvg} label={surveyType} />
            <UiIcon.Label component={HintSvg} label={questionsCount} />
          </UiSpace>
          {/* TODO Обработать логику кнопки "Пройти опрос" когда готов функционал  */}
          <UiButton
            onClick={onOpenLink}
            label="Пройти опрос"
            type="primary"
            icon={<UiIcon component={ArrowFrontSvg} width={12} height={12} />}
          />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
