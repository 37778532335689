import React from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { Post } from 'ant/components/widgets/Post';

export const LongreadLoading: React.FC = () => (
  <UiSpace size={24} direction="vertical" full style={{ padding: '32px 48px 16px' }}>
    <Post.Header.Skeleton />
    <UiSkeleton loading height={120} />
    <UiSkeleton loading height={200} />
  </UiSpace>
);
