import React, { useMemo, HTMLAttributes } from 'react';

import { getCommentListStorage } from 'ant/store/comments';
import { PostModel } from 'ant/types/models/post';
import { CommentListWithInput } from 'components-frontend/components/comment/comment-list-with-input';
import { getCommentsContentTypeByPost } from 'components-frontend/components/comment/helpers';

interface Props extends Pick<HTMLAttributes<HTMLElement>, 'style'> {
  post: PostModel;
  containerClassName?: string;
  onCommentsCountUpdate: (countDiff: number) => void;
  showInput?: boolean;
}

export const LongreadComments: React.FC<Props> = ({
  post,
  containerClassName,
  onCommentsCountUpdate,
  showInput = true,
  style,
}) => {
  const commentsStorage = useMemo(getCommentListStorage, []);

  return (
    <CommentListWithInput
      objectId={post.id}
      contentType={getCommentsContentTypeByPost(post)}
      commentsStorage={commentsStorage}
      containerClassName={containerClassName}
      onCommentsCountUpdate={onCommentsCountUpdate}
      showInput={showInput}
      placeholderText="Оставить комментарий"
      style={style}
    />
  );
};
