import { useStoreMap } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import { UiForm } from 'ant/components/ui/form';
import { UiInput } from 'ant/components/ui/input';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { TagsField } from 'ant/components/widgets/TagsField';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { MAX_LENGTH_INPUT_255, MAX_LENGTH_INPUT_80 } from 'ant/helpers/validation';
import { generateMultiSelectOptions } from 'ant/plugins/utils/options-helper';
import { getFileStorageCategoriesStorage } from 'ant/store/filestorage';
import { FileStorageEntryId } from 'ant/types/models/file.model';
import { MultiSelectField } from 'components-frontend/components/multiselect-field/MultiSelectField';

type Props = {
  parent: FileStorageEntryId;
};

export const FileStorageCreateUpdateFields: FC<PropsWithChildren<Props>> = (props) => {
  const { children, parent } = props;

  const fileStorageCategoriesStorage = useMemo(getFileStorageCategoriesStorage, []);

  useAbstractStorage(fileStorageCategoriesStorage.storage, {
    autoFetchParams: { entryId: parent },
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const categoriesOptions = useStoreMap(fileStorageCategoriesStorage.storage.store, ({ data }) =>
    generateMultiSelectOptions(data, { valuePath: 'id', labelPath: 'name' }),
  );

  return (
    <>
      <UiForm.Item name="name" label="Название">
        <UiInput placeholder="Введите название" maxLength={MAX_LENGTH_INPUT_80} />
      </UiForm.Item>
      {children}
      <UiForm.Item name="description" label="Описание">
        <UiInput.TextArea placeholder="Введите описание" maxLength={MAX_LENGTH_INPUT_255} />
      </UiForm.Item>
      <UiForm.Item name="tags" label="Теги">
        <TagsField />
      </UiForm.Item>
      <UiRender type={UiRenderType.DisplayNone} visible={Boolean(categoriesOptions.length)}>
        <UiForm.Item name="categories" shouldUpdate label="Категории">
          <MultiSelectField name="categories" options={categoriesOptions} />
        </UiForm.Item>
      </UiRender>
    </>
  );
};
