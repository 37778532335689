import React, { FC } from 'react';

import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { PostProfileEntryModel } from 'ant/types/models/post';
import { SingleSkill, Skills } from 'components-frontend/components/skills/Skills';

interface Props {
  post: PostProfileEntryModel;
}

export const PostSkill: FC<Props> = (props) => {
  const { post } = props;
  const singleSkill: SingleSkill = {
    userId: post.owner.keycloakUser.keycloakId,
    skillId: post.extraInfo.id,
  };

  return (
    <UiSpace size={12} direction="vertical">
      <UiTypography.Title level={2}>{post.extraInfo.name}</UiTypography.Title>
      <UiTypography.Text>{post.text}</UiTypography.Text>
      <Skills skillType={post.entityType} singleSkill={singleSkill} />
    </UiSpace>
  );
};
