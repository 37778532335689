import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { fileStorageContextRouteMap } from 'ant/store/filestorage/constants';
import { getFileStorageRouteParams } from 'ant/store/filestorage/helpers';
import { isCommentContentType, isCommentSomeOfContentTypes } from 'ant/types/guards/comment';
import { CMSPageModel, CMSViewType } from 'ant/types/models/cms.model';
import {
  CommentDetailListModel,
  CommentContentTypes,
  CommentAlbumImageEntryModel,
  CommentFileVersionEntryModel,
} from 'ant/types/models/comment.model';
import { FileStorageListEntryModel } from 'ant/types/models/file.model';
import { NewsArticle } from 'ant/types/models/news.model';
import { PostBlogModel, MicropostModel } from 'ant/types/models/post';
import { ProjectModel, ProjectsTasksModel } from 'ant/types/models/projects';
import { UserProfileModel } from 'ant/types/models/user.model';

const getFileRoutePath = (file: FileStorageListEntryModel) => {
  if (!file.context) {
    return '';
  }

  return getRoutePath(fileStorageContextRouteMap[file.context.service], {
    ...getFileStorageRouteParams(file),
    fileId: file.id,
  });
};

type CommentLinkInfo = {
  title?: string;
  to?: string;
};

export const getCommentLinkInfo = (comment: CommentDetailListModel): CommentLinkInfo => {
  if (isCommentContentType<PostBlogModel>(comment, CommentContentTypes.Post)) {
    return { title: comment.data.title };
  }

  if (isCommentContentType<MicropostModel>(comment, CommentContentTypes.Micropost)) {
    return { title: comment.data.title };
  }

  if (isCommentContentType<NewsArticle>(comment, CommentContentTypes.NewsEntry)) {
    return { title: comment.data.title };
  }

  if (isCommentContentType<CMSPageModel>(comment, CommentContentTypes.Cms)) {
    return {
      title: comment.data.name,
      to: getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: comment.data.url }),
    };
  }

  if (
    isCommentSomeOfContentTypes<UserProfileModel>(comment, [
      CommentContentTypes.Gamification,
      CommentContentTypes.ProfileEntry,
    ])
  ) {
    return {
      title: getFullNameWithoutPatronymic(comment.data.fullName),
      to: getRoutePath(RouteNames.Profile, { id: comment.data.id }),
    };
  }

  if (isCommentContentType<CommentAlbumImageEntryModel>(comment, CommentContentTypes.AlbumImage)) {
    return {
      title: comment.data.blog.name,
      to: getRoutePath(RouteNames.GroupView, { id: comment.data.blog.slug || comment.data.blog.id }),
    };
  }

  if (isCommentContentType<ProjectModel>(comment, CommentContentTypes.Project)) {
    return { title: comment.data.title, to: getRoutePath(RouteNames.ProjectsView, { id: comment.data.id }) };
  }

  if (isCommentContentType<ProjectsTasksModel>(comment, CommentContentTypes.Task)) {
    return { title: comment.data.title, to: getRoutePath(RouteNames.Tasks) };
  }

  if (isCommentContentType<FileStorageListEntryModel>(comment, CommentContentTypes.File)) {
    return {
      title: comment.data.name,
      to: getFileRoutePath(comment.data),
    };
  }

  if (isCommentContentType<CommentFileVersionEntryModel>(comment, CommentContentTypes.FileVersion)) {
    return {
      title: comment.data.file.name,
      to: getFileRoutePath(comment.data.file),
    };
  }

  return {};
};
