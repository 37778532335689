import { AxiosError } from 'axios';
import { createEffect, createEvent, sample } from 'effector';

import { endpoints } from 'ant/endpoints';
import {
  AbstractStorageConfiguration,
  abstractStorageFactory,
} from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { stringifyEntityText } from 'ant/plugins/utils/markup-content';
import { DictPaginated, PaginatedListResults, PaginatedNextResults, PaginationParams } from 'ant/types/api';
import { isPaginatedListResults, isPaginatedNextResults } from 'ant/types/guards/pagination';
import { isBlogPost, isMicropost, isNewsPost } from 'ant/types/guards/post';
import { PostBasicModel, PostModel, PostStatuses } from 'ant/types/models/post';
import {
  TimelineBlogEntryContentModel,
  TimelineContent,
  TimelineMicropostContentModel,
  TimelineModel,
  TimelineNewsContentModel,
  TimelineRecordModel,
  TimelineSettingsAllModel,
  TimelineTypes,
} from 'ant/types/models/timelines.model';
import {
  ApprovePostParams,
  attachMultipleAttachmentsToPost,
  createPost,
  CreatePostParams,
  deletePost,
  DeletePostParams,
  deprecatedCreatePost,
  DeprecatedCreatePostParams,
  DeprecatedUpdatePostParams,
  fetchPost,
  FetchPostParams,
  getSinglePostEndpoint,
  reactOnNews,
  ReactOnNewsParams,
  reactOnPost,
  ReactOnPostParams,
  switchFavoritePost,
  SwitchFavoritePostParams,
  switchPinPost,
  SwitchPinPostParams,
  UpdateCommentsCountEventParams,
  updatePost,
  UpdatePostParams,
  updatePostsSettings,
} from 'components-frontend/store/post/api';
import { isTimelineRecordModelSomeOfTypes } from 'components-frontend/typings/guards/timeline';

type SwitchFavoritePostEvent = {
  updatedPost: PostBasicModel;
};

const updatePostsFavoriteEvent = createEvent<SwitchFavoritePostEvent>();

export const createPostEffect = createEffect<CreatePostParams, PostModel, AxiosError>(async (params) => {
  const { data: post } = await createPost<PostModel>(params);

  return post;
});

export const updatePostEffect = createEffect<UpdatePostParams, PostModel, AxiosError>(async (params) => {
  const { data: post } = await updatePost<UpdatePostParams, PostModel>(params);

  return post;
});

export const deletePostEffect = createEffect<DeletePostParams, unknown, AxiosError>((params) =>
  deletePost(params),
);

// TODO: Вытащить News в отдельную фабрику?
export const getSinglePostStorage = <T extends PostModel>() => {
  const storage = abstractStorageFactory<T, T, null, FetchPostParams>({
    endpointBuilder: getSinglePostEndpoint,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    // TODO: решить, должны ли приходить пермишены для новостей с бекенда
    dataMapper: (post) => (isNewsPost(post) ? { ...post, permissions: [] } : post),
  });
  const { refetchWithLastParams, store } = storage;

  const reactOnPostEffect = createEffect<ReactOnPostParams, unknown, AxiosError>((params) =>
    reactOnPost(params),
  );
  const reactOnNewsEffect = createEffect<ReactOnNewsParams, unknown, AxiosError>((params) =>
    reactOnNews(params),
  );

  /**
   * @deprecated Используй updatePostEffect
   */
  const deprecatedUpdatePostEffect = createEffect<DeprecatedUpdatePostParams, void, AxiosError>((params) => {
    const { text, textType, ...restParams } = params;
    const textData = text && textType ? { text: stringifyEntityText(textType, text) } : {};
    const updateData = {
      ...textData,
      ...restParams,
    };

    return updatePost(updateData)
      .then(() =>
        attachMultipleAttachmentsToPost({
          attachments: restParams.entitiesToAttach,
          postType: restParams.postType,
          postId: restParams.postId,
        }),
      )
      .then(refetchWithLastParams);
  });

  const switchFavoritePostEffect = createEffect<SwitchFavoritePostParams, unknown, AxiosError>((params) =>
    switchFavoritePost(params),
  );

  const updateCommentsCountEvent = createEvent<number>();

  store
    .on(updatePostEffect.done, () => {
      storage.refetchWithLastParams();
    })
    .on(updateCommentsCountEvent, (state, commentsCount) => {
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              commentsCount,
            }
          : state.data,
      };
    })
    .on(switchFavoritePostEffect.done, (state, { params: { favorite } }) => {
      if (state.data && (isBlogPost(state.data) || isMicropost(state.data))) {
        updatePostsFavoriteEvent({ updatedPost: { ...state.data, favorite } });
      }

      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              favorite,
            }
          : state.data,
      };
    });

  return {
    storage,
    reactOnPostEffect,
    reactOnNewsEffect,
    deprecatedUpdatePostEffect,
    switchFavoritePostEffect,
    updateCommentsCountEvent,
  };
};

export enum TagsTypes {
  Story = 'story',
}

export type FetchPostsParams = {
  userId?: string;
  pinnedOnly?: boolean;
  myEntries?: boolean;
  flag?: PostStatuses;
  user?: string;
  tags?: TagsTypes;
} & Partial<PaginationParams>;

type GetPostsResponse =
  | PaginatedListResults<PostModel>
  | DictPaginated<PostModel>
  | PaginatedNextResults<TimelineModel>;
type GetPostsStorage = AbstractStorageConfiguration<
  GetPostsResponse,
  PostModel[],
  PostModel[],
  FetchPostsParams
>;

export type PostTimelineModel = PostModel | TimelineRecordModel;

export interface GetPostsStorageParams extends Pick<GetPostsStorage, 'shouldAppendData' | 'dataMapper'> {
  baseEndpoint: string;
  baseParams?: FetchPostsParams;
  shouldAddRemovePostsOnFavoriteChange?: boolean;
  // TODO: Убрать после полного перехода на сервис /timelines
  shouldResetOnPostCreate?: boolean;
}

// TODO: Привести пагинацию к одному виду, к виду DictPaginated
export const getPostsStorage = (storageParams: GetPostsStorageParams) => {
  const {
    baseEndpoint,
    baseParams,
    shouldAppendData = false,
    shouldAddRemovePostsOnFavoriteChange = false,
    // TODO: Убрать после полного перехода на сервис /timelines
    shouldResetOnPostCreate = false,
  } = storageParams;

  const storage = abstractStorageFactory<
    GetPostsResponse,
    PostTimelineModel[],
    PostTimelineModel[],
    FetchPostsParams
  >({
    defaultValue: [],
    shouldAppendData,
    cancelPendingRequestOnFetch: true,
    endpointBuilder: (params) => buildEndpointWithQueryParams(baseEndpoint, { ...baseParams, ...params }),
    paginationInfoRetriever: (paginatedData) => {
      if (isPaginatedNextResults(paginatedData)) {
        const { meta } = paginatedData;

        return { next: meta.next };
      }

      return {
        count: paginatedData.meta?.objectsTotal,
        pageSize: paginatedData.meta?.objectsCount,
        page: paginatedData.meta?.pageNumber,
      };
    },
    dataMapper: (data) => {
      if (isPaginatedNextResults(data)) {
        const items: PostTimelineModel[] = [];
        const availableTimelineRecordTypes: TimelineTypes[] = [
          TimelineTypes.ThanksCreated,
          TimelineTypes.BadgeManualCreated,
          TimelineTypes.BadgeAutoCreated,
          TimelineTypes.SkillApproved,
          TimelineTypes.CompetenceApproved,
          TimelineTypes.UserStructureUpdated,
          TimelineTypes.CommentCreatedNews,
          TimelineTypes.CommentCreatedMicropost,
          TimelineTypes.CommentCreatedEntry,
          TimelineTypes.CommentCreatedThanks,
          TimelineTypes.CommentCreatedUserBadge,
          TimelineTypes.CommentCreatedFileVersion,
          TimelineTypes.CommentCreatedCms,
          TimelineTypes.CommentCreatedAlbumImage,
          TimelineTypes.CommentCreatedTask,
          TimelineTypes.CommentCreatedRecord,
          TimelineTypes.CommentReplyNews,
          TimelineTypes.CommentReplyMicropost,
          TimelineTypes.CommentReplyEntry,
          TimelineTypes.CommentReplyThanks,
          TimelineTypes.CommentReplyUserBadge,
          TimelineTypes.CommentReplyFileVersion,
          TimelineTypes.CommentReplyCms,
          TimelineTypes.CommentReplyAlbumImage,
          TimelineTypes.CommentReplyTask,
          TimelineTypes.CommentReplyRecord,
        ];

        data.results.forEach(({ recordsGroup = [] }) => {
          recordsGroup.forEach((record) => {
            const { id, actor, target, createdAt, publishedAt } = record;
            const baseRecord = { id, actor, target, createdAt, publishedAt };

            if (
              isTimelineRecordModelSomeOfTypes<TimelineBlogEntryContentModel>(record, [
                TimelineTypes.BlogEntryCreated,
              ])
            ) {
              items.push(record.content.entry);
            }

            if (
              isTimelineRecordModelSomeOfTypes<TimelineMicropostContentModel>(record, [
                TimelineTypes.MicropostCreated,
              ])
            ) {
              items.push(record.content.micropost);
            }

            if (
              isTimelineRecordModelSomeOfTypes<TimelineNewsContentModel>(record, [TimelineTypes.NewsPinned])
            ) {
              items.push(record.content.news);
            }

            if (isTimelineRecordModelSomeOfTypes<TimelineContent>(record, availableTimelineRecordTypes)) {
              items.push({ type: record.type, ...baseRecord, content: record.content });
            }
          });
        });

        return items;
      }

      if (isPaginatedListResults(data)) {
        return data.results;
      }

      return data.items;
    },
  });

  const { store, refetchWithLastParams } = storage;

  const resetAndRefetchEvent = createEvent();

  const refetchSinglePostEffect = createEffect<FetchPostParams, PostModel, AxiosError>((params) =>
    fetchPost(params).then(({ data }) => data),
  );

  const reactOnPostEffect = createEffect<ReactOnPostParams, unknown, AxiosError>((params) =>
    reactOnPost(params),
  );

  /**
   * @deprecated Используй createPostEffect
   */
  const deprecatedCreatePostEffect = createEffect<DeprecatedCreatePostParams, void, AxiosError>((params) =>
    deprecatedCreatePost(params)
      .then(({ data: { id } }) => {
        return attachMultipleAttachmentsToPost({
          attachments: params.entitiesToAttach,
          postType: params.postType,
          postId: id,
        });
      })
      .then(refetchWithLastParams),
  );

  /**
   * @deprecated Используй updatePostEffect
   */
  const deprecatedUpdatePostEffect = createEffect<DeprecatedUpdatePostParams, void, AxiosError>((params) => {
    const { text, textType, ...restParams } = params;
    const textData = text && textType ? { text: stringifyEntityText(textType, text) } : { text: null };
    const updateData = {
      ...textData,
      ...restParams,
    };

    return updatePost(updateData)
      .then(() => {
        return attachMultipleAttachmentsToPost({
          attachments: restParams.entitiesToAttach,
          postType: restParams.postType,
          postId: restParams.postId,
        });
      })
      .then(refetchWithLastParams);
  });

  const changePostStatusEffect = createEffect<ApprovePostParams, PostTimelineModel, AxiosError>((params) =>
    updatePost<ApprovePostParams, PostTimelineModel>(params).then(({ data }) => data),
  );

  const switchFavoritePostEffect = createEffect<SwitchFavoritePostParams, unknown, AxiosError>((params) =>
    switchFavoritePost(params),
  );

  const switchPinPostEffect = createEffect<SwitchPinPostParams, unknown, AxiosError>((params) =>
    switchPinPost(params),
  );

  const unpinPinnedPostEffect = createEffect<SwitchPinPostParams, unknown, AxiosError>(({ postId }) =>
    switchPinPost({ postId, isPinned: false }),
  );

  const updateCommentsCountEvent = createEvent<UpdateCommentsCountEventParams>();

  // TODO: Убрать после полного перехода на сервис /timelines
  sample({
    clock: createPostEffect.done,
    source: storage.store,
    target: createEffect(() => {
      if (shouldResetOnPostCreate) {
        const params = storage.getLastRequestParams();

        storage.resetStoreEvent();
        storage.fetchEffect({ ...params, pageNumber: 1 });
      }
    }),
  });

  store
    .on(deletePostEffect.done, (state, { params: { postId } }) => ({
      ...state,
      data: state.data.filter(({ id }) => id !== postId),
    }))
    .on(changePostStatusEffect.done, (state, { result }) => ({
      ...state,
      data: state.data.map((post) => {
        return post.id === result.id ? result : post;
      }),
    }))
    .on(updatePostEffect.done, (state, { result: post }) => {
      const index = state.data.findIndex(({ id }) => id === post.id);
      const data = [...state.data];

      if (index >= 0) {
        data.splice(index, 1, post);
      }

      return { ...state, data };
    })
    .on(refetchSinglePostEffect.done, (state, { params: { postId, postType }, result: newPost }) => ({
      ...state,
      data: state.data.map((post) => (post.id === postId && post.type === postType ? newPost : post)),
    }))
    .on(updateCommentsCountEvent, (state, { postId, postType, commentsCount }) => ({
      ...state,
      data: state.data.map((post) =>
        post.id === postId &&
        post.type === postType &&
        !isNewsPost(post) &&
        post.commentsCount !== commentsCount
          ? { ...post, commentsCount }
          : post,
      ),
    }))
    .on(switchFavoritePostEffect.done, (state, { params: { postId, favorite } }) => {
      if (shouldAddRemovePostsOnFavoriteChange && !favorite) {
        return {
          ...state,
          data: state.data.filter((post) => post.id !== postId),
        };
      }

      return {
        ...state,
        data: state.data.map((post) => (post.id === postId ? { ...post, favorite } : post)),
      };
    })
    .on(switchPinPostEffect.done, (state, { params: { postId, isPinned } }) => {
      if (storageParams?.baseParams?.pinnedOnly && !isPinned) {
        return {
          ...state,
          data: state.data.filter((post) => postId !== post.id),
        };
      }

      return {
        ...state,
        data: state.data.map((post) => (post.id === postId ? { ...post, isPinned } : post)),
      };
    })
    .on(unpinPinnedPostEffect.done, (state, { params: { postId } }) => {
      if (storageParams?.baseParams?.pinnedOnly) {
        return {
          ...state,
          data: state.data.filter((post) => postId !== post.id),
        };
      }

      return state;
    })
    .on(updatePostsFavoriteEvent, (state, { updatedPost }) => {
      if (shouldAddRemovePostsOnFavoriteChange) {
        if (updatedPost.favorite) {
          return {
            ...state,
            data: [updatedPost, ...state.data],
          };
        }

        return {
          ...state,
          data: state.data.filter((post) => post.id !== updatedPost.id),
        };
      }

      return {
        ...state,
        data: state.data.map((post) =>
          post.id === updatedPost.id ? { ...post, favorite: updatedPost.favorite } : post,
        ),
      };
    })
    .on(resetAndRefetchEvent, () => {
      const params = storage.getLastRequestParams();

      storage.resetStoreEvent();
      storage.fetchEffect({ ...params, pageNumber: 1 });
    });

  return {
    storage,
    refetchSinglePostEffect,
    reactOnPostEffect,
    deprecatedCreatePostEffect,
    deprecatedUpdatePostEffect,
    switchFavoritePostEffect,
    switchPinPostEffect,
    unpinPinnedPostEffect,
    changePostStatusEffect,
    updateCommentsCountEvent,
    resetAndRefetchEvent,
  };
};

export type PostsStorage = ReturnType<typeof getPostsStorage>;

export const updatePostsSettingsEffect = createEffect<
  TimelineSettingsAllModel,
  TimelineSettingsAllModel,
  AxiosError
>((params) => updatePostsSettings(params).then(({ data }) => data));

export const getPostsSettingsStorage = () => {
  const storage = abstractStorageFactory<TimelineSettingsAllModel, TimelineSettingsAllModel, null>({
    endpointBuilder: endpoints.timelines.settingsAll,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type PostsSettingsStorage = ReturnType<typeof getPostsSettingsStorage>;
