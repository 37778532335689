import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import Appreaction3Svg from 'ant/components/svg/appreaction-3.svg';
import CoinSvg from 'ant/components/svg/coin.svg';
import UserBlockedAvatarPlaceholderSvg from 'ant/components/svg/user-blocked-avatar-placeholder.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { themePalette } from 'ant/theme';
import { GamificationAddedThank, GamificationBadges } from 'ant/types/models/gamification.model';
import { GamificationItem } from 'components-frontend/components/gamification/item/GamificationItem';

import styles from './GamificationTrophy.scss';

export enum GamificationPostPropKey {
  Thank = 'thank',
  Badge = 'badge',
}

type ThankProps = { [GamificationPostPropKey.Thank]: GamificationAddedThank };
type BadgeProps = { [GamificationPostPropKey.Badge]: GamificationBadges };

export type GamificationThankPostProps = (ThankProps | BadgeProps) & {
  onClose?: () => void;
};

export const GamificationTrophy: FC<GamificationThankPostProps> = (props) => {
  const { onClose } = props;
  const GamificationTrophyAvatar: FC<PropsWithChildren> = (gamificationProps) => {
    const { children } = gamificationProps;
    let content = children;

    if (GamificationPostPropKey.Thank in props) {
      const { thank } = props;
      const isShowItemCoins = Boolean(thank.coins);

      const getAvatarProps = (src: string, condition: boolean) =>
        condition
          ? {
              size: 80,
              disabled: true,
              icon: <UserBlockedAvatarPlaceholderSvg />,
            }
          : {
              src,
              size: 80,
            };

      const fromUserAvatarProps = getAvatarProps(thank.fromUser.avatar, thank.fromUser.isActive === false);
      const toUserAvatarProps = getAvatarProps(thank.toUser.avatar, thank.toUser.isActive === false);

      content = (
        <>
          <UiRow justify="space-between" align="middle" style={{ width: 248, height: 120 }}>
            <Link to={getRoutePath(RouteNames.Profile, { id: thank.fromUser.id })} onClick={onClose}>
              <UiAvatar {...fromUserAvatarProps} />
            </Link>
            <UiAvatar
              src={thank.attributes.image}
              size={120}
              icon={<UiIcon component={Appreaction3Svg} height={60} width={60} />}
              className={styles.gamificationTrophy}
            />
            <Link to={getRoutePath(RouteNames.Profile, { id: thank.toUser.id })} onClick={onClose}>
              <UiAvatar {...toUserAvatarProps} />
            </Link>
          </UiRow>
          {isShowItemCoins && (
            <UiIcon.Label
              component={CoinSvg}
              label={thank.coins.toString()}
              color={themePalette.colorNegative}
              type={UiTypographyTextTypes.Secondary}
            />
          )}
          {children}
        </>
      );
    }

    if (GamificationPostPropKey.Badge in props) {
      const { badge } = props;

      content = (
        <>
          <GamificationItem
            badge={getFormattedDate(badge.awardedAt)}
            image={badge.badge.image}
            icon={Appreaction3Svg}
            avatarIconSize={72}
            size={120}
          />
          {children}
        </>
      );
    }

    return (
      <UiSpace size={16} direction="vertical" align="center" style={{ textAlign: 'center' }}>
        {content}
      </UiSpace>
    );
  };

  let title;
  let text;

  if (GamificationPostPropKey.Badge in props) {
    const { badge: postBadge } = props;

    title = postBadge.badge.name;
    text = postBadge.badge.description;
  }

  if (GamificationPostPropKey.Thank in props) {
    const { thank } = props;

    title = thank.attributes?.name;
    text = thank.msg;
  }

  return (
    <GamificationTrophyAvatar>
      <UiSpace size={8} direction="vertical" align="center">
        <UiTypography.Title level={2} style={{ margin: 0 }}>
          {title}
        </UiTypography.Title>
        <UiTypography.Text>{text}</UiTypography.Text>
      </UiSpace>
    </GamificationTrophyAvatar>
  );
};
