// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9d83bac5_skills-users-modal__section{padding:24px;padding-bottom:20px}._9d83bac5_skills-users-modal__suffixed-badge{padding-left:8px;padding-right:24px}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/skills/modals/skill-users/SkillsUsersModal.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AAEE,uCACE,YCFU,CDGV,mBAAA,CAGF,8CACE,gBAAA,CACA,kBCRU","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.skills-users-modal {\n  &__section {\n    padding: $baseSpacing;\n    padding-bottom: 20px;\n  }\n\n  &__suffixed-badge {\n    padding-left: 8px;\n    padding-right: $baseSpacing;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skills-users-modal__section": "_9d83bac5_skills-users-modal__section",
	"skillsUsersModal__section": "_9d83bac5_skills-users-modal__section",
	"skills-users-modal__suffixed-badge": "_9d83bac5_skills-users-modal__suffixed-badge",
	"skillsUsersModal__suffixedBadge": "_9d83bac5_skills-users-modal__suffixed-badge"
};
export default ___CSS_LOADER_EXPORT___;
