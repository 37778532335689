import React, { FC } from 'react';

import { UiUploadListItemRender } from 'ant/components/ui/upload';
import { UiUploadFileType, UiUploadFileList } from 'ant/components/ui/upload-file-list';

interface Props {
  itemRenderParams: Parameters<UiUploadListItemRender>;
  type?: UiUploadFileType;
}

export const UploadsFormListItem: FC<Props> = (props) => {
  const { itemRenderParams, ...uploadItemProps } = props;
  const [_, file, __, actions] = itemRenderParams;

  return <UiUploadFileList.Item file={file} onDelete={actions.remove} {...uploadItemProps} />;
};
