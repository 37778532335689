import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { UiEmpty } from 'ant/components/ui/empty';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { CommentListStorage } from 'ant/store/comments';
import { CommentContentTypes, CommentId, CommentObjectId } from 'ant/types/models/comment.model';
import { ReactionId } from 'ant/types/models/reactions.model';

import { CommentList, CommentListProps } from '../comment-list';
import { CommentListStandaloneLoading } from './loading/CommentListStandaloneLoading';

export interface CommentListStandaloneProps extends Partial<Pick<CommentListProps, 'maxNestLevelWithShift'>> {
  objectId: CommentObjectId;
  contentType: CommentContentTypes;
  storage: CommentListStorage;
  onComment?: (objectId: CommentObjectId) => void;
}

export const CommentListStandalone: FC<CommentListStandaloneProps> = (props) => {
  const { storage, objectId, contentType, maxNestLevelWithShift = 5, onComment: onCommentSuccess } = props;

  const {
    storage: commentListStorage,
    updateCommentEffect,
    deleteCommentEffect,
    reactOnCommentEffect,
    createCommentEffect,
  } = storage;

  const isCreateCommentPending = useStore(createCommentEffect.pending);
  const isUpdateCommentPending = useStore(updateCommentEffect.pending);
  const isDeleteCommentPending = useStore(deleteCommentEffect.pending);
  const isCommentActionPending = isCreateCommentPending || isUpdateCommentPending || isDeleteCommentPending;

  const {
    data: commentsData,
    loading: isCommentsLoading,
    error: commentsError,
  } = useAbstractStorage(commentListStorage, {
    resetStoreOnUnmount: true,
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    autoFetchParams: { contentType, objectId },
  });

  const onComment = (text: string, parentId: CommentId | null) =>
    createCommentEffect({ text, parentId, objectId, contentType })
      .then(commentListStorage.refetchWithLastParams)
      .then(() => onCommentSuccess?.(objectId));

  const onUpdateComment = (commentId: CommentId, text: string) =>
    updateCommentEffect({ commentId, text, objectId, contentType });

  const onDeleteComment = (commentId: CommentId) => deleteCommentEffect({ commentId });

  const onReaction = (commentId: CommentId, reactionId: ReactionId, isLike?: boolean) =>
    reactOnCommentEffect({ commentId, reactionId, isLike });

  const onActualSend = (text: string, parentId: CommentId | null = null) => onComment(text, parentId);

  const isDataExist = commentsData.length > 0;
  const isLoading = isCommentsLoading && !isDataExist;
  const isEmpty = !isDataExist && !isLoading;

  if (commentsError) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Ошибка получения комментариев' }} />;
  }

  return (
    <>
      {isLoading && <CommentListStandaloneLoading />}
      {isDataExist && (
        <CommentList
          maxNestLevelWithShift={maxNestLevelWithShift}
          loading={isCommentActionPending}
          onReaction={onReaction}
          onCommentDelete={onDeleteComment}
          onCommentEdit={onUpdateComment}
          onSend={onActualSend}
          comments={commentsData}
        />
      )}
      {isEmpty && <UiEmpty.Feed emptyMessage={{ header: 'Нет комментариев' }} />}
    </>
  );
};
