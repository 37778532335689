import { EditorValue } from 'ant/components/widgets/Editor';
import { endpoints } from 'ant/endpoints';
import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { api } from 'ant/plugins/api';
import { Endpoints } from 'ant/plugins/endpoints';
import { stringifyEntityText } from 'ant/plugins/utils/markup-content';
import { NewsEndpoints } from 'ant/store/news/endpoints';
import { TagId } from 'ant/types/models/base/base-tag';
import { BlogId } from 'ant/types/models/blog.model';
import { EntityContentType } from 'ant/types/models/entity-text';
import { FileStorageAttachmentEntityModel } from 'ant/types/models/file.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { PostTypes, PostModel, PostSettings, PostId, PostStatuses } from 'ant/types/models/post';
import { PostAttachmentModel, PostAttachmentTypes } from 'ant/types/models/post-attachment';
import { ReactionNewsContentType, ReactionPostContentType } from 'ant/types/models/reactions.model';
import { TimelineSettingsAllModel } from 'ant/types/models/timelines.model';

export type FetchPostParams = { postId: PostId; postType: PostTypes };

/**
 * @deprecated Используй CreatePostParams
 */
export type DeprecatedCreatePostParams = {
  blogId?: BlogId;
  timelineUserId?: string;
  postType: PostTypes;
  text: string | null;
  textType?: EntityContentType;
  title: string;
  tags: (string | number)[];
  entitiesToAttach: PostAttachmentModel[];
  body?: EditorValue;
};

export type CreatePostParams = {
  blogId?: BlogId;
  toUser?: KeycloakId;
  postType: PostTypes;
  title: string;
  tags: TagId[];
  entitiesToAttach: PostAttachmentModel[];
  body?: EditorValue;
  settings?: PostSettings;
};

export type UpdatePostBaseParams = {
  postId: PostId;
  postType: PostTypes;
};

/**
 * @deprecated Используй UpdatePostParams
 */
export interface DeprecatedUpdatePostParams extends UpdatePostBaseParams {
  text: string;
  textType: EntityContentType;
  title: string;
  tags: (string | number)[];
  entitiesToAttach: PostAttachmentModel[];
  settings?: PostSettings;
  body?: EditorValue;
}

export interface UpdatePostParams extends UpdatePostBaseParams {
  title: string;
  tags: TagId[];
  entitiesToAttach: PostAttachmentModel[];
  settings?: PostSettings;
  body?: EditorValue;
}

export interface ApprovePostParams extends UpdatePostBaseParams {
  flag: PostStatuses;
}

export type DeletePostParams = { postId: number; postType: PostTypes; statusComment?: string };

export type ReactOnPostParams = {
  postId: PostId;
  postType: PostTypes;
  isLike: boolean;
  reactionId: string;
};

export type ReactOnNewsParams = {
  objectId: number;
  objectType: ReactionNewsContentType;
  isLike: boolean;
  reactionId: string;
};

export type SwitchFavoritePostParams = { postId: PostId; postType: PostTypes; favorite: boolean };
export type SwitchPinPostParams = { postId: PostId; isPinned: boolean };
export type UpdateCommentsCountEventParams = { postId: PostId; postType: PostTypes; commentsCount: number };
export type UpdateFavoriteResponse = { favorite: boolean };

export type AttachToPostParams = {
  postId: PostId;
  postType: PostTypes;
  attachmentType: PostAttachmentTypes;
  attachedEntityId: number | string;
};

export type AttachMultipleEntitiesParams = {
  postId: PostId;
  postType: PostTypes;
  attachments: PostAttachmentModel[];
};

export type SinglePostEndpointParams = {
  postId: PostId;
  postType: PostTypes;
};

export const getSinglePostEndpoint = ({ postId, postType }: SinglePostEndpointParams) => {
  if (postType === PostTypes.Entry) {
    return BlogsEndpoints.blogEntryId(postId);
  }

  if (postType === PostTypes.Simple || postType === PostTypes.Stories) {
    return NewsEndpoints.article(postId);
  }

  if (postType === PostTypes.GamificationEntry) {
    return Endpoints.gamificationEntry(postId);
  }

  return BlogsEndpoints.micropost(postId);
};

const getCreatePostEndpoint = ({ postType }: { postType: PostTypes }) => {
  if (postType === PostTypes.Entry) {
    return BlogsEndpoints.blogEntryId();
  }

  return BlogsEndpoints.micropost();
};

export const fetchPost = (params: FetchPostParams) =>
  api.get<PostModel>({
    url: getSinglePostEndpoint(params),
  });

/**
 * @deprecated Используй createPost
 */
export const deprecatedCreatePost = (params: DeprecatedCreatePostParams) => {
  const { blogId, postType, timelineUserId: toUser, text, textType, ...data } = params;

  return api.post<{ id: PostId }>({
    url: getCreatePostEndpoint({ postType }),
    data: {
      blogs: blogId ? [blogId] : undefined,
      toUser,
      text: text && textType ? stringifyEntityText(textType, text) : null,
      ...data,
    },
  });
};

export const createPost = <T extends PostModel>(params: CreatePostParams) => {
  const { blogId, postType, ...data } = params;

  return api.post<T>({
    url: getCreatePostEndpoint({ postType }),
    data: {
      blogs: blogId ? [blogId] : undefined,
      // Временная старая реализация к посту так как не готов бэк (B2BCORE-5805)
      text: '',
      ...data,
    },
  });
};

export const updatePost = <T extends UpdatePostBaseParams, D>({ postId, postType, ...data }: T) =>
  api.patch<D>({
    url: getSinglePostEndpoint({ postId, postType }),
    data: {
      ...data,
    },
  });

export const deletePost = ({ postId, postType, statusComment = 'Delete post' }: DeletePostParams) =>
  api.delete({
    url: getSinglePostEndpoint({ postId, postType }),
    data: { statusComment },
  });

export const reactOnPost = ({ postId, postType, reactionId, isLike }: ReactOnPostParams) => {
  const method = isLike ? 'post' : 'delete';

  const contentTypeMap = {
    [PostTypes.Entry]: ReactionPostContentType.Post,
    [PostTypes.Simple]: ReactionPostContentType.Micropost,
    [PostTypes.Stories]: ReactionPostContentType.Micropost,
    [PostTypes.Micropost]: ReactionPostContentType.Micropost,
    [PostTypes.GamificationEntry]: ReactionPostContentType.GamificationEntry,
    [PostTypes.ProfileEntry]: ReactionPostContentType.ProfileEntry,
  };

  return api[method]({
    url: BlogsEndpoints.reactionId(contentTypeMap[postType], postId, reactionId),
  });
};

export const reactOnNews = ({ objectId, objectType, reactionId, isLike }: ReactOnNewsParams) => {
  if (!isLike) {
    return api.delete({
      url: NewsEndpoints.reaction(objectType, objectId),
    });
  }

  return api.post({
    url: NewsEndpoints.reactionSave(),
    data: {
      emojiUuid: reactionId,
      contentType: objectType,
      objectId,
    },
  });
};

export const switchFavoritePost = ({ postId, favorite, postType }: SwitchFavoritePostParams) =>
  api.post<UpdateFavoriteResponse>({
    url: BlogsEndpoints.blogsFavoriteStatus(postType, postId),
    data: { favorite },
  });

export const switchPinPost = ({ postId, isPinned }: SwitchPinPostParams) =>
  api.patch<UpdateFavoriteResponse>({
    url: BlogsEndpoints.blogsChangePinStatus(postId),
    data: { pinStatus: isPinned },
  });

export const attachToPost = ({ postType, attachmentType, attachedEntityId, postId }: AttachToPostParams) =>
  api.post<FileStorageAttachmentEntityModel>({
    url: BlogsEndpoints.blogAttachments(postType, postId),
    data: {
      attachmentType,
      attachmentId: attachedEntityId,
    },
  });

export const attachMultipleAttachmentsToPost = async (params: AttachMultipleEntitiesParams) => {
  const { attachments = [], postType, postId } = params;

  const postAttachments = Promise.all(
    attachments.map(({ attachmentType, attachedEntity }) => {
      return attachToPost({
        postType,
        postId,
        attachmentType,
        attachedEntityId: attachedEntity.id,
      });
    }),
  );

  try {
    await postAttachments;
  } catch (e) {
    throw new Error(e);
  }
};

export const updatePostsSettings = (data: TimelineSettingsAllModel) =>
  api.put<TimelineSettingsAllModel>({
    url: endpoints.timelines.settingsAll(),
    data,
  });
