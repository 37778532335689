import React, { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const MenuLinkItem: FC<PropsWithChildren<LinkProps>> = (props) => {
  const { to, children, ...restProps } = props;

  if (window.location.pathname === to) {
    return <>{children}</>;
  }

  return (
    <Link to={to} {...restProps}>
      {children}
    </Link>
  );
};
