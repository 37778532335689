import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import CheckSVG from 'ant/components/svg/check.svg';
import CommunitySVG from 'ant/components/svg/community.svg';
import DownloadSVG from 'ant/components/svg/download.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiTypography } from 'ant/components/ui/typography';
import { declension, peopleDeclension, voteDeclension } from 'ant/plugins/declension';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { PollOptionModel, PollQuestionModel, PollQuestionTypes } from 'ant/types/models/poll';

import styles from './RespondentsModal.scss';

interface Props {
  isOpen: boolean;
  isAuthor: boolean;
  isSingleQuestionPoll: boolean;
  onClose: () => void;
  question: PollQuestionModel;
  saveToFileXLSX: (questionId: number, optionId: number, name: string) => void;
  uniqueRespondentsCount: number;
}

const RespondentsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  question,
  isAuthor,
  saveToFileXLSX,
  isSingleQuestionPoll,
  uniqueRespondentsCount,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<PollOptionModel>();

  useEffect(() => {
    setSelectedOption(question.options ? question.options[0] : undefined);
  }, [question]);

  const onSaveFile = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!selectedOption) {
      return;
    }

    saveToFileXLSX(question.id, selectedOption.id, String(question.id));
  };

  const onSelect = (option: PollOptionModel) => {
    setSelectedOption(option);
  };

  const isPrimary = (optionId: number) => selectedOption?.id === optionId;

  // todo добавить процент проголосовавших по готовности бэка
  const getVotedRespondentsText = (): string => {
    if (!question.answersCount) {
      return '';
    }

    const answersCount =
      question.type === PollQuestionTypes.SINGLE_CHOICE ? question.answersCount : uniqueRespondentsCount;

    const pronoun = isSingleQuestionPoll ? '' : ' Вы';
    const conjunction = !isSingleQuestionPoll && answersCount > 1 ? ' и' : '';

    return `${declension(answersCount, voteDeclension)}${pronoun}${conjunction} ${answersCount} ${declension(
      answersCount,
      peopleDeclension,
    )}`;
  };

  return (
    <UiModal type={UiModalTypes.Large} isOpen={isOpen} onClose={onClose}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          steps={getModalStepsForSingleTitle(
            isSingleQuestionPoll ? question.text : `${question.number}. ${question.text}`,
          )}
        />
      </UiModal.Header>
      <UiModal.Content>
        <div className={styles.respondentsModal__block}>
          <div className={styles.respondentsModal__buttonsGroup}>
            {question.options?.map((option) => {
              return (
                <UiButton
                  key={option.id}
                  className={classNames(styles.respondentsModal__button, {
                    [styles.respondentsModal__button_inactive]: !isPrimary(option.id),
                  })}
                  type={isPrimary(option.id) ? 'primary' : 'secondary'}
                  icon={
                    option.answeredByMe ? (
                      <UiIcon
                        className={classNames(styles.respondentsModal__icon, {
                          [styles.respondentsModal__icon_inactive]: !isPrimary(option.id),
                        })}
                        component={CheckSVG}
                        height={20}
                        width={20}
                      />
                    ) : null
                  }
                  label={option.option}
                  onClick={() => onSelect(option)}
                />
              );
            })}
          </div>
          <div className={styles.respondentsModal__header}>
            <UiTypography.Text strong type="secondary" className={styles.respondentsModal__text}>
              <UiIcon
                component={CommunitySVG}
                height={20}
                width={20}
                className={styles.respondentsModal__iconCommunity}
              />
              {getVotedRespondentsText()}
            </UiTypography.Text>
            {isAuthor && (
              <UiButton
                type="link"
                label="Сохранить выборку в файл"
                icon={<UiIcon component={DownloadSVG} height={20} width={20} />}
                onClick={onSaveFile}
              />
            )}
          </div>
        </div>
        <div className={styles.respondentsModal__respondents}>
          {/* todo добавить ленивую подгрузку или пагинацию */}
          {selectedOption?.answerRespondents.map((respondent) => {
            return (
              <Link
                to={getRoutePath(RouteNames.Profile, {
                  id: respondent.keycloakUser?.keycloakId || respondent.id,
                })}
                key={respondent.id}
              >
                <div className={styles.respondentsModal__respondent}>
                  <UiAvatar size={40} src={respondent.avatar} />
                  <div className={styles.respondentsModal__info}>
                    {/* todo подставить реальные имя, фамилию и должность по готовности бэка */}
                    <UiTypography.Title level={4} style={{ margin: 0 }}>
                      {`${respondent.firstName} ${respondent.lastName}`}
                    </UiTypography.Title>
                    <UiTypography.Text type="secondary">{respondent.position?.name}</UiTypography.Text>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </UiModal.Content>
    </UiModal>
  );
};

export { RespondentsModal };
