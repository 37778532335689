import React, { useMemo } from 'react';

import { UiCard } from 'ant/components/ui/card';
import { UiEmpty } from 'ant/components/ui/empty';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { WeatherCurrentLoader } from 'components-frontend/components/weather/current/loading/WeatherCurrentLoading';
import WeatherDay from 'components-frontend/components/weather/day/WeatherDay';
import { WeatherLoading } from 'components-frontend/components/weather/loading/WeatherLoading';
import { getWeatherStorage } from 'components-frontend/store/weather';
import { Weather } from 'components-frontend/typings/weather';
import { Widget, WidgetModule } from 'core-components/types';

import styles from './Weather.scss';
import { WeatherCurrent } from './current/WeatherCurrent';
import { WeatherHeader } from './header/WeatherHeader';

export interface Props extends WidgetModule {
  widget: Widget;
}

const MAX_DAYS_COUNT = 4;

const WeatherWidgetComponent: React.FC<Props> = ({ widget }) => {
  const cityId = widget.data?.cityId;
  const cityName = widget.data?.cityName;

  const weatherStorage = useMemo(() => getWeatherStorage({ cityId }), [cityId]);
  const {
    data: weather,
    loading: weatherLoading,
    error: weatherError,
  } = useAbstractStorage(weatherStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const dailyWeather = weather?.daily?.slice(1, MAX_DAYS_COUNT + 1);
  const currentWeather = useMemo(
    () => (!weather?.hourly?.length ? weather?.current : weather?.hourly[0]),
    [weather],
  );
  const isWeatherInfoEmpty = currentWeather === undefined || weatherLoading;

  return (
    <UiCard title={<WeatherHeader locationName={!isWeatherInfoEmpty && cityName} />}>
      {weatherError && <UiEmpty.Feed emptyMessage={{ header: 'Для вашего города нет данных' }} />}
      {!weatherError && weatherLoading && (
        <>
          <WeatherCurrentLoader />
          <div className={styles.weather__daily}>
            <WeatherLoading maxCount={MAX_DAYS_COUNT} />
          </div>
        </>
      )}
      {!weatherError && !weatherLoading && (
        <>
          <WeatherCurrent currentWeather={currentWeather} />
          <div className={styles.weather__daily}>
            {dailyWeather?.map((day) => day && <WeatherDay key={day.date} day={day} />)}
          </div>
        </>
      )}
    </UiCard>
  );
};

const Weather = WeatherWidgetComponent;

export { Weather };
