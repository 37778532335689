import React, { FC, KeyboardEvent } from 'react';

import FlameSvg from 'ant/components/svg/flame.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { UiImageBackground } from 'ant/components/ui/image-background';
import { UiOverlay } from 'ant/components/ui/overlay';
import { UiTag } from 'ant/components/ui/tag';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { NewsArticle, NewsCategoriesDist } from 'ant/types/models/news.model';
import { PostTypes } from 'ant/types/models/post';

import styles from './NewsPinnedItem.scss';

const titleStyle: React.CSSProperties = {
  margin: '12px 0 0',
  color: 'white',
};

interface Props {
  newsArticle: NewsArticle;
  newsCategoriesMap?: NewsCategoriesDist | null;
}

export const NewsPinnedItem: FC<Props> = (props) => {
  const { newsArticle, newsCategoriesMap } = props;
  const { id, categoryId, cover, type, title, important } = newsArticle;
  const newsCategory = categoryId && newsCategoriesMap?.[categoryId];

  const openLongread = () => {
    const newsLongreadParams = { newsId: id, newsType: type || PostTypes.Simple };

    openGlobalModal(GlobalModalNames.NewsLongread, newsLongreadParams, newsLongreadParams);
  };

  const onPreviewKeyPress = ({ key }: KeyboardEvent) => {
    if (key === 'Enter') {
      openLongread();
    }
  };

  return (
    <div
      role="link"
      tabIndex={-1}
      onClick={openLongread}
      onKeyPress={onPreviewKeyPress}
      className={styles.newsPinnedItem}
    >
      <UiOverlay
        content={
          <>
            {important && (
              <UiIcon
                component={FlameSvg}
                width={20}
                height={20}
                className={styles.newsPinnedItem__importantIcon}
              />
            )}

            <div className={styles.newsPinnedItem__header}>
              {newsCategory && (
                <UiTag strong title={newsCategory.name} color={newsCategory.extraInfo.color} />
              )}

              <UiTypography.Title level={3} style={titleStyle}>
                <UiTruncateMarkup lines={3} truncate>
                  {title}
                </UiTruncateMarkup>
              </UiTypography.Title>
            </div>
          </>
        }
      >
        <UiImageBackground className={styles.newsPinnedItem__img} url={cover} />
      </UiOverlay>
    </div>
  );
};
