import React, { FC, useMemo } from 'react';

import { ImageList, ImageListImage } from 'ant/components/ui/image-list/ImageList';
import { FileStorageFileModel } from 'ant/types/models/file.model';
import { PostAttachmentModel, PostFormAttachmentId } from 'ant/types/models/post-attachment';

type Props = {
  attachments: PostAttachmentModel<FileStorageFileModel>[];
  onDelete?: (id: PostFormAttachmentId) => void;
};

export const PostImages: FC<Props> = ({ attachments, onDelete }) => {
  const mappedImages: ImageListImage[] = useMemo(
    () =>
      attachments.map(({ attachedEntity, id }) => ({
        id,
        url: attachedEntity.file,
      })),
    [attachments],
  );

  return <ImageList images={mappedImages} onDelete={onDelete} />;
};
