import classNames from 'classnames';
import React, { FC, useState, useEffect } from 'react';

import ClearSVG from 'ant/components/svg/clear.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiForm } from 'ant/components/ui/form';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUploadOriginFile, UiUpload, UiUploadProps } from 'ant/components/ui/upload';
import { UiUploadFileType } from 'ant/components/ui/upload-file-list';
import { checkFileSizeUploadEffect } from 'ant/helpers/validation/file-size-helper';
import { AddAttachmentParams } from 'ant/store/filestorage';
import { FileId } from 'ant/store/filestorage/api';
import { FileStorageEntryId } from 'ant/types/models/file.model';
import { UiUploadFileAttach } from 'ant/types/uploads-form-item';

import { UploadsFormListItem } from '../list-item/UploadsFormListItem';
import styles from './UploadsFormItem.scss';

const MAX_FILES_COUNT = 10;
const MAX_FILES_TOTAL_SIZE = 10;

export interface UploadsFormItemProps extends UiUploadProps {
  name: string;
  buttonLabel: string;
  formItemLabel?: string;
  uploadFileType: UiUploadFileType;
  fileSizeText?: string;
  maxTotalSize?: number;
  maxCount?: number;
  isBusy?: boolean;
  formItemClassName?: string;
  onAddAttachment?: ({ id, size, name, url }: AddAttachmentParams) => void;
}

const getFileListFromEvent = (e: { fileList: UiUploadFileAttach<FileId | FileStorageEntryId> }) => e.fileList;

const UploadsFormItem: FC<UploadsFormItemProps> = (props) => {
  const {
    name,
    buttonLabel,
    formItemLabel,
    isBusy = false,
    uploadFileType,
    fileSizeText,
    maxTotalSize = MAX_FILES_TOTAL_SIZE,
    maxCount = MAX_FILES_COUNT,
    formItemClassName,
    onAddAttachment,
    multiple = true,
    style,
    ...restProps
  } = props;

  const [maxAmountError, setMaxAmountError] = useState(false);
  const [maxTotalSizeError, setMaxTotalSizeError] = useState(false);

  const clearIcon = <UiIcon component={ClearSVG} width={20} height={20} />;

  useEffect(() => {
    if (maxAmountError) {
      message.error(`Общее количество файлов должно быть не больше ${maxCount}`);
      setMaxAmountError(false);
    }

    if (maxTotalSizeError) {
      message.error(`Общий вес файлов должен быть не больше ${maxTotalSize}Мб`);
      setMaxTotalSizeError(false);
    }
  }, [maxAmountError, maxTotalSizeError]);

  const onBeforeUpload = async (file: UiUploadOriginFile, fileList: UiUploadOriginFile[]) => {
    await checkFileSizeUploadEffect({ file }).catch((e) => {
      message.error(`${file.name}. ${e}`);
      return UiUpload.LIST_IGNORE;
    });

    if (!maxAmountError && maxCount && fileList.length > maxCount) {
      setMaxAmountError(true);
      return UiUpload.LIST_IGNORE;
    }

    if (maxTotalSize) {
      const summarySize = fileList.reduce((acc, currentFile) => acc + currentFile.size, 0);
      const isLessThanMaxSize = summarySize / 1024 / 1024 < maxTotalSize;

      if (!isLessThanMaxSize && !maxTotalSizeError) {
        setMaxTotalSizeError(true);
      }

      return isLessThanMaxSize || UiUpload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <UiForm.Item
      name={name}
      valuePropName="fileList"
      getValueFromEvent={getFileListFromEvent}
      className={classNames(styles.uploadsFormItem, formItemClassName)}
      label={formItemLabel}
      style={style}
    >
      <UiUpload
        multiple={multiple}
        maxCount={maxCount}
        showUploadList={{ showRemoveIcon: true, removeIcon: clearIcon }}
        beforeUpload={onBeforeUpload}
        itemRender={(...params) => <UploadsFormListItem itemRenderParams={params} type={uploadFileType} />}
        {...restProps}
      >
        <UiButton type="link" label={buttonLabel} disabled={isBusy} />
        {fileSizeText && (
          <UiTypography.Footnote type="secondary" style={{ display: 'block', margin: '16px 0' }}>
            {fileSizeText}
          </UiTypography.Footnote>
        )}
      </UiUpload>
    </UiForm.Item>
  );
};

export { UploadsFormItem };
