import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { SearchListItem } from 'ant/types/models/search.model';
import {
  FullNameModel,
  UserAvatarModel,
  Gender,
  PhoneNames,
  BaseJobModel,
  UserProfileModel,
} from 'ant/types/models/user.model';

export interface ProfileMain extends UserAvatarModel {
  id: string;
  externalId?: KeycloakId;
  bossKeycloakId?: KeycloakId;
  fullName: FullNameModel;
  gender?: Gender;
  username: string;
  superAdmin: boolean;
  isActive: boolean;
  job?: BaseJobModel & Pick<UserProfileModel, 'remoteEmployee'>;
  actualAddess?: string;
  mobilePhone?: string;
  workPhone?: PhoneNames.WorkPhone;
  workMessenger?: string;
}

export const searchListResultDataMapper = (searchModel: ProfileMain[]): SearchListItem[] => {
  return searchModel.map((model) => ({
    id: model.id,
    title: getFullNameWithoutPatronymic(model.fullName),
    subTitle: model?.job?.position?.name ?? '',
    image: model?.smallAvatar ?? '',
    isActive: model.isActive,
  }));
};
