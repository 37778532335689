import React, { useState } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';
import { NotificationFilters } from 'ant/types/models/notification.model';
import { SettingsModal } from 'components-frontend/components/notifications-drawer/settings-modal/SettingsModal';

import styles from './DrawerTitle.scss';

type Props = {
  title: string;
  icon: SvgrComponent;
  onChange: (filters?: NotificationFilters) => void;
};

export const DrawerTitle: React.FC<Props> = ({ title, icon, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.drawerTitle}>
      <SettingsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onChange={onChange} />
      <UiTypography.Title level={3} className={styles.drawerTitle__label}>
        {title}
        <UiButton
          type="link-secondary"
          icon={<UiIcon component={icon} width={20} height={20} />}
          onClick={() => setIsModalOpen(true)}
        />
      </UiTypography.Title>
    </div>
  );
};
