import React, { FC } from 'react';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiSpace } from 'ant/components/ui/space';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { retrieveTextFromHtml } from 'ant/plugins/retrieve-text-from-html';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { parseMentionsToPlainText } from 'ant/plugins/utils/markup-content';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { NotificationMicropostEntryModel, NotificationModel } from 'ant/types/models/notification.model';
import { NotificationBlogPostContent } from 'components-frontend/components/notifications-drawer/notification/blog/post/content/NotificationBlogPostContent';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

type Props = {
  notification: NotificationModel<NotificationMicropostEntryModel>;
};

export const NotificationMicropostEnrty: FC<Props> = (props) => {
  const { notification } = props;
  const {
    createdAt,
    content,
    initiator: { fullName, keycloakId },
    image: initiatorAvatar,
  } = notification;
  const { micropost } = content;
  const { body, id, type } = micropost;

  const plainTextContent = retrieveTextFromHtml(body?.data || '');
  const postText = plainTextContent && parseMentionsToPlainText(plainTextContent);

  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const notificationAvatar = { src: initiatorAvatar } satisfies UiAvatarProps;
  const notificationHeaderLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const notificationDescription = getNotificationDescription(notification);

  const onOpenPost = () => openGlobalModal(GlobalModalNames.PostLongread, { postId: id, postType: type });

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={notificationHeaderLink}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={notificationAvatar}
      />
      <Notification.Body>
        <UiSpace size={12} direction="vertical" full>
          <NotificationBlogPostContent visible content={postText} onClick={onOpenPost} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
