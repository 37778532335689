import React, { FC } from 'react';

import { UiRow } from 'ant/components/ui/grid';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { createArrayMock } from 'ant/plugins/create-mocks';

export const FileInfoCardGeneralLoading: FC = () => {
  return (
    <UiSpace size={24} style={{ padding: '0 24px 24px 24px' }} direction="vertical">
      <UiRow gutter={[0, 16]}>
        {createArrayMock(5, (_, index) => (
          <UiSpace key={index}>
            <UiSkeleton loading height={20} width={130} />
            <UiSkeleton loading height={20} width={186} />
          </UiSpace>
        ))}
      </UiRow>
      <UiRow justify="space-between">
        <UiSkeleton loading width={140} height={24} />
        <UiSkeleton loading width={80} height={24} />
      </UiRow>
    </UiSpace>
  );
};
