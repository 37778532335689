import React, { FC } from 'react';

import SkillProSvg from 'ant/components/svg/skill-pro.svg';
import SkillTagSvg from 'ant/components/svg/skill-tag.svg';
import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypographyTextTypes } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { ContentSkillModel, NotificationModel } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<ContentSkillModel>;
}

export const NotificationSkill: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    content,
    initiator: { fullName, keycloakId },
    service: serviceTitle,
  } = notification;
  const { name, approversCount, proveThreshold } = content;
  const IconSvg = approversCount >= proveThreshold ? SkillProSvg : SkillTagSvg;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />

      <Notification.Body>
        <UiRow>
          <UiIcon.Label type={UiTypographyTextTypes.Primary} component={IconSvg} size={32} strong>
            <UiTruncateMarkup truncate>{name}</UiTruncateMarkup>
          </UiIcon.Label>
        </UiRow>
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
