import React from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';

import styles from './NewsPageItemLoading.scss';

const NewsPageItemLoading = () => {
  return (
    <div className={styles.newsPageItemLoading}>
      <div className={styles.newsPageItemLoading__image}>
        <UiSkeleton loading width={300} height={184} />
      </div>
      <div>
        <div className={styles.newsPageItemLoading__date}>
          <UiSkeleton loading width={190} height={20} />
        </div>
        <div className={styles.newsPageItemLoading__title}>
          <UiSkeleton loading width={420} height={20} />
        </div>
        <UiSkeleton loading width={420} height={80} />
        <div className={styles.newsPageItemLoading__reactions}>
          <UiSkeleton loading width={100} height={20} />
        </div>
      </div>
    </div>
  );
};

export { NewsPageItemLoading };
