import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { markNotificationReadEffect } from 'ant/store/notifications';
import { CalendarEventModel } from 'ant/types/models/calendar';
import { EventModel } from 'ant/types/models/event.model';
import { FileStorageListEntryModel } from 'ant/types/models/file.model';
import {
  ContentSkillModel,
  ContentSurveyModel,
  NotificationAlbumImageCommentModel,
  NotificationModel,
  NotificationTypes,
} from 'ant/types/models/notification.model';
import {
  isNotificationOld,
  isNotificationSomeOfTypes,
} from 'components-frontend/typings/guards/notification-guards';

import { NotificationAlbumImageComment } from '../notification/album-image/comment/NotificationAlbumImageComment';
import { NotificationBlog } from '../notification/blog/NotificationBlog';
import { NotificationCalendarEvent } from '../notification/calendar-event/NotificationCalendarEvent';
import { NotificationCms } from '../notification/cms/NotificationCms';
import { NotificationEvent } from '../notification/event/NotificationEvent';
import { NotificationFileStorage } from '../notification/file-storage/NotificationFileStorage';
import { NotificationFile } from '../notification/file/NotificationFile';
import { NotificationGamification } from '../notification/gamification/NotificationGamification';
import { NotificationMicropost } from '../notification/micropost/NotificationMicropost';
import { NotificationNews } from '../notification/news/NotificationNews';
import { NotificationSkill } from '../notification/skill/NotificationSkill';
import { NotificationSurvey } from '../notification/survey/NotificationSurvey';

export const notificationSkills = [
  NotificationTypes.SkillApproved,
  NotificationTypes.CompetenceApproved,
  NotificationTypes.SkillAssignToUser,
  NotificationTypes.CompetenceAssignToUser,
];

export const notificationBlogPost = [
  NotificationTypes.BlogEntryApproved,
  NotificationTypes.BlogEntryDeclined,
  NotificationTypes.BlogEntryRequireModeration,
  NotificationTypes.BlogEntryCreated,
  NotificationTypes.BlogEntryUpdated,
];

export const notificationBlogsInvite = [
  NotificationTypes.BlogUserInvite,
  NotificationTypes.BlogUserInviteExpert,
  NotificationTypes.BlogUserInviteModerator,
];

export const notificationBlogsComment = [
  NotificationTypes.BlogEntryCommentCreated,
  NotificationTypes.BlogEntryCommentReply,
  NotificationTypes.BlogEntryCommentUserMentioned,
  NotificationTypes.BlogEntryCommentReaction,
];

export const notificationBlogs = [
  NotificationTypes.BlogUserInviteRequestClosedBlog,
  NotificationTypes.BlogGroupAdministratorIsBlocked,
  ...notificationBlogsComment,
  ...notificationBlogsInvite,
  ...notificationBlogPost,
];

export const notificationFile = [
  NotificationTypes.BlogGroupFileCreated,
  NotificationTypes.FileVersionCommentCreated,
  NotificationTypes.FileVersionCommentReply,
  NotificationTypes.FileVersionCommentUserMentioned,
  NotificationTypes.FileVersionCommentReaction,
];

export const notificationCms = [
  NotificationTypes.CmsPageUserMentioned,
  NotificationTypes.CmsPageCommentCreated,
  NotificationTypes.CmsPageCommentReply,
  NotificationTypes.CmsPageCommentUserMentioned,
  NotificationTypes.CmsPageCommentReaction,
];

export const notificationEvents = [NotificationTypes.EventsInvite, NotificationTypes.EventsDelete];

export const notificationGamification = [
  NotificationTypes.GamificationNewThanks,
  NotificationTypes.GamificationEventsManualGiveBadges,
];

export const notificationSurvey = [NotificationTypes.SurveyPublished];

export const notificationCalendarGroupEvent = [
  NotificationTypes.CalendarGroupEventAttendeeApproveDecision,
  NotificationTypes.CalendarGroupEventAttendeeRejectDecision,
  NotificationTypes.CalendarGroupEventAttendeeMaybeDecision,
  NotificationTypes.CalendarGroupEventInvite,
  NotificationTypes.CalendarGroupEventUpdated,
  NotificationTypes.CalendarGroupEventDestroyed,
  NotificationTypes.CalendarGroupEventComingSoon,
  NotificationTypes.CalendarGroupEventDeleteAttendee,
];

export const notificationMicropost = [
  NotificationTypes.MicropostCommentCreated,
  NotificationTypes.MicropostCommentUserMentioned,
  NotificationTypes.MicropostCommentReply,
  NotificationTypes.MicropostCommentReaction,
  NotificationTypes.TimelinesSignerMicropostCreated,
];

export const notificationNews = [
  NotificationTypes.NewsCommentCreated,
  NotificationTypes.NewsCommentUserMentioned,
  NotificationTypes.NewsCommentReply,
  NotificationTypes.NewsCommentReaction,
  NotificationTypes.NewsUserMentioned,
];

export const notificationAlbumImage = [
  NotificationTypes.AlbumImageCommentCreated,
  NotificationTypes.AlbumImageCommentUserMentioned,
  NotificationTypes.AlbumImageCommentReply,
  NotificationTypes.AlbumImageCommentReaction,
];

export const notificationFileStorage = [NotificationTypes.FileStorageFileShared];

interface NotificationCardProps {
  notification: NotificationModel;
}

export const NotificationCard: FC<NotificationCardProps> = (props) => {
  const { notification } = props;
  const { content } = notification;
  const isOtherNotification = useMemo(
    () =>
      isNotificationSomeOfTypes(notification, [
        ...notificationSkills,
        ...notificationBlogs,
        ...notificationGamification,
        ...notificationSurvey,
        ...notificationEvents,
        ...notificationCalendarGroupEvent,
        ...notificationCms,
        ...notificationFile,
        ...notificationMicropost,
        ...notificationNews,
        ...notificationFileStorage,
      ]),
    [],
  );

  const isOldNotificationWithContent = Boolean(
    isNotificationOld(notification) && notification.content.description,
  );

  const isValidNotification = isNotificationOld(notification)
    ? isNotificationSomeOfTypes(notification, notificationSkills) || isOldNotificationWithContent
    : content && !isEmpty(content);

  // TODO: B2BCORE-2551 Защита от старых нотификаций с не валидными данными
  if (!isValidNotification) {
    return null;
  }

  return (
    <UiButton.Decorator onClick={() => markNotificationReadEffect(notification.id)}>
      {isNotificationSomeOfTypes<ContentSkillModel>(notification, notificationSkills) && (
        <NotificationSkill notification={notification} />
      )}
      {isNotificationSomeOfTypes<ContentSurveyModel>(notification, notificationSurvey) && (
        <NotificationSurvey notification={notification} />
      )}
      {isNotificationSomeOfTypes<EventModel>(notification, notificationEvents) && (
        <NotificationEvent notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationGamification) && (
        <NotificationGamification notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationBlogs) && (
        <NotificationBlog notification={notification} />
      )}
      {isNotificationSomeOfTypes<CalendarEventModel>(notification, notificationCalendarGroupEvent) && (
        <NotificationCalendarEvent notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationFile) && (
        <NotificationFile notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationCms) && (
        <NotificationCms notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationMicropost) && (
        <NotificationMicropost notification={notification} />
      )}
      {isNotificationSomeOfTypes(notification, notificationNews) && (
        <NotificationNews notification={notification} />
      )}
      {isNotificationSomeOfTypes<NotificationAlbumImageCommentModel>(
        notification,
        notificationAlbumImage,
      ) && <NotificationAlbumImageComment notification={notification} />}
      {isNotificationSomeOfTypes<FileStorageListEntryModel>(notification, notificationFileStorage) && (
        <NotificationFileStorage notification={notification} />
      )}
      {!isOtherNotification && (
        // TODO: B2BCORE-2551 Пока на бэке не будет нужных api будет простой шаблон как у блогов
        <NotificationBlog notification={notification} />
      )}
    </UiButton.Decorator>
  );
};
