import React, { FC } from 'react';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiRow } from 'ant/components/ui/grid';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { LinkContentModel, NotificationModel } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<LinkContentModel>;
}

export const NotificationGamificationThanks: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId },
    service: serviceTitle,
    content: { title },
  } = notification;

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />

      <Notification.Body>
        <UiRow>
          <UiTypography.Text strong>
            <UiTruncateMarkup truncate lines={2}>
              {title}
            </UiTruncateMarkup>
          </UiTypography.Text>
        </UiRow>
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
