import React, { ReactNode } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './CalendarWidgetEmpty.scss';

type Props = {
  addEvent: () => void;
};

const CalendarWidgetEmpty: React.FC<Props> = (props) => {
  const { addEvent } = props;

  const description = 'Встреч ещё не запланировано';

  const AddLink: React.FC<{ children: ReactNode }> = ({ children }) => (
    <UiTypography.Link strong onClick={addEvent}>
      {children}
    </UiTypography.Link>
  );

  return (
    <div className={styles.calendarWidgetEmpty}>
      <UiSkeleton loading={false} count={3} width="100%">
        <UiTypography.Text type="secondary">
          {description}
          <br />
          <AddLink>Добавить встречу</AddLink>
        </UiTypography.Text>
      </UiSkeleton>
    </div>
  );
};

export { CalendarWidgetEmpty };
