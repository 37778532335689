import React from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';

import styles from './SearchContentLoading.scss';

const SearchContentLoading = () => {
  return (
    <div className={styles.searchContentLoading}>
      <div className={styles.searchContentLoading__avatar}>
        <UiSkeleton circle loading width={40} height={40} />
      </div>
      <div className={styles.searchContentLoading__description}>
        <UiSkeleton loading width={120} height={20} />
        <UiSkeleton loading width={240} height={20} className={styles.searchContentLoading__structure} />
        <UiSkeleton loading height={20} />
        <UiSkeleton loading width={240} height={20} className={styles.searchContentLoading__email} />
      </div>
    </div>
  );
};

export { SearchContentLoading };
