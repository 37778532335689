import React from 'react';

import { UiSpace } from 'ant/components/ui/space';
import { UiStatCell, UiStatCellBorderType } from 'ant/components/ui/stat-cell';
import { ActionsDropdown } from 'ant/components/widgets/ActionsDropdown';
import { File } from 'ant/components/widgets/File';
import { FileStorageFileModel } from 'ant/types/models/file.model';
import { PostAttachmentModel, PostFormAttachmentId } from 'ant/types/models/post-attachment';

type Props = {
  attachments: PostAttachmentModel<FileStorageFileModel>[];
  isEdit?: boolean;
  onDelete: (id: PostFormAttachmentId) => void;
};

export const PostFiles: React.FC<Props> = (props) => {
  const { attachments, isEdit, onDelete } = props;

  return (
    <UiStatCell.Frame border={UiStatCellBorderType.Solid}>
      <UiSpace size={12} full direction="vertical">
        {attachments.map((attachment) => {
          const { attachedEntity } = attachment;

          return (
            <File
              key={attachedEntity.id}
              justify="space-between"
              stretch
              fileName={attachedEntity.name}
              file={{ ...attachedEntity, foldersCount: 0, filesCount: 0, parent: '' }}
              actions={
                isEdit
                  ? [
                      <ActionsDropdown
                        key="dropdown"
                        items={[
                          {
                            label: 'Удалить',
                            onClick: () => onDelete(attachment.id),
                          },
                        ]}
                      />,
                    ]
                  : undefined
              }
            />
          );
        })}
      </UiSpace>
    </UiStatCell.Frame>
  );
};
