import classNames from 'classnames';
import React from 'react';

import { MAX_BADGE_COUNT, UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiPagination, UiPaginationProps } from 'ant/components/ui/pagination';
import { UiTypography } from 'ant/components/ui/typography';
import { usePagination } from 'ant/helpers/hooks/use-pagination';
import { SearchCategoryType, SearchSingleCategory } from 'ant/types/models/search.model';
import Arrow from 'components-frontend/components/svg/arrow-front.svg';
import {
  SEARCH_CATEGORY_TITLE,
  SearchCategory,
} from 'components-frontend/store/search/advanced-search/constants';

import styles from './SearchCategoryResults.scss';

interface Props {
  category: SearchSingleCategory;
  searchCategory: SearchCategoryType;
  isAdvanced?: boolean;
  maxCount?: number;
  onSearch?: (category: SearchCategoryType, page?: number, pageSize?: number) => void;
  children?: React.ReactNode;
  pagination?: UiPaginationProps;
}

export const SearchCategoryResults: React.FC<Props> = (props) => {
  const {
    category,
    searchCategory,
    isAdvanced,
    pagination: paginationProp,
    maxCount,
    children,
    onSearch,
  } = props;

  const { pagination } = usePagination({
    total: paginationProp?.total,
    pageSizeOptions: paginationProp?.pageSizeOptions,
    showSizeChanger: paginationProp?.showSizeChanger,
    ...paginationProp,
  });

  const { onChange } = pagination;

  const onChangePagination: UiPaginationProps['onChange'] = (page, pageSize) => {
    onSearch?.(category, page, pageSize);
    onChange(page, pageSize);
  };

  const handleSearch = () => {
    if (onSearch) {
      onSearch(category);
    }
  };

  const shownCount = searchCategory !== SearchCategory.All ? pagination?.total : 0;
  const isMoreRecordsThanMaxCount = maxCount && pagination?.total && pagination.total > maxCount;
  const isShowAllShown = isAdvanced && isMoreRecordsThanMaxCount && searchCategory === SearchCategory.All;

  return (
    <div
      className={classNames(styles.searchCategoryResults, {
        [styles.searchCategoryResults_headerSearch]: !isAdvanced,
      })}
    >
      <div className={styles.searchCategoryResults__title}>
        <UiTypography.Title level={isAdvanced ? 2 : 3} style={{ margin: 0 }}>
          {SEARCH_CATEGORY_TITLE[category]}
        </UiTypography.Title>
        {isAdvanced && (
          <UiBadge
            className={styles.searchCategoryResults__counter}
            count={shownCount}
            overflowCount={MAX_BADGE_COUNT}
          />
        )}
        {!isAdvanced && searchCategory === SearchCategory.All && (
          <div
            role="button"
            tabIndex={-1}
            onClick={handleSearch}
            onKeyPress={handleSearch}
            className={styles.searchCategoryResults__iconWrapper}
          >
            <UiIcon width={20} height={20} component={Arrow} />
          </div>
        )}
      </div>
      {children}
      {isShowAllShown && (
        <UiButton
          className={styles.searchCategoryResults__showMoreButton}
          type="secondary"
          size="large"
          label="Показать все"
          onClick={handleSearch}
        />
      )}
      {isAdvanced && searchCategory !== SearchCategory.All && (
        <UiPagination
          className={styles.searchCategoryResults__pagination}
          responsive
          {...pagination}
          onChange={onChangePagination}
        />
      )}
    </div>
  );
};
