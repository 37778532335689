import React from 'react';

import { SearchPage, FilterCatogories } from 'components-frontend/components/search-page/SearchPage';
import { MIN_SYMBOLS_TO_SEARCH } from 'components-frontend/configs/min-symbols-to-search@default';

const searchFilters: FilterCatogories[] = [];

const SearchPageDefault: React.FC = () => (
  <SearchPage
    minSymbolsToSearch={MIN_SYMBOLS_TO_SEARCH}
    searchFilters={searchFilters}
    breadcrumbName="Поиск по пользователям"
  />
);

export { SearchPageDefault };
