import React, { FC, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiForm } from 'ant/components/ui/form';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiOptionData } from 'ant/components/ui/select';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { generateMultiSelectSingleOption } from 'ant/plugins/utils/options-helper';
import { generateTagApiOptions, generateTagFieldOptions } from 'ant/plugins/utils/tag-helper';
import { createUpdateFileStorageEntryEffect } from 'ant/store/filestorage';
import { FileStorageUpdatePayload } from 'ant/types/file-storage';
import { FileStorageEntryInfoModel } from 'ant/types/models/file.model';

import { FileStorageCreateUpdateFields } from '../fields/FileStorageCreateUpdateFields';

export type FileStorageCreateUpdateFormValues = Pick<
  FileStorageEntryInfoModel<UiOptionData>,
  'id' | 'name' | 'description' | 'tags' | 'categories'
>;

export const FileStorageUpdateModal: FC<FileStorageUpdatePayload> = (props) => {
  const { onClose, onSuccess: onSuccessModal, fileStorageInfo, parent } = props;
  const [form] = UiForm.useForm<FileStorageCreateUpdateFormValues>();

  const initialFormValues = useMemo<Partial<FileStorageCreateUpdateFormValues>>(() => {
    return {
      id: fileStorageInfo.id,
      name: fileStorageInfo.name,
      description: fileStorageInfo.description,
      tags: generateTagFieldOptions(fileStorageInfo.tags),
      categories: fileStorageInfo.categories.map((option) =>
        generateMultiSelectSingleOption(option, { valuePath: 'id', labelPath: 'name' }),
      ),
    };
  }, [fileStorageInfo]);

  const onFinish = (formValues: FileStorageCreateUpdateFormValues) => {
    const { tags, categories, ...restFormValues } = formValues;
    const id = form.getFieldValue('id');

    const params = {
      ...restFormValues,
      id,
      tags: generateTagApiOptions(tags),
      categories: categories.map(({ value }) => Number(value)),
      parent,
    };

    createUpdateFileStorageEntryEffect(params)
      .then((data) => {
        message.success('Файл успешно обновлен');
        onSuccessModal?.(data);
        onClose();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка обновления файла')));
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Редактировать файл')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm onFinish={onFinish} initialValues={initialFormValues} form={form}>
          <FileStorageCreateUpdateFields parent={parent} />
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton label="Сохранить" onClick={form.submit} size="large" type="primary" />
          <UiButton label="Отмена" onClick={onClose} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
