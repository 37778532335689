import React from 'react';

import { UiButton } from 'ant/components/ui/button/UiButton';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import GearSvg from 'components-frontend/components/svg/gear.svg';
import LocationSvg from 'components-frontend/components/svg/location.svg';

import styles from './WeatherHeader.scss';

const SHOW_SETTINGS_BUTTON = false; // TODO: Раздел в разработке

type WeatherHeaderProps = { locationName: string };

const WeatherHeader: React.FC<WeatherHeaderProps> = ({ locationName }) => {
  return (
    <span className={styles.weatherHeader}>
      <UiTypography.Title level={3} style={{ marginBottom: 0 }}>
        Погода
      </UiTypography.Title>

      <span className={styles.weatherHeader__location}>
        {locationName && (
          <a rel="noreferrer" target="_blank" href="https://pogoda.mail.ru/prognoz/moskva/">
            <UiButton type="link" icon={<UiIcon component={LocationSvg} width={20} height={20} />}>
              <UiTruncateMarkup truncate>{locationName}</UiTruncateMarkup>
            </UiButton>
          </a>
        )}

        {SHOW_SETTINGS_BUTTON && (
          <UiButton
            type="link"
            className={styles.weatherHeader__locationSettings}
            icon={<GearSvg width={20} height={20} />}
          />
        )}
      </span>
    </span>
  );
};

export { WeatherHeader };
