import React from 'react';

import { UiLinkListItem } from 'ant/components/ui/link-list-item';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { createArrayMock } from 'ant/plugins/create-mocks';

import styles from '../SearchCategoryResults.scss';

const MOCKS_COUNT = 4;

const listItemsMock = createArrayMock(MOCKS_COUNT, (_, id) => <UiLinkListItem.Skeleton key={id} />);

export const SearchCategoryMock = () => {
  return (
    <div className={styles.searchCategoryResults}>
      <div className={styles.searchCategoryResults__title}>
        <UiSkeleton loading width={100} />
      </div>
      {listItemsMock}
    </div>
  );
};
