import React, { useMemo, FC } from 'react';

import Appreaction3Svg from 'ant/components/svg/appreaction-3.svg';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getSingleBadgesStorage } from 'ant/store/gamification';
import { PostGamificationModel as GamificationPostType } from 'ant/types/models/post';
import { GamificationItem } from 'components-frontend/components/gamification/item/GamificationItem';

type Props = {
  post: GamificationPostType;
};

export const PostGamificationBadges: FC<Props> = (props) => {
  const { post } = props;

  const badgesStorage = useMemo(getSingleBadgesStorage, []);

  const { data: badgesData } = useAbstractStorage(badgesStorage.storage, {
    cancelPendingRequestOnUnmount: true,
    autoFetchAndRefetch: true,
    autoFetchParams: {
      entityId: post.entityId,
    },
  });

  if (!badgesData) {
    return null;
  }

  const { badge } = badgesData;

  return (
    <UiRow wrap={false}>
      <UiCol>
        <GamificationItem image={badge.image} icon={Appreaction3Svg} avatarIconSize={72} size={120} />
      </UiCol>

      <UiCol style={{ marginLeft: 20 }}>
        <UiTypography.Title level={2}>{badge.name}</UiTypography.Title>
        <UiTypography.Text>{badge.description}</UiTypography.Text>
      </UiCol>
    </UiRow>
  );
};
