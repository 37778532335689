import { endpoints } from 'ant/endpoints';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { TimelineId, TimelineRecordModel } from 'ant/types/models/timelines.model';

export type GetTimelineRecordStorageParams = {
  id: TimelineId;
};

export const getTimelineRecordStorage = () => {
  const storage = abstractStorageFactory<
    TimelineRecordModel,
    TimelineRecordModel,
    null,
    GetTimelineRecordStorageParams
  >({
    endpointBuilder: ({ id }) => endpoints.timelines.records(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
