import React, { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react';

import { UiDivider } from 'ant/components/ui/divider';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiRow } from 'ant/components/ui/grid';
import { UiImage } from 'ant/components/ui/image';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { UiTag } from 'ant/components/ui/tag';
import { UiTypography } from 'ant/components/ui/typography';
import { Editor } from 'ant/components/widgets/Editor';
import { Post } from 'ant/components/widgets/Post';
import { Reactions } from 'ant/components/widgets/Reactions';
import { ShareButton } from 'ant/components/widgets/ShareButton';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getActualEditorFormat } from 'ant/plugins/utils/markup-content';
import { closeGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { getNewsCategoriesStorage } from 'ant/store/news';
import { NewsLongreadPayload } from 'ant/types/longread';
import { PostNewsModel } from 'ant/types/models/post';
import { ReactionId, ReactionNewsContentType } from 'ant/types/models/reactions.model';
import { deletePostEffect, getSinglePostStorage } from 'components-frontend/store/post';

import { LongreadComments } from '../longread/comments/LongreadComments';
import { LongreadLoading } from '../longread/loading/LongreadLoading';

export type NewsLongreadProps = NewsLongreadPayload;

export const NewsLongread: FC<NewsLongreadProps> = (props) => {
  const { newsId, newsType } = props;

  const newsStorage = useMemo(() => getSinglePostStorage<PostNewsModel>(), []);
  const newsCategoriesStorage = useMemo(getNewsCategoriesStorage, []);

  const { reactOnNewsEffect, updateCommentsCountEvent } = newsStorage;
  const {
    data: news,
    loading: isNewsLoading,
    error,
  } = useAbstractStorage(newsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { postId: newsId, postType: newsType },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const { data: newsCategories } = useAbstractStorage(newsCategoriesStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  useEffect(() => {
    if (error) {
      closeGlobalModal(GlobalModalNames.NewsLongread);

      message.error(
        getErrorResponseMessage(error, `Ошибка. Новости с идентификатором ${newsId} не существует`),
      );
    }
  }, [error]);

  const baseSectionStyle: CSSProperties = { padding: '32px 48px' };

  const isReactedDisabled = !news?.settings?.isReacted;
  const isCommentsDisabled = !news?.settings?.isComments;
  const isReactions = news?.reactions && !isReactedDisabled;

  const commentsCount = news?.commentsCount || 0;
  const viewsCount = news?.viewsCount || 0;

  const commentsAndViews = (
    <>
      {!isCommentsDisabled && <Reactions.Comments count={commentsCount} />}
      <Reactions.Views count={viewsCount} />
    </>
  );

  const onDelete = useCallback(() => {
    deletePostEffect({ postId: newsId, postType: newsType }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось удалить новость')),
    );
  }, [newsId, newsType, deletePostEffect]);

  const onReaction = useCallback(
    (reactionId: ReactionId, isLike: boolean) => {
      if (news) {
        reactOnNewsEffect({
          reactionId,
          isLike,
          objectId: newsId,
          objectType: ReactionNewsContentType.News,
        }).catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось поставить реакцию')));
      }
    },
    [newsId, reactOnNewsEffect, news],
  );

  const isError = (!news && !isNewsLoading) || error;

  return (
    <>
      {isNewsLoading && <LongreadLoading />}
      {isError && <UiEmpty.Feed emptyMessage={{ header: 'Новость не загрузилась' }} />}
      {news && (
        <>
          <UiSpace size={20} direction="vertical" full style={baseSectionStyle}>
            <Post.Header author={news.author} postDate={news.publishedAt || news.createdAt}>
              <UiSpace>
                {newsCategories?.[news.categoryId] && (
                  <UiTag
                    title={newsCategories[news.categoryId].name}
                    color={newsCategories[news.categoryId].extraInfo?.color}
                  />
                )}
                <Post.Header.Actions post={news} onDelete={onDelete} />
              </UiSpace>
            </Post.Header>
            <UiTypography.Title style={{ fontSize: 40, lineHeight: '44px', marginBottom: 0 }}>
              {news.title}
            </UiTypography.Title>
            <UiImage src={news.cover} />
            <Editor.Viewer html={getActualEditorFormat(news.body).data} />
          </UiSpace>
          <UiDivider emptyMargin />
          <UiRow style={baseSectionStyle}>
            {isReactions && (
              <Reactions reactions={news.reactions || []} onReaction={onReaction}>
                <UiSpace flex size={16}>
                  {commentsAndViews}
                </UiSpace>
              </Reactions>
            )}
            {!isReactions && commentsAndViews}
            <ShareButton label="Поделиться" />
          </UiRow>
          {!isCommentsDisabled && (
            <>
              <UiDivider emptyMargin />
              <LongreadComments
                style={{ paddingLeft: 48, paddingRight: 48 }}
                post={news}
                onCommentsCountUpdate={updateCommentsCountEvent}
                showInput
              />
            </>
          )}
        </>
      )}
    </>
  );
};
