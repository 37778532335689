import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect } from 'react';

import { UiBadge } from 'ant/components/ui/badge';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { CommentListStorage } from 'ant/store/comments';
import { CommentContentTypes, CommentId, CommentObjectId } from 'ant/types/models/comment.model';
import { CommentInput } from 'components-frontend/components/comment/comment-input';
import { CommentList } from 'components-frontend/components/comment/comment-list';

import styles from './CommentListWithInput.scss';

const DEFAULT_MAX_SHIFT_LEVEL = 5;

export interface CommentListWithInputProps extends Pick<HTMLAttributes<HTMLDivElement>, 'style'> {
  objectId: CommentObjectId;
  contentType: CommentContentTypes;
  commentsStorage: CommentListStorage;
  containerClassName?: string;
  hideHeading?: boolean;
  onCommentsCountUpdate?: (countDiff: number) => void;
  maxNestLevelWithShift?: number;
  placeholderText?: string;
  placeholderEditText?: string;
  showInput?: boolean;
}

export const CommentListWithInput: React.FC<CommentListWithInputProps> = (props) => {
  const {
    objectId,
    contentType,
    commentsStorage,
    containerClassName,
    hideHeading,
    onCommentsCountUpdate,
    placeholderText,
    placeholderEditText,
    maxNestLevelWithShift = DEFAULT_MAX_SHIFT_LEVEL,
    showInput = true,
    style,
  } = props;
  const { createCommentEffect, updateCommentEffect, deleteCommentEffect, reactOnCommentEffect } =
    commentsStorage;
  const createCommentInProgress = useStore(createCommentEffect.pending);
  const updateCommentInProgress = useStore(updateCommentEffect.pending);
  const deleteCommentInProgress = useStore(deleteCommentEffect.pending);
  const commentActionInProgress =
    createCommentInProgress || deleteCommentInProgress || updateCommentInProgress;
  const {
    data: comments,
    loading: currentlyLoading,
    pagination: { count = 0 },
    fetchRequestCount,
    refetchWithLastParams,
  } = useAbstractStorage(commentsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { contentType, objectId },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });
  const isFirstLoad = fetchRequestCount <= 1;
  const shouldShowSkeleton = currentlyLoading && isFirstLoad;

  useEffect(() => {
    if (onCommentsCountUpdate) {
      onCommentsCountUpdate(count);
    }
  }, [count]);

  const hasComments = comments.length > 0;
  const shouldShowComments = currentlyLoading || hasComments;
  const shouldShowCommentsHeading = !hideHeading && shouldShowComments;

  const onComment = (text: string, parentId: CommentId | null) =>
    createCommentEffect({ objectId, contentType, text, parentId }).then(refetchWithLastParams);

  const onUpdateComment = (commentId: CommentId, text: string) =>
    updateCommentEffect({ objectId, contentType, commentId, text });

  const onDeleteComment = (commentId: CommentId) => {
    deleteCommentEffect({ commentId });
  };

  const onReaction = (commentId: CommentId, reactionId: string, isLike?: boolean) => {
    reactOnCommentEffect({ commentId, reactionId, isLike });
  };

  const onActualSend = (text: string, parentId: number | null = null) => onComment(text, parentId);

  return (
    <div className={styles.commentsWithInput}>
      {shouldShowCommentsHeading && (
        <div style={style} className={classnames(styles.commentsWithInput__heading, containerClassName)}>
          <UiTypography.Title level={3}>
            <UiSkeleton loading={shouldShowSkeleton} height={21}>
              Комментарии
              <UiBadge className={styles.commentsWithInput__count} count={count} />
            </UiSkeleton>
          </UiTypography.Title>
        </div>
      )}

      {shouldShowComments && (
        <div style={style} className={classnames(styles.commentsWithInput__comments, containerClassName)}>
          <UiSkeleton
            loading={shouldShowSkeleton}
            height={100}
            className={styles.commentsWithInput__skeletonComment}
          >
            <CommentList
              comments={comments}
              showCount={1}
              maxLevel={1}
              maxNestLevelWithShift={maxNestLevelWithShift}
              onSend={onActualSend}
              loading={commentActionInProgress}
              onCommentEdit={onUpdateComment}
              onCommentDelete={onDeleteComment}
              onReaction={onReaction}
            />
          </UiSkeleton>
        </div>
      )}
      {showInput && (
        <div
          style={style}
          className={classnames(containerClassName, styles.commentsWithInput__input, {
            [styles.commentsWithInput__inputBorder]: hasComments,
          })}
        >
          <UiSkeleton loading={currentlyLoading} height={40}>
            <CommentInput
              onSend={onActualSend}
              loading={commentActionInProgress}
              placeholderText={placeholderText}
              placeholderEditText={placeholderEditText}
            />
          </UiSkeleton>
        </div>
      )}
    </div>
  );
};
