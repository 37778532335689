import React, { FC } from 'react';

import { CMSPageModel } from 'ant/types/models/cms.model';
import {
  NotificationCmsPageCommentModel,
  NotificationModel,
  NotificationTypes,
} from 'ant/types/models/notification.model';
import { isNotificationSomeOfTypes } from 'components-frontend/typings/guards/notification-guards';

import { NotificationCmsComment } from './comment/NotificationCmsComment';
import { NotificationCmsMention } from './mention/NotificationCmsMention';

interface Props {
  notification: NotificationModel;
}

export const NotificationCms: FC<Props> = (props) => {
  const { notification } = props;

  if (isNotificationSomeOfTypes<CMSPageModel>(notification, [NotificationTypes.CmsPageUserMentioned])) {
    return <NotificationCmsMention notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationCmsPageCommentModel>(notification, [
      NotificationTypes.CmsPageCommentCreated,
      NotificationTypes.CmsPageCommentUserMentioned,
      NotificationTypes.CmsPageCommentReaction,
      NotificationTypes.CmsPageCommentReply,
    ])
  ) {
    return <NotificationCmsComment notification={notification} />;
  }

  return null;
};
