import { isBlogPost, isGamificationPost, isNewsPost, isProfileEntry } from 'ant/types/guards/post';
import { CommentContentTypes } from 'ant/types/models/comment.model';
import { PostModel } from 'ant/types/models/post';

export const getCommentsContentTypeByPost = (post: PostModel) => {
  if (isNewsPost(post)) {
    return CommentContentTypes.NewsEntry;
  }

  if (isGamificationPost(post)) {
    return CommentContentTypes.Gamification;
  }

  if (isProfileEntry(post)) {
    return CommentContentTypes.ProfileEntry;
  }

  return isBlogPost(post) ? CommentContentTypes.Post : CommentContentTypes.Micropost;
};
