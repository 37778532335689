import React, { createContext, FC } from 'react';

import { UiEmpty } from 'ant/components/ui/empty';
import {
  TimelineBadgeContentModel,
  TimelineCommentContentModels,
  TimelineCompetenceContentModel,
  TimelineRecordModel,
  TimelineSkillContentModel,
  TimelineThanksContentModel,
  TimelineTypes,
  TimelineUserStructureContentModel,
} from 'ant/types/models/timelines.model';
import {
  isTimelineRecordActorModel,
  isTimelineRecordModelSomeOfTypes,
  isTimelineRecordTargetActorModel,
  isTimelineRecordTargetModel,
} from 'components-frontend/typings/guards/timeline';

import { TimelineCardBadge } from './badge/TimelineCardBadge';
import { TimelineCardComment } from './comment/TimelineCardComment';
import { getTimelineCardCommentEntry } from './comment/helpers';
import { TimelineCardSkill } from './skill/TimelineCardSkill';
import { TimelineCardThanks } from './thanks/TimelineCardThanks';
import { TimelineCardUserStructure } from './user-structure/TimelineCardUserStructure';

export type TimelineCardGutter = [number, number];

export const timelineBadgeRecords: TimelineTypes[] = [
  TimelineTypes.BadgeAutoCreated,
  TimelineTypes.BadgeManualCreated,
];

const timelineCommentRecords: TimelineTypes[] = [
  TimelineTypes.CommentCreatedNews,
  TimelineTypes.CommentCreatedMicropost,
  TimelineTypes.CommentCreatedEntry,
  TimelineTypes.CommentCreatedThanks,
  TimelineTypes.CommentCreatedUserBadge,
  TimelineTypes.CommentCreatedFileVersion,
  TimelineTypes.CommentCreatedCms,
  TimelineTypes.CommentCreatedAlbumImage,
  TimelineTypes.CommentCreatedTask,
  TimelineTypes.CommentCreatedRecord,
  TimelineTypes.CommentReplyNews,
  TimelineTypes.CommentReplyMicropost,
  TimelineTypes.CommentReplyEntry,
  TimelineTypes.CommentReplyThanks,
  TimelineTypes.CommentReplyUserBadge,
  TimelineTypes.CommentReplyFileVersion,
  TimelineTypes.CommentReplyCms,
  TimelineTypes.CommentReplyAlbumImage,
  TimelineTypes.CommentReplyTask,
  TimelineTypes.CommentReplyRecord,
];

export const TimelineCardGutterContext = createContext<TimelineCardGutter>([0, 0]);

export type TimelineCardProps = {
  record: TimelineRecordModel;
  gutter: TimelineCardGutter;
  onClick?: (record: TimelineRecordModel) => void;
};

export const TimelineCard: FC<TimelineCardProps> = (props) => {
  const { record, gutter, ...restProps } = props;
  const isBadge =
    isTimelineRecordModelSomeOfTypes<TimelineBadgeContentModel>(record, timelineBadgeRecords) &&
    isTimelineRecordTargetModel<TimelineBadgeContentModel>(record);
  const isThanks =
    isTimelineRecordModelSomeOfTypes<TimelineThanksContentModel>(record, [TimelineTypes.ThanksCreated]) &&
    isTimelineRecordActorModel<TimelineThanksContentModel>(record);
  const isSkill =
    isTimelineRecordModelSomeOfTypes<TimelineSkillContentModel>(record, [TimelineTypes.SkillApproved]) &&
    isTimelineRecordTargetActorModel<TimelineSkillContentModel>(record);
  const isCompetence =
    isTimelineRecordModelSomeOfTypes<TimelineCompetenceContentModel>(record, [
      TimelineTypes.CompetenceApproved,
    ]) && isTimelineRecordTargetActorModel<TimelineCompetenceContentModel>(record);
  const isPostUserStructure =
    isTimelineRecordModelSomeOfTypes<TimelineUserStructureContentModel>(record, [
      TimelineTypes.UserStructureUpdated,
    ]) && isTimelineRecordTargetModel<TimelineUserStructureContentModel>(record);
  const isComment =
    isTimelineRecordModelSomeOfTypes<TimelineCommentContentModels>(record, timelineCommentRecords) &&
    isTimelineRecordActorModel<TimelineCommentContentModels>(record);

  const TimelineComponent: FC = () => {
    if (isBadge) {
      return <TimelineCardBadge record={record} {...restProps} />;
    }

    if (isThanks) {
      return <TimelineCardThanks record={record} {...restProps} />;
    }

    if (isPostUserStructure) {
      return <TimelineCardUserStructure record={record} {...restProps} />;
    }

    if (isSkill || isCompetence) {
      return <TimelineCardSkill record={record} {...restProps} />;
    }

    if (isComment) {
      const entry = getTimelineCardCommentEntry(record);

      if (entry) {
        return <TimelineCardComment entry={entry} record={record} />;
      }
    }

    return <UiEmpty.Feed emptyMessage={{ header: 'Неверный тип записи' }} />;
  };

  return (
    <TimelineCardGutterContext.Provider value={gutter}>
      <TimelineComponent />
    </TimelineCardGutterContext.Provider>
  );
};
