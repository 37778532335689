import { format, addDays, subDays, isToday } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React from 'react';

import ArrowLeftSVG from 'ant/components/svg/arrow-left.svg';
import ArrowRightSVG from 'ant/components/svg/arrow-right.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './ProfileTimeslotsDatePicker.scss';

const TITLE_STYLE = {
  margin: 0,
};

type Props = {
  date: Date;
  onDateChange: (date: Date) => void;
  isUserActive?: boolean;
};

export const ProfileTimeslotsDatePicker: React.FC<Props> = (props) => {
  const { date, onDateChange, isUserActive } = props;
  const weekName = format(date, 'EEEEEE', { locale: ru });

  const onPrevDayClick = () => {
    const prevDay = subDays(date, 1);

    onDateChange(prevDay);
  };

  const onNextDayClick = () => {
    const nextDay = addDays(date, 1);

    onDateChange(nextDay);
  };

  const isPrevDayButtonDisabled = isToday(date);

  return (
    <div className={styles.profileTimeslotsDatePicker}>
      <UiTypography.Text className={styles.profileTimeslotsDatePicker__week}>{weekName}</UiTypography.Text>
      <span className={styles.profileTimeslotsDatePicker__picker}>
        <UiButton
          disabledFocus
          onClick={onPrevDayClick}
          icon={<UiIcon component={ArrowLeftSVG} />}
          type="link-secondary"
          disabled={!isUserActive || isPrevDayButtonDisabled}
          className={styles.profileTimeslotsDatePicker__button}
        />

        <UiTypography.Title level={3} className={styles.profileTimeslotsDatePicker__date} style={TITLE_STYLE}>
          {date.getDate()}
        </UiTypography.Title>

        <UiButton
          onClick={onNextDayClick}
          icon={<UiIcon component={ArrowRightSVG} />}
          type="link-secondary"
          disabledFocus
          className={styles.profileTimeslotsDatePicker__button}
          disabled={!isUserActive}
        />
      </span>
    </div>
  );
};
