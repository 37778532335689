import React, { FC } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification, NotificationHeaderProps } from 'ant/components/widgets/Notification';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { parseMentionsToPlainText } from 'ant/plugins/utils/markup-content';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { LongreadParams, NewsLongreadParams } from 'ant/types/longread';
import { UTCDate } from 'ant/types/models/issuer-date.model';
import { NotificationStatuses } from 'ant/types/models/notification.model';

import { NotificationTargetTypes } from './constants';

const isNewsLongreadTarget = (target: LongreadParams | NewsLongreadParams): target is NewsLongreadParams => {
  return 'newsType' in target;
};

type NotificationTargetPage = {
  targetType: NotificationTargetTypes.Longread;
  targetDescription: string;
  targetParams: LongreadParams | NewsLongreadParams;
};

type NotificationTargetLongread = {
  targetType: NotificationTargetTypes.Page;
  targetDescription: string;
  targetName: string;
  targetUrl?: string;
};

type NotificationTarget = NotificationTargetPage | NotificationTargetLongread;

type NotificationCommentBase = {
  status: NotificationStatuses;
  commentText: string;
  replyText?: string;
  createdAt: UTCDate;
};

type NotificationCommentProps = NotificationCommentBase & NotificationTarget & NotificationHeaderProps;

export const NotificationComment: FC<NotificationCommentProps> = (props) => {
  const {
    status,
    commentText,
    replyText,
    createdAt,
    targetType,
    targetDescription,
    ...notificationHeaderProps
  } = props;

  const NotificationCommentTarget: FC = () => {
    if (targetType === NotificationTargetTypes.Longread) {
      const { targetParams } = props;
      const longread = isNewsLongreadTarget(targetParams)
        ? GlobalModalNames.NewsLongread
        : GlobalModalNames.PostLongread;

      return (
        <UiButton
          label="Посмотреть"
          type="primary"
          onClick={() => openGlobalModal(longread, targetParams, targetParams)}
        />
      );
    }

    if (targetType === NotificationTargetTypes.Page) {
      const { targetName, targetUrl } = props;

      return targetUrl ? <RouterLink to={targetUrl}>{targetName}</RouterLink> : null;
    }

    return null;
  };

  return (
    <Notification status={status}>
      <Notification.Header {...notificationHeaderProps} />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          {replyText && (
            <>
              <UiTruncateMarkup lines={2} truncate tooltipProps={{ title: replyText }}>
                {parseMentionsToPlainText(replyText)}
              </UiTruncateMarkup>
              <UiTypography.Text type="secondary">На ваш комментарий:</UiTypography.Text>
            </>
          )}
          <UiTruncateMarkup lines={2} truncate tooltipProps={{ title: commentText }}>
            {parseMentionsToPlainText(commentText)}
          </UiTruncateMarkup>
          <UiTypography.Text type="secondary">{targetDescription}</UiTypography.Text>
          <NotificationCommentTarget />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
