import React from 'react';

import { UiModal } from 'ant/components/ui/modals';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography/UiTypography';
import { getFormattedDateToWord } from 'ant/plugins/utils/get-formatted-date';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { Note } from 'ant/types/notes';

import { NotesWidgetItem } from '../item/NotesWidgetItem';
import styles from './NotesWidgetList.scss';

type Props = {
  notesList: Note[];
  onOpenNoteEditor: (note?: Note) => void;
};

const NotesWidgetList: React.FC<Props> = React.memo(({ notesList, onOpenNoteEditor }) => (
  <>
    <UiModal.Header hasBottomBorder>
      <UiModal.Header.Title steps={getModalStepsForSingleTitle('Все заметки')} />
    </UiModal.Header>

    <UiModal.Content className={styles.notesWidgetList__content}>
      {notesList.map((note) => (
        <NotesWidgetItem
          key={note.id}
          onClick={() => onOpenNoteEditor(note)}
          className={styles.notesWidgetList__item}
        >
          <UiTypography.Title level={3}>
            <UiTruncateMarkup truncate>{note.title}</UiTruncateMarkup>
          </UiTypography.Title>
          <UiTypography.Text strong type="secondary">
            {getFormattedDateToWord({ date: note.createdAt })}
          </UiTypography.Text>
        </NotesWidgetItem>
      ))}
    </UiModal.Content>
  </>
));

export { NotesWidgetList };
