import React, { FC, useContext } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { Post } from 'ant/components/widgets/Post';
import { CommentContentTypes } from 'ant/types/models/comment.model';
import { TimelineRecordActorModel, TimelineThanksContentModel } from 'ant/types/models/timelines.model';
import { GamificationTrophy } from 'components-frontend/components/gamification/trophy/GamificationTrophy';
import { PostCompound } from 'components-frontend/components/post/compound/PostCompound';
import { PostGamificationWrapper } from 'components-frontend/components/post/gamification/wrapper/PostGamificationWrapper';

import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record: TimelineRecordActorModel<TimelineThanksContentModel>;
}

export const TimelineCardThanks: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { content, createdAt, publishedAt, actor } = record;
  const { thanks } = content;

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header padding={[0, horizontalGutter]} author={actor} postDate={publishedAt || createdAt} />
      <Post.Body padding={[16, horizontalGutter]}>
        <UiButton.Decorator onClick={() => onClick?.(record)}>
          <PostGamificationWrapper style={{ minHeight: '228px' }}>
            <UiRow align="middle" justify="center">
              <UiCol>
                <GamificationTrophy thank={content.thanks} />
              </UiCol>
            </UiRow>
          </PostGamificationWrapper>
        </UiButton.Decorator>
      </Post.Body>
      <Post.Footer>
        <PostCompound.CommentsWithInput
          showInput={Boolean(record.actor.isActive)}
          objectId={thanks.id}
          contentType={CommentContentTypes.Thanks}
        />
      </Post.Footer>
    </Post>
  );
};
