import { stringify } from 'query-string';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { retrieveTextFromHtml } from 'ant/plugins/retrieve-text-from-html';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getFormattedDateToWord } from 'ant/plugins/utils/get-formatted-date';
import { parseMentionsToPlainText } from 'ant/plugins/utils/markup-content';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { BlogTabs } from 'ant/types/blogs';
import { NotificationModel, NotificationTypes } from 'ant/types/models/notification.model';
import { PostBlogModel } from 'ant/types/models/post';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';
import { isNotificationType } from 'components-frontend/typings/guards/notification-guards';

import { NotificationBlogPostContent } from './content/NotificationBlogPostContent';

type Props = {
  notification: NotificationModel<PostBlogModel>;
};

export const NotificationBlogPost: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { notification } = props;
  const {
    createdAt,
    content: { blogs, id: postId, type: postType, createdAt: postCreateAt, body: postBody },
    initiator: { fullName, keycloakId },
    notificationType,
    image: initiatorAvatar,
  } = notification;

  const isModeratorDecisionType = [
    NotificationTypes.BlogEntryApproved,
    NotificationTypes.BlogEntryDeclined,
  ].includes(notificationType);

  const {
    name: blogName,
    id: blogId,
    slug: blogSlug,
    fileStorageImageUrl: blogAvatar,
  } = Array.isArray(blogs) ? blogs[0] : blogs;
  const blogRoute = getRoutePath(RouteNames.GroupView, { id: blogSlug || blogId });

  const plainTextContent = retrieveTextFromHtml(postBody?.data || '');
  const postText = plainTextContent && parseMentionsToPlainText(plainTextContent);

  const notificationTitle = isModeratorDecisionType ? blogName : getFullNameWithoutPatronymic(fullName);
  const notificationAvatar = { src: blogAvatar || initiatorAvatar } satisfies UiAvatarProps;
  const notificationHeaderLink = isModeratorDecisionType
    ? blogRoute
    : getRoutePath(RouteNames.Profile, { id: keycloakId });

  const NotificationDescription: FC<{ title: string; subTitle: string }> = (descriptionProps) => {
    const { title, subTitle } = descriptionProps;

    return (
      <UiSpace full direction="vertical">
        <UiTypography.Text type="secondary">{title}</UiTypography.Text>
        <UiSpace full size={4} direction="horizontal">
          <UiTypography.Text type="secondary">{subTitle}</UiTypography.Text>
          <UiTypography.Link strong href={getRoutePath(RouteNames.GroupView, { id: blogSlug || blogId })}>
            {blogName}
          </UiTypography.Link>
        </UiSpace>
      </UiSpace>
    );
  };

  const notificationDescription = useMemo(() => {
    const postCreateTime = getFormattedDateToWord({ date: postCreateAt, dateFormat: 'dd MMMM yyyy в HH:mm' });

    if (
      isNotificationType(NotificationTypes.BlogEntryApproved, notificationType) ||
      isNotificationType(NotificationTypes.BlogEntryDeclined, notificationType)
    ) {
      return `${
        isNotificationType(NotificationTypes.BlogEntryApproved, notificationType) ? 'Опубликован' : 'Отклонён'
      } предложенный вами ${postCreateTime} пост:`;
    }

    return <NotificationDescription title={getNotificationDescription(notification)} subTitle="в группе:" />;
  }, [postCreateAt]);

  const onOpenPost = () => openGlobalModal(GlobalModalNames.PostLongread, { postId, postType });
  const onOpenModerationFeed = () =>
    navigate({
      pathname: blogRoute,
      search: stringify({ tab: BlogTabs.Premoderation }),
    });

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={notificationHeaderLink}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={notificationAvatar}
      />
      <Notification.Body>
        <UiSpace size={12} direction="vertical" full>
          <NotificationBlogPostContent
            visible={isNotificationType(NotificationTypes.BlogEntryApproved, notificationType)}
            content={postText}
            onClick={onOpenPost}
          />
          <NotificationBlogPostContent
            visible={
              isNotificationType(NotificationTypes.BlogEntryCreated, notificationType) ||
              isNotificationType(NotificationTypes.BlogEntryUpdated, notificationType)
            }
            content={postText}
            onClick={onOpenPost}
          />
          <NotificationBlogPostContent
            visible={isNotificationType(NotificationTypes.BlogEntryDeclined, notificationType)}
            content={postText}
          />
          <NotificationBlogPostContent
            visible={isNotificationType(NotificationTypes.BlogEntryRequireModeration, notificationType)}
            content={postText}
            onClick={onOpenModerationFeed}
          />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
