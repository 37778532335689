import React, { FC, useMemo } from 'react';

import { UiDivider } from 'ant/components/ui/divider';
import { UiRow } from 'ant/components/ui/grid';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { Post } from 'ant/components/widgets/Post';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { utcToLocalDate } from 'ant/plugins/utils/get-formatted-date';
import { parseEntityText } from 'ant/plugins/utils/markup-content';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { LongreadParams, NewsLongreadParams, TimelineLongreadParams } from 'ant/types/longread';
import { CommentDetailListModel } from 'ant/types/models/comment.model';
import { NewsId } from 'ant/types/models/news.model';
import { PostId, PostTypes } from 'ant/types/models/post';
import { TimelineId, TimelineRecordActorModel, TimelineTypes } from 'ant/types/models/timelines.model';
import { Gender } from 'ant/types/models/user.model';

export type TimelineCardCommentEntry = {
  title: string;
  comment: CommentDetailListModel;
  parentComment?: CommentDetailListModel;
  id?: PostId | NewsId | TimelineId;
  type?: TimelineTypes;
  targetUrl?: string;
};

interface Props {
  record: Omit<TimelineRecordActorModel, 'content'>;
  entry: TimelineCardCommentEntry;
}

const longreadDict: Partial<Record<TimelineTypes, GlobalModalNames>> = {
  [TimelineTypes.CommentCreatedEntry]: GlobalModalNames.PostLongread,
  [TimelineTypes.CommentReplyEntry]: GlobalModalNames.PostLongread,
  [TimelineTypes.CommentCreatedNews]: GlobalModalNames.NewsLongread,
  [TimelineTypes.CommentReplyNews]: GlobalModalNames.NewsLongread,
  [TimelineTypes.CommentCreatedMicropost]: GlobalModalNames.PostLongread,
  [TimelineTypes.CommentReplyMicropost]: GlobalModalNames.PostLongread,
  [TimelineTypes.CommentCreatedRecord]: GlobalModalNames.TimelineLongread,
  [TimelineTypes.CommentReplyRecord]: GlobalModalNames.TimelineLongread,
};

const longreadParamsDict: Partial<
  Record<
    TimelineTypes,
    (id: PostId | NewsId | TimelineId) => LongreadParams | NewsLongreadParams | TimelineLongreadParams
  >
> = {
  [TimelineTypes.CommentCreatedEntry]: (id: PostId) => ({ postId: id, postType: PostTypes.Entry }),
  [TimelineTypes.CommentReplyEntry]: (id: PostId) => ({ postId: id, postType: PostTypes.Entry }),
  [TimelineTypes.CommentCreatedNews]: (id: NewsId) => ({ newsId: id, newsType: PostTypes.Simple }),
  [TimelineTypes.CommentReplyNews]: (id: NewsId) => ({ newsId: id, newsType: PostTypes.Simple }),
  [TimelineTypes.CommentCreatedMicropost]: (id: PostId) => ({ postId: id, postType: PostTypes.Micropost }),
  [TimelineTypes.CommentReplyMicropost]: (id: PostId) => ({ postId: id, postType: PostTypes.Micropost }),
  [TimelineTypes.CommentCreatedRecord]: (id: TimelineId) => ({ timelineId: id }),
  [TimelineTypes.CommentReplyRecord]: (id: TimelineId) => ({ timelineId: id }),
};

export const TimelineCardComment: FC<Props> = (props) => {
  const { record, entry } = props;
  const { publishedAt, createdAt, actor, type: recordType } = record;
  const { title, comment, parentComment, type, id, targetUrl } = entry;

  const parsedCommentText = parseEntityText(comment.text).textValue;
  const parsedParentCommentText = parentComment && parseEntityText(parentComment.text).textValue;

  const commentText = parsedParentCommentText || parsedCommentText;
  const replyText = parentComment ? parsedCommentText : undefined;

  const genderSuffix = actor.gender === Gender.Female ? 'а' : '';
  const genderCreateCommentPrefix = `Оставил${genderSuffix} комментарий`;
  const genderReplyCommentPrefix = `Ответил${genderSuffix} на комментарий`;

  const commentTargetNames: Partial<Record<TimelineTypes, string>> = {
    [TimelineTypes.CommentCreatedNews]: `${genderCreateCommentPrefix} к новости`,
    [TimelineTypes.CommentCreatedEntry]: `${genderCreateCommentPrefix} к посту`,
    [TimelineTypes.CommentCreatedMicropost]: `${genderCreateCommentPrefix} к микропосту`,
    [TimelineTypes.CommentCreatedCms]: `${genderCreateCommentPrefix} к странице`,
    [TimelineTypes.CommentCreatedAlbumImage]: `${genderCreateCommentPrefix} к изображению`,
    [TimelineTypes.CommentCreatedFileVersion]: `${genderCreateCommentPrefix} к файлу`,
    [TimelineTypes.CommentCreatedUserBadge]: `${genderCreateCommentPrefix} к награде`,
    [TimelineTypes.CommentCreatedThanks]: `${genderCreateCommentPrefix} к благодарности`,
    [TimelineTypes.CommentCreatedTask]: `${genderCreateCommentPrefix} к задаче`,
    [TimelineTypes.CommentCreatedRecord]: `${genderCreateCommentPrefix} к записи`,

    [TimelineTypes.CommentReplyNews]: `${genderReplyCommentPrefix} к новости`,
    [TimelineTypes.CommentReplyEntry]: `${genderReplyCommentPrefix} к посту`,
    [TimelineTypes.CommentReplyMicropost]: `${genderReplyCommentPrefix} к микропосту`,
    [TimelineTypes.CommentReplyCms]: `${genderReplyCommentPrefix} к странице`,
    [TimelineTypes.CommentReplyAlbumImage]: `${genderReplyCommentPrefix} к изображению`,
    [TimelineTypes.CommentReplyFileVersion]: `${genderReplyCommentPrefix} к файлу`,
    [TimelineTypes.CommentReplyUserBadge]: `${genderReplyCommentPrefix} к награде`,
    [TimelineTypes.CommentReplyThanks]: `${genderReplyCommentPrefix} к благодарности`,
    [TimelineTypes.CommentReplyTask]: `${genderReplyCommentPrefix} к задаче`,
    [TimelineTypes.CommentReplyRecord]: `${genderReplyCommentPrefix} к записи`,
  };

  const titleLink = useMemo(() => {
    if (id && type) {
      const longread = longreadDict[type] || GlobalModalNames.TimelineLongread;
      const params = longreadParamsDict[type]?.(id) || { timelineId: id };

      return (
        <UiTypography.Link style={{ fontSize: 20 }} onClick={() => openGlobalModal(longread, params, params)}>
          {title}
        </UiTypography.Link>
      );
    }

    if (targetUrl) {
      return (
        <RouterLink to={targetUrl} style={{ fontSize: 20 }} strong>
          {title}
        </RouterLink>
      );
    }

    return null;
  }, [type, id, targetUrl, title]);

  const body = useMemo(() => {
    if (parentComment) {
      return (
        <UiRow>
          <UiDivider emptyMargin type="vertical" style={{ height: 'inherit' }} />
          <UiSpace direction="vertical" size={8} style={{ padding: '16px 24px' }}>
            <UiSpace direction="vertical" size={0}>
              <UiTypography.Text strong type={UiTypographyTextTypes.Secondary}>
                {getFullNameWithoutPatronymic(parentComment.user)}
              </UiTypography.Text>
              <UiTypography.Text type={UiTypographyTextTypes.Secondary}>
                {utcToLocalDate(parentComment.createdAt, 'dd MMMM в H:mm')}
              </UiTypography.Text>
            </UiSpace>
            <UiTypography.Text>{commentText}</UiTypography.Text>
          </UiSpace>
        </UiRow>
      );
    }

    return <UiTypography.Text>{commentText}</UiTypography.Text>;
  }, [commentText, parentComment]);

  return (
    <Post style={{ paddingTop: 24 }}>
      <Post.Header
        padding={[0, 24]}
        author={actor}
        postDate={publishedAt || createdAt}
        extra={<UiTypography.Text type="secondary">{commentTargetNames[recordType]}</UiTypography.Text>}
      />
      <Post.Body padding={[16, 24]}>
        <UiSpace size={16} direction="vertical">
          {titleLink}
          {body}
          {replyText && <UiTypography.Text>{replyText}</UiTypography.Text>}
        </UiSpace>
        {/* TODO: Add reactions https://gitlab.digital-insider.ru/di/main-frontend/-/issues/249 */}
        {/* <Post.Body.Reactions post={post} onReaction={onPostReaction} /> */}
      </Post.Body>
    </Post>
  );
};
