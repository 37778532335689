import React, { FC } from 'react';

import ErrorSvg from 'ant/components/svg/error.svg';
import SuccessSvg from 'ant/components/svg/success.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypographyTextTypes } from 'ant/components/ui/typography';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { acceptInviteEffect, rejectInviteEffect } from 'ant/store/blogs';
import { notificationsStore } from 'ant/store/notifications';
import { themePalette } from 'ant/theme';
import { BlogInviteModel, NotificationModel } from 'ant/types/models/notification.model';

type Props = {
  notification: NotificationModel<BlogInviteModel>;
};

export const BlogInviteActions: FC<Props> = (props) => {
  const { notification } = props;
  const { id, isSubscribed, isUserInvite } = notification.content;

  const onAccept = () => {
    acceptInviteEffect({ blogId: id })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось принять приглашение')));
  };

  const onReject = () => {
    rejectInviteEffect({ blogId: id })
      .then(() => {
        message.success('Приглашение отклонено');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось отклонить приглашение')));
  };

  if (isSubscribed && !isUserInvite) {
    return (
      <UiIcon.Label
        component={SuccessSvg}
        style={{ color: themePalette.colorSuccess }}
        type={UiTypographyTextTypes.Primary}
        label="Вы подписались"
        strong
      />
    );
  }

  if (!isSubscribed && !isUserInvite) {
    return (
      <UiIcon.Label
        component={ErrorSvg}
        style={{ color: themePalette.colorNegative }}
        type={UiTypographyTextTypes.Primary}
        label="Вы отклонили"
        strong
      />
    );
  }

  if (!isSubscribed && isUserInvite) {
    return (
      <UiSpace align="center">
        <UiButton label="Подписаться" type="primary" onClick={onAccept} />
        <UiButton label="Отклонить" type="tertiary" onClick={onReject} />
      </UiSpace>
    );
  }

  return null;
};
