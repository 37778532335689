import React from 'react';

import { UiButton } from 'ant/components/ui/button/UiButton';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

import styles from './CalendarWidgetListItem.scss';

interface Props {
  title: string;
  subtitle: string;
  onClick?: () => void;
}

const CalendarWidgetListItem: React.FC<Props> = ({ title, subtitle, onClick }) => {
  return (
    <UiButton className={styles.calendarWidgetListItem} onClick={onClick}>
      <span className={styles.calendarWidgetListItem__title}>{title}</span>
      <UiTruncateMarkup
        truncate
        lines={3}
        lineHeight="20px"
        className={styles.calendarWidgetListItem__subtitle}
      >
        {subtitle}
      </UiTruncateMarkup>
    </UiButton>
  );
};

export { CalendarWidgetListItem };
