import humps from 'humps';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { Weather } from 'components-frontend/typings/weather';
import { WeatherEndpoints } from './endpoints';

export type GetWeatherStorageParams = { cityId: string };
export type GetWeatherStorageResponse = Record<string, Weather.Info>;

export const getWeatherStorage = ({ cityId }: GetWeatherStorageParams) => {
  const storage = abstractStorageFactory<GetWeatherStorageResponse, Weather.Info, null>({
    endpointBuilder: () => buildEndpointWithQueryParams(WeatherEndpoints.weather(), { cities: cityId }),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data[humps.camelize(cityId)],
  });

  return { storage };
};

export type WeatherStorage = ReturnType<typeof getWeatherStorage>;
