import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { format, isPast } from 'date-fns';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCheckbox } from 'ant/components/ui/checkbox';
import { UiDatePicker } from 'ant/components/ui/date-picker';
import { UiForm } from 'ant/components/ui/form';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiInput } from 'ant/components/ui/input';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiTimePicker } from 'ant/components/ui/time-picker';
import { normalizeValueTrimStart } from 'ant/helpers/normalize';
import { MAX_LENGTH_INPUT_255, requiredRule } from 'ant/helpers/validation';
import { currentProfileStore } from 'ant/plugins/current-profile-service';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { mergeAntDateAndTime } from 'ant/plugins/utils/merge-date-and-time';
import { closeGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { CreateUpdatePollModel, PollForm, PollModel } from 'ant/types/models/poll';
import { createPollEffect, updatePollEffect } from 'components-frontend/store/poll';

import styles from './PollEditor.scss';
import { INITIAL_QUESTION_VALUE } from './constants';
import { PollQuestions } from './poll-questions/PollQuestions';

type Props = {
  onSuccess: (createdPoll: PollModel) => void;
  poll?: PollModel;
};

export const PollEditor: React.FC<Props> = (props) => {
  const { onSuccess, poll } = props;
  const [isLimitedTime, setLimitedTime] = useState(false);

  const [form] = UiForm.useForm();

  const profile = useStore(currentProfileStore);
  const isUpdating = useStore(updatePollEffect.pending);
  const isCreating = useStore(createPollEffect.pending);
  const isLoading = isUpdating || isCreating;

  const handleTimeLimit = (event: CheckboxChangeEvent) => {
    setLimitedTime(event.target.checked);
  };

  const pollTitle = `${poll ? 'Редактировать' : 'Создать'} мини-опрос`;

  useEffect(() => {
    if (poll && poll.dateEnd) {
      setLimitedTime(true);
    }
  }, [poll]);

  const initialValues = useMemo(() => {
    if (poll) {
      return {
        title: poll.title,
        isAnonymous: poll.isAnonymous,
        isLimited: Boolean(poll.dateEnd),
        limitDate: poll.dateEnd ? new Date(poll.dateEnd) : null,
        limitHour: poll.dateEnd ? new Date(poll.dateEnd) : null,
        questions: poll.questions.map(({ options, type: questionType, text }) => ({
          text,
          type: questionType,
          options: options?.map(({ option }) => option),
        })),
      };
    }

    return {
      questions: [INITIAL_QUESTION_VALUE],
    };
  }, [poll]);

  const onClose = () => {
    closeGlobalModal(GlobalModalNames.PollEditor);
  };

  const getFormattedDateEnd = (limitHour?: Date, limitDate?: Date) => {
    let result;

    if (isLimitedTime && limitDate && limitHour) {
      const mergedDate = limitHour ? mergeAntDateAndTime(limitDate, limitHour) : limitDate;

      result = format(mergedDate, "yyyy-MM-dd'T'HH:mm:ssx");
    }

    return result;
  };

  const onFinish = useCallback(
    (pollForm: PollForm) => {
      const { title, isAnonymous, limitDate, limitHour, questions } = pollForm;

      if (!profile) {
        message.error('Профиль пользователя не найден');
        return;
      }

      const newPoll: CreateUpdatePollModel = {
        title,
        moderators: [profile.id],
        dateEnd: getFormattedDateEnd(limitHour, limitDate),
        isAnonymous,
        questions: questions.map(({ options, type: questionType, text }) => ({
          text,
          type: questionType,
          options: options?.filter(Boolean)?.map((option) => ({ option })),
        })),
      };

      const effect = poll ? updatePollEffect({ id: poll.id, body: newPoll }) : createPollEffect(newPoll);

      effect
        .then((pollData) => {
          onSuccess(pollData);
          onClose();
        })
        .catch((e) =>
          message.error(getErrorResponseMessage(e, `Ошибка ${poll ? 'редактирования' : 'создания'}`)),
        );
    },
    [poll, profile, isLimitedTime],
  );

  return (
    <UiForm
      form={form}
      onFinish={onFinish}
      layout="vertical"
      requiredMark
      initialValues={initialValues}
      className={styles.pollEditor}
    >
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(pollTitle)} />
      </UiModal.Header>
      <UiModal.Content>
        <UiForm.Section className={styles.pollEditor__general} hasBottomBorder>
          <UiRow style={{ paddingBottom: 24 }}>
            <UiCol span={24}>
              <UiForm.Item
                className={styles.pollEditor__title}
                label="Название опроса"
                name="title"
                rules={[requiredRule]}
                normalize={normalizeValueTrimStart}
              >
                <UiInput maxLength={MAX_LENGTH_INPUT_255} size="large" disabled={isLoading} />
              </UiForm.Item>

              <UiForm.Item name="isAnonymous" valuePropName="checked">
                <UiCheckbox disabled={isLoading}>Анонимный опрос</UiCheckbox>
              </UiForm.Item>

              <UiRow justify="space-between" align="middle">
                <UiCol>
                  <UiForm.Item
                    name="isLimited"
                    valuePropName="checked"
                    className={styles.pollEditor__checkbox}
                  >
                    <UiCheckbox onChange={handleTimeLimit} disabled={isLoading}>
                      Ограничить время голосования
                    </UiCheckbox>
                  </UiForm.Item>
                </UiCol>

                <UiCol>
                  <UiRow>
                    <UiForm.Item name="limitDate">
                      <UiDatePicker
                        size="small"
                        disabled={!isLimitedTime || isLoading}
                        disabledDate={(date: Date) => isPast(new Date(date))}
                        className={styles.pollEditor__datePicker}
                      />
                    </UiForm.Item>

                    <UiForm.Item name="limitHour">
                      <UiTimePicker
                        size="small"
                        disabled={!isLimitedTime || isLoading}
                        className={styles.pollEditor__timePicker}
                      />
                    </UiForm.Item>
                  </UiRow>
                </UiCol>
              </UiRow>
            </UiCol>
          </UiRow>
        </UiForm.Section>

        <PollQuestions disabled={isLoading} form={form} />
      </UiModal.Content>

      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiForm.Item shouldUpdate noStyle>
            {({ isFieldsTouched, getFieldsError }) => {
              const isDisabled = poll
                ? false
                : !isFieldsTouched(['title']) || getFieldsError().some(({ errors }) => errors.length > 0);

              return (
                <UiButton
                  type="primary"
                  label={poll ? 'Сохранить' : 'Прикрепить к посту'}
                  htmlType="submit"
                  disabled={isDisabled || isLoading}
                />
              );
            }}
          </UiForm.Item>

          <UiButton type="tertiary" label="Отмена" onClick={onClose} disabled={isLoading} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
