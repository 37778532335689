import { SearchAllCategory, SearchSingleCategory } from 'ant/types/models/search.model';

export const SearchCategory = { ...SearchAllCategory, ...SearchSingleCategory };

export const SEARCH_CATEGORY_TITLE: Record<SearchAllCategory | SearchSingleCategory, string> = {
  [SearchCategory.All]: 'Искать везде',
  [SearchCategory.Post]: 'Посты',
  [SearchCategory.Profile]: 'Пользователи',
  [SearchCategory.Comment]: 'Комментарии',
  [SearchCategory.Blog]: 'Группы',
  [SearchCategory.Files]: 'Файлы',
  [SearchCategory.News]: 'Новости',
  [SearchCategory.Pages]: 'Страницы',
  [SearchCategory.Event]: 'Мероприятия',
  [SearchCategory.Tag]: 'Теги',
};

export const ADVANCED_SEARCH_CATEGORIES_AMOUNT = 3;
export const ADVANCED_SEARCH_CATEGORY_AMOUNT = 15;
