import React, { useCallback, useMemo, useState } from 'react';

import AddSvg from 'ant/components/svg/add.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiIcon } from 'ant/components/ui/icon';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { UiWidgetHeader } from 'ant/components/ui/widget-header';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getFormattedDateToWord } from 'ant/plugins/utils/get-formatted-date';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { getWidgetStorage } from 'ant/store/pages';
import { Note } from 'ant/types/notes';
import NotesSvg from 'components-frontend/components/svg/notes.svg';
import { Widget, WidgetModule } from 'core-components/types';

import styles from './NotesWidget.scss';
import { NotesWidgetItem } from './item/NotesWidgetItem';
import { NotesWidgetList } from './list/NotesWidgetList';

const NOTES_LIST_LENGTH = 4;
const notesListMocks = createArrayMock(NOTES_LIST_LENGTH, (_, id) => (
  <div key={id} className={styles.notesWidget__listLoading}>
    <UiSkeleton loading height={20} width="90%" />
    <UiSkeleton loading height={12} width="50%" />
  </div>
));

interface Props extends WidgetModule {
  widget: Widget;
}

const NotesWidget: React.FC<Props> = ({ widget: widgetProp }) => {
  const { id: widgetId } = widgetProp;
  const [isOpenNotesList, setIsOpenNotesList] = useState(false);

  const widgetStorage = useMemo(() => getWidgetStorage({ id: widgetId }), [widgetId]);
  const { data: widget, loading } = useAbstractStorage(widgetStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const widgetDataNotes: Note[] = widget?.data?.notes || [];
  const isNotesListEmpty = !loading && !widgetDataNotes.length;

  const onSaveNote = useCallback(
    (newNote: Note) => {
      const notes = widgetDataNotes.filter((note) => note.id !== newNote.id);

      notes.unshift(newNote);

      return widgetStorage.updateUserWidgetDataEffect({ widgetId, data: { notes } });
    },
    [widgetDataNotes],
  );

  const onToggleOpenNotesList = () => setIsOpenNotesList((value) => !value);

  const onOpenNoteEditor = useCallback(
    (note?: Note) =>
      openGlobalModal(GlobalModalNames.NoteEditor, {
        note,
        onSave: onSaveNote,
        onClose: () => closeGlobalModal(GlobalModalNames.NoteEditor),
      }),
    [onSaveNote],
  );

  const notesList = useMemo(
    () =>
      widgetDataNotes.slice(0, NOTES_LIST_LENGTH).map((note) => (
        <NotesWidgetItem
          key={note.id}
          onClick={() => onOpenNoteEditor(note)}
          className={styles.notesWidget__listItem}
        >
          <UiTypography.Text strong>
            <UiTruncateMarkup truncate>{note.title}</UiTruncateMarkup>
          </UiTypography.Text>
          <UiTypography.Text strong type="secondary" className={styles.notesWidget__listItem__time}>
            {getFormattedDateToWord({ date: note.createdAt })}
          </UiTypography.Text>
        </NotesWidgetItem>
      )),
    [widgetDataNotes],
  );

  const Title = (
    <UiWidgetHeader
      title="Заметки"
      className={styles.notesWidget__header}
      icon={<UiIcon component={NotesSvg} width={20} height={20} className={styles.notesWidget__headerIcon} />}
    >
      <UiButton
        type="link"
        className={styles.notesWidget__headerAddIcon}
        icon={<UiIcon component={AddSvg} width={20} height={20} />}
        onClick={() => onOpenNoteEditor()}
      />
    </UiWidgetHeader>
  );

  const EmptyNotesList = (
    <div className={styles.notesWidget__listEmpty}>
      <UiTypography.Text type="secondary">
        Заметки ещё не создавались
        <br />
        <UiTypography.Link strong onClick={() => onOpenNoteEditor()}>
          Добавить заметку
        </UiTypography.Link>
      </UiTypography.Text>
    </div>
  );

  return (
    <UiCard emptyPadding title={Title} className={styles.notesWidget}>
      {isNotesListEmpty && EmptyNotesList}
      {loading && notesListMocks}
      {!loading && notesList}
      {widgetDataNotes.length > NOTES_LIST_LENGTH && (
        <div className={styles.notesWidget__footer}>
          <UiButton block size="large" type="tertiary" label="Все заметки" onClick={onToggleOpenNotesList} />
          <UiModal type={UiModalTypes.Medium} isOpen={isOpenNotesList} onClose={onToggleOpenNotesList}>
            <NotesWidgetList notesList={widgetDataNotes} onOpenNoteEditor={onOpenNoteEditor} />
          </UiModal>
        </div>
      )}
    </UiCard>
  );
};

export { NotesWidget };
