import React, { useMemo } from 'react';

import CaseSvg from 'ant/components/svg/case.svg';
import MyDepartmentSvg from 'ant/components/svg/my-department.svg';
import StudySvg from 'ant/components/svg/study.svg';
import TeamSvg from 'ant/components/svg/team.svg';
import { UiList } from 'ant/components/ui/list';
import { CardLinks, CardLinksProps } from 'ant/components/widgets/CardLinks/CardLinks';
import { authService } from 'ant/plugins/auth-service';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { Widget, WidgetModule } from 'core-components/types';

enum CustomLinkType {
  ToProfile = 'to-profile',
}

enum CardTypes {
  TeamWork = 'team_work',
  PersonnelService = 'personal_service',
  Structure = 'structure',
  TalentManagement = 'talent_management',
}

const cardIcons: Record<CardTypes, SvgrComponent> = {
  [CardTypes.TeamWork]: TeamSvg,
  [CardTypes.PersonnelService]: CaseSvg,
  [CardTypes.Structure]: MyDepartmentSvg,
  [CardTypes.TalentManagement]: StudySvg,
};

type WidgetCardLink = {
  title: string;
} & ({ customLinkType: CustomLinkType } | { link: string });

interface WidgetData extends Omit<CardLinksProps, 'links'> {
  links: WidgetCardLink[];
  type: CardTypes;
}

interface WidgetProps extends Omit<Widget, 'data' | 'defaultData'> {
  data: WidgetData[];
}

interface Props extends WidgetModule {
  widget: WidgetProps;
}

const NavigationWidget: React.FC<Props> = ({ widget: { data: widgetData } }) => {
  const keycloakId = authService.getCurrentUserId();

  const widgetDataWithModifiedLinks: CardLinksProps[] = useMemo(() => {
    return widgetData.map((widgetInfo) => ({
      ...widgetInfo,
      icon: cardIcons[widgetInfo.type],
      links: widgetInfo.links.map((widgetCardLink) => {
        if (
          keycloakId &&
          'customLinkType' in widgetCardLink &&
          widgetCardLink.customLinkType === CustomLinkType.ToProfile
        ) {
          return {
            link: getRoutePath(RouteNames.Profile, { id: keycloakId }),
            title: widgetCardLink.title,
          };
        }

        return {
          link: 'link' in widgetCardLink ? widgetCardLink.link : '',
          title: widgetCardLink.title,
        };
      }),
    }));
  }, [widgetData, keycloakId]);

  return (
    <UiList
      grid={{ gutter: 20, column: 2 }}
      dataSource={widgetDataWithModifiedLinks}
      renderItem={(widgetInfo) => (
        <UiList.Item>
          <CardLinks header={widgetInfo.header} icon={widgetInfo.icon} links={widgetInfo.links} />
        </UiList.Item>
      )}
    />
  );
};

export { NavigationWidget };
