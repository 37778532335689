import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictionariesEndpoints } from 'ant/store/dictionaries/endpoints';
import { DictMatchTypes, DictPaginatedObjects } from 'ant/types/api';
import { RecordResponse } from 'ant/types/dictionary';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchTagDictStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginatedObjects<RecordResponse>,
    RecordResponse[],
    RecordResponse[],
    SearchParams
  >({
    endpointBuilder: () =>
      buildEndpointWithQueryParams(DictionariesEndpoints.dictsSearchRecords(Dictionaries.Names.Tags), {
        ordering: 'name',
      }),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataBuilder: ({ searchString, ...rest }: SearchParams) => ({
      name: { type: DictMatchTypes.Icontains, value: searchString, ...rest },
    }),
    dataMapper: (data) => data.objects,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount }),
  });

  return { storage };
};
