import { useStore } from 'effector-react';
import React, { FC, useState, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiForm } from 'ant/components/ui/form';
import { message } from 'ant/components/ui/message';
import { SearchOptionComplete, SearchOptionItem } from 'ant/components/widgets/SearchOptionComplete';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { generateAutoCompleteOptions } from 'ant/plugins/utils/options-helper';
import { competenciesDictsStorage } from 'ant/store/profile';
import { GetSkillsStorage } from 'ant/store/profile/skills';
import { SaveSkillParams } from 'ant/store/profile/skills/api';
import { DictMatchTypes } from 'ant/types/api';
import { SkillType } from 'ant/types/models/profile.model';

import { skillsTextMap } from '../Skills';
import { SkillsAddModal } from '../modals/skills-add/SkillsAddModal';

interface InitialValues {
  skillsSelect: SearchOptionItem | null;
}

const initialValues: InitialValues = {
  skillsSelect: null,
};

interface Props {
  skillType: SkillType;
  closeModal: () => void;
  skillStorage: GetSkillsStorage;
  existingSkillsIds: string[];
}

/** @deprecated заменить на SkillsAddModal */
export const SkillsChoice: FC<Props> = (props) => {
  const { closeModal, skillType, skillStorage, existingSkillsIds } = props;
  const value: SearchOptionItem = { id: '', name: '' };
  const [searchQuery, setQuerySearch] = useState('');
  const [isSkillsSelect, setIsSkillsSelect] = useState(false);

  const { data: competenciesData } = useAbstractStorage(competenciesDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { name: { type: DictMatchTypes.Icontains, value: searchQuery } },
  });

  const { saveSkillEffect } = skillStorage;
  const saveSkillInProgress = useStore(saveSkillEffect.pending);

  const competenciesOptions = useMemo(() => {
    const filtered = competenciesData.filter((item) => !existingSkillsIds.includes(item.id));

    return generateAutoCompleteOptions(filtered);
  }, [existingSkillsIds, competenciesData]);

  const filteredOptions = useMemo(
    () =>
      competenciesOptions.filter((item) =>
        String(item.label)?.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [competenciesOptions, searchQuery],
  );

  const SubmitChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Сохранить"
      htmlType="submit"
      loading={saveSkillInProgress}
      disabled={saveSkillInProgress || !isSkillsSelect}
    />
  );

  const onFinish = ({ skillsSelect }: { skillsSelect: SearchOptionItem }) => {
    const { name } = skillsSelect;

    const selectedSkill = filteredOptions.find((item) => String(item.label) === name);

    if (selectedSkill) {
      const saveSkillParams: SaveSkillParams = {
        name: selectedSkill.value,
        skillId: selectedSkill.key,
        skillType,
      };

      saveSkillEffect(saveSkillParams)
        .then(closeModal)
        .catch((e) => message.error(getErrorResponseMessage(e)));
    }
  };

  const onValuesChange = (values: InitialValues) => setIsSkillsSelect(Boolean(values && values.skillsSelect));

  return (
    <UiForm onFinish={onFinish} onValuesChange={onValuesChange} initialValues={initialValues}>
      <SkillsAddModal
        title={`Добавить ${skillsTextMap[skillType].OneDown}`}
        onCancel={closeModal}
        submitChildren={SubmitChildren}
      >
        <UiForm.Item name="skillsSelect" noStyle>
          <SearchOptionComplete
            options={filteredOptions}
            onSearch={setQuerySearch}
            searchQuery={searchQuery}
            updateOptionListHandler={setQuerySearch}
            value={value}
          />
        </UiForm.Item>
      </SkillsAddModal>
    </UiForm>
  );
};
