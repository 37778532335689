import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { UiImageBackground } from 'ant/components/ui/image-background';
import { UiOverlay, UiOverlayTypes } from 'ant/components/ui/overlay';

import styles from './EventImage.scss';

type Props = {
  image?: string;
  standalone?: boolean;
};

const EventImage: FC<PropsWithChildren<Props>> = ({ image, children, standalone }) => {
  return (
    <div
      className={classNames(styles.eventImage, {
        [styles.eventImage_standalone]: standalone,
      })}
    >
      <UiOverlay type={UiOverlayTypes.Primary} content={children}>
        <UiImageBackground className={styles.eventImage__background} url={image || ''} />
      </UiOverlay>
    </div>
  );
};

export { EventImage };
