import classNames from 'classnames';
import React, { ReactNode, FC, PropsWithChildren } from 'react';
import { animated, useTransition } from 'react-spring';

import styles from './Backdrop.scss';

interface BackdropProps {
  children: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

export const Backdrop: FC<PropsWithChildren<BackdropProps>> = (props) => {
  const { children, isOpen, onClick } = props;

  const transitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 250,
    },
  });

  const clonedChildren = React.Children.map(children, (child) => {
    const { props: childProps } = child as React.ReactElement<{ className?: string }>;
    const { className } = childProps;

    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // @ts-expect-error TODO: разобраться в типах
        className: classNames({ [styles.backdrop__item]: isOpen }, className),
      });
    }

    return null;
  });

  return (
    <>
      {transitions(
        (style, item) => item && <animated.div style={style} className={styles.backdrop} onClick={onClick} />,
      )}
      {clonedChildren}
    </>
  );
};
