import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiSpace } from 'ant/components/ui/space';
import { Notification } from 'ant/components/widgets/Notification';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { RouteNames, getRoutePath } from 'ant/plugins/router';
import { fileStorageContextRouteMap } from 'ant/store/filestorage/constants';
import { getFileStorageRouteParams } from 'ant/store/filestorage/helpers';
import { FileStorageListEntryModel } from 'ant/types/models/file.model';
import { NotificationModel } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<FileStorageListEntryModel>;
}

export const NotificationFileStorage: FC<Props> = (props) => {
  const { notification } = props;
  const { createdAt, content, image, initiator } = notification;
  const { context, id } = content;
  const navigate = useNavigate();

  const notificationTitle = getFullNameWithoutPatronymic(initiator.fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const notificationDescription = getNotificationDescription(notification);

  const routeParams = getFileStorageRouteParams(content);

  const routPathToFile = useMemo(() => {
    if (id && context) {
      return getRoutePath(fileStorageContextRouteMap[context.service], { ...routeParams, fileId: id });
    }

    return null;
  }, [id, context, routeParams]);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: initiator.keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />
      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          {routPathToFile && (
            <>
              <RouterLink to={routPathToFile}>{content.name}</RouterLink>
              <UiButton label="Посмотреть" type="primary" onClick={() => navigate(routPathToFile)} />
            </>
          )}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
