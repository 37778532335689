import React, { FC, useMemo } from 'react';

import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { VideoPlayer } from 'ant/components/widgets/VideoPlayer';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';

import {
  FileInfoCardModalProps,
  getMaxPossibleElementDimension,
} from '../image-modal/FileInfoCardImageModal';

export const FileInfoCardVideoModal: FC<FileInfoCardModalProps> = (props) => {
  const { fileItem, isShareable, onShare, ...modalProps } = props;

  const containerResolution = useMemo<{ width?: number; height?: number }>(() => {
    if (fileItem?.additional?.videoData) {
      const {
        additional: {
          videoData: { width: originalWidth, height: originalHeight },
        },
      } = fileItem;

      return getMaxPossibleElementDimension(
        originalWidth,
        originalHeight,
        window.innerWidth,
        window.innerHeight,
      );
    }

    return {
      width: undefined,
      height: undefined,
    };
  }, [fileItem]);

  return (
    <UiModal type={UiModalTypes.Fit} {...modalProps}>
      <UiModal.Header>
        <UiModal.Header.Title truncate steps={getModalStepsForSingleTitle(fileItem.name)} />
      </UiModal.Header>
      <UiModal.Content>
        <VideoPlayer
          id="player"
          url={fileItem?.file}
          isShareable={isShareable}
          containerWidth={containerResolution.width}
          containerHeight={containerResolution.height}
        />
      </UiModal.Content>
    </UiModal>
  );
};
