import React, { FC } from 'react';

import { GamificationBadges } from 'ant/types/models/gamification.model';
import { LinkContentModel, NotificationModel, NotificationTypes } from 'ant/types/models/notification.model';
import { isNotificationSomeOfTypes } from 'components-frontend/typings/guards/notification-guards';

import { NotificationGamificationReward } from './reward/NotificationGamificationReward';
import { NotificationGamificationThanks } from './thanks/NotificationGamificationThanks';

interface Props {
  notification: NotificationModel;
}

export const NotificationGamification: FC<Props> = (props) => {
  const { notification } = props;

  if (isNotificationSomeOfTypes<LinkContentModel>(notification, [NotificationTypes.GamificationNewThanks])) {
    return <NotificationGamificationThanks notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<GamificationBadges>(notification, [
      NotificationTypes.GamificationEventsManualGiveBadges,
    ])
  ) {
    return <NotificationGamificationReward notification={notification} />;
  }

  return null;
};
