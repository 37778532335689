import React, { FC, useMemo } from 'react';

import { getCommentListStorage } from 'ant/store/comments';
import {
  CommentListWithInput,
  CommentListWithInputProps,
} from 'components-frontend/components/comment/comment-list-with-input';

import styles from './PostCompoundCommentsWithInput.scss';

type Props = Pick<
  CommentListWithInputProps,
  'objectId' | 'contentType' | 'onCommentsCountUpdate' | 'showInput'
>;

export const PostCompoundCommentsWithInput: FC<Props> = (props) => {
  const commentsStorage = useMemo(getCommentListStorage, []);

  return (
    <CommentListWithInput
      {...props}
      commentsStorage={commentsStorage}
      containerClassName={styles.postCompoundCommentsWithInput}
      hideHeading
    />
  );
};
