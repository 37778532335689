// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9a27d3cf_poll-controls{padding-top:24px}._9a27d3cf_poll-controls__button{width:100%}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/poll/poll-controls/PollControls.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,yBACE,gBCDY,CDGZ,iCACE,UAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.poll-controls {\n  padding-top: $baseSpacing;\n\n  &__button {\n    width: 100%;\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-controls": "_9a27d3cf_poll-controls",
	"pollControls": "_9a27d3cf_poll-controls",
	"poll-controls__button": "_9a27d3cf_poll-controls__button",
	"pollControls__button": "_9a27d3cf_poll-controls__button"
};
export default ___CSS_LOADER_EXPORT___;
