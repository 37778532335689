import React, { FC, useCallback, useMemo } from 'react';

import PinSvg from 'ant/components/svg/pin.svg';
import { UiCollapse } from 'ant/components/ui/collapse';
import { UiDivider } from 'ant/components/ui/divider';
import { UiIcon } from 'ant/components/ui/icon';
import { UiImage } from 'ant/components/ui/image';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { Post } from 'ant/components/widgets/Post';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { isSomePostBlogsNegativeKey } from 'ant/plugins/get-post-blogs';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { useTheme } from 'ant/theme/provider';
import { CommentContentTypes } from 'ant/types/models/comment.model';
import { PostNewsModel } from 'ant/types/models/post';
import { ReactionId, ReactionNewsContentType } from 'ant/types/models/reactions.model';
import { PostsStorage, getSinglePostStorage } from 'components-frontend/store/post';

import { PostCompound } from '../compound';

type Props = {
  post: PostNewsModel;
  postsStorage: PostsStorage;
};

export const NewsPost: FC<Props> = (props) => {
  const { post, postsStorage } = props;
  const { id: postId, type: postType } = post;

  const newsStorage = useMemo(() => getSinglePostStorage<PostNewsModel>(), []);
  const { reactOnNewsEffect, updateCommentsCountEvent } = newsStorage;

  const [{ variables: themeVariables }] = useTheme();

  const isCommentsDisabledPostSettings = post?.settings && !post.settings.isComments;
  const isCommentsDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isComments');
  const isCommentsDisabled = isCommentsDisabledPostSettings || isCommentsDisabledPostBlogs;
  const showComments = !isCommentsDisabled;

  const openLongread = useCallback(() => {
    const newsLongreadParams = {
      newsId: postId,
      newsType: post.type,
    };

    const newsLongreadPayload = {
      ...newsLongreadParams,
      onEditApply: postsStorage.storage.refetchWithLastParams,
    };

    openGlobalModal(GlobalModalNames.NewsLongread, newsLongreadPayload, newsLongreadParams);
  }, [postId, postType, postsStorage.storage.refetchWithLastParams]);

  const onPostReaction = useCallback(
    (reactionId: ReactionId, isLike: boolean) => {
      reactOnNewsEffect({
        objectId: postId,
        objectType: ReactionNewsContentType.News,
        reactionId,
        isLike,
      }).catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось поставить реакцию')));
    },
    [postId, postType, reactOnNewsEffect],
  );

  const onPostCommentsCountUpdate = useCallback(
    (commentsCount: number) => updateCommentsCountEvent(commentsCount),
    [postId, postType, updateCommentsCountEvent],
  );

  return (
    <Post style={{ paddingTop: 24 }}>
      <Post.Header
        padding={[0, 24]}
        author={post.author}
        postDate={post.publishedAt || post.createdAt}
        extra={
          post.timelinePinned && (
            <UiIcon component={PinSvg} width={20} height={20} color={themeVariables.colorBrand} />
          )
        }
      />
      <Post.Body padding={[16, 24]}>
        <UiSpace size={24} full direction="vertical">
          <UiCollapse.Content>
            <Post.Body.Markup
              post={post}
              onClick={openLongread}
              extra={<UiImage src={post.cover} style={{ margin: '24px 0' }} />}
            />
          </UiCollapse.Content>

          <Post.Body.Reactions post={post} onReaction={onPostReaction} />
        </UiSpace>
      </Post.Body>
      <UiDivider emptyMargin />
      <Post.Footer>
        {showComments && (
          <PostCompound.CommentsWithInput
            objectId={post.id}
            showInput={Boolean(post.author.isActive || post.author.keycloakUser?.isActive)}
            contentType={CommentContentTypes.NewsEntry}
            onCommentsCountUpdate={onPostCommentsCountUpdate}
          />
        )}
      </Post.Footer>
    </Post>
  );
};
