import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiForm } from 'ant/components/ui/form';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiSpinner } from 'ant/components/ui/spinner';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { GetWidgetStorage } from 'ant/store/pages';
import { WidgetDataNavigation, WidgetDefaultDataNavigation } from 'components-frontend/typings/navigation';

import { NavigationMenuType } from '../menu/NavigationMenu';
import styles from './NavigationEdit.scss';
import { SectionsList } from './sections-list/SectionsList';

type Props = {
  onClose: () => void;
  navigation: NavigationMenuType;
  navigationWidgetStorage: GetWidgetStorage<WidgetDataNavigation>;
};

export type NavigationEditSectionsForm = { sections: WidgetDefaultDataNavigation[] };

export const NavigationEdit: FC<Props> = (props) => {
  const { navigation, onClose, navigationWidgetStorage } = props;
  const { widgetId, defaultMenu: sections } = navigation;
  const [form] = UiForm.useForm<NavigationEditSectionsForm>();
  const { updateWidgetDefaultDataEffect } = navigationWidgetStorage;
  const savePending = useStore(updateWidgetDefaultDataEffect.pending);

  const onSaveSuccess = useCallback(() => {
    onClose();
    message.success('Сохранено');
  }, [onClose]);

  const onSaveEdit = () => {
    const { sections: data } = form.getFieldsValue();

    updateWidgetDefaultDataEffect({ widgetId, data })
      .then(onSaveSuccess)
      .catch((e) => message.error(e.response?.data?.detail?.toString() || 'Ошибка сохранения'));
  };

  return (
    <>
      <UiModal.Header className={styles.navigationEdit__header}>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Настройка меню')} />
      </UiModal.Header>

      <UiModal.Content>
        <UiSpinner spinning={savePending}>
          <UiForm
            form={form}
            name="navigation-edit-sections"
            initialValues={{ sections }}
            className={styles.navigationEdit__formContent}
            onFinish={onSaveEdit}
          >
            <SectionsList />
          </UiForm>
        </UiSpinner>
      </UiModal.Content>

      <UiModal.Footer className={styles.navigationEdit__footer}>
        <UiButton size="large" type="primary" label="Сохранить" onClick={form.submit} loading={savePending} />
        <UiButton size="large" type="tertiary" label="Отмена" onClick={onClose} />
      </UiModal.Footer>
    </>
  );
};
