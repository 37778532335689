import { UiSelectDefaultOption } from 'ant/components/ui/select';
import { PermissionForm } from 'components-frontend/typings/navigation';

export const isPermissionOptions = (
  permissions: PermissionForm[],
): permissions is UiSelectDefaultOption[] => {
  if (permissions.length > 0) {
    const [firstItem] = permissions;

    return typeof firstItem === 'object' && 'value' in firstItem;
  }

  return false;
};
