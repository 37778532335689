import classnames from 'classnames';
import React from 'react';

import { UiTable, UiTableProps } from 'ant/components/ui/table';

import styles from './SlotsTable.scss';
import { SlotsTableSelection, SlotsTableSelectionComponent } from './selection/SlotsTableSelection';
import { SlotsTableSlot, SlotsTableSlotComponent } from './slot/SlotsTableSlot';

type SlotsTableComposition = {
  Slot: SlotsTableSlotComponent;
  Selection: SlotsTableSelectionComponent;
};

interface SlotsTableProps extends UiTableProps {
  disabled?: boolean;
}

export const SlotsTable: React.FC<SlotsTableProps> & SlotsTableComposition = (props) => {
  const { className, disabled, ...restProps } = props;

  return (
    <>
      <UiTable
        scroll={{ x: 'max-content' }}
        sticky
        {...restProps}
        pagination={false}
        className={classnames(
          styles.slotsTable,
          {
            [styles.slotsTable__disabled]: disabled,
          },
          className,
        )}
      />
    </>
  );
};

SlotsTable.Slot = SlotsTableSlot;
SlotsTable.Selection = SlotsTableSelection;
