import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './PostGamificationWrapper.scss';

export type PostGamificationWrapperProps = Pick<HTMLAttributes<HTMLDivElement>, 'style'>;

export const PostGamificationWrapper: FC<PropsWithChildren<PostGamificationWrapperProps>> = (props) => {
  const { children, style } = props;

  return (
    <div style={style} className={styles.postGamificationWrapper}>
      {children}
    </div>
  );
};
