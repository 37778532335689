import { useStore } from 'effector-react';
import qs from 'query-string';
import React, { ChangeEventHandler, FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { AnimatedSearchInputWidget } from 'ant/components/widgets/AnimatedSearch/AnimatedSearch';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { newsCategoriesStore } from 'ant/store/news';
import { SearchCategoryType, SearchSingleCategory } from 'ant/types/models/search.model';
import {
  HEADER_SEARCH_CATEGORIES_AMOUNT,
  HEADER_SEARCH_CATEGORY_AMOUNT,
  SEARCH_ORIGIN_LIST,
} from 'components-frontend/components/header/search/constants';
import { ResultLists } from 'components-frontend/components/header/search/result-lists/ResultLists';
import { getSearchStorage, SearchCategory } from 'components-frontend/store/search/advanced-search';

import { Backdrop } from '../../backdrop';

const MIN_SYMBOLS_TO_SEARCH = 2;

const { searchStorages, searchStatusStore, resetStoresEvent, startSearchEvent } = getSearchStorage({
  storageNames: [
    SearchSingleCategory.Profile,
    SearchSingleCategory.Blog,
    SearchSingleCategory.Post,
    SearchSingleCategory.Comment,
    SearchSingleCategory.News,
    SearchSingleCategory.Pages,
  ],
  params: {
    searchCategoryAmount: HEADER_SEARCH_CATEGORY_AMOUNT,
    searchAllAmount: HEADER_SEARCH_CATEGORIES_AMOUNT,
  },
});

export const HeaderSearch: FC = () => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCategory, setSearchCategory] = useState<SearchCategoryType>(SearchCategory.All);

  const { data: newsCategories } = useAbstractStorage(newsCategoriesStore.storage, {
    autoFetchAndRefetch: true,
  });
  const { loading } = useStore(searchStatusStore);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && searchInputRef.current) {
        setIsSearchOpen(false);
        searchInputRef.current.blur();
      }
    },
    [setIsSearchOpen, searchInputRef.current],
  );

  const onClearSearch = () => {
    searchInputRef.current?.focus();
    setSearchTerm('');
    resetStoresEvent();
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => setSearchTerm(value);

  const onFocus = () => setIsSearchOpen(true);

  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener('keydown', onKeyDown, false);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);

      setSearchTerm('');
      setSearchCategory(SearchCategory.All);
      resetStoresEvent();
    };
  }, [isSearchOpen]);

  const isSearchTermValid = searchTerm.trim().length >= MIN_SYMBOLS_TO_SEARCH;

  const onSearch = (query: string) => {
    resetStoresEvent();

    startSearchEvent({
      searchString: query,
      searchCategory,
    });
  };

  const debouncedFetchSearchResult = useDebouncedCallback((query) => {
    if (!isSearchTermValid) {
      resetStoresEvent();
      return;
    }

    onSearch(query);
  }, 500);

  useEffect(() => {
    const query = searchTerm.trim();

    debouncedFetchSearchResult(query);
  }, [searchTerm, searchCategory, debouncedFetchSearchResult]);

  const handleSearchCategoryChange = (newSearchCategory: SearchCategoryType) => {
    if (newSearchCategory !== searchCategory) {
      resetStoresEvent();
      setSearchCategory(newSearchCategory as SearchCategoryType);
    }

    searchInputRef.current?.focus();
  };

  const handleShowMoreClick = (listType: string) => {
    navigate({
      pathname: getRoutePath(RouteNames.AdvancedSearch),
      search: qs.stringify(
        {
          category: listType,
          query: searchTerm,
          pageNumber: 1,
          pageSize: 15,
        },
        { skipEmptyString: true },
      ),
    });

    setIsSearchOpen(false);
  };

  return (
    <Backdrop isOpen={isSearchOpen} onClick={() => setIsSearchOpen(false)}>
      <AnimatedSearchInputWidget
        ref={searchInputRef}
        value={searchTerm}
        isFocused={isSearchOpen}
        onChange={onChange}
        onFocus={onFocus}
        onClear={onClearSearch}
      >
        <ResultLists
          originList={SEARCH_ORIGIN_LIST}
          searchCategory={searchCategory}
          isLoading={loading}
          onShowMoreClick={handleShowMoreClick}
          onSearchCategoryChange={handleSearchCategoryChange}
          onClose={() => setIsSearchOpen(false)}
          searchBlogStorage={searchStorages[SearchSingleCategory.Blog]}
          searchCommentStorage={searchStorages[SearchSingleCategory.Comment]}
          searchPostStorage={searchStorages[SearchSingleCategory.Post]}
          searchProfileStorage={searchStorages[SearchSingleCategory.Profile]}
          searchNewsStorage={searchStorages[SearchSingleCategory.News]}
          searchPagesStorage={searchStorages[SearchSingleCategory.Pages]}
          newsCategoriesDist={newsCategories}
        />
      </AnimatedSearchInputWidget>
    </Backdrop>
  );
};
