import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { CreateUpdatePollModel, PollModel, VoteModel } from 'ant/types/models/poll';
import {
  createPoll,
  updatePollById,
  deletePollById,
  vote,
  exportPollToXLSX,
  exportPollAnswerToXLSX,
} from 'components-frontend/store/poll/api';

type UpdateParams = {
  id: number;
  body: CreateUpdatePollModel;
};

type VoteParams = {
  id: number;
  body: VoteModel[];
};

type ExportXLSParams = {
  questionId: number;
  optionId: number;
};

export const createPollEffect = createEffect<CreateUpdatePollModel, PollModel, AxiosError>((params) =>
  createPoll(params).then(({ data }) => data),
);

export const updatePollEffect = createEffect<UpdateParams, PollModel, AxiosError>(({ id, body }) =>
  updatePollById(id, body).then(({ data }) => data),
);

export const deletePollByIdEffect = createEffect<number, unknown, AxiosError>(deletePollById);

export const votePollEffect = createEffect<VoteParams, PollModel, AxiosError>(({ id, body }) =>
  vote(id, body).then(({ data }) => data),
);

export const exportPollToXLSXEffect = createEffect<number, Blob, AxiosError>((id) =>
  exportPollToXLSX(id).then(({ data }) => data),
);

export const exportPollAnswerToXLSXEffect = createEffect<ExportXLSParams, Blob, AxiosError>(
  ({ questionId, optionId }) => exportPollAnswerToXLSX(questionId, optionId).then(({ data }) => data),
);
