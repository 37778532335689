import React from 'react';

import { UiCheckbox } from 'ant/components/ui/checkbox';
import { UiInput } from 'ant/components/ui/input';
import { UiRadio } from 'ant/components/ui/radio';
import { normalizeValueTrimStart } from 'ant/helpers/normalize';
import { PollOptionModel, PollQuestionModel, PollQuestionTypes, VoteModel } from 'ant/types/models/poll';

import { ValueChange } from '../PollAnswers';
import { ANSWER_PREFIX } from '../constants';
import { FileInput } from './file-input/FileInput';

type PollAnswersInputProps = {
  question: PollQuestionModel;
  option?: PollOptionModel;
  answer?: VoteModel;
  onValueChange?: ValueChange;
  isDisabled?: boolean;
};

export const PollAnswersInput: React.FC<PollAnswersInputProps> = (props) => {
  const { question, option, isDisabled, answer, onValueChange } = props;

  if (question.type === PollQuestionTypes.SINGLE_CHOICE) {
    return <UiRadio disabled={isDisabled} value={option?.id} id={`${ANSWER_PREFIX}${option?.id}`} />;
  }

  if (question.type === PollQuestionTypes.MULTIPLE_CHOICE) {
    return (
      <UiCheckbox
        disabled={isDisabled}
        value={option?.id}
        checked={false}
        id={`${ANSWER_PREFIX}${option?.id}`}
      />
    );
  }

  if (question.type === PollQuestionTypes.FREE_FILE) {
    return (
      <FileInput
        question={question}
        onChange={(vote) => onValueChange?.(vote, question.id)}
        disabled={isDisabled}
        value={Number(answer?.value)}
      />
    );
  }

  return (
    <UiInput
      onChange={(e) =>
        onValueChange?.(
          [{ value: normalizeValueTrimStart(e.target.value), question: question.id }],
          question.id,
        )
      }
      disabled={isDisabled}
      placeholder="Введите значение"
      value={normalizeValueTrimStart(answer?.value || '')}
    />
  );
};
