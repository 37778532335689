import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { PostBasicModel } from 'ant/types/models/post';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchPostStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<PostBasicModel>,
    PostBasicModel[],
    PostBasicModel[],
    SearchParams
  >({
    endpointBuilder: ({ searchString, pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(BlogsEndpoints.entrySearch(), { searchString, pageNumber, pageSize }),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
