import classNames from 'classnames';
import React, { useMemo } from 'react';

import CheckSVG from 'ant/components/svg/check.svg';
import DownloadSVG from 'ant/components/svg/download.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiDivider } from 'ant/components/ui/divider';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiProgress } from 'ant/components/ui/progress';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypographyTextTypes, UiTypography } from 'ant/components/ui/typography';
import { AvatarsTooltip } from 'ant/components/widgets/AvatarsTooltip/AvatarsTooltip';
import { themePalette } from 'ant/theme';
import { PollModel, PollOptionModel, PollQuestionModel, PollQuestionTypes } from 'ant/types/models/poll';

import { ANSWER_PREFIX } from '../constants';
import styles from './PollAnswersDetail.scss';

type PollAnswerDetailProps = {
  poll: PollModel;
  question: PollQuestionModel;
  option: PollOptionModel;
  onClick?: () => void;
  onSaveFile?: (questionId: number, optionId: number, name: string) => void;
  answerClassName?: string;
  uniqueRespondentsCount: number;
};

export const PollAnswersDetail: React.FC<PollAnswerDetailProps> = (props) => {
  const { poll, onClick, option, answerClassName, question, onSaveFile, uniqueRespondentsCount } = props;
  const { isAnonymous, userHasResult: isFinished, isAuthor } = poll;

  const detailTitle = useMemo(
    () => (
      <UiRow align="middle" className={styles.pollAnswersDetail__tooltip}>
        <UiTypography.Text>Проголосовали:</UiTypography.Text>
        <UiTypography.Text strong>{option.optionAnswersCount || 0}</UiTypography.Text>
      </UiRow>
    ),
    [option.optionAnswersCount],
  );

  const answerPercentage = useMemo(() => {
    const { optionAnswersCount } = option;

    if (!question.answersCount || !optionAnswersCount) {
      return 0;
    }

    if (question.type === PollQuestionTypes.MULTIPLE_CHOICE) {
      return Math.round((optionAnswersCount * 100) / uniqueRespondentsCount);
    }

    return Math.round((optionAnswersCount * 100) / question.answersCount);
  }, [option.optionAnswersCount, question.answersCount, uniqueRespondentsCount]);

  const showMore = () => {
    if (isAnonymous || !isFinished) {
      return;
    }

    onClick?.();
  };

  const onKeyPress: React.KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      showMore();
    }
  };

  const saveFileHandler = (e: React.MouseEvent, questionId: number, optionId: number, name: string) => {
    e.stopPropagation();
    onSaveFile?.(questionId, optionId, name);
  };

  return (
    <AvatarsTooltip
      key={option.id}
      users={option.answerRespondents}
      title={detailTitle}
      onClickSuffix={showMore}
      isActivate={isFinished && !isAnonymous && option.answerRespondents.length > 0}
    >
      <div
        role="menuitem"
        tabIndex={-1}
        onClick={showMore}
        onKeyPress={onKeyPress}
        className={styles.pollAnswersDetail__answer}
      >
        <label htmlFor={`${ANSWER_PREFIX}${option.id}`} className={styles.pollAnswersDetail__label}>
          <UiRow
            justify="space-between"
            className={classNames(styles.pollAnswersDetail__info, answerClassName)}
          >
            <UiRow align="middle">
              {option.answeredByMe ? (
                <UiIcon.Label
                  type={UiTypographyTextTypes.Primary}
                  label={option.option}
                  component={CheckSVG}
                  className={styles.pollAnswersDetail__checkIcon}
                />
              ) : (
                <UiTypography.Text>{option.option}</UiTypography.Text>
              )}

              <UiDivider.Dot type="secondary" />

              <UiTypography.Text type="secondary">{option.optionAnswersCount}</UiTypography.Text>
            </UiRow>

            <UiRow justify="start">
              <UiTypography.Text strong className={styles.pollAnswersDetail__percentage}>
                <UiRow justify="end" style={{ width: '100%' }}>
                  {`${answerPercentage} %`}
                </UiRow>
              </UiTypography.Text>

              {isAuthor && (
                <UiTooltip title="Сохранить выборку в файл" placement="left">
                  <UiButton
                    type="link-secondary"
                    className={styles.pollAnswersDetail__downloadIcon}
                    icon={<UiIcon component={DownloadSVG} height={20} width={20} />}
                    onClick={(e) => saveFileHandler(e, question.id, option.id, String(question.id))}
                  />
                </UiTooltip>
              )}
            </UiRow>
          </UiRow>

          <UiProgress
            className={styles.pollAnswersDetail__progressBar}
            strokeLinecap="square"
            percent={answerPercentage}
            showInfo={false}
            strokeColor={themePalette.colorAccidentBlue}
            trailColor={themePalette.colorTaskUnconfirmed}
            strokeWidth={4}
          />
        </label>
      </div>
    </AvatarsTooltip>
  );
};
