import { CMSEndpoints } from 'ant/endpoints/cms';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { CMSPageModel } from 'ant/types/models/cms.model';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchPagesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CMSPageModel>,
    CMSPageModel[],
    CMSPageModel[],
    SearchParams
  >({
    endpointBuilder: ({ pageNumber, pageSize, searchString, searchField, sectionId }) =>
      buildEndpointWithQueryParams(CMSEndpoints.pages(), {
        pageNumber,
        pageSize,
        query: searchString,
        searchField,
        sectionId,
      }),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
