import { ProfileEndpoints } from 'ant/endpoints/profile';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { SearchListItem, SearchParams } from 'ant/types/models/search.model';
import { ProfileMain, searchListResultDataMapper } from 'components-frontend/typings/search/profile';

export const getSearchProfileAdvancedStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProfileMain>,
    SearchListItem[],
    SearchListItem[],
    SearchParams
  >({
    endpointBuilder: ({ searchString, ...restParams }) =>
      buildEndpointWithQueryParams(ProfileEndpoints.v3AdvancedSearch(), {
        query: searchString,
        skipEmptyName: true,
        ...restParams,
      }),
    defaultValue: [],
    dataMapper: ({ items }) => searchListResultDataMapper(items),
    paginationInfoRetriever: ({ meta: { objectsTotal: count } }) => ({ count }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
