import React, { FC, PropsWithChildren } from 'react';

import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './PollQuestion.scss';

export type PollQuestionType = {
  questionIndex: number;
  title: string;
  total: number;
};

export const PollQuestion: FC<PropsWithChildren<PollQuestionType>> = (props) => {
  const { children, total, questionIndex, title } = props;

  return (
    <>
      <UiRow className={styles.pollQuestion} gutter={[0, 8]}>
        <UiCol span={24}>
          <UiTypography.Title level={1} style={{ margin: 0 }}>
            <UiTruncateMarkup truncate lines={10}>
              {title}
            </UiTruncateMarkup>
          </UiTypography.Title>
        </UiCol>

        {total > 1 && (
          <UiCol span={24}>
            <UiTypography.Text strong type="secondary">
              {`Вопрос ${questionIndex + 1} из ${total}`}
            </UiTypography.Text>
          </UiCol>
        )}
      </UiRow>

      {children}
    </>
  );
};
