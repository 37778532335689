import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiCol } from 'ant/components/ui/grid';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { BlogInviteModel, NotificationModel } from 'ant/types/models/notification.model';

import { BlogInviteActions } from './actions/BlogInviteActions';

interface Props {
  notification: NotificationModel<BlogInviteModel>;
}

export const NotificationBlogInvite: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId },
    service: serviceTitle,
    content: { id: blogId, name },
  } = notification;

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle="предложение подписаться на группу"
        avatar={avatar}
      />
      <Notification.Body>
        <Link to={getRoutePath(RouteNames.GroupView, { id: blogId })}>
          <UiTypography.Link strong style={{ display: 'block' }}>
            <UiTruncateMarkup truncate lines={2}>
              {name}
            </UiTruncateMarkup>
          </UiTypography.Link>
        </Link>
        <UiCol style={{ marginBottom: 8 }}>
          <BlogInviteActions notification={notification} />
        </UiCol>
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
