import { ActionsDropdownItem } from 'ant/components/widgets/ActionsDropdown';
import { openFileDialog } from 'ant/plugins/utils/open-file-dialog';
import { UploadFileStorageMultipleAttachmentsEffect } from 'ant/store/filestorage';
import { FileStorageApiVersions } from 'ant/store/filestorage/api';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { PostAttachedEntity, PostAttachmentTypes } from 'ant/types/models/post-attachment';

import { attachmentActionFields, singleAttachmentTypes } from './constants';

type GetAttachmentsActionByTypeFn = {
  type: PostAttachmentTypes;
  uploadEffect: UploadFileStorageMultipleAttachmentsEffect;
  callback: (attachmentType: PostAttachmentTypes, attachedEntity: PostAttachedEntity) => void;
};

export interface AttachmentsAction extends ActionsDropdownItem {
  type: PostAttachmentTypes;
}

const getAttachmentActionByType = (params: GetAttachmentsActionByTypeFn) => {
  const { type, callback, uploadEffect } = params;

  if (type === PostAttachmentTypes.Poll) {
    openGlobalModal(GlobalModalNames.PollEditor, {
      onSuccess: (poll) => callback(PostAttachmentTypes.Poll, poll),
    });
  }

  if (type === PostAttachmentTypes.Event) {
    openGlobalModal(GlobalModalNames.EventsList, {
      onSelect: (event) => callback(PostAttachmentTypes.Event, event),
    });
  }

  if (type === PostAttachmentTypes.Image || type === PostAttachmentTypes.File) {
    const accept = type === PostAttachmentTypes.Image ? FileUploadAccepts.ImageAll : FileUploadAccepts.All;

    openFileDialog({ multiple: true, accept }).then((files) => {
      uploadEffect({
        files,
        apiVersion: FileStorageApiVersions.v3,
      }).then((uploadedFiles) => uploadedFiles.forEach((uploadedFile) => callback(type, uploadedFile)));
    });
  }
};

export const getAttachmentActions = (
  uploadEffect: GetAttachmentsActionByTypeFn['uploadEffect'],
  callback: GetAttachmentsActionByTypeFn['callback'],
): AttachmentsAction[] => {
  return Object.values(PostAttachmentTypes).map((attachmentType) => {
    const type = attachmentType as PostAttachmentTypes;

    return {
      type,
      label: attachmentActionFields[attachmentType].label,
      icon: attachmentActionFields[attachmentType].icon,
      onClick: () => getAttachmentActionByType({ type, uploadEffect, callback }),
    };
  });
};

export const isSingleAttachmentType = (type: PostAttachmentTypes) => singleAttachmentTypes.has(type);
