import React, { FC } from 'react';

import { UiFormComposition } from 'ant/components/ui/form/UiForm';
import { UiRadio } from 'ant/components/ui/radio';
import { UiSpace } from 'ant/components/ui/space';
import { themePalette } from 'ant/theme';

import styles from './MarkerColorField.scss';

export enum MarkerColor {
  Red,
  Coral,
  Orange,
  Yellow,
  Lime,
  Sea,
  Turquoise,
  Cyan,
  Blue,
  Lavender,
  Pink,
  Magenta,
  Silver,
  Gold,
}

export const MarkerColorMap: Record<MarkerColor, string> = {
  [MarkerColor.Red]: themePalette.colorAccidentRed,
  [MarkerColor.Coral]: themePalette.colorAccidentCoral,
  [MarkerColor.Orange]: themePalette.colorAccidentOrange,
  [MarkerColor.Yellow]: themePalette.colorAccidentYellow,
  [MarkerColor.Lime]: themePalette.colorAccidentLime,
  [MarkerColor.Sea]: themePalette.colorAccidentSea,
  [MarkerColor.Turquoise]: themePalette.colorAccidentTurquoise,
  [MarkerColor.Cyan]: themePalette.colorAccidentCyan,
  [MarkerColor.Blue]: themePalette.colorAccidentBlue,
  [MarkerColor.Lavender]: themePalette.colorAccidentLavender,
  [MarkerColor.Pink]: themePalette.colorAccidentPink,
  [MarkerColor.Magenta]: themePalette.colorAccidentMagenta,
  [MarkerColor.Silver]: themePalette.colorAccidentTagSilver,
  [MarkerColor.Gold]: themePalette.colorAccidentGold,
};

export const MarkerColorField: FC<Partial<UiFormComposition['Item']>> = (props) => {
  return (
    <div className={styles.markerColorField}>
      <UiRadio.Group buttonStyle="solid" size="small" className={styles.markerColorField} {...props}>
        <UiSpace direction="horizontal" size={7.69}>
          {Object.values(MarkerColorMap).map((markerColor) => (
            <UiRadio.Button key={markerColor} value={markerColor} style={{ background: markerColor }} />
          ))}
        </UiSpace>
      </UiRadio.Group>
    </div>
  );
};
