// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a5880971_poll-options__validation{margin-bottom:0}.a5880971_poll-options__validation .ant-form-item-control-input{min-height:0}.a5880971_poll-options__option{margin-bottom:8px}.a5880971_poll-options__button{margin-bottom:0;margin-top:12px}.a5880971_poll-options__button .ant-form-item-control-input{min-height:20px}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/poll/poll-editor/poll-options/PollOptions.scss"],"names":[],"mappings":"AAEE,mCACE,eAAA,CAEA,gEACE,YAAA,CAIJ,+BACE,iBAAA,CAGF,+BACE,eAAA,CACA,eAAA,CAEA,4DACE,eAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.poll-options {\n  &__validation {\n    margin-bottom: 0;\n\n    :global .ant-form-item-control-input {\n      min-height: 0;\n    }\n  }\n\n  &__option {\n    margin-bottom: 8px;\n  }\n\n  &__button {\n    margin-bottom: 0;\n    margin-top: 12px;\n\n    :global .ant-form-item-control-input {\n      min-height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-options__validation": "a5880971_poll-options__validation",
	"pollOptions__validation": "a5880971_poll-options__validation",
	"poll-options__option": "a5880971_poll-options__option",
	"pollOptions__option": "a5880971_poll-options__option",
	"poll-options__button": "a5880971_poll-options__button",
	"pollOptions__button": "a5880971_poll-options__button"
};
export default ___CSS_LOADER_EXPORT___;
