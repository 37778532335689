import { stringify } from 'query-string';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CalendarSvg from 'ant/components/svg/calendar.svg';
import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { utcToLocalDate } from 'ant/plugins/utils/get-formatted-date';
import { CalendarEventModel, CalendarViewType } from 'ant/types/models/calendar';
import { NotificationModel, NotificationTypes } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';
import { isNotificationType } from 'components-frontend/typings/guards/notification-guards';

import { CalendarEventActions } from './actions/CalendarEventActions';

interface Props {
  notification: NotificationModel<CalendarEventModel>;
}

export const NotificationCalendarEvent: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { notification } = props;
  const {
    image,
    createdAt,
    content,
    initiator: { fullName, keycloakId },
    service: serviceTitle,
  } = notification;
  const { since, summary } = content;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const eventStartTime = useMemo(() => utcToLocalDate(since), [since]);
  const onClick = () => {
    return navigate(
      { pathname: getRoutePath(RouteNames.Calendar), search: stringify({ tab: CalendarViewType.Day }) },
      { state: { date: since } },
    );
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />
      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          <UiSpace size={4} direction="vertical">
            <UiTypography.Link strong onClick={onClick}>
              <UiTruncateMarkup truncate lines={2}>
                {summary}
              </UiTruncateMarkup>
            </UiTypography.Link>
            {eventStartTime && <UiIcon.Label component={CalendarSvg} label={eventStartTime} />}
          </UiSpace>
          {isNotificationType(notification.notificationType, NotificationTypes.CalendarGroupEventInvite) && (
            <CalendarEventActions notification={notification} />
          )}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
