import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { UiDivider } from 'ant/components/ui/divider';
import { UiEmpty } from 'ant/components/ui/empty';
import { message } from 'ant/components/ui/message';
import { UiModalBase } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { UiTag } from 'ant/components/ui/tag';
import { Post, PostSwitchAction } from 'ant/components/widgets/Post';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getPostBlogsMap } from 'ant/plugins/get-post-blogs';
import { capitalizeFirstLetter } from 'ant/plugins/utils/capitalize-first-letter';
import { getPostAttachmentsStorage } from 'ant/store/attachment';
import { closeGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { isBasicPost, isBlogPost } from 'ant/types/guards/post';
import { PostLongreadPayload } from 'ant/types/longread';
import { PostTypes } from 'ant/types/models/post';
import { PostFormAttachmentId } from 'ant/types/models/post-attachment';
import { ReactionId } from 'ant/types/models/reactions.model';
import { deletePostEffect, getSinglePostStorage } from 'components-frontend/store/post';

import { PostAttachmentsAdapter } from '../post/attachments/adapter/PostAttachmentsAdapter';
import { PostCompound } from '../post/compound';
import { LongreadComments } from './comments/LongreadComments';
import { LongreadLoading } from './loading/LongreadLoading';

export type LongreadProps = PostLongreadPayload;

export const PostLongread: FC<LongreadProps> = (props) => {
  const { postId, postType } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const isDeletePostPending = useStore(deletePostEffect.pending);

  const postStorage = useMemo(getSinglePostStorage, []);
  const postAttachmentsStorage = useMemo(
    () => getPostAttachmentsStorage({ postId, postType }),
    [postId, postType],
  );

  const { reloadAttachmentByIdEffect } = postAttachmentsStorage;
  const { reactOnPostEffect, updateCommentsCountEvent, switchFavoritePostEffect } = postStorage;

  const {
    data: post,
    loading: isPostLoading,
    error,
  } = useAbstractStorage(postStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { postId, postType },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const { data: postAttachments, loading: isPostAttachmentsLoading } = useAbstractStorage(
    postAttachmentsStorage.storage,
    {
      autoFetchAndRefetch: true,
      cancelPendingRequestOnUnmount: true,
    },
  );

  const onPostEdit = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const onPostDelete = useCallback(() => {
    const postName = postType === PostTypes.Micropost ? 'микропост' : 'пост';

    UiModalBase.warning({
      title: `Удалить ${postName}`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      disabled: isDeletePostPending,
      onOk: async (confirmClose) => {
        try {
          await deletePostEffect({ postId, postType });
          confirmClose();
          closeGlobalModal(GlobalModalNames.PostLongread);
          message.success(`${capitalizeFirstLetter(postName)} удален`);
        } catch (e) {
          message.error(getErrorResponseMessage(e, `Ошибка удаления ${postName}`));
        }
      },
    });
  }, [postId, postType, deletePostEffect]);

  const onPostReaction = useCallback(
    (reactionId: ReactionId, isLike: boolean) => {
      if (post) {
        reactOnPostEffect({ reactionId, isLike, postId: post.id, postType }).catch((e) =>
          message.error(getErrorResponseMessage(e, 'Не удалось поставить реакцию')),
        );
      }
    },
    [post?.id, postType, reactOnPostEffect],
  );

  const onPostFavoriteSwitch: PostSwitchAction = useCallback(
    (isFavorite) => {
      switchFavoritePostEffect({ postId, postType, favorite: isFavorite }).catch(() =>
        message.error('Не удалось добавить пост в избранное'),
      );
    },
    [postId, postType, switchFavoritePostEffect],
  );

  const onAttachmentReload = useCallback(
    (attachmentId: PostFormAttachmentId) => reloadAttachmentByIdEffect({ attachmentId }),
    [reloadAttachmentByIdEffect],
  );

  const tags = post ? post.tags : [];

  useEffect(() => {
    if (error) {
      closeGlobalModal(GlobalModalNames.PostLongread);

      message.error(
        getErrorResponseMessage(error, `Ошибка. Поста с идентификатором ${postId} не существует`),
      );
    }
  }, [error]);

  const blogs = post && isBlogPost(post) ? post?.blogs : [];
  const isCommentsDisabledByBlog = useMemo(() => {
    return Array.isArray(blogs) ? blogs.some((blog) => !blog.isComments) : !blogs?.isComments;
  }, [blogs]);

  const isPostEntry = post && isBasicPost(post);

  const isCommentsDisabledBySettings = isPostEntry && !post?.settings?.isComments;
  const isCommentsDisabled = isCommentsDisabledByBlog || isCommentsDisabledBySettings;

  const isLoading = isPostLoading || isPostAttachmentsLoading;
  const isError = (!post && !isLoading) || error;

  const postBlogs = useMemo(() => {
    return post ? getPostBlogsMap(post) : [];
  }, [post]);

  if (isError) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Пост не загрузился' }} />;
  }

  if (isLoading) {
    return <LongreadLoading />;
  }

  return (
    <>
      {isPostEntry && isEditMode && (
        <PostCompound.Edit
          onCancel={() => setIsEditMode(false)}
          post={post}
          postType={post.type}
          attachmentsStorage={postAttachmentsStorage}
          attachments={postAttachments}
        />
      )}
      {isPostEntry && !isEditMode && (
        <Post style={{ paddingTop: 32 }}>
          <Post.Header
            blogs={postBlogs}
            author={post.author}
            postDate={post.publishedAt || post.createdAt}
            padding={[0, 48]}
          >
            <UiSpace>
              <Post.Header.Actions
                onEdit={onPostEdit}
                post={post}
                onFavorite={onPostFavoriteSwitch}
                onDelete={onPostDelete}
              />
            </UiSpace>
          </Post.Header>
          <Post.Body padding={[16, 48]}>
            <UiSpace size={24} full direction="vertical">
              <Post.Body.Markup post={post} />
              {tags.length > 0 && <UiTag.Group tags={tags} />}
              {postAttachments.length > 0 && (
                <Post.Body.Attachments
                  attachments={postAttachments}
                  renderItem={(item) => (
                    <PostAttachmentsAdapter value={item} reloadAttachment={onAttachmentReload} />
                  )}
                />
              )}
              <Post.Body.Reactions post={post} onReaction={onPostReaction} />
            </UiSpace>
          </Post.Body>
          <UiDivider emptyMargin />
          <Post.Footer>
            {!isCommentsDisabled && (
              <LongreadComments
                style={{ paddingLeft: 48, paddingRight: 48 }}
                post={post}
                onCommentsCountUpdate={updateCommentsCountEvent}
                showInput={Boolean(post.author.isActive)}
              />
            )}
          </Post.Footer>
        </Post>
      )}
    </>
  );
};
