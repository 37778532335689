import React from 'react';
import { Link } from 'react-router-dom';

import AddSVG from 'ant/components/svg/add.svg';
import { UiButton } from 'ant/components/ui/button/UiButton';
import { UiIcon } from 'ant/components/ui/icon';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import CalendarSVG from 'calendar-frontend/components/svg/calendar-widget.svg';

import styles from './CalendarWidgetHeader.scss';

interface Props {
  addEvent: () => void;
}

const CalendarWidgetHeader: React.FC<Props> = ({ addEvent }) => (
  <div className={styles.calendarWidgetHeader}>
    <Link className={styles.calendarWidgetHeader__titleBlock} to={getRoutePath(RouteNames.Calendar)}>
      <UiIcon width={24} height={24} component={CalendarSVG} className={styles.calendarWidgetHeader__icon} />
      <span className={styles.calendarWidgetHeader__title}>Календарь</span>
    </Link>
    <UiButton type="link-secondary" onClick={addEvent} icon={<AddSVG />} />
  </div>
);

export { CalendarWidgetHeader };
