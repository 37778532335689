import classNames from 'classnames';
import React from 'react';

import DeleteSVG from 'ant/components/svg/delete.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiModal } from 'ant/components/ui/modals/modal';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';

import styles from './CreateNewSection.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  onSave: () => void;
  onCancel: () => void;
  onRemove?: () => void;
};

export const CreateNewSection: React.FC<Props> = ({ title, children, onSave, onCancel, onRemove }) => (
  <>
    <UiModal.Header>
      <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
    </UiModal.Header>
    <UiModal.Content className={styles.createNewSection__content}>{children}</UiModal.Content>
    <UiModal.Footer hasTopBorder={false}>
      <UiButton size="large" block type="primary" label="Сохранить" onClick={onSave} />
      <UiButton
        block
        size="large"
        type="tertiary"
        label="Отмена"
        onClick={onCancel}
        className={styles.createNewSection__button_leftMargin}
      />

      {onRemove && (
        <UiButton
          block
          size="large"
          type="secondary"
          onClick={onRemove}
          icon={<UiIcon component={DeleteSVG} width={20} height={20} />}
          className={classNames(styles.createNewSection__button, styles.createNewSection__button_leftMargin)}
        />
      )}
    </UiModal.Footer>
  </>
);
