import React, { FC } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { createArrayMock } from 'ant/plugins/create-mocks';

import styles from '../current/WeatherCurrent.scss';

interface Prop {
  maxCount: number;
}

export const WeatherLoading: FC<Prop> = ({ maxCount }) => {
  return (
    <>
      {createArrayMock(maxCount, (_, id) => (
        <span key={id} className={styles.weather__dailyMock}>
          <UiSkeleton loading width={52} height={14} />
          <UiSkeleton circle loading width={52} height={50} />
          <UiSkeleton loading width={52} height={14} />
        </span>
      ))}
    </>
  );
};
