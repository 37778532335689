import React, { FC } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiForm } from 'ant/components/ui/form';
import { UiRow } from 'ant/components/ui/grid';
import { UiImage } from 'ant/components/ui/image';
import { UiInput } from 'ant/components/ui/input';
import {
  UiTypographySymbol,
  UiTypographySymbolName,
} from 'ant/components/ui/typography/symbol/UiTypographySymbol';
import { requiredRule } from 'ant/helpers/validation';

const LOGO_SRC = '/media/static/logo.svg';

interface FormValues {
  username: string;
  password: string;
}

export const SignInForm: FC = () => {
  const [form] = UiForm.useForm<FormValues>();

  return (
    <UiCard emptyPadding shadow="large" size="default" style={{ padding: '20px 40px 30px', width: 500 }}>
      <UiCard.Content>
        <UiRow justify="center" style={{ padding: 30 }}>
          <UiImage src={LOGO_SRC} />
        </UiRow>
        <UiForm form={form} layout="vertical" size="large">
          <UiForm.Item name="username" label="Логин" required rules={[requiredRule]}>
            <UiInput placeholder="Введите логин" />
          </UiForm.Item>
          <UiForm.Item name="password" label="Пароль" required rules={[requiredRule]}>
            <UiInput.Password placeholder="Введите пароль" />
          </UiForm.Item>
          <UiForm.Item label={<UiTypographySymbol name={UiTypographySymbolName.NBSP} />}>
            <UiRow justify="center">
              <UiButton type="primary" label="Войти" onClick={form.submit} style={{ width: 188 }} />
            </UiRow>
          </UiForm.Item>
        </UiForm>
      </UiCard.Content>
    </UiCard>
  );
};
