import React, { FC, useMemo } from 'react';

import { UiCol, UiRow } from 'ant/components/ui/grid';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getSingleThankStorage } from 'ant/store/gamification';
import { PostGamificationModel as GamificationPostType } from 'ant/types/models/post';
import { GamificationTrophy } from 'components-frontend/components/gamification/trophy/GamificationTrophy';

type Props = {
  post: GamificationPostType;
};

export const PostGamificationThanks: FC<Props> = (props) => {
  const { post } = props;

  const thankStorage = useMemo(getSingleThankStorage, []);

  const { data: thanksData } = useAbstractStorage(thankStorage.storage, {
    cancelPendingRequestOnUnmount: true,
    autoFetchAndRefetch: true,
    autoFetchParams: {
      entityId: post.entityId,
    },
  });

  if (!thanksData) {
    return null;
  }

  return (
    <UiRow align="middle" justify="center">
      <UiCol>
        <GamificationTrophy thank={thanksData} />
      </UiCol>
    </UiRow>
  );
};
