import React from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './NavigationFavoritesActions.scss';

type NavigationFavoritesProps = {
  isFavoritesEdit: boolean;
  isFavoritesEmpty: boolean;
  isSuccessEditDisabled: boolean;
  isSuccessEditLoading: boolean;
  onClickEditHandler: React.MouseEventHandler<HTMLElement>;
  onSuccessEditHandler: React.MouseEventHandler<HTMLElement>;
  onCancelEditHandler: React.MouseEventHandler<HTMLElement>;
};

const EDIT_DESCRIPTION = `Нажмите чекбокс, чтобы добавить\nраздел и настраивайте удобный\nпорядок перетаскиванием.`;
const EMPTY_DESCRIPTION = `Добавьте сюда важные разделы,\nчтобы они всегда были под рукой.`;

export const NavigationFavoritesActions: React.FC<NavigationFavoritesProps> = (props) => {
  const {
    isFavoritesEdit,
    isFavoritesEmpty,
    onClickEditHandler,
    onSuccessEditHandler,
    onCancelEditHandler,
    isSuccessEditDisabled,
    isSuccessEditLoading,
  } = props;

  if (!isFavoritesEdit && !isFavoritesEmpty) {
    return null;
  }

  return (
    <div className={styles.navigationFavoritesActions}>
      {isFavoritesEmpty && (
        <UiTypography.Text className={styles.navigationFavoritesActions__description}>
          {isFavoritesEdit ? EDIT_DESCRIPTION : EMPTY_DESCRIPTION}
        </UiTypography.Text>
      )}

      <div className={styles.navigationFavoritesActions__actions}>
        {isFavoritesEdit && (
          <>
            <UiButton
              type="primary"
              label="Готово"
              loading={isSuccessEditLoading}
              onClick={onSuccessEditHandler}
              disabled={isSuccessEditDisabled || isSuccessEditLoading}
            />
            <UiButton type="tertiary" label="Отмена" onClick={onCancelEditHandler} />
          </>
        )}

        {!isFavoritesEdit && isFavoritesEmpty && (
          <UiButton type="primary" label="Добавить разделы" onClick={onClickEditHandler} />
        )}
      </div>
    </div>
  );
};
