import classNames from 'classnames';
import React, { useMemo } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { downloadFile } from 'ant/plugins/utils/download-file';
import { PollModel, PollQuestionModel, PollQuestionTypes, VoteModel } from 'ant/types/models/poll';
import { ANSWER_PREFIX } from 'components-frontend/components/poll/poll-answers/constants';
import { PollAnswersDetail } from 'components-frontend/components/poll/poll-answers/poll-answers-detail/PollAnswersDetail';
import { PollAnswersInput } from 'components-frontend/components/poll/poll-answers/poll-answers-input/PollAnswersInput';
import { RespondentsModal } from 'components-frontend/components/poll/respondents-modal/RespondentsModal';
import { exportPollAnswerToXLSXEffect } from 'components-frontend/store/poll';

import styles from './PollAnswers.scss';
import { GroupAnswersWrapper } from './group-answers-wrapper/GroupAnswersWrapper';

export type ValueChange = (val: VoteModel[] | null, questionId: number) => void;

type Props = {
  poll: PollModel;
  question: PollQuestionModel;
  disabled: boolean;
  onChange?: ValueChange;
  answers: VoteModel[];
  answerClassName?: string;
  isSingleQuestionPoll: boolean;
};

const inputQuestionSubtypes = [PollQuestionTypes.FREE_ANSWER, PollQuestionTypes.FREE_FILE];

export const PollAnswers: React.FC<Props> = (props) => {
  const { poll, question, disabled, onChange, answers, answerClassName, isSingleQuestionPoll } = props;
  const { userHasResult: isFinished, isAuthor = false } = poll;
  const [isRespondentsModalOpen, setIsRespondentsModalOpen] = React.useState(false);

  const saveToFileXLSX = (questionId: number, optionId: number, name: string) => {
    exportPollAnswerToXLSXEffect({ questionId, optionId }).then((file) => {
      downloadFile(file, `${name}.xlsx`);
    });
  };

  const onValueChange: ValueChange = (val, questionId) => {
    if (isFinished || !onChange) {
      return;
    }

    onChange(val, questionId);
  };

  const uniqueRespondentsCount = useMemo(() => {
    const uniqueRespondents = new Set();

    question.options?.forEach((opt) =>
      opt.answerRespondents.forEach((respondent) => uniqueRespondents.add(respondent.id)),
    );

    return uniqueRespondents.size;
  }, [question.options]);

  if (isFinished) {
    return (
      <>
        {question.options?.map((option) => (
          <PollAnswersDetail
            poll={poll}
            question={question}
            option={option}
            onSaveFile={saveToFileXLSX}
            answerClassName={answerClassName}
            onClick={() => setIsRespondentsModalOpen(true)}
            uniqueRespondentsCount={uniqueRespondentsCount}
            key={option.id}
          />
        ))}

        <RespondentsModal
          isOpen={isRespondentsModalOpen}
          isAuthor={isAuthor}
          isSingleQuestionPoll={isSingleQuestionPoll}
          question={question}
          onClose={() => setIsRespondentsModalOpen(false)}
          saveToFileXLSX={saveToFileXLSX}
          uniqueRespondentsCount={uniqueRespondentsCount}
        />
      </>
    );
  }

  if (!isFinished) {
    if (inputQuestionSubtypes.includes(question.type)) {
      return (
        <PollAnswersInput
          answer={answers[0]}
          question={question}
          isDisabled={disabled}
          onValueChange={onValueChange}
        />
      );
    }

    return (
      <GroupAnswersWrapper
        onChange={onValueChange}
        question={question}
        answers={answers}
        className={styles.pollAnswers}
      >
        {question.options?.map((option) => (
          <label
            key={option.id}
            htmlFor={`${ANSWER_PREFIX}${option.id}`}
            className={styles.pollAnswers__label}
          >
            <div className={classNames(styles.pollAnswers__answerInfo, answerClassName)}>
              <PollAnswersInput question={question} option={option} isDisabled={disabled} />
              <UiTypography.Text className={styles.pollAnswers__text}>
                <UiTruncateMarkup truncate lines={3}>
                  {option.option}
                </UiTruncateMarkup>
              </UiTypography.Text>
            </div>
          </label>
        ))}
      </GroupAnswersWrapper>
    );
  }

  return null;
};
