import React, { FC } from 'react';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { parseEntityText } from 'ant/plugins/utils/markup-content';
import { fileStorageContextRouteMap } from 'ant/store/filestorage/constants';
import { getFileStorageRouteParams } from 'ant/store/filestorage/helpers';
import { NotificationFileVersionCommentModel, NotificationModel } from 'ant/types/models/notification.model';
import {
  NotificationComment,
  NotificationTargetTypes,
} from 'components-frontend/components/notifications-drawer/notification/comment';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<NotificationFileVersionCommentModel>;
}

export const NotificationFileVersionComment: FC<Props> = (props) => {
  const { notification } = props;

  const { image, createdAt, initiator, content } = notification;
  const { fullName, keycloakId } = initiator;
  const { fileVersion, file, comment, parent } = content;
  const { name, id } = file;

  const parsedParentText = parent?.text && parseEntityText(parent.text).textValue;
  const parsedCommentText = comment?.text && parseEntityText(comment.text).textValue;

  const commentText = parsedParentText || parsedCommentText;
  const replyText = parent ? parsedCommentText : undefined;

  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const targetDescription = `К версии ${fileVersion.revision}, файла:`;
  const targetUrl =
    file?.context &&
    getRoutePath(fileStorageContextRouteMap[file.context.service], {
      ...getFileStorageRouteParams(file),
      fileId: id,
    });

  return (
    <NotificationComment
      status={notification.status}
      title={notificationTitle}
      subTitle={notificationDescription}
      avatar={avatar}
      to={userLink}
      commentText={commentText}
      replyText={replyText}
      createdAt={createdAt}
      targetType={NotificationTargetTypes.Page}
      targetName={name}
      targetUrl={targetUrl}
      targetDescription={targetDescription}
    />
  );
};
