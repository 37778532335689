import { useStore } from 'effector-react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { UiButton } from 'ant/components/ui/button';
import { UiLinkListItem } from 'ant/components/ui/link-list-item';
import { UiList } from 'ant/components/ui/list';
import { UiTabItem, UiTabs } from 'ant/components/ui/tabs';
import { UiTypography } from 'ant/components/ui/typography';
import { declension, memberDeclension } from 'ant/plugins/declension';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getCommentSearchStorage } from 'ant/store/comments';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { LongreadParams } from 'ant/types/longread';
import { CMSViewType } from 'ant/types/models/cms.model';
import { NewsCategoriesDist } from 'ant/types/models/news.model';
import { PostBasicModel } from 'ant/types/models/post';
import { OriginListTyping, SearchCategoryType } from 'ant/types/models/search.model';
import { CommentListItem } from 'components-frontend/components/header/search/comment-list-item/CommentListItem';
import { HEADER_SEARCH_CATEGORY_AMOUNT } from 'components-frontend/components/header/search/constants';
import { NewsListItem } from 'components-frontend/components/header/search/news-list-item/NewsListItem';
import { SearchCategoryResults } from 'components-frontend/components/header/search/search-category-results/SearchCategoryResults';
import { SearchCategoryMock } from 'components-frontend/components/header/search/search-category-results/search-category-mock/SearchCategoryMock';
import { PostCompound } from 'components-frontend/components/post/compound';
import { SearchCategory } from 'components-frontend/store/search/advanced-search';
import { getSearchBlogStorage } from 'components-frontend/store/search/search-blog';
import { getSearchNewsStorage } from 'components-frontend/store/search/search-news';
import { getSearchPagesStorage } from 'components-frontend/store/search/search-pages';
import { getSearchPostStorage } from 'components-frontend/store/search/search-post';
import { getSearchProfileStorage } from 'components-frontend/store/search/search-profile';

import styles from './ResultLists.scss';

type Props = {
  originList: OriginListTyping[];
  searchCategory: SearchCategoryType;
  isLoading?: boolean;
  onSearchCategoryChange: (newSearchCategory: SearchCategoryType) => void;
  onShowMoreClick: (listType: SearchCategoryType) => void;
  onClose: () => void;
  searchBlogStorage: ReturnType<typeof getSearchBlogStorage>;
  searchCommentStorage: ReturnType<typeof getCommentSearchStorage>;
  searchPostStorage: ReturnType<typeof getSearchPostStorage>;
  searchProfileStorage: ReturnType<typeof getSearchProfileStorage>;
  newsCategoriesDist: NewsCategoriesDist | null;
  searchNewsStorage: ReturnType<typeof getSearchNewsStorage>;
  searchPagesStorage: ReturnType<typeof getSearchPagesStorage>;
};

const WIDGET_SETTINGS_TOOLBAR_HEIGHT = 40;
const HEADER_HEIGHT = 72;
const DROPDOWN_TABS_HEIGHT = 64;
const DROPDOWN_BOTTOM_OFFSET = 56;

const DROPDOWN_CONTENT_MAX_HEIGHT_STYLE = `calc(100vh - ${
  WIDGET_SETTINGS_TOOLBAR_HEIGHT + HEADER_HEIGHT + DROPDOWN_TABS_HEIGHT + DROPDOWN_BOTTOM_OFFSET
}px)`;

export const ResultLists: React.FC<Props> = (props) => {
  const {
    originList,
    searchCategory,
    isLoading,
    onSearchCategoryChange,
    onShowMoreClick,
    onClose,
    searchBlogStorage,
    searchCommentStorage,
    searchPostStorage,
    searchProfileStorage,
    newsCategoriesDist,
    searchNewsStorage,
    searchPagesStorage,
  } = props;
  const { data: searchBlogs, pagination: blogsPagination } = useStore(searchBlogStorage.storage.store);
  const { data: searchComments, pagination: commentsPagination } = useStore(
    searchCommentStorage.storage.store,
  );
  const { data: searchPosts, pagination: postsPagination } = useStore(searchPostStorage.storage.store);
  const { data: searchProfiles, pagination: profilesPagination } = useStore(
    searchProfileStorage.storage.store,
  );

  const { data: searchNews, pagination: searchNewsPagination } = useStore(searchNewsStorage.storage.store);
  const { data: searchPages, pagination: searchPagesPagination } = useStore(searchPagesStorage.storage.store);

  const blogsList = useMemo(
    () =>
      searchBlogs.map((row) => (
        <UiLinkListItem
          to={getRoutePath(RouteNames.GroupView, { id: row.slug || row.id })}
          key={row.id}
          avatar={{ src: row.fileStorageImageUrl }}
          title={row.name}
          subTitle={`${row.totalParticipants} ${declension(row.totalParticipants, memberDeclension)}`}
          onClick={onClose}
        />
      )),
    [searchBlogs],
  );

  const pagesList = useMemo(() => {
    return (
      <UiList
        dataSource={searchPages}
        renderItem={(row) => {
          return (
            <Link to={getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: row.url })}>
              <UiList.Item className={styles.resultListItem}>
                <UiTypography.Text strong>{row.name}</UiTypography.Text>
              </UiList.Item>
            </Link>
          );
        }}
      />
    );
  }, [searchPages]);

  const commentsList = useMemo(
    () =>
      searchComments.map((comment) => (
        <CommentListItem key={comment.id} comment={comment} onClickProfileLink={onClose} />
      )),
    [searchComments],
  );

  const onPostClick = (post: PostBasicModel) => {
    const postLongreadParams: LongreadParams = { postId: Number(post.id), postType: post.type };

    openGlobalModal(GlobalModalNames.PostLongread, postLongreadParams, postLongreadParams);
  };

  const postsList = useMemo(() => {
    return (
      <UiList
        split={false}
        dataSource={searchPosts}
        renderItem={(post) => (
          <UiList.Item style={{ padding: 0 }} onClick={() => onPostClick(post)}>
            <PostCompound.SearchView compact post={post} />
          </UiList.Item>
        )}
      />
    );
  }, [searchPosts]);

  const profilesList = useMemo(
    () =>
      searchProfiles.map((row) => (
        <UiLinkListItem
          to={getRoutePath(RouteNames.Profile, { id: row.id })}
          key={row.id}
          avatar={{ src: row.image }}
          title={row.title}
          isActive={row.isActive}
          subTitle={row.subTitle}
          onClick={onClose}
        />
      )),
    [searchProfiles],
  );

  const newsList = useMemo(
    () =>
      searchNews.map((feed) => (
        <NewsListItem key={feed.id} newsFeed={feed} newsCategoriesDist={newsCategoriesDist} />
      )),
    [searchNews, newsCategoriesDist],
  );

  const contentContainerStyles: React.CSSProperties = {
    maxHeight: DROPDOWN_CONTENT_MAX_HEIGHT_STYLE,
  };

  const advancedSearchBtn = (
    <UiButton
      className={styles.resultList__advancedSearchBtn}
      type="link"
      onClick={() => onShowMoreClick(searchCategory)}
    >
      Расширенный поиск
    </UiButton>
  );

  const commonProps = {
    searchCategory,
    onSearch: onSearchCategoryChange,
  };

  const totalCount = useMemo(() => {
    const paginationArr = [
      blogsPagination,
      commentsPagination,
      postsPagination,
      profilesPagination,
      searchPagesPagination,
    ];

    return paginationArr.reduce((total: number, { count = 0 }) => total + count, 0);
  }, [blogsPagination, commentsPagination, postsPagination, profilesPagination, searchPagesPagination]);

  const tabItems = useMemo<UiTabItem<string>[]>(() => {
    return originList.map(({ type, title }) => ({ key: type, label: title }));
  }, [originList]);

  const isShowAllShown =
    (totalCount && searchCategory === SearchCategory.All) || totalCount > HEADER_SEARCH_CATEGORY_AMOUNT;

  return (
    <div className={styles.resultList}>
      <UiTabs
        defaultActiveKey={searchCategory}
        activeKey={searchCategory}
        onChange={(key) => {
          onSearchCategoryChange(key as SearchCategoryType);
        }}
        tabBarExtraContent={{
          right: advancedSearchBtn,
        }}
        items={tabItems}
      />
      <div className={styles.resultList__contentContainer} style={contentContainerStyles}>
        <div className={styles.resultList__resultsContainer}>
          {searchProfiles.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Profile}
              pagination={{ total: profilesPagination.count }}
              {...commonProps}
            >
              {profilesList}
            </SearchCategoryResults>
          )}
          {searchBlogs.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Blog}
              pagination={{ total: blogsPagination.count }}
              {...commonProps}
            >
              {blogsList}
            </SearchCategoryResults>
          )}
          {searchPosts.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Post}
              pagination={{ total: postsPagination.count }}
              {...commonProps}
            >
              {postsList}
            </SearchCategoryResults>
          )}
          {searchComments.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Comment}
              pagination={{ total: commentsPagination.count }}
              {...commonProps}
            >
              {commentsList}
            </SearchCategoryResults>
          )}
          {searchNews.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.News}
              pagination={{ total: searchNewsPagination.count }}
              {...commonProps}
            >
              {newsList}
            </SearchCategoryResults>
          )}
          {searchPages.length > 0 && (
            <SearchCategoryResults
              category={SearchCategory.Pages}
              pagination={{ total: searchPagesPagination.count }}
              {...commonProps}
            >
              {pagesList}
            </SearchCategoryResults>
          )}
          {isLoading && <SearchCategoryMock />}
        </div>
        {isShowAllShown && (
          <div className={styles.resultList__showMoreBtnWrapper}>
            <UiButton
              className={styles.resultList__showMoreBtn}
              type="secondary"
              size="large"
              label="Все результаты поиска"
              onClick={() => onShowMoreClick(searchCategory)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
