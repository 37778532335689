import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { fileStorageContextRouteMap } from 'ant/store/filestorage/constants';
import { getFileStorageRouteParams } from 'ant/store/filestorage/helpers';
import { CMSViewType } from 'ant/types/models/cms.model';
import {
  TimelineCommentAlbumImageContentModel,
  TimelineCommentCmsContentModel,
  TimelineCommentContentModels,
  TimelineCommentFileVersionContentModel,
  TimelineCommentMicropostContentModel,
  TimelineCommentNewsContentModel,
  TimelineCommentPostContentModel,
  TimelineCommentRecordContentModel,
  TimelineCommentThanksContentModel,
  TimelineCommentUserBadgeContentModel,
  TimelineRecordModel,
  TimelineTypes,
} from 'ant/types/models/timelines.model';
import { Gender } from 'ant/types/models/user.model';
import { isTimelineRecordModelSomeOfTypes } from 'components-frontend/typings/guards/timeline';

import { TimelineCardCommentEntry } from './TimelineCardComment';

export const getTimelineCardCommentEntry = (
  record: TimelineRecordModel<TimelineCommentContentModels>,
): TimelineCardCommentEntry | null => {
  const {
    content: { comment, parent: parentComment },
  } = record;

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentNewsContentModel>(record, [
      TimelineTypes.CommentCreatedNews,
      TimelineTypes.CommentReplyNews,
    ])
  ) {
    const { content } = record;
    const { news } = content;

    return { title: news.title, comment, parentComment, id: news.id, type: record.type };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentPostContentModel>(record, [
      TimelineTypes.CommentCreatedEntry,
      TimelineTypes.CommentReplyEntry,
    ])
  ) {
    const { content } = record;
    const { entry: post } = content;

    return {
      title: post?.title || 'Перейти к посту',
      comment,
      parentComment,
      id: post.id,
      type: record.type,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentMicropostContentModel>(record, [
      TimelineTypes.CommentCreatedMicropost,
      TimelineTypes.CommentReplyMicropost,
    ])
  ) {
    const { content } = record;
    const { micropost } = content;

    return {
      title: micropost?.title || 'Перейти к посту',
      comment,
      parentComment,
      id: micropost.id,
      type: record.type,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentFileVersionContentModel>(record, [
      TimelineTypes.CommentCreatedFileVersion,
      TimelineTypes.CommentReplyFileVersion,
    ])
  ) {
    const { content } = record;
    const { file, fileVersion } = content;

    const targetUrl =
      file?.context &&
      getRoutePath(fileStorageContextRouteMap[file.context.service], {
        ...getFileStorageRouteParams(file),
        fileId: file.id,
      });

    return { title: `${file.name} (Версия ${fileVersion.revision})`, comment, parentComment, targetUrl };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentAlbumImageContentModel>(record, [
      TimelineTypes.CommentCreatedAlbumImage,
      TimelineTypes.CommentReplyAlbumImage,
    ])
  ) {
    const { content } = record;
    const { blog } = content;
    const { slug, id } = blog;

    return {
      title: `В альбоме группы: ${blog.name}`,
      comment,
      parentComment,
      targetUrl: `${getRoutePath(RouteNames.GroupView, { id: slug || id })}#picture`,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentCmsContentModel>(record, [
      TimelineTypes.CommentCreatedCms,
      TimelineTypes.CommentReplyCms,
    ])
  ) {
    const { content } = record;
    const { cms } = content;
    const { name, url } = cms;

    return {
      title: name,
      comment,
      parentComment,
      targetUrl: `${getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: url })}`,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentThanksContentModel>(record, [
      TimelineTypes.CommentCreatedThanks,
      TimelineTypes.CommentReplyThanks,
    ])
  ) {
    const { content } = record;
    const { thanks } = content;
    const { attributes, toUser } = thanks;
    const { name } = attributes;

    return {
      title: name,
      comment,
      parentComment,
      targetUrl: `${getRoutePath(RouteNames.Profile, { id: toUser.id })}`,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentUserBadgeContentModel>(record, [
      TimelineTypes.CommentCreatedUserBadge,
      TimelineTypes.CommentReplyUserBadge,
    ])
  ) {
    const { content } = record;
    const { userBadge } = content;
    const { badge, user } = userBadge;
    const { name } = badge;

    return {
      title: name,
      comment,
      parentComment,
      targetUrl: `${getRoutePath(RouteNames.Profile, { id: user.id })}`,
    };
  }

  if (
    isTimelineRecordModelSomeOfTypes<TimelineCommentRecordContentModel>(record, [
      TimelineTypes.CommentCreatedRecord,
      TimelineTypes.CommentReplyRecord,
    ])
  ) {
    const { content, target, type } = record;
    const { record: innerRecord } = content;
    const { content: innerRecordContent, id } = innerRecord;
    const { oldPosition, oldGroup, group, position } = innerRecordContent;

    const isUserFemale = target?.gender === Gender.Female;
    let actionWord = isUserFemale ? 'Переведена ' : 'Переведен ';

    if (!oldGroup && !oldPosition) {
      actionWord = isUserFemale ? 'Принята ' : 'Принят ';
    }

    const groupName = group ? `в ${group.name} ` : '';
    const positionName = position ? `на должность ${position.name}` : '';

    return {
      title: `${actionWord}${groupName}${positionName}`,
      comment,
      parentComment,
      id,
      type,
    };
  }

  return null;
};
