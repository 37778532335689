import { createEvent, createStore } from 'effector';

import { AbstractStorage } from 'ant/helpers/storage/abstract-storage-factory';
import { getCommentSearchStorage } from 'ant/store/comments';
import {
  PagesSearchParams,
  ProfileSearchParams,
  SearchCategoryType,
  SearchParams,
  SearchSingleCategory,
} from 'ant/types/models/search.model';
import { SearchCategory } from 'components-frontend/store/search/advanced-search/constants';
import { getSearchBlogStorage } from 'components-frontend/store/search/search-blog';
import { getSearchEventStorage } from 'components-frontend/store/search/search-event';
import { getSearchFilesStorage } from 'components-frontend/store/search/search-files';
import { getSearchNewsStorage } from 'components-frontend/store/search/search-news';
import { getSearchPagesStorage } from 'components-frontend/store/search/search-pages';
import { getSearchPostStorage } from 'components-frontend/store/search/search-post';
import { getSearchProfileAdvancedStorage } from 'components-frontend/store/search/search-profile-advanced';
import { getSearchTagDictStorage } from 'components-frontend/store/search/search-tag';

export type AdditionalSearchParams = PagesSearchParams & Partial<ProfileSearchParams>;

export interface StartSearchParams extends SearchParams {
  searchCategory: SearchCategoryType;
}

type SearchStatusState = {
  loading: boolean;
  isAnyFetched: boolean;
  isShowEmpty: boolean;
};

type Storage = {
  storage: AbstractStorage<any, any, any, SearchParams>;
};

type GetSearchStorageParams<T> = {
  storageNames: T[];
  params: {
    searchCategoryAmount: number;
    searchAllAmount?: number;
  };
};

const searchStorages: Record<SearchSingleCategory, () => Storage> = {
  [SearchSingleCategory.Profile]: getSearchProfileAdvancedStorage,
  [SearchSingleCategory.Blog]: getSearchBlogStorage,
  [SearchSingleCategory.News]: getSearchNewsStorage,
  [SearchSingleCategory.Pages]: getSearchPagesStorage,
  [SearchSingleCategory.Post]: getSearchPostStorage,
  [SearchSingleCategory.Comment]: getCommentSearchStorage,
  [SearchSingleCategory.Files]: getSearchFilesStorage,
  [SearchSingleCategory.Event]: getSearchEventStorage,
  [SearchSingleCategory.Tag]: getSearchTagDictStorage,
};

export const getSearchStorage = <T extends SearchSingleCategory>({
  storageNames,
  params,
}: GetSearchStorageParams<T>) => {
  const defaultSearchStatus: SearchStatusState = {
    loading: false,
    isAnyFetched: false,
    isShowEmpty: false,
  };
  const storages = storageNames.map((storageName) => ({
    category: storageName,
    storage: searchStorages[storageName as T](),
  }));

  const searchStatusStore = createStore<SearchStatusState>(defaultSearchStatus);

  const resetStoresEvent = createEvent();
  const startSearchEvent = createEvent<StartSearchParams>();
  const completeSearchEvent = createEvent();
  const additionalSearchParamsStore = createStore<AdditionalSearchParams>({});
  const resetAdditionalSearchParamsEvent = createEvent();
  const setAdditionalSearchParamsEvent = createEvent<AdditionalSearchParams>();

  resetStoresEvent.watch(() => {
    storages.forEach(({ storage }) => {
      storage.storage.cancelPendingRequest();
      storage.storage.resetStoreEvent();
    });
  });

  additionalSearchParamsStore
    .on(setAdditionalSearchParamsEvent, (state, extraParams) => {
      return { ...state, ...extraParams };
    })
    .reset(resetAdditionalSearchParamsEvent);

  startSearchEvent.watch((searchParams) => {
    const { searchCategory, searchString, pageNumber, pageSize } = searchParams;

    const additionalParams = additionalSearchParamsStore.getState();

    const fetchParams: SearchParams = {
      searchString,
      pageNumber,
      pageSize: searchCategory === SearchCategory.All ? params.searchAllAmount : pageSize,
      isDraft: searchCategory === SearchSingleCategory.Event ? false : undefined,
      ...additionalParams,
    };

    const fetchArr: Promise<unknown>[] = [];

    storages.forEach(({ storage, category }) => {
      if (searchCategory === category || searchCategory === SearchCategory.All) {
        fetchArr.push(storage.storage.fetchEffect(fetchParams));
      }
    });

    Promise.allSettled(fetchArr).then(() => completeSearchEvent());
  });

  searchStatusStore
    .on(startSearchEvent, () => {
      return { loading: true, isAnyFetched: true, isShowEmpty: false };
    })
    .on(completeSearchEvent, (state) => {
      const isShowEmpty = !storages.some(({ storage }) => storage.storage.store.getState().data?.length);

      return { ...state, loading: false, isShowEmpty };
    })
    .reset(resetStoresEvent);

  return {
    searchStorages: storages.reduce(
      (res, curr) =>
        Object.assign(res, {
          [curr.category]: curr.storage,
        }),
      {},
    ) as Record<T, Storage>,
    searchStatusStore,
    resetStoresEvent,
    startSearchEvent,
    additionalSearchParamsStore,
    setAdditionalSearchParamsEvent,
    resetAdditionalSearchParamsEvent,
  };
};

export { SearchCategory } from 'components-frontend/store/search/advanced-search/constants';
