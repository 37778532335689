import { UiUploadFileStatusType } from 'ant/components/ui/upload';
import { FileStorageEntryId, FileStorageObjectModel } from 'ant/types/models/file.model';
import { UiUploadFileAttach } from 'ant/types/uploads-form-item';

export const getFileObjectsIds = (
  fileObjects: UiUploadFileAttach<FileStorageEntryId>[],
): FileStorageEntryId[] => {
  const isDefined = <T>(argument?: T): argument is T => {
    return argument !== undefined;
  };

  return fileObjects.map((fileStorageObject) => fileStorageObject.attachmentId).filter(isDefined);
};

export const mapFileStorageObjectsToFormValue = (
  fileStorageObjects: FileStorageObjectModel[] = [],
): UiUploadFileAttach<FileStorageEntryId>[] => {
  return fileStorageObjects.map((item) => {
    const { id, file, size, name } = item;

    return {
      uid: id,
      attachmentId: id,
      url: file,
      size,
      name,
      status: UiUploadFileStatusType.Done,
    };
  });
};
