import React, { CSSProperties, FC, useMemo, useState } from 'react';

import ExitFullscreenSvg from 'ant/components/svg/exit-fullscreen.svg';
import FullscreenSvg from 'ant/components/svg/fullscreen.svg';
import ShareSvg from 'ant/components/svg/share.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiImage } from 'ant/components/ui/image';
import { UiModal, UiModalProps, UiModalTypes } from 'ant/components/ui/modals';
import { modalHeaderHeight, modalOffset } from 'ant/components/ui/modals/modal/helpers';
import { UiOverlay } from 'ant/components/ui/overlay';
import { UiSpace } from 'ant/components/ui/space';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { themePalette } from 'ant/theme';
import { FileStorageListEntry } from 'ant/types/models/file.model';

const getMaxPossibleElementSideSize = (elementSize: number, viewSize: number) => {
  if (elementSize > viewSize) {
    return viewSize - modalHeaderHeight - modalOffset * 2;
  }

  return elementSize;
};

export const getMaxPossibleElementDimension = (
  elementWidth: number,
  elementHeight: number,
  viewWidth: number,
  viewHeight: number,
) => {
  const width = getMaxPossibleElementSideSize(elementWidth, viewWidth);
  const height = getMaxPossibleElementSideSize(elementHeight, viewHeight);

  const widthByRatio = width / elementWidth;
  const heightByRatio = height / elementHeight;

  const aspectRatio = widthByRatio > heightByRatio ? heightByRatio : widthByRatio;

  return {
    width: elementWidth * aspectRatio,
    height: elementHeight * aspectRatio,
  };
};

export interface FileInfoCardModalProps extends Omit<UiModalProps, 'type'> {
  fileItem: FileStorageListEntry;
  isShareable: boolean;
  onShare: () => void;
}

export const FileInfoCardImageModal: FC<FileInfoCardModalProps> = (props) => {
  const { fileItem, isShareable, onShare, onClose, ...modalProps } = props;

  const [isFullscreenMode, setIsFullscreenMode] = useState(false);

  const onCloseModal = () => {
    setIsFullscreenMode(false);
    onClose();
  };

  const modalContentStyles = useMemo<CSSProperties>(() => {
    if (isFullscreenMode) {
      return {
        backgroundColor: themePalette.colorBlack,
      };
    }

    if (fileItem?.additional?.imageData) {
      const {
        additional: {
          imageData: { width: originalWidth, height: originalHeight },
        },
      } = fileItem;

      return getMaxPossibleElementDimension(
        originalWidth,
        originalHeight,
        window.innerWidth,
        window.innerHeight,
      );
    }

    return {
      width: 'auto',
      height: 'auto',
    };
  }, [fileItem, isFullscreenMode]);

  return (
    <UiModal
      type={isFullscreenMode ? UiModalTypes.FullScreen : UiModalTypes.Fit}
      {...modalProps}
      onClose={onCloseModal}
    >
      {!isFullscreenMode && (
        <UiModal.Header>
          <UiModal.Header.Title truncate steps={getModalStepsForSingleTitle(fileItem.name)} />
        </UiModal.Header>
      )}
      <UiModal.Content style={modalContentStyles}>
        <UiOverlay
          overlayStyles={{ background: 'transparent' }}
          content={
            <UiRow justify="end" style={{ height: '100%', cursor: 'default' }}>
              <UiSpace size={16} align="end" style={{ marginBottom: 30, marginRight: 30 }}>
                {isShareable && (
                  <UiButton
                    type="link"
                    size="small"
                    disabledFocus
                    icon={<UiIcon color="white" component={ShareSvg} width={30} height={30} />}
                    onClick={onShare}
                  />
                )}
                <UiButton
                  type="link"
                  size="small"
                  disabledFocus
                  icon={
                    <UiIcon
                      color="white"
                      component={isFullscreenMode ? ExitFullscreenSvg : FullscreenSvg}
                      width={30}
                      height={30}
                    />
                  }
                  onClick={() => setIsFullscreenMode((prevState) => !prevState)}
                />
              </UiSpace>
            </UiRow>
          }
        >
          <UiRow justify="center">
            <UiSpace align="center" flex style={isFullscreenMode ? { height: '100vh' } : undefined}>
              <UiImage
                rounded={false}
                style={{ objectFit: 'contain', maxHeight: '100vh' }}
                src={fileItem.file}
              />
            </UiSpace>
          </UiRow>
        </UiOverlay>
      </UiModal.Content>
    </UiModal>
  );
};
