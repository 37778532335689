// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b494be05_skills-modal__header{margin:0}.b494be05_skills-modal__header-title{min-height:44px;padding:24px 24px 0}.b494be05_skills-modal__content{display:flex;flex-direction:column;padding:24px}.b494be05_skills-modal__content-wrapper{display:flex;margin-top:24px}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/skills/modals/skills-add/SkillsAddModal.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AAEE,+BACE,QAAA,CAEA,qCACE,eAAA,CACA,mBAAA,CAIJ,gCACE,YAAA,CACA,qBAAA,CACA,YCbU,CDeV,wCACE,YAAA,CACA,eCjBQ","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.skills-modal {\n  &__header {\n    margin: 0;\n\n    &-title {\n      min-height: 44px;\n      padding: $baseSpacing $baseSpacing 0;\n    }\n  }\n\n  &__content {\n    display: flex;\n    flex-direction: column;\n    padding: $baseSpacing;\n\n    &-wrapper {\n      display: flex;\n      margin-top: $baseSpacing;\n    }\n  }\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skills-modal__header": "b494be05_skills-modal__header",
	"skillsModal__header": "b494be05_skills-modal__header",
	"skills-modal__header-title": "b494be05_skills-modal__header-title",
	"skillsModal__headerTitle": "b494be05_skills-modal__header-title",
	"skills-modal__content": "b494be05_skills-modal__content",
	"skillsModal__content": "b494be05_skills-modal__content",
	"skills-modal__content-wrapper": "b494be05_skills-modal__content-wrapper",
	"skillsModal__contentWrapper": "b494be05_skills-modal__content-wrapper"
};
export default ___CSS_LOADER_EXPORT___;
