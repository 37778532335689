import React, { useState, useMemo } from 'react';

import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { SearchBreadcrumb } from 'components-frontend/components/search-page/breadcrumb/SearchBreadcrumb';
import { SearchCategory } from 'components-frontend/store/search/advanced-search';

import styles from './SearchPage.scss';
import { SearchContent } from './search-content/SearchContent';
import { SearchFilters } from './search-filters/SearchFilters';

export interface SearchFilter {
  label: string;
  id?: string;
  key?: string;
  value?: string;
}

export interface FilterCatogories extends SearchFilter {
  searchTitle: string;
  searchPlaceholder: string;
  dictionaryName: Dictionaries.Names;
}

type Props = {
  minSymbolsToSearch: number;
  searchFilters: FilterCatogories[];
  breadcrumbName: string;
};

const SearchPage: React.FC<Props> = ({ minSymbolsToSearch, searchFilters, breadcrumbName }) => {
  const [selectedSearchFilters, setSelectedSearchFilters] = useState<SearchFilter[]>([]);

  const changeFilters = (selectedSearchFilter: SearchFilter) => {
    const notChangedFilters = selectedSearchFilters.filter(
      (searchFilter) => searchFilter.label !== selectedSearchFilter.label,
    );

    if (selectedSearchFilter.id) {
      setSelectedSearchFilters([...notChangedFilters, selectedSearchFilter]);
    } else {
      setSelectedSearchFilters(notChangedFilters);
    }
  };

  const deleteFilters = () => setSelectedSearchFilters([]);

  const fetchParams = useMemo(
    () =>
      selectedSearchFilters.reduce(
        (params, filter) => {
          const filterParam = filter.key ? { [filter.key]: filter.value } : {};

          return { ...params, ...{ filterParam } };
        },
        {
          searchString: '',
        },
      ),
    [selectedSearchFilters],
  );

  return (
    <>
      <SearchBreadcrumb breadcrumbName={breadcrumbName} />
      <div className={styles.searchPage}>
        <SearchContent
          selectedSearchFilters={selectedSearchFilters}
          fetchParams={fetchParams}
          deleteFilter={changeFilters}
          deleteAllFilters={deleteFilters}
          origin={SearchCategory.Profile}
          minSymbolsToSearch={minSymbolsToSearch}
        />
        {searchFilters.length > 0 && (
          <SearchFilters
            changeFilters={changeFilters}
            searchFilters={searchFilters}
            selectedSearchFilters={selectedSearchFilters}
          />
        )}
      </div>
    </>
  );
};

export { SearchPage };
