import React from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';
import DropUpSvg from 'ant/components/svg/drop-up.svg';
import EditSvg from 'ant/components/svg/edit.svg';
import LinkAddSvg from 'ant/components/svg/link-add.svg';
import MeatballSvg from 'ant/components/svg/meatball.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiForm, UiFormItemProps, UiFormListItemMoveActionType } from 'ant/components/ui/form';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { ActionsDropdown } from 'ant/components/widgets/ActionsDropdown';
import { NavigationItem, WidgetDefaultDataNavigation } from 'components-frontend/typings/navigation';

import styles from '../NavigationEdit.scss';
import { AddSectionLinkHandler } from '../sections-list/SectionsList';

const titleStyle = { margin: 0 };
const iconProps = { width: 20, height: 20 };

export interface ItemListField<T = any> extends UiFormItemProps<T> {
  name: number;
  onRemove: () => void;
  index: number;
}
export type SectionItemListField = ItemListField<WidgetDefaultDataNavigation>;
export interface SectionItemLinkListField extends ItemListField<NavigationItem> {
  onMove: UiFormListItemMoveActionType;
}

interface Props extends SectionItemListField {
  onSectionEdit: (formItemProps: SectionItemListField) => void;
  onSectionMove: UiFormListItemMoveActionType;
  onSectionLinkEdit: (formItemProps: SectionItemLinkListField) => void;
  onSectionLinkAdd: ({ addSectionLink }: AddSectionLinkHandler) => void;
}

export const SectionItemsList: React.FC<Props> = (props) => {
  const {
    index: sectionIndex,
    name,
    value,
    onChange,
    onRemove,
    onSectionEdit,
    onSectionMove,
    onSectionLinkEdit,
    onSectionLinkAdd,
  } = props;

  const onSelectActionEdit = () => onSectionEdit({ index: sectionIndex, name, value, onChange, onRemove });
  const actionsDropdownItems = [
    { label: 'Редактировать раздел', onClick: onSelectActionEdit },
    { label: 'Переместить вверх', onClick: () => onSectionMove(sectionIndex, sectionIndex - 1) },
    { label: 'Переместить вниз', onClick: () => onSectionMove(sectionIndex, sectionIndex + 1) },
  ];

  const SectionLinkField: React.FC<SectionItemLinkListField> = ({ index, value: item, onMove, ...rest }) => (
    <div className={styles.navigationEdit__sectionItem}>
      <UiButton
        type="link-secondary"
        icon={<UiIcon component={EditSvg} {...iconProps} />}
        onClick={() => onSectionLinkEdit({ index, value: item, onMove, ...rest })}
      />
      <UiTypography.Text className={styles.navigationEdit__sectionItemLabel}>
        <UiTruncateMarkup truncate>{item?.label}</UiTruncateMarkup>
      </UiTypography.Text>
      <span className={styles.navigationEdit__sectionItemActions}>
        <UiButton
          type="link-secondary"
          onClick={() => onMove(index, index - 1)}
          icon={<UiIcon component={DropUpSvg} {...iconProps} />}
        />
        <UiButton
          type="link-secondary"
          onClick={() => onMove(index, index + 1)}
          icon={<UiIcon component={DropDownSvg} {...iconProps} />}
        />
      </span>
    </div>
  );

  return (
    <div className={styles.navigationEdit__section}>
      <UiTypography.Title level={4} style={titleStyle} className={styles.navigationEdit__sectionTitle}>
        <UiTruncateMarkup truncate>{value?.section.name}</UiTruncateMarkup>
        <ActionsDropdown icon={MeatballSvg} items={actionsDropdownItems} />
      </UiTypography.Title>

      <UiForm.List name={[name, 'items']}>
        {(items, { add: addSectionLink, remove, move }) => (
          <>
            {items.map((field, index) => (
              <UiForm.Item noStyle {...field}>
                <SectionLinkField
                  name={field.name}
                  onRemove={() => remove(field.name)}
                  onMove={move}
                  index={index}
                />
              </UiForm.Item>
            ))}

            <UiButton
              disabledFocus
              type="link"
              label="Добавить ссылку"
              onClick={() => onSectionLinkAdd({ addSectionLink })}
              icon={<UiIcon component={LinkAddSvg} width={20} height={20} />}
              className={styles.navigationEdit__sectionAddLink}
            />
          </>
        )}
      </UiForm.List>
    </div>
  );
};
