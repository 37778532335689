import { FormListFieldData } from 'antd/lib/form/FormList';
import React, { useEffect } from 'react';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import DeleteSvg from 'ant/components/svg/close.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiForm, UiFormInstance } from 'ant/components/ui/form';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiInput } from 'ant/components/ui/input';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { normalizeValueTrimStart } from 'ant/helpers/normalize';
import { MAX_LENGTH_INPUT_255 } from 'ant/helpers/validation';
import {
  INITIAL_OPTIONS_LENGTH,
  INITIAL_QUESTION_VALUE,
} from 'components-frontend/components/poll/poll-editor/constants';

import styles from './PollOptions.scss';

type Props = {
  disabled: boolean;
  parentListName: string;
  parentFieldName: string | number;
  form: UiFormInstance;
};

const PollOptions: React.FC<Props> = ({ disabled = false, form, parentListName, parentFieldName }: Props) => {
  const listName = 'options';

  useEffect(() => {
    const parentList = form.getFieldValue(parentListName);

    if (!parentList[parentFieldName]) {
      parentList[parentFieldName] = INITIAL_QUESTION_VALUE;
      form.setFieldsValue(parentList);
    }
  }, []);

  const getOptionsNames = (fields: FormListFieldData[]) =>
    fields.map((_, index) => [parentListName, parentFieldName, listName, index]);

  return (
    <UiForm.List name={[parentFieldName, listName]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <UiForm.Item
              className={styles.pollOptions__option}
              label={index === 0 ? 'Варианты ответа' : ''}
              key={field.key}
            >
              <UiRow align="middle" gutter={[24, 0]}>
                <UiCol flex="1 0 0">
                  <UiForm.Item {...field} style={{ margin: 0 }} normalize={normalizeValueTrimStart}>
                    <UiInput
                      size="large"
                      disabled={disabled}
                      autoFocus={fields.length > INITIAL_OPTIONS_LENGTH}
                      maxLength={MAX_LENGTH_INPUT_255}
                    />
                  </UiForm.Item>
                </UiCol>

                <UiCol>
                  <UiRender type={UiRenderType.VisibilityHidden} visible={fields.length > 2}>
                    <UiButton
                      size="middle"
                      icon={<UiIcon component={DeleteSvg} height={16} width={16} />}
                      type="link-secondary"
                      onClick={() => remove(field.name)}
                      disabled={disabled || fields.length <= 2}
                    />
                  </UiRender>
                </UiCol>
              </UiRow>
            </UiForm.Item>
          ))}
          <UiForm.Item
            className={styles.pollOptions__validation}
            dependencies={[...getOptionsNames(fields)]}
            name="validation"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  const parentList = getFieldValue(parentListName);

                  if (parentList[parentFieldName].options.filter(Boolean).length >= INITIAL_OPTIONS_LENGTH) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Должно быть не менее двух вариантов ответа'));
                },
              }),
            ]}
          >
            <UiInput type="hidden" />
          </UiForm.Item>
          <UiForm.Item className={styles.pollOptions__button}>
            <UiButton
              type="link"
              label="Добавить вариант"
              icon={<UiIcon component={AddCircleSvg} height={20} width={20} />}
              onClick={() => add()}
              disabled={disabled}
            />
          </UiForm.Item>
        </>
      )}
    </UiForm.List>
  );
};

export { PollOptions };
