import React from 'react';

import { SearchListItem, SearchCategoryType } from 'ant/types/models/search.model';
import { SearchCategory } from 'components-frontend/store/search/advanced-search';

import { SearchItemProfile } from './SearchItemProfile';

type Props = {
  item: SearchListItem;
  origin: SearchCategoryType;
};

export const SearchItemAdapter: React.FC<Props> = ({ item, origin }) => {
  if (origin === SearchCategory.Profile) {
    return <SearchItemProfile profile={item} />;
  }

  return null;
};
