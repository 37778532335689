import React, { FC, useCallback, useMemo, ChangeEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { UiRow } from 'ant/components/ui/grid';
import { UiInput } from 'ant/components/ui/input';
import { UiLinkListItem } from 'ant/components/ui/link-list-item';
import { UiList } from 'ant/components/ui/list';
import { UiModal, UiModalSteps } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getGamificationUsersByBadgeIdStorage } from 'ant/store/gamification';
import { GamificationBadgeInfo } from 'ant/types/models/gamification.model';

const MOCKS_COUNT = 3;

interface Props {
  badge: GamificationBadgeInfo;
  onClose: () => void;
}

export const TimelineCardBadgeUsersModal: FC<Props> = (props) => {
  const { badge, onClose } = props;
  const { id, name } = badge;

  const { storage } = useMemo(getGamificationUsersByBadgeIdStorage, []);
  const {
    data: awardedUsers,
    loading: awardedUsersLoading,
    fetchFx: filterAwardedUsers,
    pagination: { count: totalAwardedUser },
  } = useAbstractStorage(storage, {
    autoFetchAndRefetch: Boolean(id),
    autoFetchParams: { badgeId: id },
  });

  const onInputChangeDebounced = useDebouncedCallback((userName: string) => {
    filterAwardedUsers({ badgeId: id, userName });
  }, 500);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onInputChangeDebounced(e.target.value),
    [onInputChangeDebounced],
  );

  const titleStep: UiModalSteps = useMemo(() => {
    return {
      1: {
        title: `Обладатели награды "${name}"`,
        badgeValue: totalAwardedUser,
      },
    };
  }, [name, totalAwardedUser]);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={titleStep} />
      </UiModal.Header>
      <UiModal.Content style={{ padding: '24px 0' }}>
        <UiSpace size={20} full direction="vertical">
          <UiRow style={{ padding: '0 24px' }}>
            <UiInput.Search size="large" placeholder="Поиск" onChange={onInputChange} />
          </UiRow>
          {awardedUsersLoading &&
            createArrayMock(MOCKS_COUNT, (_, key) => <UiLinkListItem.Skeleton key={key} />)}
          {!awardedUsersLoading && (
            <UiList
              split={false}
              dataSource={awardedUsers}
              renderItem={({ user }) => {
                return (
                  <UiList.Item noStyle>
                    <UiLinkListItem
                      to={getRoutePath(RouteNames.Profile, { id: user.id })}
                      onClick={onClose}
                      avatar={{ src: user.avatar }}
                      title={getFullNameWithoutPatronymic(user.fullName)}
                      subTitle={user.position?.name}
                    />
                  </UiList.Item>
                );
              }}
            />
          )}
        </UiSpace>
      </UiModal.Content>
    </>
  );
};
