import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import styles from './EmptyFeed@default.scss';

export const EmptyFeedDefault = () => {
  return (
    <UiTypography.Text type="secondary" className={styles.emptyFeed}>
      Нет новых уведомлений
    </UiTypography.Text>
  );
};
