import React, { FC } from 'react';

import SuccessSvg from 'ant/components/svg/success.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypographyTextTypes } from 'ant/components/ui/typography';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { confirmEventParticipationEffect, declineEventParticipationEffect } from 'ant/store/events';
import { notificationsStore } from 'ant/store/notifications';
import { themePalette } from 'ant/theme';
import { EventModel } from 'ant/types/models/event.model';
import { NotificationModel } from 'ant/types/models/notification.model';

type Props = {
  notification: NotificationModel<EventModel>;
};

export const NotificationEventActions: FC<Props> = (props) => {
  const { notification } = props;

  const { isUserParticipate, isUserInvite, isRemoved, id } = notification.content;
  const isUserRejected = !isUserInvite && !isUserParticipate;

  const isEventInactive = isUserRejected || isRemoved;

  const onAccept = () => {
    confirmEventParticipationEffect({ id, body: { invitedGuestsCount: 0 } })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось принять приглашение'));
  };

  const onReject = () => {
    declineEventParticipationEffect(id)
      .then(() => {
        message.success('Приглашение отклонено');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалить отклонить приглашение'));
  };

  if (isUserParticipate) {
    return (
      <UiIcon.Label
        component={SuccessSvg}
        style={{ color: themePalette.colorSuccess }}
        type={UiTypographyTextTypes.Primary}
        label="Вы участвуете"
        strong
      />
    );
  }

  return (
    <UiSpace align="center">
      <UiButton label="Принять участие" type="primary" disabled={isEventInactive} onClick={onAccept} />
      <UiButton label="Отказаться" type="tertiary" disabled={isEventInactive} onClick={onReject} />
    </UiSpace>
  );
};
