import classNames from 'classnames';
import React, { useMemo, useEffect, useState } from 'react';

import CheckSVG from 'ant/components/svg/check.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiInput } from 'ant/components/ui/input';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useDebounce } from 'ant/helpers/hooks/use-debounce';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { getDictsStorage } from 'ant/store/dictionaries';
import { DictDataParams } from 'ant/types/api';
import { RecordResponse } from 'ant/types/dictionary';
import { SearchFilter, FilterCatogories } from 'components-frontend/components/search-page/SearchPage';

import styles from './SearchFiltersModal.scss';

interface Props {
  searchFilter: FilterCatogories;
  onClose: () => void;
  changeFilters: (selectedSearchFilter: SearchFilter) => void;
  selectedSearchFilters: SearchFilter[];
}

const SearchFiltersModal: React.FC<Props> = ({
  searchFilter,
  onClose,
  changeFilters,
  selectedSearchFilters,
}) => {
  const dictsStorage = useMemo(
    () =>
      getDictsStorage<RecordResponse, DictDataParams>({
        dictionaryName: searchFilter.dictionaryName,
      }),
    [searchFilter.dictionaryName],
  );

  const { data: categories, loading } = useAbstractStorage(dictsStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const [selectedFilter, setSelectedFilter] = useState<SearchFilter>();
  const [searchText, setSearchText] = useState('');
  const [filteredBySearchCategories, setFilteredBySearchCategories] = useState<RecordResponse[]>([]);

  const onChangeFilters = () => {
    if (!selectedFilter) {
      return;
    }

    changeFilters(selectedFilter);
    onClose();
  };

  useEffect(() => {
    if (!categories || !selectedSearchFilters) {
      return;
    }

    const filterToSelect = selectedSearchFilters.find((filter) =>
      categories.some((category) => category.id === filter.id),
    );

    if (filterToSelect) {
      setSelectedFilter(filterToSelect);
    }
  }, [searchFilter, categories]);

  useEffect(() => {
    setFilteredBySearchCategories(categories);
  }, [categories]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);
  const onClear = () => setSearchText('');

  useDebounce(
    () => {
      const text = searchText.trim();

      if (text.length > 0) {
        setFilteredBySearchCategories(
          categories.filter(
            ({ attributes: { name } }) => name.toLowerCase().indexOf(text.toLowerCase()) > -1,
          ),
        );
      }
    },
    500,
    [searchText],
  );

  const onFilterSet = (id: string, name: string) =>
    setSelectedFilter({
      id,
      key: searchFilter.key,
      label: searchFilter.label,
      value: name,
    });

  return (
    <UiModal type={UiModalTypes.Small} isOpen={Boolean(searchFilter)} onClose={onClose}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(searchFilter?.searchTitle)} />
      </UiModal.Header>
      <UiModal.Content className={styles.searchFiltersModal__content}>
        <UiInput.Search
          className={styles.searchFiltersModal__input}
          allowClear
          size="large"
          placeholder={searchFilter?.searchPlaceholder}
          onClear={onClear}
          onChange={onChangeSearch}
        />
        <div className={styles.searchFiltersModal__items}>
          <UiSkeleton loading={loading} height={150}>
            {filteredBySearchCategories.map(({ id, attributes: { name } }) => (
              <UiButton
                key={id}
                className={styles.searchFiltersModal__item}
                type="tertiary"
                onClick={() => onFilterSet(id, name)}
              >
                <UiTypography.Text
                  strong
                  className={classNames({
                    [styles.searchFiltersModal__itemText]: selectedFilter?.id !== id,
                    [styles.searchFiltersModal__itemText_selected]: selectedFilter?.id === id,
                  })}
                >
                  {name}
                </UiTypography.Text>
                {selectedFilter?.id === id && (
                  <UiIcon
                    className={styles.searchFiltersModal__icon}
                    component={CheckSVG}
                    height={20}
                    width={20}
                  />
                )}
              </UiButton>
            ))}
          </UiSkeleton>
        </div>

        <div className={styles.searchFiltersModal__actions}>
          <UiButton
            className={styles.searchFiltersModal__action}
            type="tertiary"
            label="Отмена"
            size="large"
            onClick={onClose}
          />
          <UiButton
            className={styles.searchFiltersModal__action}
            type="primary"
            label="Применить"
            size="large"
            onClick={onChangeFilters}
          />
        </div>
      </UiModal.Content>
    </UiModal>
  );
};

export { SearchFiltersModal };
