import { EventsEndpoints } from 'ant/endpoints/events';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { EventModel } from 'ant/types/models/event.model';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchEventStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<EventModel>, EventModel[], EventModel[], SearchParams>(
    {
      endpointBuilder: ({ pageNumber, pageSize, searchString, isDraft }) =>
        buildEndpointWithQueryParams(EventsEndpoints.eventsList(), {
          pageNumber,
          pageSize,
          searchString,
          isDraft,
        }),
      defaultValue: [],
      dataMapper: ({ items }) => items,
      paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
      cancelPendingRequestOnFetch: true,
    },
  );

  return { storage };
};
