import React, { FC } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { createArrayMock } from 'ant/plugins/create-mocks';

export const CommentListStandaloneLoading: FC = () => {
  return (
    <>
      {createArrayMock(3, (_, index) => (
        <UiSpace full key={index} size={24} style={{ padding: '12px 0' }} direction="vertical">
          <UiSpace>
            <UiSkeleton loading circle width={40} height={40} />
            <UiSpace direction="vertical">
              <UiSkeleton loading width={150} />
              <UiSkeleton loading width={150} />
            </UiSpace>
          </UiSpace>
          <UiSkeleton loading width={400} height={30} />
          <UiSpace>
            <UiSkeleton loading width={100} />
            <UiSkeleton loading width={100} />
          </UiSpace>
        </UiSpace>
      ))}
    </>
  );
};
