// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._66d28868_uploads-form-item{width:100%}._66d28868_uploads-form-item .ant-upload.ant-upload-select-text{display:flex;min-height:40px;align-items:center}._66d28868_uploads-form-item .ant-btn-icon-only{height:20px}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/uploads-form/item/UploadsFormItem.scss"],"names":[],"mappings":"AACA,6BACE,UAAA,CAEA,gEACE,YAAA,CACA,eAAA,CACA,kBAAA,CAGF,gDACE,WAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.uploads-form-item {\n  width: 100%;\n\n  :global .ant-upload.ant-upload-select-text {\n    display: flex;\n    min-height: 40px;\n    align-items: center;\n  }\n\n  :global .ant-btn-icon-only {\n    height: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploads-form-item": "_66d28868_uploads-form-item",
	"uploadsFormItem": "_66d28868_uploads-form-item"
};
export default ___CSS_LOADER_EXPORT___;
