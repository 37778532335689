import React, { FC, useMemo } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useLongreadModalParams } from 'ant/helpers/hooks/use-longread-modal-params';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { getNewsFeedStorage, newsCategoriesStore } from 'ant/store/news';

import styles from './NewsPinned.scss';
import { NewsPinnedItem } from './item/NewsPinnedItem';

const MAX_NEWS_COUNTS = 6;
const newsPinnedMocks = createArrayMock(MAX_NEWS_COUNTS, (_, id) => (
  <div key={id} className={styles.newsPinned__itemLoading}>
    <UiSkeleton loading height="100%" />
  </div>
));

export const NewsPinned: FC = () => {
  useLongreadModalParams(GlobalModalNames.NewsLongread, ['newsId', 'newsType']);

  const newsFeedStorage = useMemo(getNewsFeedStorage, []);

  const { data: newsFeed, loading } = useAbstractStorage(newsFeedStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { pageSize: MAX_NEWS_COUNTS, pinned: true },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const { data: newsCategories } = useAbstractStorage(newsCategoriesStore.storage, {
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
  });

  const newsPinned = useMemo(() => {
    return newsFeed?.map((newsArticle) => (
      <NewsPinnedItem key={newsArticle.id} newsArticle={newsArticle} newsCategoriesMap={newsCategories} />
    ));
  }, [newsFeed, newsCategories]);

  return (
    <div className={styles.newsPinned}>
      {loading && newsPinnedMocks}
      {!loading && newsPinned}
    </div>
  );
};
