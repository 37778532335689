import React from 'react';
import { useLocation } from 'react-router-dom';

import { Navbar } from 'ant/components/layout/navbar';
import { themeWidths } from 'ant/theme';

import { NavigationMenu } from './menu/NavigationMenu';

export const Navigation = () => {
  const location = useLocation();

  return (
    <Navbar collapseDeps={[location]} style={{ top: themeWidths.layoutHeaderHeight }}>
      <NavigationMenu />
    </Navbar>
  );
};
