import { useStore } from 'effector-react';
import React, { useState, useMemo, useEffect, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import CalendarSVG from 'ant/components/svg/calendar.svg';
import EventSvg from 'ant/components/svg/event.svg';
import NotificationSvg from 'ant/components/svg/notification.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { BadgeColors, UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { ActionsDropdown } from 'ant/components/widgets/ActionsDropdown';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useUnmount } from 'ant/helpers/hooks/use-unmount';
import { authService } from 'ant/plugins/auth-service';
import {
  currentProfileRolesPermissionsMapStore,
  currentProfileStore,
} from 'ant/plugins/current-profile-service';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { useSettingsConfig } from 'ant/providers/settings-config';
import { refetchOnCreateUpdateMeetingEvent } from 'ant/store/calendar/api';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { notificationsStore } from 'ant/store/notifications';
import {
  NotificationFetchStatuses,
  NotificationFilters,
  // NotificationBodyTypes,
  // NotificationTitleTypes,
} from 'ant/types/models/notification.model';
import { EventsAllowedPermission } from 'ant/types/models/permissions.model';
import { NotificationsDrawer } from 'components-frontend/components/notifications-drawer/NotificationsDrawer';

import styles from './HeaderButtons.scss';

type Props = {
  drawerTitle: string;
  markAllAsReadLabel: string;
  // notificationTitleType: NotificationTitleTypes;
  // notificationBodyType: NotificationBodyTypes;
  icon: SvgrComponent;
  emptyNotificationsFeed: React.ReactNode;
  afterAvatarElement: React.ReactNode;
};

const getAllNotificationsParams = { status: NotificationFetchStatuses.All };

export const HeaderButtons: FC<Props> = (props) => {
  const {
    drawerTitle,
    markAllAsReadLabel,
    // notificationTitleType,
    // notificationBodyType,
    icon,
    emptyNotificationsFeed,
    afterAvatarElement,
  } = props;
  const profile = useStore(currentProfileStore);
  const permissions = useStore(currentProfileRolesPermissionsMapStore);
  const isAllowCreateEvents = permissions.has(EventsAllowedPermission.CreateEvents);

  const { config: configSettings } = useSettingsConfig();

  const {
    data: notificationsInfo,
    loading,
    fetchFx,
    error,
  } = useAbstractStorage(notificationsStore, {
    autoFetchAndRefetch: true,
    autoFetchParams: getAllNotificationsParams,
  });

  const { results: notifications, unreadCount } = notificationsInfo;

  const location = useLocation();

  useUnmount(notificationsStore.resetStoreEvent);

  const [visible, setVisible] = useState(false);

  const openDrawer = () => {
    setVisible(true);
    notificationsStore.refetchWithLastParams();
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [location]);

  const fetchNotifications = (filters?: NotificationFilters) => {
    const filterParams = filters ? { ordering: filters.toString() } : {};

    fetchFx({ ...filterParams, ...getAllNotificationsParams });
  };

  const headerActions = useMemo(() => {
    const meetingActions = [];

    if (isAllowCreateEvents) {
      meetingActions.push({
        label: 'Создать мероприятие',
        onClick: () => openGlobalModal(GlobalModalNames.CreateUpdateEvent),
        icon: EventSvg,
      });
    }

    meetingActions.push({
      label: 'Создать встречу',
      onClick: () =>
        openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
          onSuccess: refetchOnCreateUpdateMeetingEvent,
        }),
      icon: CalendarSVG,
    });

    return meetingActions;
  }, [refetchOnCreateUpdateMeetingEvent, isAllowCreateEvents]);

  return (
    <div className={styles.headerButtons}>
      {!configSettings.layouts?.headerEventsMeetingsDropdownButtonDisabled?.value && (
        <ActionsDropdown
          items={headerActions}
          dropdownButtonProps={{
            type: 'default',
            size: 'large',
            round: true,
            className: styles.headerButtons__actionsDropdownButton,
            icon: <UiIcon component={AddCircleSvg} width={20} height={20} />,
          }}
          trigger={['click']}
        >
          <UiButton />
        </ActionsDropdown>
      )}

      <NotificationsDrawer
        visible={visible}
        onClose={closeDrawer}
        loading={loading}
        notifications={notifications}
        drawerTitle={drawerTitle}
        // TODO: выяснить почему не используется
        // notificationTitleType={notificationTitleType}
        // notificationBodyType={notificationBodyType}
        icon={icon}
        emptyFeed={emptyNotificationsFeed}
        error={error}
        onChange={(filters) => fetchNotifications(filters)}
        markAllAsReadLabel={markAllAsReadLabel}
      />
      <UiBadge
        className={styles.headerButtons__notificationWrapper}
        count={unreadCount}
        color={BadgeColors.Warning}
      >
        <UiButton
          size="large"
          round
          className={styles.headerButtons__notificationButton}
          icon={<UiIcon component={NotificationSvg} width={20} height={20} />}
          onClick={openDrawer}
        />
      </UiBadge>

      <Link
        className={styles.headerButtons__avatarLinkWrapper}
        to={getRoutePath(RouteNames.Profile, { id: String(authService.getCurrentUserId()) })}
      >
        <UiAvatar className={styles.headerButtons__avatar} size={48} src={profile?.smallAvatar} />
      </Link>

      {afterAvatarElement}
    </div>
  );
};
