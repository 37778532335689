import React, { ReactNode } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { SkillType } from 'ant/types/models/profile.model';
import { Gender, UserProfileModel } from 'ant/types/models/user.model';

import { skillsTextMap } from '../Skills';
import styles from './SkillsEmpty.scss';

type Props = {
  isOwner: boolean;
  addSkill: () => void;
  userProfile?: UserProfileModel;
  loading: boolean;
  skillType: SkillType;
};

export const SkillsEmpty: React.FC<Props> = (props) => {
  const { isOwner, userProfile, addSkill, loading, skillType } = props;

  const userFirstName = userProfile?.fullName?.firstName;
  const isUserFemale = userProfile?.gender === Gender.Female;
  const isUserActive = Boolean(userProfile?.isActive);

  const visitorDescription = `${userFirstName} ещё не ${isUserFemale ? 'добавила' : 'добавил'} ${
    skillsTextMap[skillType].ManyDown
  }. Может быть, вы знаете, в чём ${isUserFemale ? 'она сильна?' : 'он силён?'}`;

  const blockedUserDescription = `Здесь ничего нет`;

  const AddLink: React.FC<{ children: ReactNode }> = (addLinkProps) => {
    const { children } = addLinkProps;

    return (
      <UiTypography.Link strong onClick={addSkill}>
        {children}
      </UiTypography.Link>
    );
  };

  return (
    <div className={styles.skillsEmpty}>
      {isOwner && (
        <UiTypography.Text type="secondary">
          <AddLink>Добавьте</AddLink>
          {` ${skillsTextMap[skillType].ManyDown}, чтобы ваши коллеги знали, что вы умеете`}
        </UiTypography.Text>
      )}
      {!isOwner && (
        <UiSkeleton loading={loading} count={3} width="100%">
          <UiTypography.Text type="secondary">
            {isUserActive && (
              <UiSpace direction="vertical">
                {visitorDescription}
                <AddLink>{`Добавить ${skillsTextMap[skillType].OneDown}`}</AddLink>
              </UiSpace>
            )}
            {!isUserActive && blockedUserDescription}
          </UiTypography.Text>
        </UiSkeleton>
      )}
    </div>
  );
};
