import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UiRoute } from 'ant/components/ui/breadcrumb';
import { UiCard } from 'ant/components/ui/card';
import { UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { UiDivider } from 'ant/components/ui/divider';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { message } from 'ant/components/ui/message';
import { UiModalBase } from 'ant/components/ui/modals';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { UiUploadFile, UiUploadOriginFile } from 'ant/components/ui/upload';
import { FileStorageList, FileStorageListItemSelect } from 'ant/components/widgets/FileStorageList';
import { UploadDraggerArea } from 'ant/components/widgets/UploadDraggerArea';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { UploadFile } from 'ant/helpers/storage/abstract-files-upload-factory';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { replaceParams } from 'ant/plugins/router';
import { getFormattedEndpointExtensions } from 'ant/plugins/utils/get-formatted-endpoint-extensions';
import { useCurrentProfile } from 'ant/providers/current-profile';
import {
  FileStorageListParams,
  getFileStorageFolderInfoStorage,
  getFileStorageHierarchyStorage,
  getFileStorageListStorage,
  getFileStorageSearchListStorage,
  getFilesUploadStorage,
  uploadFileStorageAttachmentEffectFactory,
} from 'ant/store/filestorage';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import {
  FileStorageEntryId,
  FileStorageEntryType,
  FileStorageListEntryModel,
  FileStoragePermissions,
  PreloadedFileModel,
} from 'ant/types/models/file.model';

import { AddEditFileFolderModal } from '../../add-edit-folder-modal/AddEditFileFolderModal';
import { FileListManagerControlBar } from './control-bar/FileListManagerControlBar';
import { FileListManagerExtensionsModal } from './extensions-modal/FileListManagerExtensionsModal';
import { FileListManagerNavigation } from './navigation/FIleListManagerNavigation';
import { FileListManagerPermissionModal } from './permissions-modal/FileListManagerPermissionModal';

enum FileListManagerMultiSelectAction {
  Move = 'Move',
  Delete = 'Delete',
}

export type FileListProps = {
  rootId: FileStorageEntryId;
  rootRoute: UiRoute;
  folderPath: string;
  filePath: string;
  categoryIds?: string;
  isMultiSelectPermission?: boolean;
};

export const FileListManager: FC<FileListProps> = (props) => {
  const { rootId, filePath, folderPath, rootRoute, categoryIds, isMultiSelectPermission } = props;
  const navigate = useNavigate();
  const { id: currentUserId, superAdmin: isSuperAdmin } = useCurrentProfile();
  const { entryId } = useParams<{ entryId?: FileStorageEntryId }>();
  const activeFileStorageId: FileStorageEntryId = entryId || rootId;
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false);
  const [editFolder, setEditFolder] = useState<FileStorageListEntryModel | null>(null);
  const [editFolderPermissions, setEditFolderPermissions] = useState<FileStorageListEntryModel | null>(null);
  const [editFolderExtensions, setEditFolderExtensions] = useState<FileStorageListEntryModel | null>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedEntries, setSelectedEntries] = useState<FileStorageEntryId[]>([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  const {
    storage: fileStorageListStorage,
    deleteFileStorageEntryEffect,
    createFileStorageFolderEffect,
    updateFileStorageFolderEffect,
    createUpdateFileStorageFileEvent,
  } = useMemo(() => getFileStorageListStorage(activeFileStorageId), [activeFileStorageId]);
  const { storage: fileStorageSearchListStorage } = useMemo(
    () => getFileStorageSearchListStorage(rootId),
    [rootId],
  );

  const fileInfoStorage = useMemo(getFileStorageFolderInfoStorage, []);
  const { data: fileStorageInfo } = useAbstractStorage(fileInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: activeFileStorageId,
    },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnChangeParams: { id: activeFileStorageId },
  });

  const fileAllowedTypes = useMemo(() => {
    if (fileStorageInfo?.extensions) {
      const { video, image } = fileStorageInfo.extensions;
      let result = '';

      if (image.length > 0) {
        result += getFormattedEndpointExtensions(image, 'image');
      }

      if (video.length > 0) {
        result += getFormattedEndpointExtensions(video, 'video');
      }

      return result;
    }

    return '';
  }, [fileStorageInfo]);

  const isHasPermissionToCreateFiles = useMemo(() => {
    return fileStorageInfo?.permissions?.includes(FileStoragePermissions.CreateFile);
  }, [fileStorageInfo]);

  const { data: fileStorageList } = useAbstractStorage(fileStorageListStorage);
  const { data: fileStorageSearchList, loading: fileStorageSearchListLoading } = useAbstractStorage(
    fileStorageSearchListStorage,
  );

  const fileStorageHierarchyStorage = useMemo(
    () => getFileStorageHierarchyStorage(activeFileStorageId),
    [activeFileStorageId],
  );
  const uploadAttachmentEffect = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>();
  }, []);

  const isCreateFilePending = useStore(uploadAttachmentEffect.pending);
  const isCreateFolderPending = useStore(createFileStorageFolderEffect.pending);
  const isUpdateFolderPending = useStore(updateFileStorageFolderEffect.pending);

  const { data: fileStorageHierarchy } = useAbstractStorage(fileStorageHierarchyStorage.storage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    autoFetchParams: {
      upto: rootId,
    },
  });

  const routes = useMemo(() => {
    const parentRoutes = fileStorageHierarchy
      .filter((route) => route.id !== rootId)
      .map((route) => ({
        path: replaceParams(folderPath, { entryId: route.id }),
        breadcrumbName: route.name,
      }));

    return [rootRoute, ...parentRoutes];
  }, [rootRoute, fileStorageHierarchy]);

  const onDelete = (items: FileStorageListEntryModel[]) => {
    let interactionText = {
      title: 'Удалить вложения?',
      successText: 'Вложения успешно удалены',
      errorText: 'Ошибка удаления вложения',
    };

    if (items.length === 1) {
      const isFolder = items[0].type === FileStorageEntryType.Folder;

      interactionText = {
        title: `Удалить ${isFolder ? 'папку' : 'файл'}?`,
        successText: isFolder ? 'Папка успешно удалена' : 'Файл успешно удален',
        errorText: `Ошибка удаления ${isFolder ? 'папки' : 'файла'}`,
      };
    }

    UiModalBase.warning({
      title: interactionText.title,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: (close) => {
        deleteFileStorageEntryEffect({ objectIds: items.map((item) => item.id) })
          .then(() => {
            close();
            message.success(interactionText.successText);
            setIsMultiSelect(false);
          })
          .catch((e) => message.error(getErrorResponseMessage(e, interactionText.errorText)));
      },
    });
  };

  const onClick = (item: FileStorageListEntryModel) => {
    if (item.type === FileStorageEntryType.Folder) {
      navigate(replaceParams(folderPath, { entryId: item.id }));
    } else {
      navigate(replaceParams(filePath, { fileId: item.id }));
    }
  };

  const onChangePermissions = (item: FileStorageListEntryModel) => {
    if (item.type === FileStorageEntryType.Folder) {
      setEditFolderPermissions(item);
    }
  };

  const onChangeExtensions = (item: FileStorageListEntryModel) => {
    if (item.type === FileStorageEntryType.Folder) {
      setEditFolderExtensions(item);
    }
  };

  const onMove = (items: FileStorageListEntryModel[]) => {
    openGlobalModal(GlobalModalNames.FileStorageMoveEntries, {
      rootId,
      rootName: 'Файлы',
      moveItems: items,
      onSuccess: () => {
        fileStorageListStorage.refetchWithLastParams();
        setIsMultiSelect(false);
      },
      onClose: () => closeGlobalModal(GlobalModalNames.FileStorageMoveEntries),
    });
  };

  const onAddFolderApply = (name: string) => {
    createFileStorageFolderEffect({ name, parent: activeFileStorageId })
      .then(() => {
        message.success('Папка успешно создана');
        setIsCreateFolderOpen(false);
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка создания папки')));
  };

  const onRenameFolderApply = (name: string) => {
    if (editFolder) {
      updateFileStorageFolderEffect({ name, id: editFolder.id })
        .then(() => {
          message.success('Папка успешно переименована');
          setEditFolder(null);
        })
        .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка переименования папки')));
    }
  };

  const { storage: filesUploadStorage } = useMemo(getFilesUploadStorage, []);

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      filesUploadStorage.uploadFilesEvent({ filesToUpload: uploadFileList, appendData: false });

      if (filesToUpload.length > 1) {
        openGlobalModal(GlobalModalNames.FileStorageCreateMultiple, {
          parent: activeFileStorageId,
          uploadStore: filesUploadStorage,
          onSuccess: () => {
            fileStorageListStorage.refetchWithLastParams();
          },
          onClose: () => closeGlobalModal(GlobalModalNames.FileStorageCreateMultiple),
        });
      } else {
        openGlobalModal(GlobalModalNames.FileStorageCreate, {
          parent: activeFileStorageId,
          uploadStore: filesUploadStorage,
          onSuccess: createUpdateFileStorageFileEvent,
          onClose: () => {
            closeGlobalModal(GlobalModalNames.FileStorageCreate);
          },
        });
      }
    },
    [filesUploadStorage, activeFileStorageId, fileStorageListStorage, createUpdateFileStorageFileEvent],
  );

  const onPickFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      uploadFiles(filesToUpload);
    },
    [uploadFiles],
  );

  const filterQuery = useMemo<FileStorageListParams>(() => {
    const categoriesParams = {
      categories: categoryIds,
    };

    if (searchText) {
      return {
        ...categoriesParams,
        query: searchText,
      };
    }

    return categoriesParams;
  }, [searchText, categoryIds]);

  const isSearchQuery = useMemo(() => Boolean(filterQuery?.query), [filterQuery]);
  const isSearchDataNotFound = useMemo(
    () => isSearchQuery && !fileStorageSearchListLoading && fileStorageSearchList.length === 0,
    [isSearchQuery, fileStorageSearchList, fileStorageSearchListLoading],
  );

  const onSelectChange = useCallback(
    (newSelectedRowKeys: UiCheckboxValueType[]) => {
      setSelectedEntries(newSelectedRowKeys.map(String));
    },
    [setSelectedEntries],
  );

  const onSelectAllEntries = useCallback(() => {
    const entriesList = isSearchQuery ? fileStorageSearchList : fileStorageList;

    setSelectedEntries(entriesList.map((entry) => entry.id));
  }, [setSelectedEntries, fileStorageSearchList, fileStorageList, isSearchQuery]);

  const onMultipleAction = (action: FileListManagerMultiSelectAction) => {
    const selectedEntriesMap = new Map(selectedEntries.map((id) => [id, id]));
    const entriesList = isSearchQuery ? fileStorageSearchList : fileStorageList;
    const entriesToAction = entriesList.filter((entry) => selectedEntriesMap.has(entry.id));

    if (action === FileListManagerMultiSelectAction.Delete) {
      onDelete(entriesToAction);
    }

    if (action === FileListManagerMultiSelectAction.Move) {
      onMove(entriesToAction);
    }
  };

  useEffect(() => {
    setSelectedEntries([]);
  }, [fileStorageSearchList, fileStorageList]);

  const fileSelect = useMemo<FileStorageListItemSelect>(
    () => ({
      selectedItemKeys: selectedEntries,
      onChange: onSelectChange,
    }),
    [selectedEntries, onSelectChange],
  );

  const onFileSelect = useCallback(
    (file: FileStorageListEntryModel) => {
      let selected = [...selectedEntries, file.id];

      if (selectedEntries.includes(file.id)) {
        selected = selectedEntries.filter((id) => id !== file.id);
      }

      setSelectedEntries(selected);
    },
    [selectedEntries],
  );

  const isHasUpload = !isSearchQuery && isHasPermissionToCreateFiles;

  const getFileActions = useCallback(
    (file: FileStorageListEntryModel) => {
      const { permissions, type } = file;
      const isFolder = file.type === FileStorageEntryType.Folder;

      const isFolderUpdateAllowed = permissions?.includes?.(FileStoragePermissions.UpdateFolder);
      const isDeleteAllowed =
        type === FileStorageEntryType.File
          ? permissions?.includes?.(FileStoragePermissions.DeleteFile)
          : permissions?.includes?.(FileStoragePermissions.DeleteFolder);

      const result = [];

      if (isDeleteAllowed) {
        result.push({
          label: 'Удалить',
          onClick: () => onDelete([file]),
        });
      }

      if (isFolder) {
        result.push({
          label: 'Перейти',
          onClick: () => onClick(file),
        });
      }

      if (isFolder && isSuperAdmin) {
        result.push({
          label: 'Изменить доступ',
          onClick: () => onChangePermissions(file),
        });
      }

      if (isFolder && isSuperAdmin) {
        result.push({
          label: 'Ограничить по типу файла',
          onClick: () => onChangeExtensions(file),
        });
      }

      if (onMove) {
        result.push({
          label: 'Переместить',
          onClick: () => onMove([file]),
        });
      }

      if (isFolder && isFolderUpdateAllowed) {
        result.push({
          label: 'Переименовать',
          onClick: () => setEditFolder(file),
        });
      }

      return result;
    },
    [currentUserId, isSuperAdmin, onMove, setEditFolder],
  );

  return (
    <>
      <UiCard size="default" emptyPadding>
        <UiRow>
          <UiCol span={24}>
            <FileListManagerNavigation
              routes={routes}
              onSearch={setSearchText}
              onClear={() => setSearchText('')}
            />
            <UiDivider emptyMargin />
            <FileListManagerControlBar>
              <FileListManagerControlBar.LeftActions
                isSearching={isSearchQuery}
                isMultiSelect={isMultiSelect}
                isMultiSelectPermission={isMultiSelectPermission}
                selectedCount={selectedEntries.length}
                totalCount={isSearchQuery ? fileStorageSearchList.length : fileStorageList.length}
                onMultipleModeClick={() => setIsMultiSelect(true)}
                onCreateFolderClick={() => setIsCreateFolderOpen(true)}
                onCancel={() => setIsMultiSelect(false)}
                onSelectAll={onSelectAllEntries}
                onDeselectAll={() => setSelectedEntries([])}
                foundItemsCount={fileStorageSearchList.length}
              />
              {isMultiSelect && (
                <FileListManagerControlBar.RightActions
                  onMove={() => onMultipleAction(FileListManagerMultiSelectAction.Move)}
                  onDelete={() => onMultipleAction(FileListManagerMultiSelectAction.Delete)}
                />
              )}
            </FileListManagerControlBar>
            <UiDivider emptyMargin />
            {isHasUpload && (
              <UiCol style={{ padding: '20px 24px' }}>
                <UploadDraggerArea
                  onPickFiles={onPickFiles}
                  loading={isCreateFilePending}
                  multiple
                  accept={fileAllowedTypes}
                  compact
                />
              </UiCol>
            )}
            <UiRender type={UiRenderType.DisplayNone} visible={!isSearchDataNotFound}>
              <UiCol style={{ paddingTop: isSearchQuery ? 16 : 0 }}>
                <FileStorageList
                  storage={isSearchQuery ? fileStorageSearchListStorage : fileStorageListStorage}
                  pageSize={20}
                  onClick={onClick}
                  query={filterQuery}
                  fileSelect={isMultiSelect ? fileSelect : undefined}
                  onFileSelect={onFileSelect}
                  getFileActions={getFileActions}
                />
              </UiCol>
            </UiRender>
            {isSearchDataNotFound && (
              <UiEmpty.Feed.Message
                emptyMessage={{
                  header: 'Ничего не найдено',
                  description: 'Попробуйте сформулировать запрос по-другому',
                }}
              />
            )}
          </UiCol>
        </UiRow>
      </UiCard>
      <AddEditFileFolderModal
        title="Создать папку"
        inputPlaceholder="Название папки"
        isOpen={isCreateFolderOpen}
        onClose={() => setIsCreateFolderOpen(false)}
        onApply={onAddFolderApply}
        loading={isCreateFolderPending}
      />
      <AddEditFileFolderModal
        title="Переименовать папку"
        inputPlaceholder="Название папки"
        existingItemName={editFolder ? editFolder.name : ''}
        isOpen={Boolean(editFolder)}
        onClose={() => setEditFolder(null)}
        onApply={onRenameFolderApply}
        loading={isUpdateFolderPending}
      />
      {editFolderPermissions && (
        <FileListManagerPermissionModal
          onClose={() => setEditFolderPermissions(null)}
          isOpen={Boolean(editFolderPermissions)}
          folder={editFolderPermissions}
        />
      )}
      {editFolderExtensions && (
        <FileListManagerExtensionsModal
          onClose={() => setEditFolderExtensions(null)}
          isOpen={Boolean(editFolderExtensions)}
          folder={editFolderExtensions}
        />
      )}
    </>
  );
};
