import React, { FC, MouseEvent } from 'react';

import DeleteOutlineSvg from 'ant/components/svg/delete-outline.svg';
import DownloadOutlineSvg from 'ant/components/svg/download-outline.svg';
import InfoCircleOutlineSvg from 'ant/components/svg/info-circle-outline.svg';
import RefreshOutlineSvg from 'ant/components/svg/refresh-outline.svg';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiDivider } from 'ant/components/ui/divider';
import { UiFile } from 'ant/components/ui/file';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon, UiIconProps } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { colorTextInvertTooltipStyle } from 'ant/components/widgets/UserProfile';
import { getFullName } from 'ant/plugins/name-formatters';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { themePalette } from 'ant/theme';
import { FileStorageEntryVersionId, FileStorageEntryVersionModel } from 'ant/types/models/file.model';

type FileVersionPanelExtraOnAction = (versionId: FileStorageEntryVersionId) => void;

interface Props extends FileStorageEntryVersionModel {
  onDelete: FileVersionPanelExtraOnAction;
  onRestore: FileVersionPanelExtraOnAction;
  isLatestRevision: boolean;
  isOnlyRevision: boolean;
}

export const FileVersionsPanelExtra: FC<Props> = (props) => {
  const {
    revision,
    onDelete,
    onRestore,
    isLatestRevision,
    isOnlyRevision,
    createdAt,
    size,
    createdBy,
    file: url,
    name,
    isDeleted,
  } = props;

  const Subtitle: FC = () => (
    <UiSpace.Compact direction="vertical">
      <UiTypography.Footnote type={UiTypographyTextTypes.Tertiary} strong>
        {createdAt ? getFormattedDate(createdAt, 'dd.MM.yyyy в hh:mm') : ''}
        <UiDivider.Dot type="secondary" />
        {getFormattedFileSize(size)}
      </UiTypography.Footnote>
      <UiTypography.Footnote type={UiTypographyTextTypes.Tertiary} strong>
        {getFullName(createdBy) || 'Неизвестный автор'}
      </UiTypography.Footnote>
    </UiSpace.Compact>
  );

  const TooltipContent: FC = () => (
    <UiFile
      size="large"
      fileName={name}
      title={
        <UiTypography.Title style={colorTextInvertTooltipStyle} level={3}>
          <UiTruncateMarkup truncate>{name}</UiTruncateMarkup>
        </UiTypography.Title>
      }
      subtitle={<Subtitle />}
    />
  );

  const onAction = (e: MouseEvent, action: (versionId: FileStorageEntryVersionId) => void) => {
    e.stopPropagation();
    action(revision);
  };

  const baseIconProps: UiIconProps = {
    color: themePalette.colorIcon,
    width: 20,
    height: 20,
  };

  const baseButtonProps: UiButtonProps = {
    type: 'link-secondary',
    disabledFocus: true,
  };

  const isPossibleToDelete = !isOnlyRevision && !isDeleted;
  const isPossibleToRefresh = (!isLatestRevision && !isOnlyRevision) || (!isLatestRevision && isDeleted);

  return (
    <UiRow gutter={[0, 16]} justify="space-between" wrap={false} style={{ width: '100%', marginLeft: 16 }}>
      <UiSpace size={16} flex>
        {!isDeleted && (
          <UiTooltip overlayInnerStyle={{ padding: 20 }} title={<TooltipContent />}>
            <UiButton
              {...baseButtonProps}
              icon={<UiIcon {...baseIconProps} component={InfoCircleOutlineSvg} />}
            />
          </UiTooltip>
        )}
        {url && !isDeleted && (
          <UiTooltip title="Скачать">
            <UiButton
              {...baseButtonProps}
              target="_blank"
              href={url}
              icon={<UiIcon {...baseIconProps} component={DownloadOutlineSvg} />}
            />
          </UiTooltip>
        )}
      </UiSpace>
      <UiSpace size={16} flex style={{ marginLeft: 56 }}>
        {isPossibleToRefresh && (
          <UiTooltip title="Восстановить">
            <UiButton
              {...baseButtonProps}
              icon={<UiIcon {...baseIconProps} component={RefreshOutlineSvg} />}
              onClick={(e) => onAction(e, onRestore)}
            />
          </UiTooltip>
        )}
        {isPossibleToDelete && (
          <UiTooltip title="Удалить">
            <UiButton
              {...baseButtonProps}
              icon={<UiIcon {...baseIconProps} component={DeleteOutlineSvg} />}
              onClick={(e) => onAction(e, onDelete)}
            />
          </UiTooltip>
        )}
      </UiSpace>
    </UiRow>
  );
};
