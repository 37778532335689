import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, useLayoutEffect, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

import ArrowUpSvg from 'ant/components/svg/arrow-up.svg';
import CloseSVG from 'ant/components/svg/close.svg';
import { UiBackTop } from 'ant/components/ui/back-top';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './PageOverlay.scss';

type Props = {
  visible: boolean;
  onClose?: () => void;
};

const ccn = classNames.bind(styles);

export const PageOverlay: FC<PropsWithChildren<Props>> = (props) => {
  const [portalElem, setPortalElem] = useState<HTMLDivElement | null>(null);
  const [overlayScrollElem, setOverlayScrollElem] = useState<HTMLDivElement | null>(null);
  const { visible, onClose, children } = props;

  const onScrollRefChange = useCallback((node: HTMLDivElement | null) => {
    setOverlayScrollElem(node);
  }, []);

  const content = (
    <div className={ccn(styles.pageOverlay, { [styles.pageOverlay_visible]: visible })}>
      <div className={styles.pageOverlay__main} ref={onScrollRefChange}>
        {overlayScrollElem && (
          <UiBackTop target={() => overlayScrollElem}>
            <UiButton
              className={styles.pageOverlay__scrollToTop}
              type="link-secondary"
              label="Наверх"
              icon={<UiIcon component={ArrowUpSvg} width={20} height={20} />}
            />
          </UiBackTop>
        )}

        <div className={styles.pageOverlay__content}>{visible ? children : null}</div>
      </div>
      {onClose && (
        <UiButton
          type="link-secondary"
          className={styles.pageOverlay__close}
          icon={<UiIcon component={CloseSVG} width={20} height={20} />}
          onClick={onClose}
          label="Закрыть"
        />
      )}
    </div>
  );

  useLayoutEffect(() => {
    const newPortalElem = document.createElement('div');

    document.body.appendChild(newPortalElem);

    setPortalElem(newPortalElem);

    return () => {
      document.body.removeChild(newPortalElem);
    };
  }, []);

  const enableScrolling = () => {
    if (overlayScrollElem) {
      enableBodyScroll(overlayScrollElem);
    }
  };

  useLayoutEffect(() => {
    if (overlayScrollElem && visible) {
      disableBodyScroll(overlayScrollElem);
    }

    return enableScrolling;
  }, [overlayScrollElem, visible]);

  return portalElem ? ReactDOM.createPortal(content, portalElem) : null;
};
