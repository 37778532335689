import React, { FC, useMemo } from 'react';

import { UiCard } from 'ant/components/ui/card';
import { UiMenu, UiMenuItemType, UiMenuSelectEventHandler } from 'ant/components/ui/menu';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getFileStorageCategoriesStorage } from 'ant/store/filestorage';
import { FileStorageEntryId } from 'ant/types/models/file.model';

type Props = {
  entryId: FileStorageEntryId;
  onSelect?: UiMenuSelectEventHandler;
  selectedCategoryIds?: string;
};

export const CATEGORIES_ALL_KEY = 'CATEGORIES_ALL_KEY';

const CATEGORIES_ALL = { label: 'Все', key: CATEGORIES_ALL_KEY } satisfies UiMenuItemType;

export const FileStorageCategoryMenu: FC<Props> = (props) => {
  const { entryId, onSelect, selectedCategoryIds } = props;
  const selectedCategories = selectedCategoryIds?.split(',');
  const activeCategories =
    selectedCategories && selectedCategories.length > 0 ? selectedCategories : [CATEGORIES_ALL.key];

  const fileStorageCategoriesStorage = useMemo(getFileStorageCategoriesStorage, []);

  const { data: fileStorageCategoryData } = useAbstractStorage(fileStorageCategoriesStorage.storage, {
    autoFetchParams: { entryId },
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const categoriesMenuItems = useMemo<UiMenuItemType[]>(() => {
    return [
      CATEGORIES_ALL,
      ...fileStorageCategoryData.map((entry) => ({
        key: String(entry.id),
        label: entry.name,
      })),
    ];
  }, [fileStorageCategoryData]);

  return (
    <UiCard emptyPadding>
      <UiCard.Header>
        <UiTypography.Title style={{ marginBottom: 0 }} level={3}>
          Категории
        </UiTypography.Title>
      </UiCard.Header>
      <UiCard.Content>
        <UiMenu
          multiple
          style={{ marginBottom: 24 }}
          items={categoriesMenuItems}
          onSelect={onSelect}
          defaultSelectedKeys={[String(categoriesMenuItems[0].key)]}
          selectedKeys={activeCategories}
        />
      </UiCard.Content>
    </UiCard>
  );
};
