import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import GearSvg from 'ant/components/svg/gear.svg';
import { UiButtonProps } from 'ant/components/ui/button';
import { ActionsDropdown, ActionsDropdownItem } from 'ant/components/widgets/ActionsDropdown';
import { authService } from 'ant/plugins/auth-service';
import { isPathMatchRoute, RouteNames } from 'ant/plugins/router';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { populatedLayoutStorage } from 'ant/store/populated-layout';
import { HeaderButtons } from 'components-frontend/components/header/buttons/HeaderButtons';
import { EmptyFeedDefault } from 'components-frontend/components/notifications-drawer/empty-feed/EmptyFeed@default';
// import { NotificationBodyTypes, NotificationTitleTypes } from 'components-frontend/typings/notification';

import styles from './HeaderButtons@default.scss';

const pollActions: ActionsDropdownItem[] = [
  {
    label: 'Настройка ленты',
    onClick: () =>
      openGlobalModal(GlobalModalNames.TimelineSettings, {
        onClose: () => closeGlobalModal(GlobalModalNames.TimelineSettings),
      }),
  },
  { label: 'Выйти из профиля', onClick: () => authService.logout() },
];

const dropdownButtonProps: UiButtonProps = {
  size: 'large',
};

const AfterAvatarElement = () => {
  const { pathname } = useLocation();

  const items = useMemo(() => {
    const isMainPage = isPathMatchRoute(pathname, RouteNames.Main);

    if (isMainPage) {
      return [
        {
          label: 'Редактировать виджеты',
          onClick: () => populatedLayoutStorage.setIsEditLayoutEvent(),
        },
        ...pollActions,
      ];
    }

    return pollActions;
  }, [pathname]);

  return (
    <ActionsDropdown
      items={items}
      dropdownButtonProps={dropdownButtonProps}
      className={styles.headerButtons__button}
      overlayClassName={styles.headerButtons__dropdown}
      itemClassName={styles.headerButtons__item}
      menuClassName={styles.headerButtons__menu}
    />
  );
};

export const HeaderButtonsDefault: FC = () => (
  <HeaderButtons
    drawerTitle="Лента уведомлений"
    markAllAsReadLabel="Пометить все как просмотренные"
    // TODO: выяснить почему не используется
    // notificationTitleType={NotificationTitleTypes.initiator}
    // notificationBodyType={NotificationBodyTypes.User}
    icon={GearSvg}
    emptyNotificationsFeed={<EmptyFeedDefault />}
    afterAvatarElement={<AfterAvatarElement />}
  />
);
