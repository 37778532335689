import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiSpace } from 'ant/components/ui/space';
import { Notification } from 'ant/components/widgets/Notification';
import { authService } from 'ant/plugins/auth-service';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { GamificationBadges } from 'ant/types/models/gamification.model';
import { NotificationModel } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<GamificationBadges>;
}

export const NotificationGamificationReward: FC<Props> = (props) => {
  const { notification } = props;
  const { createdAt, content } = notification;

  const currentUserId = authService.getCurrentUserId();
  const navigate = useNavigate();

  const notificationTitle = content.badge.name;
  const notificationDescription = getNotificationDescription(notification);
  const avatar = { src: content.badge.image } satisfies UiAvatarProps;

  const profileRewardLocation = {
    pathname: getRoutePath(RouteNames.ProfileRewardsProgress, { id: String(currentUserId) }),
    state: {
      notificationBadge: content,
    },
  };

  const onClick = () => navigate(profileRewardLocation);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={profileRewardLocation}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />
      <Notification.Body>
        <UiSpace full>
          <UiButton label="Посмотреть" type="primary" onClick={onClick} />
        </UiSpace>
        <Notification.Body.Footer date={createdAt} />
      </Notification.Body>
    </Notification>
  );
};
