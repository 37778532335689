import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import ViewSvg from 'ant/components/svg/view.svg';
import { UiCard } from 'ant/components/ui/card';
import { UiDescriptions, UiDescriptionsItemProps } from 'ant/components/ui/descriptions';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiIcon } from 'ant/components/ui/icon';
import { UiUserLinkCell } from 'ant/components/ui/user-link-cell';
import { Reactions } from 'ant/components/widgets/Reactions';
import { numberAbbreviator } from 'ant/plugins/number-formatters';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { GetFileStorageEntryInfoStorage } from 'ant/store/filestorage';
import { FileStorageEntryId } from 'ant/types/models/file.model';
import { ReactionId } from 'ant/types/models/reactions.model';

import { FileInfoCardGeneralLoading } from './loading/FileInfoCardGeneralLoading';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  id: FileStorageEntryId;
};

export const FileInfoCardGeneral: FC<Props> = (props) => {
  const { fileInfoStorage, id } = props;

  const { data: fileInfoData, error: fileInfoError } = useStore(fileInfoStorage.storage.store);
  const isFileInfoDataPending = useStore(fileInfoStorage.storage.fetchEffect.pending);

  const descriptionItemProps: UiDescriptionsItemProps = {
    spaceProps: { direction: 'horizontal', size: 0 },
    labelProps: { strong: false, type: 'secondary' },
    labelRowProps: { align: 'middle', justify: 'start', style: { width: 140, height: 40 } },
    span: 24,
  };

  const onReaction = useCallback(
    (reactionId: ReactionId, isLike: boolean) => {
      return fileInfoStorage.reactOnFileStorageEntryEffect({ id, reactionId, isLike });
    },
    [id],
  );

  const isDataFethed = !isFileInfoDataPending && fileInfoData;
  const isLoading = isFileInfoDataPending && !fileInfoData;
  const isEmpty = (!fileInfoData && !isLoading) || fileInfoError;

  return (
    <UiCard emptyPadding>
      <UiCard.Header>
        <UiCard.Header.Title>Общая информация</UiCard.Header.Title>
      </UiCard.Header>
      {isLoading && <FileInfoCardGeneralLoading />}
      {isDataFethed && (
        <>
          <UiCard.Content>
            <UiDescriptions padding={[0, 24]}>
              <UiDescriptions.Item {...descriptionItemProps} label="Количество версий">
                {fileInfoData.revisionsCount}
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Дата создания">
                {getFormattedDate(fileInfoData.createdAt, 'dd MMMM в HH:mm')}
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Автор">
                <UiUserLinkCell lines={1} size={32} strong user={fileInfoData.createdBy} />
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Дата изменения">
                {getFormattedDate(fileInfoData.updatedAt, 'dd MMMM в HH:mm')}
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Автор изменения">
                <UiUserLinkCell lines={1} size={32} strong user={fileInfoData.updatedBy} />
              </UiDescriptions.Item>
            </UiDescriptions>
          </UiCard.Content>
          <UiCard.Footer>
            <Reactions reactions={fileInfoData.reactions} onReaction={onReaction} />
            <UiIcon.Label
              style={{ marginLeft: 'auto' }}
              label={numberAbbreviator(fileInfoData.objectViewsCount)}
              component={ViewSvg}
              width={20}
              height={20}
            />
          </UiCard.Footer>
        </>
      )}
      {isEmpty && <UiEmpty.Feed emptyMessage={{ header: 'Ошибка получения общей информации' }} />}
    </UiCard>
  );
};
