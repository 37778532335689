import Icon from '@ant-design/icons';
import React, { CSSProperties, FC, useState } from 'react';

import ArrowExpandSvg from 'ant/components/svg/arrow-expand.svg';
import { UiButton } from 'ant/components/ui/button/UiButton';
import { useQueryParams } from 'ant/helpers/hooks/use-query-params';
import { useScrollToElementId } from 'ant/helpers/hooks/use-scroll-to-element';
import { CommentDetailListModel, CommentId } from 'ant/types/models/comment.model';
import { ReactionId } from 'ant/types/models/reactions.model';

import { CommentItem, CommentQueryParams } from '../comment-item';
import styles from './CommentList.scss';

export type CommentListProps = {
  comments: CommentDetailListModel[];
  showCount?: number;
  maxLevel?: number | null;
  loading?: boolean;
  onSend: (text: string, parentId: CommentId | null) => Promise<unknown>;
  onCommentEdit?: (commentId: CommentId, text: string) => Promise<unknown>;
  onCommentDelete?: (commentId: CommentId) => void;
  onReaction: (commentId: CommentId, reactionId: ReactionId, isLike: boolean) => void;
  maxNestLevelWithShift: number;
  style?: CSSProperties;
};

export const CommentList: FC<CommentListProps> = (props) => {
  const {
    comments,
    showCount = 1,
    maxLevel = null,
    maxNestLevelWithShift,
    onSend,
    loading,
    onCommentEdit,
    onCommentDelete,
    onReaction,
    style,
  } = props;
  const {
    queryParams: { commentId },
  } = useQueryParams<CommentQueryParams>();

  const { scrollElementId } = useScrollToElementId({ prefix: 'comment_', elementId: commentId });

  const [isExpanded, setIsExpanded] = useState(scrollElementId || comments.length <= showCount);

  const shownComments = isExpanded ? comments : comments.slice(0, showCount);
  const actualMaxLevel = isExpanded ? null : maxLevel;

  const ArrowIcon = (
    <Icon
      style={{
        display: 'flex',
        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
      }}
      component={() => <ArrowExpandSvg />}
    />
  );

  return (
    <div style={style} className={styles.commentsList}>
      {shownComments.map((comment) => {
        const isHighlighted = String(comment.id) === commentId;

        return (
          <CommentItem
            key={comment.id}
            className={styles.commentsList__comment}
            comment={comment}
            onCommentReply={onSend}
            loading={loading}
            maxLevel={actualMaxLevel}
            maxNestLevelWithShift={maxNestLevelWithShift}
            onReaction={onReaction}
            onCommentEdit={onCommentEdit}
            onCommentDelete={onCommentDelete}
            isHighlighted={isHighlighted}
            scrollElementId={isHighlighted ? scrollElementId : undefined}
          />
        );
      })}
      {!isExpanded && (
        <UiButton
          icon={ArrowIcon}
          onClick={() => setIsExpanded(true)}
          className={styles.commentsList__btnMore}
        >
          Все комментарии
        </UiButton>
      )}
    </div>
  );
};
