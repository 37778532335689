import React, { FC } from 'react';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiSpace } from 'ant/components/ui/space';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { NewsLongreadParams } from 'ant/types/longread';
import { NotificationModel } from 'ant/types/models/notification.model';
import { PostNewsModel } from 'ant/types/models/post';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<PostNewsModel>;
}

export const NotificationNewsMention: FC<Props> = (props) => {
  const { notification } = props;
  const { content, createdAt, initiator, image } = notification;
  const { title, id, type } = content;
  const { fullName, keycloakId } = initiator;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const targetParams: NewsLongreadParams = { newsId: id, newsType: type };

  const onClick = () => {
    openGlobalModal(GlobalModalNames.NewsLongread, targetParams, targetParams);
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          <UiButton type="link" onClick={onClick} label={title} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
