import { useStore } from 'effector-react';
import React, { FC, useState, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { message } from 'ant/components/ui/message';
import { UiOptionData } from 'ant/components/ui/select';
import { CreatableSearchSelect } from 'ant/components/widgets/CreatableSearchSelect';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { generateAutoCompleteOptions } from 'ant/plugins/utils/options-helper';
import { getDictsStorage } from 'ant/store/dictionaries';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { GetSkillsStorage } from 'ant/store/profile/skills';
import { SaveSkillParams } from 'ant/store/profile/skills/api';
import { DictDataParams, DictMatchTypes } from 'ant/types/api';
import { RecordResponse } from 'ant/types/dictionary';
import { SkillType } from 'ant/types/models/profile.model';

import { SkillsAddModal } from '../modals/skills-add/SkillsAddModal';

type Props = {
  closeModal: () => void;
  skillStorage: GetSkillsStorage;
};

const skillsDictsStorage = getDictsStorage<RecordResponse, DictDataParams>({
  dictionaryName: Dictionaries.Names.Skills,
  dataBuilder: ({ name: { type, value } }) => ({ name: { type, value } }),
  getEndpointParams: () => ({ ordering: 'name' }),
});

const skillsDictsOptions = skillsDictsStorage.storage.store.map(({ data }) =>
  generateAutoCompleteOptions(data),
);

export const SkillsAdd: FC<Props> = (props) => {
  const { closeModal, skillStorage } = props;
  const [searchText, setSearchText] = useState('');
  const [selectedSkill, setSelectedSkill] = useState<UiOptionData | null>(null);

  const { saveSkillEffect } = skillStorage;
  const skillsList = useStore(skillStorage.storage.store);
  const saveSkillInProgress = useStore(saveSkillEffect.pending);

  const existingSkillsOptions = useMemo(
    () =>
      generateAutoCompleteOptions(skillsList.data, {
        valuePath: 'skill.id',
        labelPath: 'skill.name',
      }),
    [skillsList.data],
  );

  useAbstractStorage(skillsDictsStorage.storage, {
    autoFetchAndRefetch: searchText.length > 0,
    autoFetchParams: { name: { type: DictMatchTypes.Icontains, value: searchText } },
  });

  const { createNewDictsRecordEffect } = skillsDictsStorage;
  const skillsOptions = useStore(skillsDictsOptions);
  const createSkillPending = useStore(createNewDictsRecordEffect.pending);

  const onSaveSkillHandler = () => {
    if (selectedSkill) {
      const saveSkillParams: SaveSkillParams = {
        name: selectedSkill.value,
        skillId: selectedSkill.key,
        skillType: SkillType.Skill,
      };

      saveSkillEffect(saveSkillParams)
        .then(closeModal)
        .catch((e) => message.error(getErrorResponseMessage(e)));
    }
  };

  const onSearchSkill = (value: string) => {
    setSearchText(value);
    setSelectedSkill(null);
  };

  const onSelectSkill = (_: string, option: UiOptionData) => {
    setSelectedSkill(option);
  };

  const SubmitChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Сохранить"
      onClick={onSaveSkillHandler}
      loading={saveSkillInProgress}
      disabled={createSkillPending || !selectedSkill}
    />
  );

  return (
    <SkillsAddModal title="Добавить навык" onCancel={closeModal} submitChildren={SubmitChildren}>
      <CreatableSearchSelect
        size="large"
        placeholder="Выбрать"
        defaultActiveFirstOption
        value={searchText}
        options={skillsOptions}
        excludeOptions={existingSkillsOptions}
        onSearch={onSearchSkill}
        onSelect={onSelectSkill}
        disabled={createSkillPending}
        onCreateEffect={createNewDictsRecordEffect}
        maxLength={50}
      />
    </SkillsAddModal>
  );
};
