import React, { FC, useMemo } from 'react';

import { PostBodyAttachmentsGroup } from 'ant/components/widgets/Post';
import { EventModel } from 'ant/types/models/event.model';
import { FileStorageFileModel } from 'ant/types/models/file.model';
import { PollModel } from 'ant/types/models/poll';
import {
  PostAttachmentModel,
  PostAttachmentTypes,
  PostFormAttachmentId,
} from 'ant/types/models/post-attachment';
import { Poll } from 'components-frontend/components/poll/Poll';
import { PostEvent } from 'components-frontend/components/post/attachments/event/PostEvent';
import { PostFiles } from 'components-frontend/components/post/attachments/files/PostFiles';
import { PostImages } from 'components-frontend/components/post/attachments/images/PostImages';

type AttachmentAction = (attachmentId: PostFormAttachmentId) => void;

type Props = {
  value: PostBodyAttachmentsGroup;
  isEdit?: boolean;
  reloadAttachment?: AttachmentAction;
  removeAttachment?: AttachmentAction;
};

export const PostAttachmentsAdapter: FC<Props> = (props) => {
  const { value, isEdit, removeAttachment, reloadAttachment } = props;
  const { attachmentType, attachments: groupedAttachments } = value;

  const renderedAttachments = useMemo(() => {
    if (attachmentType === PostAttachmentTypes.Poll) {
      return groupedAttachments.map((attachment) => (
        <Poll
          isEdit={isEdit}
          key={attachment.id}
          poll={attachment.attachedEntity as PollModel}
          onVote={() => reloadAttachment?.(attachment.id)}
          onDelete={() => removeAttachment?.(attachment.id)}
          onSuccess={() => reloadAttachment?.(attachment.id)}
        />
      ));
    }

    if (attachmentType === PostAttachmentTypes.Event) {
      return groupedAttachments.map((attachment) => (
        <PostEvent
          onDelete={isEdit ? () => removeAttachment?.(attachment.id) : undefined}
          key={attachment.id}
          event={attachment.attachedEntity as EventModel}
        />
      ));
    }

    if (attachmentType === PostAttachmentTypes.File) {
      return (
        <PostFiles
          isEdit={isEdit}
          attachments={groupedAttachments as PostAttachmentModel<FileStorageFileModel>[]}
          onDelete={(id) => removeAttachment?.(id)}
        />
      );
    }

    if (attachmentType === PostAttachmentTypes.Image) {
      return (
        <PostImages
          attachments={groupedAttachments as PostAttachmentModel<FileStorageFileModel>[]}
          onDelete={isEdit ? removeAttachment : undefined}
        />
      );
    }

    return null;
  }, [groupedAttachments]);

  return <>{renderedAttachments}</>;
};
