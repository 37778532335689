import React, { FC, SyntheticEvent, useContext, useState } from 'react';

import Appreaction3Svg from 'ant/components/svg/appreaction-3.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiTypography } from 'ant/components/ui/typography';
import { Post } from 'ant/components/widgets/Post';
import { CommentContentTypes } from 'ant/types/models/comment.model';
import { TimelineBadgeContentModel, TimelineRecordTargetModel } from 'ant/types/models/timelines.model';
import { GamificationItem } from 'components-frontend/components/gamification/item/GamificationItem';
import { PostCompound } from 'components-frontend/components/post/compound/PostCompound';
import { PostGamificationWrapper } from 'components-frontend/components/post/gamification/wrapper/PostGamificationWrapper';

import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';
import { TimelineCardBadgeUsersModal } from './users-modal/TimelineCardBadgeUsersModal';

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record: TimelineRecordTargetModel<TimelineBadgeContentModel>;
}

export const TimelineCardBadge: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [isBadgeUsersModalOpen, setIsBadgeUsersModalOpen] = useState(false);
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { content, createdAt, publishedAt, target } = record;
  const { badge, id } = content.userBadge;

  const onOpenUsersModal = (e: SyntheticEvent) => {
    e.stopPropagation();

    setIsBadgeUsersModalOpen(true);
  };

  return (
    <>
      <Post style={{ paddingTop: verticalGutter }}>
        <Post.Header padding={[0, horizontalGutter]} author={target} postDate={publishedAt || createdAt} />
        <Post.Body padding={[16, horizontalGutter]}>
          <UiButton.Decorator onClick={() => onClick?.(record)}>
            <PostGamificationWrapper style={{ minHeight: '160px' }}>
              <UiRow wrap={false}>
                <UiCol>
                  <GamificationItem
                    onClick={(event) => onOpenUsersModal(event)}
                    image={badge.image}
                    icon={Appreaction3Svg}
                    avatarIconSize={72}
                    size={120}
                  />
                </UiCol>

                <UiCol style={{ marginLeft: 20 }}>
                  <UiTypography.Title level={2}>{badge.name}</UiTypography.Title>
                  <UiTypography.Text>{badge.description}</UiTypography.Text>
                </UiCol>
              </UiRow>
            </PostGamificationWrapper>
          </UiButton.Decorator>
        </Post.Body>
        <Post.Footer>
          <PostCompound.CommentsWithInput
            showInput={Boolean(record.target?.isActive)}
            objectId={id}
            contentType={CommentContentTypes.UserBadge}
          />
        </Post.Footer>
      </Post>
      <UiModal
        type={UiModalTypes.Large}
        isOpen={isBadgeUsersModalOpen}
        onClose={() => setIsBadgeUsersModalOpen(false)}
      >
        <TimelineCardBadgeUsersModal badge={badge} onClose={() => setIsBadgeUsersModalOpen(false)} />
      </UiModal>
    </>
  );
};
