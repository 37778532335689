import { NotificationContent, NotificationModel } from 'ant/types/models/notification.model';
import { notificationDescriptionValues } from 'components-frontend/components/notifications-drawer/notification/constants';
import { isNotificationOld } from 'components-frontend/typings/guards/notification-guards';

export const getNotificationDescription = (notification: NotificationModel<NotificationContent>) => {
  if (isNotificationOld(notification)) {
    return notification.content.description;
  }

  return notificationDescriptionValues?.[notification.notificationType] || 'Уведомление';
};
