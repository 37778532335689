import React from 'react';

type Props = {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
};

const NotesWidgetItem: React.FC<Props> = ({ children, className, onClick }) => {
  const onPreviewKeyPress = ({ key }: React.KeyboardEvent) => {
    if (key === 'Enter') {
      onClick();
    }
  };

  return (
    <div role="link" tabIndex={-1} onClick={onClick} onKeyPress={onPreviewKeyPress} className={className}>
      {children}
    </div>
  );
};

export { NotesWidgetItem };
