import {
  NotificationModel,
  NotificationContent,
  NotificationTypes,
  NotificationContentV1,
  NotificationVersions,
} from 'ant/types/models/notification.model';

export const isNotificationType = (type: NotificationTypes, possibleType: NotificationTypes) =>
  type === possibleType;

export const isNotificationSomeOfTypes = <Type extends NotificationContent>(
  notification: NotificationModel<NotificationContent>,
  types: NotificationTypes[],
): notification is NotificationModel<Type> =>
  types.some((possibleType) => isNotificationType(notification.notificationType, possibleType));

export const isNotificationOld = (
  notification: NotificationModel<NotificationContent>,
): notification is NotificationModel<NotificationContentV1> => {
  return notification.version === NotificationVersions.V1;
};
