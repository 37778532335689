import React from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';

import styles from './PostCompoundLoading.scss';

export const PostCompoundLoading = () => {
  return (
    <span className={styles.postCompoundLoading}>
      <span className={styles.postCompoundLoading__header}>
        <UiSkeleton circle loading width={40} height={40} />

        <span className={styles.postCompoundLoading__headerInfo}>
          <UiSkeleton loading width={120} height={16} />
          <UiSkeleton loading width={240} height={18} />
        </span>
      </span>
      <span className={styles.postCompoundLoading__body}>
        <UiSkeleton loading width="93%" height={24} />
        <UiSkeleton loading width="82%" height={24} />
      </span>
    </span>
  );
};
