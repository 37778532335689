import React, { ReactNode } from 'react';

import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography/UiTypography';
import { Editor } from 'ant/components/widgets/Editor';
import { Reactions } from 'ant/components/widgets/Reactions';
import { CMSPageModel } from 'ant/types/models/cms.model';

export interface Props {
  page: CMSPageModel;
  actions?: ReactNode[];
}

export const PagePreview: React.FC<Props> = ({ page, actions }) => {
  const { name, body, reactions, extraData, viewsCount, commentsCount } = page;

  const isShowReactions = reactions && !extraData?.isReactionsDisabled;
  const isShowViewCount = extraData?.isShowViewCount;
  const isHasFooter = isShowReactions || isShowViewCount;

  return (
    <UiSpace full direction="vertical">
      <UiRow align="middle" wrap={false} justify="space-between">
        <UiTypography.Title level={2}>{name}</UiTypography.Title>
        {actions && actions?.length > 0 && (
          <UiCol>
            <UiSpace size={16}>{actions.map((action) => action)}</UiSpace>
          </UiCol>
        )}
      </UiRow>

      <UiTypography.Paragraph style={{ margin: 0 }} ellipsis={{ rows: 3 }}>
        <Editor.Viewer html={body.data} />
      </UiTypography.Paragraph>

      {isHasFooter && (
        <UiRow justify="space-between" align="middle" style={{ marginTop: 16 }}>
          <UiCol>
            <UiRow>
              {isShowReactions && <Reactions reactions={reactions} />}
              <Reactions.Comments count={commentsCount} />
            </UiRow>
          </UiCol>
          <UiCol>{isShowViewCount && <Reactions.Views count={viewsCount} />}</UiCol>
        </UiRow>
      )}
    </UiSpace>
  );
};
