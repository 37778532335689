// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._303903ca_calendar-widget__header-container{margin-bottom:4px}._303903ca_calendar-widget__content{margin-top:16px}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/calendar-widget/CalendarWidget.scss"],"names":[],"mappings":"AAEE,6CACE,iBAAA,CAGF,oCACE,eAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.calendar-widget {\n  &__header-container {\n    margin-bottom: 4px;\n  }\n\n  &__content {\n    margin-top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar-widget__header-container": "_303903ca_calendar-widget__header-container",
	"calendarWidget__headerContainer": "_303903ca_calendar-widget__header-container",
	"calendar-widget__content": "_303903ca_calendar-widget__content",
	"calendarWidget__content": "_303903ca_calendar-widget__content"
};
export default ___CSS_LOADER_EXPORT___;
