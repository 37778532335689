// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fc0a41c3_gamification-trophy{left:0;right:0;margin:0 auto;position:absolute;z-index:2}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/gamification/trophy/GamificationTrophy.scss","webpack://./../ant/src/assets/scss/variables/z-index.scss"],"names":[],"mappings":"AACA,8BACE,MAAA,CACA,OAAA,CACA,aAAA,CACA,iBAAA,CACA,SCGc","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.gamification-trophy {\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  position: absolute;\n  z-index: $zIndexOverlay;\n}\n","/**\n * Соответвует z-index list из antd\n * https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less#L369\n * Custom list:\n *    $zIndexNavbar,\n *    $zIndexHeader,\n */\n$zIndexBadge: auto;\n$zIndexTableFixed: 2;\n$zIndexOverlay: 2;\n$zIndexNavbar: 4;\n$zIndexHeader: 5;\n$zIndexAffix: 10;\n$zIndexBackTop: 10;\n$zIndexPickerPanel: 10;\n$zIndexPopupClose: 10;\n$zIndexModal: 1000;\n$zIndexModalMask: 1000;\n$zIndexMessage: 1010;\n$zIndexNotification: 1010;\n$zIndexPopover: 1030;\n$zIndexDropdown: 1050;\n$zIndexPicker: 1050;\n$zIndexPopoconfirm: 1060;\n$zIndexTooltip: 1070;\n$zIndexImage: 1080;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gamification-trophy": "fc0a41c3_gamification-trophy",
	"gamificationTrophy": "fc0a41c3_gamification-trophy"
};
export default ___CSS_LOADER_EXPORT___;
