import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { UiDivider } from 'ant/components/ui/divider';
import { UiFormInstance } from 'ant/components/ui/form';
import { UiSpace } from 'ant/components/ui/space';
import { CkEditorConfig, EditorExtraButton } from 'ant/components/widgets/Editor';
import { Post, PostFooterAction, PostFormFields } from 'ant/components/widgets/Post';
import { generateRandomString } from 'ant/plugins/utils/generate-random-string';
import { generateTagApiOptions } from 'ant/plugins/utils/tag-helper';
import { AttachmentsStorage } from 'ant/store/attachment';
import { uploadFileStorageMultipleAttachmentsEffectFactory } from 'ant/store/filestorage';
import { profileFullInfoStorage } from 'ant/store/profile';
import { BlogModerationType } from 'ant/types/blogs';
import { PermissionsV2Enum } from 'ant/types/models/blog.model';
import { PostBasicModel, PostSettings, PostTypes, PostSettingsVisibilityType } from 'ant/types/models/post';
import {
  PostAttachedEntity,
  PostAttachmentModel,
  PostAttachmentTypes,
  PostFormAttachmentId,
} from 'ant/types/models/post-attachment';
import { createPostEffect, updatePostEffect } from 'components-frontend/store/post';
import { CreatePostParams, UpdatePostParams } from 'components-frontend/store/post/api';

import { PostAttachmentsAdapter } from '../../attachments/adapter/PostAttachmentsAdapter';
import { PostItemProps } from '../PostCompound';
import { getAttachmentActions, isSingleAttachmentType } from './helpers';

interface Props extends Omit<PostItemProps, 'onPin' | 'post' | 'postsStorage'> {
  postType: PostTypes;
  post?: PostBasicModel;
  onCancel?: () => void;
  attachments?: PostAttachmentModel[];
  attachmentsStorage?: AttachmentsStorage;
  isShowSettingsVisibility?: boolean;
}

export const PostCompoundEdit: FC<Props> = (props) => {
  const {
    post,
    postType,
    blog,
    onCancel,
    attachments = [],
    isShowSettingsVisibility,
    attachmentsStorage,
  } = props;
  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const [postSetting, setPostSettings] = useState<PostSettings>(
    post?.settings || {
      isReacted: true,
      isComments: true,
      visibility: PostSettingsVisibilityType.Followers,
    },
  );
  const [postAttachments, setPostAttachments] = useState<PostAttachmentModel[]>(attachments);
  const formRef = useRef<UiFormInstance>(null);
  const uploadMultipleFilesEffect = useMemo(uploadFileStorageMultipleAttachmentsEffectFactory, []);

  const isUpdateFetching = useStore(updatePostEffect.pending);
  const isCreateFetching = useStore(createPostEffect.pending);
  const isFetching = isUpdateFetching || isCreateFetching;

  const onAttachmentReload = (attachmentId: PostFormAttachmentId) => {
    attachmentsStorage?.reloadAttachmentByIdEffect({ attachmentId });
  };

  const onAttachmentAdded = (attachmentType: PostAttachmentTypes, attachedEntity: PostAttachedEntity) => {
    // TODO:: Поправить когда будет реализована новая логика работы прикрепления аттачментов у бэка (B2BCORE-5805)
    const addedAttachment = {
      id: generateRandomString(8),
      attachmentId: attachedEntity.id,
      isNotYetAttached: true,
      attachmentType,
      attachedEntity,
    };

    setPostAttachments((attachmentsState) => {
      const stateUpdatedAttachments = isSingleAttachmentType(addedAttachment.attachmentType)
        ? attachmentsState.filter(({ attachmentType: type }) => type !== addedAttachment.attachmentType)
        : attachmentsState;

      return [...stateUpdatedAttachments, addedAttachment];
    });
  };

  const onAttachmentRemove = useCallback((attachmentId: PostFormAttachmentId) => {
    setPostAttachments((attachmentsState) => {
      return attachmentsState.filter(
        (attachment) =>
          attachment.id !== attachmentId &&
          attachment.attachmentId !== attachmentId.toString() &&
          attachment.attachedEntity.id !== attachmentId,
      );
    });
  }, []);

  const editorAttachmentButtons = useMemo<EditorExtraButton[]>(
    () =>
      getAttachmentActions(uploadMultipleFilesEffect, onAttachmentAdded).map((actions) => ({
        ...actions,
        id: `extra-button-${actions.type}`,
        onClick: () => {
          actions.onClick?.();
        },
      })),
    [getAttachmentActions],
  );

  const editorAttachmentButtonsConfig = useMemo<Partial<CkEditorConfig>>(() => {
    return {
      toolbar: {
        items: ['|', ...editorAttachmentButtons.map((button) => button.id)],
      },
      extraButtons: {
        items: editorAttachmentButtons,
      },
    };
  }, [editorAttachmentButtons]);

  const controls = useMemo<PostFooterAction[]>(() => {
    let confirmLabel = 'Опубликовать пост';

    const isModerator = blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts];
    const isSuggestPost = blog?.moderationType === BlogModerationType.Premoderation && !isModerator;

    if (post) {
      confirmLabel = 'Сохранить';
    }

    if (isSuggestPost) {
      confirmLabel = 'Предложить пост';
    }

    return [
      {
        label: confirmLabel,
        onClick: () => {
          formRef?.current?.submit();
        },
        type: 'primary',
        disabled: false,
        loading: isFetching,
      },
      {
        label: 'Отмена',
        onClick: () => {
          onCancel?.();
          setPostAttachments(attachments);
        },
        type: 'secondary',
      },
    ];
  }, [post, formRef?.current, onCancel, blog, isFetching]);

  const onSavePost = (postFields: PostFormFields) => {
    const commonData = {
      ...postFields,
      tags: generateTagApiOptions(postFields.tags),
      settings: postSetting,
      postType,
      entitiesToAttach: postAttachments,
    };

    if (post) {
      const postData: UpdatePostParams = {
        ...postFields,
        ...commonData,
        postId: post?.id,
      };

      updatePostEffect(postData).then(onCancel);
    } else {
      const postData: CreatePostParams = {
        ...postFields,
        ...commonData,
        blogId: blog?.id,
        ...(!blog?.id && { toUser: profileFullInfoData?.main?.id }),
      };

      createPostEffect(postData).then(onCancel);
    }
  };

  return (
    <Post style={{ padding: '24px 0' }}>
      <Post.Body padding={[0, 24]}>
        <UiSpace size={24} full direction="vertical">
          <Post.Body.Form
            onSubmit={onSavePost}
            post={post}
            ref={formRef}
            editorExtraConfig={editorAttachmentButtonsConfig}
          />

          {postAttachments.length > 0 && (
            <Post.Body.Attachments
              attachments={postAttachments}
              renderItem={(item) => (
                <PostAttachmentsAdapter
                  isEdit
                  value={item}
                  reloadAttachment={onAttachmentReload}
                  removeAttachment={onAttachmentRemove}
                />
              )}
            />
          )}
        </UiSpace>
      </Post.Body>
      <UiDivider />
      <Post.Footer padding={[0, 24]}>
        <Post.Footer.Actions
          postType={postType}
          settings={postSetting}
          actions={controls}
          isShowSettingsVisibility={isShowSettingsVisibility}
          onSettingsChange={setPostSettings}
        />
      </Post.Footer>
    </Post>
  );
};
