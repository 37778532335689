import React from 'react';

import CalendarSVG from 'ant/components/svg/calendar.svg';
import CommunitySVG from 'ant/components/svg/community.svg';
import LocationSVG from 'ant/components/svg/location.svg';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography/UiTypography';
import { declension, memberDeclension } from 'ant/plugins/declension';
import { getFormatDateTimeForPreview } from 'ant/plugins/utils/get-formatted-date';
import { EventModel } from 'ant/types/models/event.model';

import styles from './EventListItem.scss';

export type Props = {
  event: EventModel;
  onSelect: (event: EventModel) => void;
};

const EventListItem: React.FC<Props> = ({ event, onSelect }) => {
  const { fileStorageImageUrl } = event;
  const backgroundImageUrl = fileStorageImageUrl ? `url("${fileStorageImageUrl}")` : undefined;
  const placeText = event.place === 'online' ? 'Онлайн' : event.place;
  const dateAndTimeText = getFormatDateTimeForPreview(event.since, event.till, ' • ');
  const participantsText = `${event.memberTotalUids.length} ${declension(
    event.memberTotalUids.length,
    memberDeclension,
  )}`;

  return (
    <div
      role="link"
      tabIndex={-1}
      onClick={() => onSelect(event)}
      onKeyPress={() => onSelect(event)}
      className={styles.eventListItem}
    >
      <div
        className={styles.eventListItem__image}
        style={{
          backgroundImage: backgroundImageUrl,
        }}
      />
      <div className={styles.eventListItem__info}>
        <UiTruncateMarkup
          className={styles.eventListItem__infoTitle}
          truncate
          tooltipProps={{ title: event.name }}
        >
          {event.name}
        </UiTruncateMarkup>
        <div className={styles.eventListItem__infoItem}>
          <UiIcon component={LocationSVG} height={20} width={20} className={styles.eventListItem__icon} />
          <UiTruncateMarkup
            truncate
            tooltipProps={{ title: placeText }}
            className={styles.eventListItem__infoItemPlace}
          >
            {placeText}
          </UiTruncateMarkup>
        </div>
        <div className={styles.eventListItem__infoItem}>
          <UiIcon component={CalendarSVG} height={20} width={20} className={styles.eventListItem__icon} />
          <UiTypography.Text type="secondary" strong>
            {dateAndTimeText}
          </UiTypography.Text>
        </div>
        <div className={styles.eventListItem__infoItem}>
          <UiIcon component={CommunitySVG} height={20} width={20} className={styles.eventListItem__icon} />
          <UiTypography.Text type="secondary" strong>
            {participantsText}
          </UiTypography.Text>
        </div>
      </div>
    </div>
  );
};

export { EventListItem };
