import React, { FC, useMemo } from 'react';

import CalendarSvg from 'ant/components/svg/calendar.svg';
import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { utcToLocalDate } from 'ant/plugins/utils/get-formatted-date';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { EventModel } from 'ant/types/models/event.model';
import { NotificationModel, NotificationTypes } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';
import { isNotificationType } from 'components-frontend/typings/guards/notification-guards';

import { NotificationEventActions } from './actions/NotificationEventActions';

interface Props {
  notification: NotificationModel<EventModel>;
}

export const NotificationEvent: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    content,
    initiator: { fullName, keycloakId },
    service: serviceTitle,
    notificationType,
  } = notification;
  const { id, name, since, isRemoved } = content;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const truncatedName = (
    <UiTruncateMarkup truncate lines={2}>
      {name}
    </UiTruncateMarkup>
  );

  const eventStartTime = useMemo(() => utcToLocalDate(since), [since]);
  const onOpenEventLongread = () =>
    !isRemoved && openGlobalModal(GlobalModalNames.EventsLongread, { eventId: id });
  const isNotificationEventsInvite = isNotificationType(NotificationTypes.EventsInvite, notificationType);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />

      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          {isNotificationEventsInvite && (
            <UiSpace size={4} direction="vertical">
              <UiTypography.Link strong onClick={onOpenEventLongread} disabled={isRemoved}>
                {truncatedName}
              </UiTypography.Link>
              <UiIcon.Label component={CalendarSvg} label={eventStartTime} />
            </UiSpace>
          )}
          {!isNotificationEventsInvite && <UiTypography.Text strong>{truncatedName}</UiTypography.Text>}
          {isNotificationEventsInvite && <NotificationEventActions notification={notification} />}
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
