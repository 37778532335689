import { api } from 'ant/plugins/api';
import { Endpoints } from 'ant/plugins/endpoints';
import { CreateUpdatePollModel, PollModel, VoteModel } from 'ant/types/models/poll';

export const createPoll = (poll: CreateUpdatePollModel) =>
  api.post<PollModel>({
    url: Endpoints.polls(),
    data: poll,
  });

export const updatePollById = (id: number, data: CreateUpdatePollModel) =>
  api.put<PollModel>({
    url: Endpoints.pollById(id),
    data,
  });

export const deletePollById = (id: number) => {
  return api.delete({
    url: Endpoints.pollById(id),
  });
};

export const vote = (id: number, data: VoteModel[]) => {
  return api.post<PollModel>({
    url: Endpoints.vote(id),
    data,
  });
};

export const exportPollToXLSX = (id: number) => {
  return api.get<Blob>({
    url: Endpoints.exportPollToXLSX(id),
    responseType: 'blob',
  });
};

export const exportPollAnswerToXLSX = (questionId: number, optionId: number) => {
  return api.get<Blob>({
    url: Endpoints.exportPollAnswerToXLSX(questionId, optionId),
    responseType: 'blob',
  });
};
