import React, { FC, useState } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiDescriptions } from 'ant/components/ui/descriptions';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiCol } from 'ant/components/ui/grid';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUserLinkCell } from 'ant/components/ui/user-link-cell';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { usePagination } from 'ant/helpers/hooks/use-pagination';
import { useUnmount } from 'ant/helpers/hooks/use-unmount';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getFileStorageEntryHistoryStorage } from 'ant/store/filestorage';
import { FileStorageEntryId } from 'ant/types/models/file.model';

type Props = {
  id: FileStorageEntryId;
};

const PAGE_SIZE = 5;
const historyLoadingMock = createArrayMock(PAGE_SIZE, (_, idx) => (
  <UiCol key={idx} span={24}>
    <UiSkeleton loading height={44} block width={373 - 40} />
  </UiCol>
));

export const fileStorageEntryHistoryStorage = getFileStorageEntryHistoryStorage();

export const FileHistory: FC<Props> = (props) => {
  const { id } = props;
  const [currentPagination, setCurrentPagination] = useState(1);
  const { pagination, pageParams } = usePagination({
    pageSize: PAGE_SIZE,
    current: currentPagination,
  });

  useUnmount(() => fileStorageEntryHistoryStorage.storage.resetStoreEvent);

  const {
    data: fileStorageEntryHistoryData,
    loading: isFileStorageEntryHistoryLoading,
    error: fileStorageEntryHistoryError,
    pagination: { count },
  } = useAbstractStorage(fileStorageEntryHistoryStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id, ...pageParams },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const isAllPagesShowed = fileStorageEntryHistoryData.length === count;

  const onDownloadMore = () => {
    if (pagination.current && !isAllPagesShowed) {
      setCurrentPagination((prev) => prev + 1);
    }
  };

  const isHistoryEmpty =
    !isFileStorageEntryHistoryLoading &&
    fileStorageEntryHistoryData.length === 0 &&
    !fileStorageEntryHistoryError;

  const isShowData = !fileStorageEntryHistoryError || !isHistoryEmpty;

  return (
    <UiCard emptyPadding>
      <UiCard.Header style={{ padding: 20 }}>
        <UiCard.Header.Title>История изменений</UiCard.Header.Title>
      </UiCard.Header>
      <UiCard.Content style={{ padding: '0 20px 20px' }}>
        {!isShowData && (
          <UiEmpty.Feed
            emptyMessage={{ header: isHistoryEmpty ? 'Нет истории' : 'Ошибка получения истории' }}
          />
        )}
        {isShowData && (
          <UiSpace size={16} direction="vertical">
            <UiDescriptions gutter={[0, 16]}>
              {isFileStorageEntryHistoryLoading
                ? historyLoadingMock
                : fileStorageEntryHistoryData.map(
                    ({
                      summary: actionSummary,
                      actor,
                      object: { summary: objectSummary, value: objectValue },
                      createdAt,
                    }) => (
                      <UiDescriptions.Item key={createdAt} spaceProps={{ size: 4, full: true }} span={24}>
                        <UiSpace>
                          <UiTypography.Text>
                            {actionSummary} {objectSummary}
                          </UiTypography.Text>
                          <UiTypography.Text strong>{objectValue}</UiTypography.Text>
                        </UiSpace>
                        <UiSpace flex>
                          {actor && (
                            <UiUserLinkCell
                              type="secondary"
                              lines={1}
                              size={24}
                              user={actor}
                              nameStyles={{ marginLeft: 8 }}
                            />
                          )}
                          <UiTypography.Text type="secondary">
                            {getFormattedDate(createdAt, 'dd MMMM в HH:mm')}
                          </UiTypography.Text>
                        </UiSpace>
                      </UiDescriptions.Item>
                    ),
                  )}
            </UiDescriptions>
            {!isAllPagesShowed && (
              <UiButton type="link" onClick={onDownloadMore} label="Показать более ранние изменения" />
            )}
          </UiSpace>
        )}
      </UiCard.Content>
    </UiCard>
  );
};
