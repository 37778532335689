import React, { useMemo } from 'react';

import { UiRoute } from 'ant/components/ui/breadcrumb';
import { PortaledBreadcrumb } from 'ant/components/widgets/PortaledBreadcrumb';
import { getRoutePath, RouteNames } from 'ant/plugins/router';

type Props = {
  breadcrumbName: string;
};

const SearchBreadcrumb: React.FC<Props> = ({ breadcrumbName }) => {
  const breadcrumbRoutes: UiRoute[] = useMemo(
    () => [
      {
        path: getRoutePath(RouteNames.Main),
        breadcrumbName: 'Главная',
      },
      {
        path: getRoutePath(RouteNames.Search),
        breadcrumbName,
      },
    ],
    [breadcrumbName],
  );

  return <PortaledBreadcrumb breadcrumbRoutes={breadcrumbRoutes} />;
};

export { SearchBreadcrumb };
