import { useStoreMap } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UiButton } from 'ant/components/ui/button';
import { UiCheckboxValueType } from 'ant/components/ui/checkbox';
import { message } from 'ant/components/ui/message';
import { NewsList, newsFeedStorage } from 'ant/components/widgets/NewsList';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useLongreadModalParams } from 'ant/helpers/hooks/use-longread-modal-params';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { generateSelectOptions } from 'ant/plugins/utils/options-helper';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { newsCategoriesStore } from 'ant/store/news';
import { getWidgetStorage } from 'ant/store/pages';
import { CategoryId } from 'ant/types/models/news.model';
import { Widget } from 'core-components/types';

import styles from './NewsWidget.scss';

type NewsWidgetData = {
  filteredValues: CategoryId[];
};

type NewsWidgetStorage = Widget<NewsWidgetData>;

interface Props {
  widget: Widget;
}

export const NewsWidget: FC<Props> = (props) => {
  const { widget } = props;
  const navigate = useNavigate();
  const onNavigateMainNews = useCallback(() => navigate(getRoutePath(RouteNames.MainNews)), []);

  const { storage: widgetStorage, updateUserWidgetDataEffect } = useMemo(
    () =>
      getWidgetStorage<NewsWidgetStorage>({
        id: widget.id,
      }),
    [],
  );

  const { data: newsCategories } = useAbstractStorage(newsCategoriesStore.storage, {
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
  });

  const { data: newsWidget } = useAbstractStorage(widgetStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const filterValues = newsWidget?.data?.filteredValues || newsWidget?.defaultData?.filteredValues || [];

  const filterMenuItems = useMemo(
    () =>
      newsCategories
        ? generateSelectOptions(Object.values(newsCategories), { valuePath: 'id', labelPath: 'name' })
        : [],
    [newsCategories],
  );

  const onChangeFilter = (values: UiCheckboxValueType[]) =>
    updateUserWidgetDataEffect({ widgetId: widget.id, data: { filteredValues: values } }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось применить фильтры')),
    );

  const isNewsFeed = useStoreMap({
    store: newsFeedStorage.storage.store,
    keys: ['data'],
    fn: ({ data }) => Boolean(data.length),
  });

  const categoryIds = useStoreMap({
    store: widgetStorage.store,
    keys: ['data'],
    fn: ({ data }) => data?.data?.filteredValues?.join(),
  });

  useLongreadModalParams(GlobalModalNames.NewsLongread, ['newsId', 'newsType']);

  return (
    <NewsList
      title="Новости"
      filter={{ value: filterValues, items: filterMenuItems, onChange: onChangeFilter }}
      fetchParams={{ categoryIds }}
    >
      {isNewsFeed && (
        <UiButton
          block
          size="large"
          type="tertiary"
          label="Все новости"
          className={styles.newsWidget__button}
          onClick={onNavigateMainNews}
        />
      )}
    </NewsList>
  );
};
