import React from 'react';

import DeleteSVG from 'ant/components/svg/delete.svg';
import { BadgeColors, UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiForm, UiFormInstance } from 'ant/components/ui/form';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiInput } from 'ant/components/ui/input';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { UiSelect } from 'ant/components/ui/select';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { normalizeValueTrimStart } from 'ant/helpers/normalize';
import { MAX_LENGTH_INPUT_255, requiredRule } from 'ant/helpers/validation';
import { generateSelectOptions } from 'ant/plugins/utils/options-helper';
import { PollQuestionTypes } from 'ant/types/models/poll';

import { AVAILABLE_POLL_QUESTION_TYPES, INITIAL_QUESTION_VALUE } from '../constants';
import { PollOptions } from '../poll-options/PollOptions';
import styles from './PollQuestions.scss';

type Props = {
  disabled: boolean;
  form: UiFormInstance;
};

const inputSubtypes = [PollQuestionTypes.FREE_FILE, PollQuestionTypes.FREE_ANSWER];

export const PollQuestions: React.FC<Props> = (props) => {
  const { disabled = false, form } = props;
  const listName = 'questions';

  const answerTypesOptions = generateSelectOptions(AVAILABLE_POLL_QUESTION_TYPES, {
    valuePath: 'value',
    labelPath: 'label',
  });

  return (
    <UiForm.List name={listName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => (
            <UiForm.Section className={styles.pollQuestions} key={field.key}>
              <UiSpace size={8} className={styles.pollQuestions__header}>
                <UiBadge color={BadgeColors.Primary} count={field.name + 1} />
                <UiTypography.Text strong>Вопрос</UiTypography.Text>
              </UiSpace>

              <UiRow align="middle" gutter={[24, 0]}>
                <UiCol flex="1 0 0">
                  <UiForm.Item
                    {...field}
                    name={[field.name, 'text']}
                    rules={[requiredRule]}
                    normalize={normalizeValueTrimStart}
                  >
                    <UiInput maxLength={MAX_LENGTH_INPUT_255} size="large" disabled={disabled} />
                  </UiForm.Item>
                </UiCol>
                <UiCol>
                  <UiButton
                    type="link-secondary"
                    icon={<UiIcon component={DeleteSVG} height={20} width={20} />}
                    onClick={() => remove(field.name)}
                    disabled={fields.length <= 1}
                  />
                </UiCol>
              </UiRow>

              <UiRow>
                <UiCol span={24} className={styles.pollQuestions__cut}>
                  <UiForm.Item {...field} label="Тип ответа" name={[field.name, 'type']}>
                    <UiSelect
                      style={{ width: '100%' }}
                      size="large"
                      placeholder="Выбрать"
                      options={answerTypesOptions}
                    />
                  </UiForm.Item>
                </UiCol>
              </UiRow>

              <UiForm.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const questionType = getFieldValue([listName, field.name, 'type']);

                  return (
                    <UiRender type={UiRenderType.NullElement} visible={!inputSubtypes.includes(questionType)}>
                      <PollOptions
                        form={form}
                        parentListName={listName}
                        parentFieldName={field.name}
                        disabled={disabled}
                      />
                    </UiRender>
                  );
                }}
              </UiForm.Item>
            </UiForm.Section>
          ))}

          <UiForm.Section>
            <UiForm.Item>
              <UiButton
                size="large"
                block
                type="secondary"
                label="Добавить вопрос"
                disabled={disabled}
                onClick={() => add(INITIAL_QUESTION_VALUE)}
              />
            </UiForm.Item>
          </UiForm.Section>
        </>
      )}
    </UiForm.List>
  );
};
