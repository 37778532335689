import HTMLReactParser from 'html-react-parser';
import React, { FC, KeyboardEventHandler, useMemo } from 'react';

import FlameSvg from 'ant/components/svg/flame.svg';
import PinSVG from 'ant/components/svg/pin.svg';
import ViewSVG from 'ant/components/svg/view.svg';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiImage } from 'ant/components/ui/image';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { UiTag, UiTagTypes } from 'ant/components/ui/tag';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Reactions } from 'ant/components/widgets/Reactions';
import { numberAbbreviator } from 'ant/plugins/number-formatters';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getActualEditorFormat, getPlainTextFromHtml } from 'ant/plugins/utils/markup-content';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { themePalette } from 'ant/theme';
import { NewsArticle, NewsCategoriesDist } from 'ant/types/models/news.model';
import { PostTypes } from 'ant/types/models/post';

import styles from './NewsPageItem.scss';

type NewsPageItemProps = {
  newsCategoriesDist: NewsCategoriesDist | null;
  newsFeed: NewsArticle;
};

export const NewsPageItem: FC<NewsPageItemProps> = (props) => {
  const { newsCategoriesDist, newsFeed } = props;
  const {
    categoryId,
    cover,
    title,
    pinned,
    publishedAt,
    important,
    body,
    id,
    type,
    reactions,
    commentsCount,
    viewsCount,
    tags,
    settings,
  } = newsFeed;
  const { isReacted, isComments } = settings;

  const plainBodyText = useMemo(() => {
    const actualBodyFormat = getActualEditorFormat(body);

    return getPlainTextFromHtml(actualBodyFormat.data);
  }, [body]);

  const openLongread = () => {
    const newsLongreadParams = { newsId: id, newsType: type || PostTypes.Simple };

    openGlobalModal(GlobalModalNames.NewsLongread, newsLongreadParams, newsLongreadParams);
  };

  const onHTMLKeyPress: KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      openLongread();
    }
  };

  return (
    <UiRow
      onClick={openLongread}
      tabIndex={-1}
      role="link"
      onKeyDown={onHTMLKeyPress}
      className={styles.newsPageItem}
      justify="space-between"
      wrap
    >
      <UiCol span={24}>
        <UiRow wrap={false}>
          <UiCol style={{ marginRight: '24px' }} flex="300px">
            <UiImage src={cover} />
          </UiCol>

          <UiCol className={styles.newsPageItem__body} flex="auto">
            <div className={styles.newsPageItem__header}>
              {pinned && (
                <UiIcon
                  component={PinSVG}
                  width={20}
                  height={20}
                  style={{ marginRight: '12px', color: themePalette.colorIcon }}
                />
              )}
              {publishedAt && (
                <UiTypography.Text className={styles.newsPageItem__headerTime}>
                  {getFormattedDate(publishedAt)}
                </UiTypography.Text>
              )}
              <UiTag
                strong
                size={12}
                shift={6}
                title={categoryId ? newsCategoriesDist?.[categoryId]?.name : undefined}
                color={categoryId ? newsCategoriesDist?.[categoryId]?.extraInfo?.color : undefined}
                type={UiTagTypes.Dot}
              />
              {important && (
                <UiIcon
                  component={FlameSvg}
                  width={20}
                  height={20}
                  className={styles.newsPageItem__headerImportant}
                />
              )}
            </div>

            <UiTypography.Title level={2}>
              <UiTruncateMarkup truncate lines={2}>
                {title}
              </UiTruncateMarkup>
            </UiTypography.Title>

            {plainBodyText && (
              <UiTypography.Text style={{ marginBottom: '16px' }} type="secondary">
                <UiTruncateMarkup truncate lines={4}>
                  {HTMLReactParser(plainBodyText)}
                </UiTruncateMarkup>
              </UiTypography.Text>
            )}

            <UiRow justify="space-between" align="middle">
              <UiCol>
                {reactions && isReacted && (
                  <Reactions reactions={reactions}>
                    {isComments && <Reactions.Comments count={commentsCount} />}
                  </Reactions>
                )}
                {!isReacted && isComments && <Reactions.Comments count={commentsCount} />}
              </UiCol>
              <UiCol>
                <UiIcon.Label
                  label={numberAbbreviator(viewsCount)}
                  component={ViewSVG}
                  width={20}
                  height={20}
                />
              </UiCol>
            </UiRow>
          </UiCol>
        </UiRow>
      </UiCol>

      <UiRender type={UiRenderType.NullElement} visible={tags.length > 0}>
        <UiCol className={styles.newsPageItem__tags}>
          <UiTag.Group tags={tags} truncate />
        </UiCol>
      </UiRender>
    </UiRow>
  );
};
