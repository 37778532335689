import { PostModel } from 'ant/types/models/post';
import {
  TimelineContent,
  TimelineRecordActorModel,
  TimelineRecordModel,
  TimelineRecordTargetActorModel,
  TimelineRecordTargetModel,
  TimelineTypes,
} from 'ant/types/models/timelines.model';

export const isTimelineRecordModelType = (type: TimelineTypes, possibleType: TimelineTypes) =>
  type === possibleType;

export const isTimelineRecordModelSomeOfTypes = <Type extends TimelineContent>(
  timeline: TimelineRecordModel,
  types: TimelineTypes[],
): timeline is TimelineRecordModel<Type> =>
  types.some((possibleType) => isTimelineRecordModelType(timeline.type, possibleType));

export const isTimelineRecordActorModel = <Type extends TimelineContent>(
  timeline: TimelineRecordModel,
): timeline is TimelineRecordActorModel<Type> => Boolean(timeline.actor);

export const isTimelineRecordTargetModel = <Type extends TimelineContent>(
  timeline: TimelineRecordModel,
): timeline is TimelineRecordTargetModel<Type> => Boolean(timeline.target);

export const isTimelineRecordTargetActorModel = <Type extends TimelineContent>(
  timeline: TimelineRecordModel,
): timeline is TimelineRecordTargetActorModel<Type> => {
  return Boolean(timeline.target) && Boolean(timeline.actor);
};

export const isTimelineRecordModel = (
  timeline: TimelineRecordModel | PostModel,
): timeline is TimelineRecordModel => {
  return 'content' in timeline && Object.values(TimelineTypes).includes(timeline?.type);
};
