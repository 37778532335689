import { useStore } from 'effector-react';
import React, { MouseEvent, TouchEvent, useEffect, useMemo, useState } from 'react';

import ClearSVG from 'ant/components/svg/clear.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiFile } from 'ant/components/ui/file';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { message } from 'ant/components/ui/message';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUploadRequestOption } from 'ant/components/ui/upload';
import { UploadDraggerArea } from 'ant/components/widgets/UploadDraggerArea';
import { checkFileSizeUploadEffect } from 'ant/helpers/validation/file-size-helper';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { uploadFileStorageAttachmentEffectFactory } from 'ant/store/filestorage';
import { FileId, getFileFromStorage } from 'ant/store/filestorage/api';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { FileModel } from 'ant/types/models/file.model';
import { PollQuestionModel, VoteModel } from 'ant/types/models/poll';

type FileInputProps = {
  question: PollQuestionModel;
  onChange?: (vote: VoteModel[] | null) => void;
  disabled?: boolean;
  value?: FileId;
};

export const FileInput: React.FC<FileInputProps> = (props) => {
  const { onChange, disabled, question, value } = props;

  const uploadAttachmentEffect = useMemo(() => uploadFileStorageAttachmentEffectFactory(), []);
  const isLoading = useStore(uploadAttachmentEffect.pending);
  const [uploadedFile, setUploadedFile] = useState<FileModel | null>(null);

  useEffect(() => {
    if (value) {
      getFileFromStorage({
        id: value,
      }).then(({ data }) => setUploadedFile(data));
    } else {
      setUploadedFile(null);
    }
  }, [value]);

  const onRemoveFile = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    setUploadedFile(null);
    onChange?.(null);
  };

  const onUploadAttachmentEffect = async ({ file }: UiUploadRequestOption) => {
    try {
      const fileUpload = await checkFileSizeUploadEffect({ file: file as File, maxSize: 10 });
      const fileModel = await uploadAttachmentEffect(fileUpload);
      const voteModel = [{ value: String(fileModel.id), question: question.id }];

      setUploadedFile(fileModel);
      onChange?.(voteModel);

      return fileUpload;
    } catch (e) {
      return message.error(String(e));
    }
  };

  if (uploadedFile) {
    const fileUpdateDate = uploadedFile.updated ? `• ${getFormattedDate(uploadedFile.updated)}` : '';

    return (
      <UiCard size="default">
        <UiRow align="middle">
          <UiCol span={23}>
            <UiFile
              title={<UiTypography.Text strong>{uploadedFile.name}</UiTypography.Text>}
              subtitle={
                <UiTypography.Text type="secondary">
                  {getFormattedFileSize(uploadedFile.fileSize)} {fileUpdateDate}
                </UiTypography.Text>
              }
              fileName={uploadedFile.name}
            />
          </UiCol>

          <UiCol span={1}>
            <UiButton
              type="link-secondary"
              onClick={onRemoveFile}
              icon={<ClearSVG />}
              disabled={disabled}
              size="middle"
            />
          </UiCol>
        </UiRow>
      </UiCard>
    );
  }

  return (
    <UploadDraggerArea
      disabled={disabled}
      onUploadAttachment={onUploadAttachmentEffect}
      loading={isLoading}
      multiple={false}
      accept={FileUploadAccepts.All}
    />
  );
};
