import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { BlogFullModel } from 'ant/types/models/blog.model';
import { NotificationModel } from 'ant/types/models/notification.model';

interface Props {
  notification: NotificationModel<BlogFullModel>;
}

export const NotificationBlogAdministrator: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
    content: { id: blogId, name },
  } = notification;

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatarProps = { src: image };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle="Уведомление"
        avatar={avatarProps}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          <Link to={getRoutePath(RouteNames.GroupView, { id: blogId })}>
            <UiTypography.Link strong style={{ display: 'block' }}>
              <UiTruncateMarkup truncate lines={5}>
                {`Заблокирован администратор группы "${name}" - ${notificationTitle}. Необходимо
                назначить нового.`}
              </UiTruncateMarkup>
            </UiTypography.Link>
          </Link>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
