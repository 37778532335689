import { createEffect } from 'effector';
import { useStore } from 'effector-react';
import React, { useEffect, useState, FC } from 'react';

import { Layout } from 'ant/components/layout/layout';
import { useSettingsConfig } from 'ant/providers/settings-config';

type VKHRTechInitParams = {
  role: string;
  startPage: string;
  tokenService: null;
  parentSelector: string;
  notificationService: null;
};

type Window = typeof window;
type VKHRTechWindow = Window & {
  VKHRTech: {
    init: (params: VKHRTechInitParams) => void;
    unmount: () => void;
  };
};

const DOMAIN_SOURCE = 'https://vkdoc-11620-frontend-static.vkdoc.kube.mall.cloud.devmail.ru';
const VK_HR_TECH_ROOT_ID = 'vkhr-tech-root-id';

const importExternal = (url: string) => {
  return new Promise((resolve, reject) => {
    // TODO: отменить загрузку через тег script невозможно, можно переделать на fetch + abort https://share.effector.dev/W4I0ghLt
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;

    document.body.appendChild(script);
  });
};

const importExternalEffect = createEffect(() => {
  return Promise.all([
    importExternal(`${DOMAIN_SOURCE}/env.js`),
    importExternal(`${DOMAIN_SOURCE}/static/widget/widget.js`),
  ]);
});

export const PageVKHRTech: FC = () => {
  const [isError, setIsError] = useState(false);
  const { config } = useSettingsConfig();
  const isPageEnabled = config.layouts?.pageVKHRTechEnabled?.value;

  useEffect(() => {
    if (!isPageEnabled) return undefined;

    const params = {
      parentSelector: `#${VK_HR_TECH_ROOT_ID}`,
      startPage: 'events',
      role: 'employee',
      tokenService: null,
      notificationService: null,
    };
    let VKHRTechWidget = (window as VKHRTechWindow)?.VKHRTech;

    if (VKHRTechWidget?.init) {
      VKHRTechWidget.init(params);
    } else {
      importExternalEffect();
    }

    const unsubscribeDone = importExternalEffect.doneData.watch(() => {
      VKHRTechWidget = (window as VKHRTechWindow)?.VKHRTech;
      VKHRTechWidget?.init(params);
    });
    const unsubscribeFail = importExternalEffect.fail.watch(() => setIsError(true));

    return () => {
      unsubscribeDone();
      unsubscribeFail();
      VKHRTechWidget?.unmount();
    };
  }, []);

  const isImportExternalEffectPending = useStore(importExternalEffect.pending);

  return (
    <>
      <div id={VK_HR_TECH_ROOT_ID} />
      {isImportExternalEffectPending && <Layout.Loading />}
      {(isError || !isPageEnabled) && <Layout.NotFound />}
    </>
  );
};
