import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import UserSvg from 'ant/components/svg/user.svg';
import { UiCard } from 'ant/components/ui/card';
import { UiDivider } from 'ant/components/ui/divider';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { EmployeeContacts } from 'ant/components/widgets/EmployeeContacts';
import { UserProfile } from 'ant/components/widgets/UserProfile';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { currentProfileStore } from 'ant/plugins/current-profile-service';
import { getFullNameWithPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getUserGroupsListStorage } from 'ant/store/groups';
import { useTheme } from 'ant/theme/provider';

export const UserProfileWidget: FC = () => {
  const currentProfile = useStore(currentProfileStore);
  const userId = currentProfile?.id ?? '';
  const isIdExisting = Boolean(userId);
  const [{ variables: themeVariables }] = useTheme();

  const userGroupsListStorage = useMemo(getUserGroupsListStorage, []);

  const { data: userGroups } = useAbstractStorage(userGroupsListStorage.storage, {
    autoFetchAndRefetch: isIdExisting,
    autoFetchParams: { userId },
  });

  const UserGroups: FC = () => {
    return (
      <UiSpace size={4} direction="vertical">
        {userGroups.map((group) => (
          <Link to={getRoutePath(RouteNames.Structure, { id: group.id })} key={group.id}>
            <UiTypography.Footnote type="secondary">
              <UiTruncateMarkup truncate>{group.name}</UiTruncateMarkup>
            </UiTypography.Footnote>
          </Link>
        ))}
      </UiSpace>
    );
  };

  return (
    <UiCard emptyPadding>
      <UiCard.Header>
        <UiSpace size={8}>
          <UiIcon component={UserSvg} width={24} height={24} color={themeVariables.colorBrand} />
          <UiCard.Header.Title>Мой профиль</UiCard.Header.Title>
        </UiSpace>
      </UiCard.Header>
      {currentProfile && (
        <UiDivider type="horizontal" orientation="left" column emptyMargin>
          <UserProfile.Info
            size="large"
            style={{ padding: 20 }}
            fullNameProps={{ level: 4 }}
            user={{ ...currentProfile, ...currentProfile.fullName }}
            getFullName={getFullNameWithPatronymic}
          >
            <UiSpace size={8} direction="vertical">
              {currentProfile.position && (
                <UiTypography.Text type="secondary">
                  <UiTruncateMarkup truncate>{currentProfile.position.name}</UiTruncateMarkup>
                </UiTypography.Text>
              )}
              <UserGroups />
            </UiSpace>
          </UserProfile.Info>
        </UiDivider>
      )}
      <EmployeeContacts userId={userId} />
    </UiCard>
  );
};
