import React, { FC } from 'react';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { Notification } from 'ant/components/widgets/Notification';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { CMSPageModel, CMSViewType } from 'ant/types/models/cms.model';
import { NotificationModel } from 'ant/types/models/notification.model';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<CMSPageModel>;
}

export const NotificationCmsMention: FC<Props> = (props) => {
  const { notification } = props;
  const { content, createdAt, initiator, image } = notification;
  const { url, name } = content;
  const { fullName, keycloakId } = initiator;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle={notificationDescription}
        avatar={avatar}
      />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          <RouterLink to={getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: url })}>
            <UiTruncateMarkup truncate tooltipProps={{ title: name }}>
              {name}
            </UiTruncateMarkup>
          </RouterLink>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
