import React, { FC } from 'react';

import {
  GroupFileCreatedContentModel,
  NotificationFileVersionCommentModel,
  NotificationModel,
  NotificationTypes,
} from 'ant/types/models/notification.model';
import { isNotificationSomeOfTypes } from 'components-frontend/typings/guards/notification-guards';

import { NotificationFileBlog } from './blog/NotificationFileBlog';
import { NotificationFileVersionComment } from './version-comment/NotificationFileVersionComment';

interface Props {
  notification: NotificationModel;
}

export const NotificationFile: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<NotificationFileVersionCommentModel>(notification, [
      NotificationTypes.FileVersionCommentCreated,
      NotificationTypes.FileVersionCommentUserMentioned,
      NotificationTypes.FileVersionCommentReaction,
      NotificationTypes.FileVersionCommentReply,
    ])
  ) {
    return <NotificationFileVersionComment notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<GroupFileCreatedContentModel>(notification, [
      NotificationTypes.BlogGroupFileCreated,
    ])
  ) {
    return <NotificationFileBlog notification={notification} />;
  }

  return null;
};
