import React, { FC } from 'react';

import {
  NotificationMicropostCommentModel,
  NotificationMicropostEntryModel,
  NotificationModel,
  NotificationTypes,
} from 'ant/types/models/notification.model';
import { NotificationMicropostComment } from 'components-frontend/components/notifications-drawer/notification/micropost/comment/NotificationMicropostComment';
import { NotificationMicropostEnrty } from 'components-frontend/components/notifications-drawer/notification/micropost/entry/NotificationMicropostEnrty';
import { isNotificationSomeOfTypes } from 'components-frontend/typings/guards/notification-guards';

interface Props {
  notification: NotificationModel;
}

export const NotificationMicropost: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<NotificationMicropostEntryModel>(notification, [
      NotificationTypes.TimelinesSignerMicropostCreated,
    ])
  ) {
    return <NotificationMicropostEnrty notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationMicropostCommentModel>(notification, [
      NotificationTypes.MicropostCommentCreated,
      NotificationTypes.MicropostCommentReaction,
      NotificationTypes.MicropostCommentUserMentioned,
      NotificationTypes.MicropostCommentReply,
    ])
  ) {
    return <NotificationMicropostComment notification={notification} />;
  }

  return null;
};
