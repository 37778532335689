import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiForm } from 'ant/components/ui/form';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUploadFile, UiUploadFileStatusType, UiUploadOriginFile } from 'ant/components/ui/upload';
import { UiUploadFileList } from 'ant/components/ui/upload-file-list';
import { UploadDraggerArea } from 'ant/components/widgets/UploadDraggerArea';
import { UploadFile } from 'ant/helpers/storage/abstract-files-upload-factory';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { transferFileStorageEntriesEffect } from 'ant/store/filestorage';
import { GlobalModalNames, GlobalModalsStorePayloads } from 'ant/store/global-modals/modal-types';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { FileStorageEntryId } from 'ant/types/models/file.model';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.FileStorageCreateMultiple]['payload']>;

export const FileStorageCreateMultiple: FC<Props> = (props) => {
  const { onClose, onSuccess: onSuccessModal, parent, uploadStore } = props;
  const { store: uploadFilesStore, uploadFilesEvent, removeFilesEvent } = uploadStore;
  const [form] = UiForm.useForm();
  const preloadedFiles = useStore(uploadFilesStore);
  const preloadedUiFiles = useMemo(() => {
    return preloadedFiles.map((file) => ({
      ...file.fileData,
      name: file.fileData.name,
      status: file.status,
      error: file.errorMessage,
    }));
  }, [preloadedFiles]);

  const onFinish = () => {
    const isAllFilesUploaded = !preloadedUiFiles.some(
      (file) => file.status === UiUploadFileStatusType.Uploading,
    );

    if (isAllFilesUploaded) {
      transferFileStorageEntriesEffect({
        destination: parent,
        objectIds: preloadedUiFiles.map((file) => file.uid),
      })
        .then(() => {
          message.success('Файлы успешно загружены');
          onSuccessModal?.();
          onClose();
        })
        .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось загрузить файлы')));
    }
  };

  const isSaveDisabled = useMemo(() => {
    return preloadedUiFiles.some((file) => {
      return file.status === UiUploadFileStatusType.Uploading || file.status === UiUploadFileStatusType.Error;
    });
  }, [preloadedUiFiles]);

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      uploadFilesEvent({ filesToUpload: uploadFileList, appendData: true });
    },
    [uploadFilesEvent],
  );

  const onPickFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      uploadFiles(filesToUpload);
    },
    [uploadFiles],
  );

  const onRemoveFile = useCallback(
    (fileId: FileStorageEntryId) => {
      const file = preloadedFiles.find((preloadedFile) => preloadedFile.fileData.uid === fileId);

      if (file) {
        removeFilesEvent([file.key]);
      }
    },
    [removeFilesEvent, preloadedFiles],
  );

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Загрузка файлов')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm size="large" onFinish={onFinish} form={form}>
          <UiSpace direction="vertical" size={8} full>
            <UiTypography.Text>Файлы</UiTypography.Text>
            <UploadDraggerArea
              multiple
              onPickFiles={onPickFiles}
              accept={FileUploadAccepts.All}
              description="Максимальный размер файла: 2 Гб"
              compact
            />
            <UiUploadFileList files={preloadedUiFiles} onDelete={onRemoveFile} />
          </UiSpace>
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            label="Сохранить"
            onClick={form.submit}
            size="large"
            type="primary"
            disabled={isSaveDisabled}
          />
          <UiButton label="Отмена" onClick={onClose} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
