import React, { FC } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { isCommentSomeOfContentTypes } from 'ant/types/guards/comment';
import { CommentContentTypes, CommentDetailListModel } from 'ant/types/models/comment.model';
import { NewsArticle } from 'ant/types/models/news.model';
import { MicropostModel, PostBlogModel, PostTypes } from 'ant/types/models/post';

import { getCommentLinkInfo } from './helpers';

type LongreadEntry = PostBlogModel | MicropostModel | NewsArticle;
type LongreadEntryType = PostTypes.Entry | PostTypes.Micropost | PostTypes.Simple;

const longreadTypes: Partial<Record<CommentContentTypes, LongreadEntryType>> = {
  [CommentContentTypes.Post]: PostTypes.Entry,
  [CommentContentTypes.Micropost]: PostTypes.Micropost,
  [CommentContentTypes.NewsEntry]: PostTypes.Simple,
};

type Props = {
  comment: CommentDetailListModel;
};

export const CommentListItemLink: FC<Props> = (props) => {
  const { comment } = props;
  const { contentType } = comment;
  const { title = '', to = '' } = getCommentLinkInfo(comment);

  if (
    isCommentSomeOfContentTypes<LongreadEntry>(comment, [
      CommentContentTypes.Post,
      CommentContentTypes.Micropost,
      CommentContentTypes.NewsEntry,
    ])
  ) {
    const onClick = () => {
      const type = longreadTypes[contentType];

      if (!type) {
        return;
      }

      const isNews = type === PostTypes.Simple;

      if (isNews) {
        openGlobalModal(GlobalModalNames.NewsLongread, {
          newsId: comment.data.id,
          newsType: type,
        });
      } else {
        openGlobalModal(GlobalModalNames.PostLongread, {
          postId: comment.data.id,
          postType: type,
        });
      }
    };

    return (
      <UiTypography.Link strong onClick={onClick}>
        <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
      </UiTypography.Link>
    );
  }

  return (
    <RouterLink to={to}>
      <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>
    </RouterLink>
  );
};
