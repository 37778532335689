import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography';
import { UiWidgetHeader } from 'ant/components/ui/widget-header';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { authService } from 'ant/plugins/auth-service';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { profileFullInfoStorage } from 'ant/store/profile';
import { getSkillsStorage } from 'ant/store/profile/skills';
import {
  ProfileInfoModel,
  SkillId,
  SkillType,
  SkillsUsersSteps,
  UserSkill,
} from 'ant/types/models/profile.model';

import styles from './Skills.scss';
import { SkillsAdd } from './add/SkillsAdd';
import { SkillsButton } from './button/SkillsButton';
import { SkillsChoice } from './choice/SkillsChoice';
import { SkillsEmpty } from './empty/SkillsEmpty';
import { SkillsAddModal } from './modals/skills-add/SkillsAddModal';

const SHOW_SKILLS_COUNT = 10;
const SKILLS_MOCKS_COUNT = 8;

const skillsMocks = createArrayMock(SKILLS_MOCKS_COUNT, (_, id) => id);

enum SkillText {
  OneUp = 'Навык',
  OneDown = 'навык',
  ManyUp = 'Навыки',
  ManyDown = 'навыки',
  ThanDown = 'навыком',
}

enum CompetenceText {
  OneUp = 'Компетенция',
  OneDown = 'компетенцию',
  ManyUp = 'Компетенции',
  ManyDown = 'компетенции',
  ThanDown = 'компетенцией',
}

export const skillsTextMap = {
  [SkillType.Skill]: SkillText,
  [SkillType.Competence]: CompetenceText,
};

export type SingleSkill = {
  userId: string;
  skillId: SkillId;
};

export type SkillsProps = {
  skillBtnClassName?: string;
  moreBtnClassName?: string;
  skillType: SkillType;
  className?: string;
  singleSkill?: SingleSkill;
};

export const Skills: FC<SkillsProps> = (props) => {
  const { skillBtnClassName, moreBtnClassName, skillType, className, singleSkill } = props;
  const currentUserId = authService.getCurrentUserId();
  let { id: userId = '' } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState(true);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isChoiceOpen, setChoiceOpen] = useState(false);
  const [deleteSkillId, setDeleteSkillId] = useState<string | null>(null);

  const onOpenUsers = (userSkill: UserSkill, step: SkillsUsersSteps) => {
    openGlobalModal(GlobalModalNames.SkillsUsers, { userSkill, skillType, userId, step });
  };

  if (singleSkill) {
    userId = singleSkill.userId;
  }

  const skillStorage = useMemo(() => getSkillsStorage({ userId, skillType }), [userId, skillType]);
  const { data: skillsListFull, loading: skillsListLoading } = useAbstractStorage(skillStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
    autoFetchParams: { userId, skillType },
    resetStoreOnUnmount: true,
  });

  const { deleteSkillEffect } = skillStorage;
  const deleteSkillInProgress = useStore(deleteSkillEffect.pending);

  const skillsList = useMemo(
    () =>
      singleSkill ? skillsListFull.filter((item) => item.skill.id === singleSkill.skillId) : skillsListFull,
    [singleSkill, skillsListFull],
  );

  // TODO: удалить совместно с SkillsChoice
  const existingSkillsIds = useMemo(() => skillsList.map((item) => item.skill.id), [skillsList]);

  const { data: profileFullInfoData, loading: profileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
  );

  const { main: userProfileData } = profileFullInfoData || ({} as ProfileInfoModel);

  useEffect(() => setShowMore(SHOW_SKILLS_COUNT >= skillsList.length), [skillsList]);

  const isOwner = useMemo(() => userId === currentUserId, [userId]);
  const isUserActive = Boolean(userProfileData?.isActive);

  const toggleSkillsAdd = () => setIsAddOpen(!isAddOpen);
  const toggleSkillsChoice = () => setChoiceOpen(!isChoiceOpen);

  const getSkillsToggle = {
    [SkillType.Skill]: toggleSkillsAdd,
    [SkillType.Competence]: toggleSkillsChoice,
  };

  const toggleSkills = getSkillsToggle[skillType];

  const resetDeleteSkillId = () => setDeleteSkillId(null);

  const submitDelete = () => {
    if (deleteSkillId) {
      deleteSkillEffect({ skillId: deleteSkillId, skillType });
    }

    resetDeleteSkillId();
  };

  const isSingleSkill = Boolean(singleSkill);

  const SubmitDeleteChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Удалить"
      onClick={submitDelete}
      disabled={deleteSkillInProgress}
      className={styles.profileSkills__deleteBtn}
    />
  );

  const skills = useMemo(() => {
    return skillsList.map((userSkill: UserSkill) => (
      <SkillsButton
        key={userSkill.skill.id}
        isOwner={isOwner}
        userSkill={userSkill}
        skillStorage={skillStorage}
        currentUserId={currentUserId}
        onSkillDelete={setDeleteSkillId}
        buttonClassName={classNames(skillBtnClassName, {
          [styles.profileSkills__skillBtn]: !isSingleSkill,
        })}
        skillType={skillType}
        onOpenUsers={(step) => onOpenUsers(userSkill, step)}
      />
    ));
  }, [skillsList]);

  return (
    <UiSpace
      size={0}
      direction="vertical"
      className={classNames(className, { [styles.profileSkills]: !isSingleSkill })}
    >
      {isSingleSkill && skillsListLoading && (
        <UiSkeleton loading={skillsListLoading} height={38} width={250} />
      )}
      {isSingleSkill && !skillsListLoading && <>{skills}</>}
      {!isSingleSkill && (
        <>
          <UiWidgetHeader title={skillsTextMap[skillType].ManyUp} className={styles.profileSkills__wrapper}>
            {isUserActive && (
              <UiTooltip placement="left" title={`Добавить ${skillsTextMap[skillType].OneDown}`}>
                <UiButton type="link-secondary" icon={<AddCircleSvg />} onClick={toggleSkills} />
              </UiTooltip>
            )}
          </UiWidgetHeader>

          <UiSpace size={0} wrap className={styles.profileSkills__container}>
            {skillsListLoading ? (
              skillsMocks.map((id) => (
                <UiSkeleton
                  key={id}
                  loading={skillsListLoading}
                  height={38}
                  width={80 + id * 10}
                  className={styles.profileSkills__skillBtn}
                />
              ))
            ) : (
              <>
                {userProfileData &&
                  userProfileData.isActive &&
                  skills.slice(0, showMore ? skills.length : SHOW_SKILLS_COUNT)}
                {!showMore && (
                  <UiButton
                    loading={skillsListLoading}
                    type="tertiary"
                    className={classNames(styles.profileSkills__skillBtn, moreBtnClassName)}
                    label={`Ещё ${skills.length - SHOW_SKILLS_COUNT}`}
                    onClick={() => setShowMore(true)}
                  />
                )}
                {userProfileData && (!userProfileData.isActive || !skills.length) && (
                  <SkillsEmpty
                    isOwner={isOwner}
                    addSkill={toggleSkills}
                    userProfile={userProfileData}
                    loading={profileFullInfoLoading}
                    skillType={skillType}
                  />
                )}
              </>
            )}
          </UiSpace>
        </>
      )}

      <UiModal isMaskClosable={false} type={UiModalTypes.Small} isOpen={isAddOpen} onClose={toggleSkills}>
        <SkillsAdd skillStorage={skillStorage} closeModal={toggleSkills} />
      </UiModal>

      <UiModal isMaskClosable={false} type={UiModalTypes.Small} isOpen={isChoiceOpen} onClose={toggleSkills}>
        {/* TODO: заменить на SkillsAddModal */}
        <SkillsChoice
          existingSkillsIds={existingSkillsIds}
          closeModal={toggleSkills}
          skillType={skillType}
          skillStorage={skillStorage}
        />
      </UiModal>

      <UiModal type={UiModalTypes.Small} isOpen={!!deleteSkillId} onClose={resetDeleteSkillId}>
        <SkillsAddModal
          title={`Удалить ${skillsTextMap[skillType].OneDown}?`}
          onCancel={resetDeleteSkillId}
          submitChildren={SubmitDeleteChildren}
        >
          <UiTypography.Text className={styles.profileSkills__deleteNotice}>
            {`${skillsTextMap[skillType].OneUp} и все его подтверждения будут удалены без возможности восстановления`}
          </UiTypography.Text>
        </SkillsAddModal>
      </UiModal>
    </UiSpace>
  );
};
