import React, { FC } from 'react';

import CommentOutlineSvg from 'ant/components/svg/comment-outline.svg';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { themePalette } from 'ant/theme';
import { FileStorageEntryVersionModel } from 'ant/types/models/file.model';

export const FileVersionsPanelHeader: FC<FileStorageEntryVersionModel> = (props) => {
  const { revision, name, commentsCount, isDeleted } = props;

  return (
    <UiSpace size={16}>
      <UiRow>
        <UiTypography.Text
          strong
          type={isDeleted ? UiTypographyTextTypes.Danger : UiTypographyTextTypes.Primary}
        >
          <UiTruncateMarkup truncate>{`Версия ${revision} — ${
            isDeleted ? `Удалено: ${name}` : name
          }`}</UiTruncateMarkup>
        </UiTypography.Text>
      </UiRow>
      {commentsCount > 0 && (
        <UiIcon.Label
          label={commentsCount.toString()}
          type={UiTypographyTextTypes.Primary}
          strong
          component={CommentOutlineSvg}
          color={themePalette.colorIcon}
        />
      )}
    </UiSpace>
  );
};
