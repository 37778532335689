import React, { FC } from 'react';

import { UiCard } from 'ant/components/ui/card';
import { UiDivider } from 'ant/components/ui/divider';
import { SkillType } from 'ant/types/models/profile.model';

import { Skills, SkillsProps } from './Skills';
import styles from './SkillsGroup.scss';

type SkillsGroupProps = Omit<SkillsProps, 'skillType'>;

export const SkillsGroup: FC<SkillsGroupProps> = (props) => (
  <UiCard size="default" emptyPadding className={styles.skillsGroup}>
    <Skills {...props} skillType={SkillType.Skill} />
    <UiDivider emptyMargin />
    <Skills {...props} skillType={SkillType.Competence} />
  </UiCard>
);
