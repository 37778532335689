import React, { FC } from 'react';

import ArrowFrontSvg from 'ant/components/svg/arrow-front.svg';
import DeleteSvg from 'ant/components/svg/delete.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';

type Props = {
  onDelete: () => void;
  onMove: () => void;
};

export const ControlBarRightActions: FC<Props> = (props) => {
  const { onMove, onDelete } = props;

  return (
    <UiSpace size={16} align="center">
      <UiButton
        onClick={onMove}
        type="link-secondary"
        icon={<UiIcon component={ArrowFrontSvg} width={20} height={20} />}
        label="Переместить"
      />
      <UiButton
        onClick={onDelete}
        type="link-secondary"
        icon={<UiIcon component={DeleteSvg} width={20} height={20} />}
        label="Удалить"
      />
    </UiSpace>
  );
};
