import { useStoreMap, useStore } from 'effector-react';
import React, { FC, useCallback, useState, useMemo } from 'react';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import ClearSvg from 'ant/components/svg/clear.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCheckbox } from 'ant/components/ui/checkbox/UiCheckbox';
import { UiForm, UiFormListItemAddActionType } from 'ant/components/ui/form';
import { UiIcon } from 'ant/components/ui/icon';
import { UiInput } from 'ant/components/ui/input';
import { UiModal, UiModalBase, UiModalTypes } from 'ant/components/ui/modals';
import { UiSelect } from 'ant/components/ui/select';
import { normalizeValueTrim, normalizeValueTrimStart } from 'ant/helpers/normalize';
import { MAX_LENGTH_INPUT_200, validURLWithProtocolRule, requiredRule } from 'ant/helpers/validation';
import { currentProfileRolesPermissionsMapStore } from 'ant/plugins/current-profile-service';
import { checkInnerPath, normalizeToInnerUrl } from 'ant/plugins/router';
import { generateRandomString } from 'ant/plugins/utils/generate-random-string';
import { AllAllowedPermissions } from 'ant/types/models/permissions.model';
import { isPermissionOptions } from 'components-frontend/typings/guards/section';

import styles from '../NavigationEdit.scss';
import { CreateNewSection } from '../create-new-section/CreateNewSection';
import {
  SectionItemsList,
  ItemListField,
  SectionItemLinkListField,
  SectionItemListField,
} from '../section-items-list/SectionItemsList';

export type AddSectionLinkHandler = { addSectionLink: UiFormListItemAddActionType };

export const SectionsList: FC = () => {
  const [addForm] = UiForm.useForm();
  const [isSectionAddOpen, setIsSectionAddOpen] = useState(false);
  const [selectedSectionEdit, setSelectedSectionEdit] = useState<SectionItemListField | null>(null);
  const [addSectionLinkAction, setAddSectionLinkAction] = useState<AddSectionLinkHandler | null>(null);
  const [selectedSectionLinkEdit, setSelectedSectionLinkEdit] = useState<SectionItemLinkListField | null>(
    null,
  );
  const currentProfileRolesPermissionsMap = useStore(currentProfileRolesPermissionsMapStore);
  const permissionOptions = useStoreMap(currentProfileRolesPermissionsMapStore, (permissions) =>
    Array.from(permissions).map(([value, label]) => ({ label, value })),
  );
  const superAdminCheckboxPressed = UiForm.useWatch('superAdminOnly', addForm);

  const onOpenSectionEdit = (sectionField: SectionItemListField) => {
    addForm.setFieldsValue({ section: sectionField.value?.section?.name });
    setSelectedSectionEdit(sectionField);
  };

  const onOpenSectionLinkEdit = (sectionLinkField: SectionItemLinkListField) => {
    const { value } = sectionLinkField;
    let to = String(value?.to);

    if (checkInnerPath(to)) {
      to = `${process.env.REMOTE_URL || window.location.origin}${to}`;
    }

    addForm.setFieldsValue({
      label: value?.label,
      to,
      blank: value?.blank,
      permissions: value?.permissions,
      superAdminOnly: value?.superAdminOnly,
    });
    setSelectedSectionLinkEdit(sectionLinkField);
  };

  const onCloseCreateNewSection = () => {
    addForm.resetFields();
    setIsSectionAddOpen(false);
    setAddSectionLinkAction(null);
    setSelectedSectionEdit(null);
    setSelectedSectionLinkEdit(null);
  };

  const modalSettings = useMemo(() => {
    const editSectionModalTitle = isSectionAddOpen ? 'Добавить раздел' : 'Редактировать раздел';
    const editSectionLinkModalTitle = addSectionLinkAction ? 'Добавить ссылку' : 'Редактировать ссылку';
    const deleteConfirmModalTitle = selectedSectionEdit ? 'Удалить раздел меню?' : 'Удалить ссылку?';

    const isOpenSectionEditModal = isSectionAddOpen || Boolean(selectedSectionEdit);
    const isOpenSectionLinkEditModal = Boolean(addSectionLinkAction) || Boolean(selectedSectionLinkEdit);

    return {
      title: isOpenSectionEditModal ? editSectionModalTitle : editSectionLinkModalTitle,
      isOpen: isOpenSectionEditModal || isOpenSectionLinkEditModal,
      isSectionForm: isOpenSectionEditModal,
      isSectionLinkForm: isOpenSectionLinkEditModal,
      deleteConfirmModalTitle,
    };
  }, [isSectionAddOpen, selectedSectionEdit, addSectionLinkAction, selectedSectionLinkEdit]);

  const permissionsToOptions = (permissions: AllAllowedPermissions[]) =>
    permissions?.map((value) => ({
      key: value,
      label: currentProfileRolesPermissionsMap.get(value),
      value,
    }));

  const onSaveEditSection = useCallback(
    (addNewSection: UiFormListItemAddActionType) => {
      if (isSectionAddOpen && addNewSection) {
        const section = normalizeValueTrim(addForm.getFieldValue('section'));

        addNewSection({
          section: { name: section, id: generateRandomString() },
          items: [],
        });
      }

      if (selectedSectionEdit?.onChange) {
        const { value, onChange } = selectedSectionEdit;
        const section = normalizeValueTrim(addForm.getFieldValue('section'));

        onChange({
          section: { name: section, id: value?.section?.id ? value.section.id : generateRandomString() },
          items: value?.items || [],
        });
      }

      const onChangeLink = addSectionLinkAction?.addSectionLink || selectedSectionLinkEdit?.onChange;

      if (onChangeLink) {
        const to = normalizeToInnerUrl(addForm.getFieldValue('to'));
        const label = normalizeValueTrim(addForm.getFieldValue('label'));
        const currentPermissions = addForm.getFieldValue('permissions') || [];

        const permissions = isPermissionOptions(currentPermissions)
          ? currentPermissions
          : permissionsToOptions(currentPermissions);

        addForm.setFieldValue('permissions', permissions);

        onChangeLink({
          label,
          to,
          blank: addForm.getFieldValue('blank'),
          permissions,
          superAdminOnly: addForm.getFieldValue('superAdminOnly'),
        });
      }

      onCloseCreateNewSection();
    },
    [isSectionAddOpen, selectedSectionEdit, addSectionLinkAction, selectedSectionLinkEdit],
  );

  const onRemoveSectionAction = useCallback(() => {
    const { onRemove } = selectedSectionEdit || selectedSectionLinkEdit || ({} as ItemListField);

    if (onRemove) {
      const confirmModal = UiModalBase.confirm({
        title: modalSettings.deleteConfirmModalTitle,
        okText: 'Удалить',
        cancelText: 'Отмена',
        centered: true,
        autoFocusButton: null,
      });

      confirmModal.update({
        onOk: (close) => {
          onRemove();
          close();
          onCloseCreateNewSection();
        },
      });
    }
  }, [selectedSectionEdit, selectedSectionLinkEdit]);

  const RemoveButton = (
    <UiButton
      size="middle"
      type="link-secondary"
      icon={<UiIcon component={ClearSvg} width={20} height={20} />}
    />
  );

  return (
    <UiForm.List name="sections">
      {(sectionsFields, { add, remove, move }) => (
        <>
          {sectionsFields.map((field, index) => (
            <UiForm.Item noStyle {...field}>
              <SectionItemsList
                index={index}
                name={field.name}
                onRemove={() => remove(field.name)}
                onSectionMove={move}
                onSectionEdit={onOpenSectionEdit}
                onSectionLinkEdit={onOpenSectionLinkEdit}
                onSectionLinkAdd={setAddSectionLinkAction}
              />
            </UiForm.Item>
          ))}

          <UiButton
            size="large"
            type="secondary"
            label="Добавить раздел"
            onClick={() => setIsSectionAddOpen(true)}
            className={styles.navigationEdit__sectionAdd}
            icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
          />

          <UiModal type={UiModalTypes.Small} isOpen={modalSettings.isOpen} onClose={onCloseCreateNewSection}>
            <CreateNewSection
              title={modalSettings.title}
              onSave={addForm.submit}
              onCancel={onCloseCreateNewSection}
              onRemove={addSectionLinkAction || isSectionAddOpen ? undefined : onRemoveSectionAction}
            >
              <UiForm
                form={addForm}
                layout="vertical"
                name="add-or-edit-section-form"
                onFinish={() => onSaveEditSection(add)}
              >
                {modalSettings.isSectionForm && (
                  <UiForm.Item
                    label="Название"
                    name="section"
                    rules={[requiredRule]}
                    normalize={normalizeValueTrimStart}
                  >
                    <UiInput size="large" maxLength={MAX_LENGTH_INPUT_200} />
                  </UiForm.Item>
                )}

                {modalSettings.isSectionLinkForm && (
                  <>
                    <UiForm.Item
                      label="Название"
                      name="label"
                      rules={[requiredRule]}
                      normalize={normalizeValueTrimStart}
                    >
                      <UiInput size="large" maxLength={MAX_LENGTH_INPUT_200} />
                    </UiForm.Item>
                    <UiForm.Item
                      name="to"
                      label="URL-адрес"
                      rules={[validURLWithProtocolRule]}
                      normalize={normalizeValueTrim}
                    >
                      <UiInput size="large" />
                    </UiForm.Item>
                    <UiForm.Item name="blank" valuePropName="checked">
                      <UiCheckbox className={styles.navigationEdit__checkbox_center}>
                        Открывать в новом окне
                      </UiCheckbox>
                    </UiForm.Item>
                  </>
                )}
                <UiForm.Item
                  label="Разрешения"
                  name="permissions"
                  extra={superAdminCheckboxPressed ? 'Главному администратору доступны все разрешения' : ' '}
                >
                  <UiSelect
                    size="large"
                    mode="multiple"
                    disabled={superAdminCheckboxPressed}
                    showArrow
                    showSearch={false}
                    defaultActiveFirstOption
                    filterOption={false}
                    placeholder="Добавить"
                    removeIcon={RemoveButton}
                    options={permissionOptions}
                  />
                </UiForm.Item>
                <UiForm.Item name="superAdminOnly" valuePropName="checked">
                  <UiCheckbox>Доступен только главному администратору</UiCheckbox>
                </UiForm.Item>
              </UiForm>
            </CreateNewSection>
          </UiModal>
        </>
      )}
    </UiForm.List>
  );
};
