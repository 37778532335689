// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4d67a629_poll-editor{flex:1;overflow:hidden;display:flex;flex-direction:column}._4d67a629_poll-editor__title[class][class]{margin-bottom:16px}._4d67a629_poll-editor__general .ant-form-item{margin:0}._4d67a629_poll-editor__checkbox .ant-form-item-control-input-content{height:20px}._4d67a629_poll-editor__date-picker{width:128px}._4d67a629_poll-editor__time-picker{width:90px;margin-left:8px}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/poll/poll-editor/PollEditor.scss"],"names":[],"mappings":"AACA,uBACE,MAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CAEA,4CACE,kBAAA,CAKE,+CACE,QAAA,CAOF,sEACE,WAAA,CAKN,oCACE,WAAA,CAGF,oCACE,UAAA,CACA,eAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.poll-editor {\n  flex: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n\n  &__title[class][class] {\n    margin-bottom: 16px;\n  }\n\n  &__general {\n    :global {\n      .ant-form-item {\n        margin: 0;\n      }\n    }\n  }\n\n  &__checkbox {\n    :global {\n      .ant-form-item-control-input-content {\n        height: 20px;\n      }\n    }\n  }\n\n  &__date-picker {\n    width: 128px;\n  }\n\n  &__time-picker {\n    width: 90px;\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-editor": "_4d67a629_poll-editor",
	"pollEditor": "_4d67a629_poll-editor",
	"poll-editor__title": "_4d67a629_poll-editor__title",
	"pollEditor__title": "_4d67a629_poll-editor__title",
	"poll-editor__general": "_4d67a629_poll-editor__general",
	"pollEditor__general": "_4d67a629_poll-editor__general",
	"poll-editor__checkbox": "_4d67a629_poll-editor__checkbox",
	"pollEditor__checkbox": "_4d67a629_poll-editor__checkbox",
	"poll-editor__date-picker": "_4d67a629_poll-editor__date-picker",
	"pollEditor__datePicker": "_4d67a629_poll-editor__date-picker",
	"poll-editor__time-picker": "_4d67a629_poll-editor__time-picker",
	"pollEditor__timePicker": "_4d67a629_poll-editor__time-picker"
};
export default ___CSS_LOADER_EXPORT___;
