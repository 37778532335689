import { AxiosError } from 'axios';
import orderBy from 'lodash/orderBy';
import React, { FC, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiDrawer } from 'ant/components/ui/drawer';
import { ErrorBoundary } from 'ant/components/widgets/ErrorBoundary';
import { createArrayMock } from 'ant/plugins/create-mocks';
// import { DrawerFooter } from './footer/DrawerFooter'; // TODO: Раздел в разработке
import { markAllNotificationsRead } from 'ant/store/notifications/api';
import {
  NotificationFilters,
  NotificationModel,
  NotificationContent,
  NotificationStatuses,
  NotificationSortAttributes,
} from 'ant/types/models/notification.model';

import styles from './NotificationsDrawer.scss';
import { FeedError } from './feed-error/FeedError';
import { NotificationLoading } from './loading/NotificationLoading';
import { NotificationCard } from './notification-card/NotificationCard';
import { DrawerTitle } from './title/DrawerTitle';

const emptyPaddingStyle: React.CSSProperties = { padding: 0 };
const MOCKS_LENGTH = 5;
const notificationsFeedMocks = createArrayMock(MOCKS_LENGTH, (_, key) => <NotificationLoading key={key} />);

type Props = {
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  notifications: NotificationModel<NotificationContent>[];
  drawerTitle: string;
  // notificationTitleType: NotificationTitleTypes;
  // notificationBodyType: NotificationBodyTypes;
  icon: SvgrComponent;
  emptyFeed: React.ReactNode;
  onChange: (filters?: NotificationFilters) => void;
  error: AxiosError | null;
  markAllAsReadLabel: string;
};

export const NotificationsDrawer: FC<Props> = (props) => {
  const {
    visible,
    onClose,
    loading,
    notifications,
    drawerTitle,
    icon,
    emptyFeed,
    onChange,
    error,
    markAllAsReadLabel,
  } = props;
  const isEmptyFeed = !loading && !error && notifications?.length === 0;

  const isUnread = useMemo(
    () => notifications.some(({ status }) => status === NotificationStatuses.Unread),
    [notifications],
  );

  return (
    <UiDrawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      // footer={<DrawerFooter />} // TODO: Раздел в разработке
      width={400}
      footerStyle={emptyPaddingStyle}
      bodyStyle={emptyPaddingStyle}
      headerStyle={emptyPaddingStyle}
      title={<DrawerTitle title={drawerTitle} icon={icon} onChange={onChange} />}
      className={styles.notificationsDrawer}
    >
      <ErrorBoundary>
        {!isEmptyFeed && !loading && (
          <UiButton
            type="link-secondary"
            className={styles.notificationsDrawer__markAsRead}
            onClick={() => markAllNotificationsRead().then(() => onChange())}
            disabled={!isUnread}
            label={markAllAsReadLabel}
          />
        )}
        {loading && notificationsFeedMocks}
        {isEmptyFeed && emptyFeed}
        {error && <FeedError />}
        {!loading &&
          !error &&
          orderBy(notifications, NotificationSortAttributes.Date, 'desc').map((notification) => (
            <NotificationCard key={notification.id} notification={notification} />
          ))}
      </ErrorBoundary>
    </UiDrawer>
  );
};
