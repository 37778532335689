import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';

import FaqSvg from 'ant/components/svg/faq.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCheckbox } from 'ant/components/ui/checkbox';
import { UiForm } from 'ant/components/ui/form';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiModal, UiModalProps, UiModalTypes } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { UiSpinner } from 'ant/components/ui/spinner';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { getFileStorageFolderExtensions } from 'ant/store/filestorage';
import { themePalette } from 'ant/theme';
import {
  FileStorageFolderImageExtensions,
  FileStorageFolderVideoExtensions,
  FileStorageListEntryModel,
} from 'ant/types/models/file.model';

type FormValues = {
  extensions: string[];
};

interface Props extends Omit<UiModalProps, 'type'> {
  folder: FileStorageListEntryModel;
}

export const FileListManagerExtensionsModal: FC<Props> = (props) => {
  const { isOpen, onClose, folder } = props;
  const [form] = UiForm.useForm<FormValues>();

  const imageExtensionsNames = useMemo(() => Object.keys(FileStorageFolderImageExtensions).join(','), []);
  const videoExtensionsNames = useMemo(() => Object.keys(FileStorageFolderVideoExtensions).join(','), []);

  const { storage: fileStorageFolderUserRolesStorage, setFileStorageFolderExtensionsEffect } = useMemo(
    getFileStorageFolderExtensions,
    [],
  );

  const isSetFileStorageFolderExtensionsPending = useStore(setFileStorageFolderExtensionsEffect.pending);

  const { data: dataExtensions, loading: isExtensionsLoading } = useAbstractStorage(
    fileStorageFolderUserRolesStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { entryId: folder.id },
      cancelPendingRequestOnUnmount: true,
    },
  );

  const extensions = useMemo<string[]>(() => {
    const extensionsOptionsValue = [];

    if (dataExtensions && dataExtensions.image.length > 0) {
      extensionsOptionsValue.push(imageExtensionsNames);
    }

    if (dataExtensions && dataExtensions.video.length > 0) {
      extensionsOptionsValue.push(videoExtensionsNames);
    }

    return extensionsOptionsValue;
  }, [isExtensionsLoading]);

  const onFinish = async (formValues: FormValues) => {
    const { extensions: formExtensions } = formValues;
    const videoExtensions = [];
    const imageExtensions = [];

    if (formExtensions.includes(imageExtensionsNames)) {
      imageExtensions.push(...Object.values(FileStorageFolderImageExtensions));
    }

    if (formExtensions.includes(videoExtensionsNames)) {
      videoExtensions.push(...Object.values(FileStorageFolderVideoExtensions));
    }

    try {
      await setFileStorageFolderExtensionsEffect({
        entryId: folder.id,
        video: videoExtensions,
        image: imageExtensions,
      });

      message.success('Ограничения файлов на директорию успешно установлены');
      onClose?.();
    } catch (e) {
      message.error(getErrorResponseMessage(e, 'Не удалось установить ограничения файлов на директорию'));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      extensions,
    });
  }, [extensions]);

  const tooltipTitle =
    'Если ограничение по типу файла не выставлено, то в данную папку можно добавлять любые файлы';

  return (
    <UiModal type={UiModalTypes.Medium} isOpen={isOpen} onClose={onClose}>
      <UiSpinner spinning={isExtensionsLoading}>
        <UiForm form={form} size="small" layout="vertical" onFinish={onFinish}>
          <UiModal.Header hasBottomBorder>
            <UiModal.Header.Title steps={getModalStepsForSingleTitle('Тип файлов для папки')}>
              <UiTooltip title={tooltipTitle}>
                <UiIcon
                  style={{ marginLeft: 8 }}
                  color={themePalette.colorIcon}
                  component={FaqSvg}
                  width={20}
                  height={20}
                />
              </UiTooltip>
            </UiModal.Header.Title>
          </UiModal.Header>

          <UiModal.Content basePadding>
            <UiSpace size={4} direction="vertical" style={{ marginBottom: 16 }}>
              <UiTypography.Text type="secondary">Название папки</UiTypography.Text>
              <UiTypography.Title level={3}>{folder.name}</UiTypography.Title>
            </UiSpace>

            <UiForm.Item
              style={{ marginBottom: 0 }}
              name="extensions"
              label="Какие файлы можно загружать в папку:"
            >
              <UiCheckbox.Group>
                <UiSpace style={{ padding: '8px 24px' }} direction="vertical" size={16} full>
                  <UiCheckbox value={imageExtensionsNames}>
                    Графический файл ({imageExtensionsNames})
                  </UiCheckbox>
                  <UiCheckbox value={videoExtensionsNames}>Видеофайл ({videoExtensionsNames})</UiCheckbox>
                </UiSpace>
              </UiCheckbox.Group>
            </UiForm.Item>
          </UiModal.Content>

          <UiModal.Footer hasTopBorder>
            <UiModal.Footer.Buttons>
              <UiButton
                disabled={isSetFileStorageFolderExtensionsPending}
                type="primary"
                size="large"
                onClick={form.submit}
                label="Сохранить"
              />
              <UiButton type="secondary" size="large" onClick={onClose} label="Отмена" />
            </UiModal.Footer.Buttons>
          </UiModal.Footer>
        </UiForm>
      </UiSpinner>
    </UiModal>
  );
};
