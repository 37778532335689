import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiDivider } from 'ant/components/ui/divider';
import { UiFile } from 'ant/components/ui/file';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { Notification } from 'ant/components/widgets/Notification';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { getFormattedFileSize } from 'ant/plugins/utils/get-formatted-file-size';
import { GroupFileCreatedContentModel, NotificationModel } from 'ant/types/models/notification.model';

interface Props {
  notification: NotificationModel<GroupFileCreatedContentModel>;
}

export const NotificationFileBlog: FC<Props> = (props) => {
  const { notification } = props;
  const navigate = useNavigate();

  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId },
    content: { blog, file },
  } = notification;

  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const onFileClick = () => {
    navigate(getRoutePath(RouteNames.GroupFileView, { id: blog.slug, fileId: file.id }));
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subTitle="Загружен новый файл"
        avatar={avatar}
      />

      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          <UiSpace full size={4} direction="horizontal">
            <UiTypography.Text type="secondary">в группу:</UiTypography.Text>
            <UiTypography.Link strong href={getRoutePath(RouteNames.GroupView, { id: blog.slug })}>
              {blog.name}
            </UiTypography.Link>
          </UiSpace>
          <UiFile
            title={
              <UiTypography.Text strong type="secondary">
                <UiTruncateMarkup truncate tooltipProps={{ title: file.name }}>
                  {file.name}
                </UiTruncateMarkup>
              </UiTypography.Text>
            }
            subtitle={
              <UiTypography.Text type="secondary">
                {getFormattedFileSize(file.size)}
                <UiDivider.Dot type="secondary" />
                {createdAt ? getFormattedDate(createdAt, 'dd.MM.yyyy') : ''}
              </UiTypography.Text>
            }
            fileName={file.name}
          />
          <UiButton label="Посмотреть" type="primary" onClick={onFileClick} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
