import React, { FC, useMemo } from 'react';

import { UiEmpty } from 'ant/components/ui/empty';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { TimelineLongreadPayload } from 'ant/types/longread';
import { LongreadLoading } from 'components-frontend/components/longread/loading/LongreadLoading';
import { TimelineCard } from 'components-frontend/components/timeline-card/TimelineCard';
import { getTimelineRecordStorage } from 'components-frontend/store/timeline';

export type LongreadProps = TimelineLongreadPayload;

export const TimelineLongread: FC<LongreadProps> = (props) => {
  const { timelineId } = props;
  const { storage: timelineRecordStorage } = useMemo(getTimelineRecordStorage, []);
  const {
    data: timelineRecordData,
    loading: isTimelineRecordLoading,
    error,
  } = useAbstractStorage(timelineRecordStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
    autoFetchParams: { id: timelineId },
  });

  const isError = (!timelineRecordData && !isTimelineRecordLoading) || error;

  return (
    <>
      {isTimelineRecordLoading && <LongreadLoading />}
      {isError && <UiEmpty.Feed emptyMessage={{ header: 'Не удалось загрузить запись' }} />}
      {timelineRecordData && <TimelineCard gutter={[32, 48]} record={timelineRecordData} />}
    </>
  );
};
