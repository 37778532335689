import React, { FC } from 'react';

import ClearSVG from 'ant/components/svg/clear.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiInput } from 'ant/components/ui/input';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { SearchCategoryType, SearchParams } from 'ant/types/models/search.model';
import { SearchFilter } from 'components-frontend/components/search-page/SearchPage';
import { SearchContentLoading } from 'components-frontend/components/search-page/search-content/loading/SearchContentLoading';
import { SearchItemAdapter } from 'components-frontend/components/search-page/search-item/SearchItemAdapter';
import { getSearchProfileStorage } from 'components-frontend/store/search/search-profile';

import styles from './SearchContent.scss';

type Props = {
  selectedSearchFilters: SearchFilter[];
  deleteFilter: (selectedSearchFilter: SearchFilter) => void;
  deleteAllFilters: () => void;
  origin: SearchCategoryType;
  minSymbolsToSearch: number;
  fetchParams: SearchParams;
};

const searchProfileStorage = getSearchProfileStorage();
const MOCKS_LENGTH = 3;

const searchResultMocks = createArrayMock(MOCKS_LENGTH, (_, key) => <SearchContentLoading key={key} />);

export const SearchContent: FC<Props> = (props) => {
  const { selectedSearchFilters, deleteFilter, deleteAllFilters, origin, minSymbolsToSearch, fetchParams } =
    props;

  const {
    data: foundUsers,
    loading,
    fetchFx: fetchUserSearch,
  } = useAbstractStorage(searchProfileStorage.storage, {});

  const onSearch = (query: string) => {
    if (query.length >= minSymbolsToSearch) {
      fetchUserSearch({ ...fetchParams, query });
    }
  };

  return (
    <div className={styles.searchContent}>
      <UiInput.SearchBox
        allowClear
        size="large"
        placeholder="Поиск"
        enterButton="Найти"
        className={styles.searchContent__input}
        onSearch={onSearch}
      />
      <div className={styles.searchContent__filters}>
        {selectedSearchFilters.map((filter) => (
          <div key={filter.label} className={styles.searchContent__filter}>
            <UiTypography.Text>{`${filter.label}: `}</UiTypography.Text>
            <UiTypography.Text strong>{filter.value}</UiTypography.Text>
            <UiIcon
              component={ClearSVG}
              height={20}
              width={20}
              onClick={() => deleteFilter({ label: filter.label })}
              className={styles.searchContent__icon}
            />
          </div>
        ))}
      </div>
      <div className={styles.searchContent__searchResultsHeader}>
        <div>
          <UiTypography.Text>Найдено: </UiTypography.Text>
          <UiTypography.Text strong>{foundUsers.length}</UiTypography.Text>
        </div>
        <UiButton
          type="link"
          icon={<ClearSVG />}
          label="Очистить фильтры"
          onClick={() => deleteAllFilters()}
        />
      </div>
      {loading && searchResultMocks}
      {!loading && foundUsers.map((item) => <SearchItemAdapter item={item} origin={origin} key={item.id} />)}
    </div>
  );
};
