import classNames from 'classnames';
import React, { useMemo } from 'react';

import GrabberSvg from 'ant/components/svg/grabber.svg';
import { UiCheckbox, UiCheckboxChangeEvent } from 'ant/components/ui/checkbox/UiCheckbox';
import { DraggablePropsStyles, UiDraggable } from 'ant/components/ui/drag-drop-context';
import { UiForm, UiFormItemProps } from 'ant/components/ui/form';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { getPathnameWithoutParams } from 'ant/plugins/router';
import { NavigationItem } from 'components-frontend/typings/navigation';

import { MenuLinkItem } from '../../menu/link-item/MenuLinkItem';
import styles from './NavigationFavoritesItem.scss';

const $shadow8px = '0 4px 8px rgba(46, 48, 51, 0.08)';

export type CheckboxChangeEventHandlerCustom = (e: UiCheckboxChangeEvent, name: string | undefined) => void;

interface Props extends UiFormItemProps<NavigationItem> {
  index: number;
  selectedKeys: string[];
  isFavoritesEdit: boolean;
  isSuccessEditLoading: boolean;
  onCheckboxFavoritesChange: CheckboxChangeEventHandlerCustom;
}

const getItemStyle = (isDragging: boolean, draggableStyle: DraggablePropsStyles): React.CSSProperties => ({
  userSelect: 'none',
  boxShadow: isDragging ? $shadow8px : 'none',
  ...draggableStyle,
});

export const NavigationFavoritesItem: React.FC<Props> = (props) => {
  const { value, index, selectedKeys, isFavoritesEdit, isSuccessEditLoading, onCheckboxFavoritesChange } =
    props;

  const isSelected = useMemo(() => {
    if (value?.to) {
      return selectedKeys.includes(getPathnameWithoutParams(value.to));
    }

    return false;
  }, [selectedKeys, value]);

  const onChange = (name: string | undefined) => (e: UiCheckboxChangeEvent) => {
    onCheckboxFavoritesChange(e, name);
  };

  return (
    <UiDraggable isDragDisabled={!isFavoritesEdit} draggableId={String(value?.to)} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {value && (
            <div className={styles.navigationFavoritesItem}>
              <UiForm.Item noStyle name={[index, 'checked']} valuePropName="checked">
                <UiCheckbox
                  disabled={isSuccessEditLoading}
                  onChange={onChange(value?.to)}
                  className={classNames({
                    [styles.navigationFavoritesItem__checkbox]: isFavoritesEdit,
                    [styles.navigationFavoritesItem__checkbox_hide]: !isFavoritesEdit,
                  })}
                />
              </UiForm.Item>

              <MenuLinkItem to={value.to} target={value.blank ? '_blank' : '_self'}>
                <UiTruncateMarkup
                  className={classNames(styles.navigationFavoritesItem__label, {
                    [styles.navigationFavoritesItem__label_selected]: isSelected && !isFavoritesEdit,
                  })}
                  truncate
                  tooltipProps={{ title: value.label, placement: 'right' }}
                >
                  {value.label}
                </UiTruncateMarkup>
              </MenuLinkItem>

              <UiIcon
                component={GrabberSvg}
                width={20}
                height={20}
                className={classNames(styles.navigationFavoritesItem__dragIcon, {
                  [styles.navigationFavoritesItem__dragIcon_hide]: isFavoritesEdit,
                })}
              />
            </div>
          )}
        </div>
      )}
    </UiDraggable>
  );
};
