import classNames from 'classnames';
import React, { useMemo } from 'react';

import ArrowDownRightSvg from 'ant/components/svg/arrow-down-right.svg';
import ArrowUpRightSvg from 'ant/components/svg/arrow-up-right.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';
import { numberReplaceSeparator } from 'ant/plugins/number-formatters';
import { CurrencyRates } from 'components-frontend/typings/currency-rates';

import styles from './CurrencyItem.scss';

type CurrencyItemProps = {
  currency: CurrencyRates.CurrencyRate;
  date?: string;
};

const ccn = classNames.bind(styles);

export const CurrencyItem: React.FC<CurrencyItemProps> = ({ currency, date }) => {
  const { icon, currencyCode, dynamics: currDynamics, rate } = currency;

  const dynamics = Number(currDynamics);
  const isPositiveDynamic = dynamics > 0;
  const formattedRate = useMemo(() => numberReplaceSeparator(Number(rate).toFixed(4)), [rate]);
  const DefaultIcon = <span className={styles.currencyItem__iconMock}>{currencyCode}</span>;
  const DynamicIcon = isPositiveDynamic ? ArrowUpRightSvg : ArrowDownRightSvg;
  const dynamicUpClassNames = { [styles.currencyItem__dynamic_up]: isPositiveDynamic };

  return (
    <div className={styles.currencyItem}>
      <UiAvatar size={40} src={icon} icon={DefaultIcon} className={styles.currencyItem__icon} />

      <span className={styles.currencyItem__info}>
        <span className={styles.currencyItem__infoWrapper}>
          <UiTypography.Text strong>{formattedRate}</UiTypography.Text>

          {Boolean(dynamics) && (
            <>
              <UiTypography.Text className={ccn(styles.currencyItem__dynamic, dynamicUpClassNames)}>
                {isPositiveDynamic ? '+' : '−'}
                {numberReplaceSeparator(Math.abs(dynamics))}
              </UiTypography.Text>
              <UiIcon
                width={20}
                height={20}
                component={DynamicIcon}
                className={ccn(styles.currencyItem__dynamicIcon, dynamicUpClassNames)}
              />
            </>
          )}

          <UiTypography.Text type="secondary">{date}</UiTypography.Text>
        </span>

        <UiTypography.Text className={styles.currencyItem__name}>{currencyCode}</UiTypography.Text>
      </span>
    </div>
  );
};
