import React, { FC } from 'react';

import { GamificationItemTypes } from 'ant/types/models/gamification.model';
import { PostGamificationModel as GamificationPostType } from 'ant/types/models/post';

import { PostGamificationBadges } from './badges/PostGamificationBadges';
import { PostGamificationThanks } from './thanks/PostGamificationThanks';
import { PostGamificationWrapper } from './wrapper/PostGamificationWrapper';

type PostItemGamificationProps = {
  post: GamificationPostType;
};

export const PostGamification: FC<PostItemGamificationProps> = (props) => {
  const { post } = props;

  if (post.entityType === GamificationItemTypes.Badge) {
    return (
      <PostGamificationWrapper style={{ minHeight: '160px' }}>
        <PostGamificationBadges post={post} />
      </PostGamificationWrapper>
    );
  }

  if (post.entityType === GamificationItemTypes.Thanks) {
    return (
      <PostGamificationWrapper style={{ minHeight: '228px' }}>
        <PostGamificationThanks post={post} />
      </PostGamificationWrapper>
    );
  }

  return null;
};
