// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../assets/images/pattern-achieve.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1e48cbe0_post-gamification-wrapper{margin-top:20px;padding:20px;border-radius:4px;position:relative;background:var(--default-themeVariables-colorBrand08) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", "",{"version":3,"sources":["webpack://./../components-frontend/src/components/post/gamification/wrapper/PostGamificationWrapper.scss","webpack://./../ant/src/assets/scss/variables/content.scss"],"names":[],"mappings":"AACA,qCACE,eAAA,CACA,YAAA,CACA,iBCEiB,CDDjB,iBAAA,CACA,6FAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.post-gamification-wrapper {\n  margin-top: 20px;\n  padding: 20px;\n  border-radius: $borderRadiusHalf;\n  position: relative;\n  background: $colorBrand08 url('components-frontend/assets/images/pattern-achieve.png');\n}\n","$borderRadiusBase: get('themeSizes.borderRadiusBase');\n$baseSpacing: 24px;\n$spacerHeight: 32px;\n$widgetMargin: get('themeOffsets.widgetMargin');\n\n$borderRadius: 8px; // TODO: вынести в тему\n$borderRadiusHalf: 4px; // TODO: вынести в тему\n$borderRadiusQuarter: 2px; // TODO: вынести в тему\n$blockquoteBorderRadius: get('themeLongRead.blockquoteBorderRadius');\n\n$modalOffset: 72px;\n$spacerBorder: 1px solid $colorStroke;\n$dashedBorder: 1px dashed $colorStrokeDark;\n\n$spaceBase: 4px;\n\n$space2XS: $spaceBase;\n$spaceXS: $spaceBase * 2;\n$spaceS: $spaceBase * 3;\n$spaceM: $spaceBase * 4;\n$spaceL: $spaceBase * 5;\n$spaceXL: $spaceBase * 6;\n$space2XL: $spaceBase * 8;\n$space3XL: $spaceBase * 9;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post-gamification-wrapper": "_1e48cbe0_post-gamification-wrapper",
	"postGamificationWrapper": "_1e48cbe0_post-gamification-wrapper"
};
export default ___CSS_LOADER_EXPORT___;
