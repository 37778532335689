import HTMLReactParser from 'html-react-parser';
import React, { FC } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

import { PostCompoundSearchViewProps } from '../PostCompoundSearchView';

interface PostCompoundSearchViewContentProps extends Pick<PostCompoundSearchViewProps, 'compact'> {
  text: string;
}

export const PostCompoundSearchViewContent: FC<PostCompoundSearchViewContentProps> = (props) => {
  const { text, compact } = props;

  return (
    <UiTruncateMarkup contentStyle={{ textAlign: 'left' }} truncate lines={compact ? 2 : 4}>
      {HTMLReactParser(text)}
    </UiTruncateMarkup>
  );
};
