import EventIcon from 'ant/components/svg/event.svg';
import FileIcon from 'ant/components/svg/file.svg';
import PictureIcon from 'ant/components/svg/picture.svg';
import PollIcon from 'ant/components/svg/poll.svg';
import { PostAttachmentTypes } from 'ant/types/models/post-attachment';

type AttachmentActionParams = {
  icon: SvgrComponent;
  label: string;
};

export const attachmentActionFields: Record<PostAttachmentTypes, AttachmentActionParams> = {
  [PostAttachmentTypes.File]: {
    icon: FileIcon,
    label: 'Документ',
  },
  [PostAttachmentTypes.Image]: {
    icon: PictureIcon,
    label: 'Изображение',
  },
  [PostAttachmentTypes.Poll]: {
    icon: PollIcon,
    label: 'Мини-опрос',
  },
  [PostAttachmentTypes.Event]: {
    icon: EventIcon,
    label: 'Мероприятие',
  },
};

export const singleAttachmentTypes = new Set<PostAttachmentTypes>([
  PostAttachmentTypes.Poll,
  PostAttachmentTypes.Event,
]);
