import { useStore } from 'effector-react';
import React, { FC, useContext, useMemo, useState, MouseEvent } from 'react';

import { UiAvatarSize } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUser } from 'ant/components/ui/user';
import { Post } from 'ant/components/widgets/Post';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { declenseFullName } from 'ant/plugins/utils/declension';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { getSkillsStorage } from 'ant/store/profile/skills';
import { SkillType } from 'ant/types/models/profile.model';
import {
  TimelineCompetenceContentModel,
  TimelineRecordTargetActorModel,
  TimelineSkill,
  TimelineSkillContentModel,
  TimelineTypes,
} from 'ant/types/models/timelines.model';
import { Gender } from 'ant/types/models/user.model';
import { isTimelineRecordModelSomeOfTypes } from 'components-frontend/typings/guards/timeline';

import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';

type SkillData = {
  skillType: SkillType;
  info: TimelineSkill;
  description: string;
};

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record:
    | TimelineRecordTargetActorModel<TimelineSkillContentModel>
    | TimelineRecordTargetActorModel<TimelineCompetenceContentModel>;
}

export const TimelineCardSkill: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { target, publishedAt, createdAt, actor } = record;

  const { id: currentProfileId } = useCurrentProfile();

  const data = useMemo<SkillData>(() => {
    let cardSkillInfo: TimelineSkill;
    let cardType: SkillType;

    if (
      isTimelineRecordModelSomeOfTypes<TimelineCompetenceContentModel>(record, [
        TimelineTypes.CompetenceApproved,
      ])
    ) {
      cardType = SkillType.Competence;
      cardSkillInfo = record.content.userCompetence;
    } else {
      cardType = SkillType.Skill;
      cardSkillInfo = record.content.skill;
    }

    const isActorFemale = actor.gender === Gender.Female;
    const actionName = isActorFemale ? 'подтвердила' : 'подтвердил';
    const skillName = cardType === SkillType.Competence ? 'компетенцию' : 'навык';

    return {
      info: cardSkillInfo,
      skillType: cardType,
      description: `${actionName} ${skillName}`,
    };
  }, [record]);

  const { info, skillType, description } = data;

  const [isApproved, setIsApproved] = useState(() => {
    return info.approvers.some((approver) => approver.userFrom.id === currentProfileId);
  });

  const targetId = target.keycloakUser ? target.keycloakUser.keycloakId : '';

  const { approveSkillEffect } = useMemo(() => {
    return getSkillsStorage({ userId: info.ownerUserId, skillType });
  }, [info.ownerUserId, skillType]);

  const isApproveSkillLoading = useStore(approveSkillEffect.pending);

  const onApproveChange = (event: MouseEvent<HTMLButtonElement>) => {
    const isAccept = !isApproved;

    event.stopPropagation();

    approveSkillEffect({ skillId: info.skill.id, isAccept, skillType })
      .then(() => setIsApproved(isAccept))
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка смены статуса')));
  };

  const onSkillClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();

    openGlobalModal(GlobalModalNames.SkillsUsers, {
      userId: info.ownerUserId,
      skillType,
      userSkill: info,
    });
  };

  const avatarProps = {
    size: UiAvatarSize.S,
    src: target.smallAvatar,
  };

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header
        padding={[0, horizontalGutter]}
        author={actor}
        postDate={publishedAt || createdAt}
        extra={description}
      />
      <Post.Body padding={[16, 80]}>
        <UiButton.Decorator onClick={() => onClick?.(record)}>
          <UiSpace size={16} direction="vertical">
            <UiSpace size={8} direction="vertical">
              <UiUser.Info
                avatar={avatarProps}
                isActive={Boolean(target.isActive || target.keycloakUser?.isActive)}
                title={declenseFullName(target)}
                to={getRoutePath(RouteNames.Profile, { id: targetId })}
              />
              <UiTypography.Title level={2} style={{ margin: 0 }}>
                <UiTypography.Link
                  style={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                  strong
                  onClick={onSkillClick}
                >
                  {info.skill.name}
                </UiTypography.Link>
              </UiTypography.Title>
            </UiSpace>
            <UiButton
              size="small"
              type={isApproved ? 'secondary' : 'primary'}
              label={isApproved ? 'Отменить' : 'Подтвердить'}
              onClick={onApproveChange}
              loading={isApproveSkillLoading}
              disabled={!actor.isActive || !target.isActive}
            />
          </UiSpace>
        </UiButton.Decorator>
      </Post.Body>
    </Post>
  );
};
