import React, { FC } from 'react';

import { UiAvatarProps } from 'ant/components/ui/avatar';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { parseEntityText } from 'ant/plugins/utils/markup-content';
import { CMSViewType } from 'ant/types/models/cms.model';
import { NotificationCmsPageCommentModel, NotificationModel } from 'ant/types/models/notification.model';
import {
  NotificationComment,
  NotificationTargetTypes,
} from 'components-frontend/components/notifications-drawer/notification/comment';
import { getNotificationDescription } from 'components-frontend/plugins/notification/get-notification-description';

type Props = {
  notification: NotificationModel<NotificationCmsPageCommentModel>;
};

export const NotificationCmsComment: FC<Props> = (props) => {
  const { notification } = props;

  const { content, createdAt, image, initiator } = notification;
  const { fullName, keycloakId } = initiator;
  const { cms, comment, parent } = content;
  const { url, name } = cms;

  const parsedParentText = parent?.text && parseEntityText(parent.text).textValue;
  const parsedCommentText = comment?.text && parseEntityText(comment.text).textValue;

  const commentText = parsedParentText || parsedCommentText;
  const replyText = parent ? parsedCommentText : undefined;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  return (
    <NotificationComment
      status={notification.status}
      title={notificationTitle}
      subTitle={notificationDescription}
      avatar={avatar}
      to={userLink}
      commentText={commentText}
      replyText={replyText}
      createdAt={createdAt}
      targetType={NotificationTargetTypes.Page}
      targetName={name}
      targetUrl={getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: url })}
      targetDescription="К странице:"
    />
  );
};
