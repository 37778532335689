import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import React, { KeyboardEventHandler } from 'react';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiDivider } from 'ant/components/ui/divider';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { NewsArticle, NewsCategoriesDist } from 'ant/types/models/news.model';
import { PostTypes } from 'ant/types/models/post';

import styles from '../result-lists/ResultLists.scss';

type NewsListItemProps = {
  newsCategoriesDist: NewsCategoriesDist | null;
  newsFeed: NewsArticle;
};

export const NewsListItem: React.FC<NewsListItemProps> = (props) => {
  const { newsCategoriesDist, newsFeed } = props;

  const openLongread = () => {
    const newsLongreadParams = { newsId: newsFeed.id, newsType: newsFeed.type || PostTypes.Simple };

    openGlobalModal(GlobalModalNames.NewsLongread, newsLongreadParams, newsLongreadParams);
  };

  const onHTMLKeyPress: KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      openLongread();
    }
  };

  return (
    <div className={styles.resultListItem}>
      <UiAvatar src={newsFeed.cover} size={40} />
      <div
        tabIndex={-1}
        onKeyPress={onHTMLKeyPress}
        role="link"
        onClick={openLongread}
        className={styles.resultListItemDescription}
      >
        <UiTypography.Title>
          <UiTruncateMarkup
            className={styles.resultListItemDescription__title}
            truncate
            tooltipProps={{ title: newsFeed.title }}
          >
            {newsFeed.title}
          </UiTruncateMarkup>
        </UiTypography.Title>

        <UiTypography.Text type="secondary">
          <UiTruncateMarkup truncate>
            {format(new Date(newsFeed.publishedAt), 'dd MMMM, H:mm', { locale: ru })}
            <UiDivider.Dot type="secondary" />
            {(newsFeed.categoryId && newsCategoriesDist?.[newsFeed.categoryId]?.name) ?? 'Без категории'}
          </UiTruncateMarkup>
        </UiTypography.Text>
      </div>
    </div>
  );
};
