import round from 'lodash/round';
import React from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { capitalizeFirstLetter } from 'ant/plugins/utils/capitalize-first-letter';
import HumiditySvg from 'components-frontend/components/svg/weather-humidity.svg';
import PressureSvg from 'components-frontend/components/svg/weather-pressure.svg';
import WindSvg from 'components-frontend/components/svg/weather-wind.svg';
import { WeatherIcon } from 'components-frontend/components/weather/type/WeatherType';
import { Weather } from 'components-frontend/typings/weather';

import styles from './WeatherCurrent.scss';

type Props = { currentWeather?: Weather.CurrentDay };

const WeatherCurrent: React.FC<Props> = ({ currentWeather }) => {
  const { temp, tempSummary, feelsLike, indicators, meta } = currentWeather || ({} as Weather.CurrentDay);

  return (
    <span className={styles.weatherCurrent}>
      <WeatherIcon size={72} type={meta?.icon} />
      <UiTypography.Text className={styles.weatherCurrent__temp}>
        <UiTypography.Text className={styles.weatherCurrent__tempMain}>
          {round(temp)}
          &deg;
        </UiTypography.Text>
        {round(tempSummary?.max)}
        &deg;&nbsp;
        {round(tempSummary?.min)}
        &deg;
      </UiTypography.Text>

      <div className={styles.weatherCurrent__info}>
        <UiTypography.Text className={styles.weatherInformation__infoTitle}>
          <UiTruncateMarkup truncate>{capitalizeFirstLetter(meta?.description)}</UiTruncateMarkup>
        </UiTypography.Text>

        <UiTypography.Text className={styles.weatherCurrent__infoFeelings}>
          Ощущается как&nbsp;
          {round(feelsLike)}
          &deg;
        </UiTypography.Text>

        {indicators?.windSpeed && (
          <UiTypography.Text className={styles.weatherCurrent__infoIndicator}>
            <WindSvg />
            {round(indicators?.windSpeed)}
            &nbsp;м/с,&nbsp;
            {indicators?.windDirection}
          </UiTypography.Text>
        )}

        {indicators?.pressure && (
          <UiTypography.Text className={styles.weatherCurrent__infoIndicator}>
            <PressureSvg />
            {round(indicators?.pressure)}
            &nbsp;мм
          </UiTypography.Text>
        )}

        {indicators?.humidity && (
          <UiTypography.Text className={styles.weatherCurrent__infoIndicator}>
            <HumiditySvg />
            {`${indicators?.humidity}%`}
          </UiTypography.Text>
        )}
      </div>
    </span>
  );
};

export { WeatherCurrent };
