import React, { FC, useCallback, useMemo } from 'react';

import AttachSvg from 'ant/components/svg/attach.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTag } from 'ant/components/ui/tag';
import { UiTypography } from 'ant/components/ui/typography';
import { Post } from 'ant/components/widgets/Post';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getPlainTextFromHtml, parseTextPostContent } from 'ant/plugins/utils/markup-content';
import { getPostAttachmentsStorage } from 'ant/store/attachment';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { LongreadParams } from 'ant/types/longread';
import { PostBasicModel } from 'ant/types/models/post';

import { PostCompoundSearchViewContent } from './content/PostCompoundSearchViewContent';

export interface PostCompoundSearchViewProps {
  post: PostBasicModel;
  compact?: boolean;
}

export const PostCompoundSearchView: FC<PostCompoundSearchViewProps> = (props) => {
  const { post, compact } = props;
  const { id, type, tags, title } = post;

  const plainBodyText = useMemo(() => {
    const parsedHtml = parseTextPostContent(post);

    return getPlainTextFromHtml(parsedHtml);
  }, [post]);

  const { storage: attachmentsStorage } = useMemo(() => {
    return getPostAttachmentsStorage({
      postId: id,
      postType: type,
    });
  }, [id, type]);

  const { data: postAttachments } = useAbstractStorage(attachmentsStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onPostClick = useCallback(() => {
    const postLongreadParams: LongreadParams = { postId: Number(post.id), postType: post.type };

    openGlobalModal(GlobalModalNames.PostLongread, postLongreadParams, postLongreadParams);
  }, [post]);

  const isHasHeaderSubtitle = compact && plainBodyText;

  return (
    <Post style={{ padding: '12px 0' }}>
      <UiSpace size={12} full direction="vertical">
        <Post.Header
          padding={[0, 24]}
          author={post.author}
          postDate={post.publishedAt || post.createdAt}
          subTitle={
            isHasHeaderSubtitle && <PostCompoundSearchViewContent compact={compact} text={plainBodyText} />
          }
        />
        {!compact && (
          <Post.Body padding={[0, 24]}>
            <UiButton.Decorator style={{ display: 'inherit' }} onClick={onPostClick}>
              <UiSpace size={16} full direction="vertical">
                <UiSpace size={0} full direction="vertical">
                  {title && <UiTypography.Title level={2}>{title}</UiTypography.Title>}
                  {plainBodyText && <PostCompoundSearchViewContent compact={compact} text={plainBodyText} />}
                </UiSpace>
                {Boolean(tags.length) && <UiTag.Group tags={tags} />}
                {Boolean(postAttachments.length) && (
                  <UiIcon.Label component={AttachSvg} label={`Вложения: ${postAttachments.length}`} strong />
                )}
              </UiSpace>
            </UiButton.Decorator>
          </Post.Body>
        )}
      </UiSpace>
    </Post>
  );
};
