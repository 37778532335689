import React, { FC, CSSProperties, useContext } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiTypography } from 'ant/components/ui/typography';
import { Post } from 'ant/components/widgets/Post';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { CommentContentTypes } from 'ant/types/models/comment.model';
import {
  TimelineRecordTargetModel,
  TimelineUserStructureContentModel,
} from 'ant/types/models/timelines.model';
import { Gender } from 'ant/types/models/user.model';
import { PostCompound } from 'components-frontend/components/post/compound/PostCompound';

import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';

const linkStyle: CSSProperties = { fontSize: 'inherit', lineHeight: 'inherit', fontWeight: 'inherit' };

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record: TimelineRecordTargetModel<TimelineUserStructureContentModel>;
}

export const TimelineCardUserStructure: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { content, target, createdAt, publishedAt } = record;
  const { oldPosition, position, oldGroup, group } = content;

  const isUserFemale = target.gender === Gender.Female;
  let actionWord = isUserFemale ? 'Переведена ' : 'Переведен ';

  if (!oldGroup && !oldPosition) {
    actionWord = isUserFemale ? 'Принята ' : 'Принят ';
  }

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header padding={[0, horizontalGutter]} author={target} postDate={publishedAt || createdAt} />
      <Post.Body padding={[16, horizontalGutter]}>
        <UiButton.Decorator onClick={() => onClick?.(record)}>
          <UiTypography.Title level={2}>
            {actionWord}
            {group && (
              <>
                {'в '}
                <RouterLink style={linkStyle} to={getRoutePath(RouteNames.Structure, { id: group.id })}>
                  {group.name}
                </RouterLink>
              </>
            )}
            {position && (
              <>
                {' на должность '}
                <RouterLink style={linkStyle} to={getRoutePath(RouteNames.AdvancedSearch)}>
                  {position.name}
                </RouterLink>
              </>
            )}
          </UiTypography.Title>
        </UiButton.Decorator>
      </Post.Body>
      <Post.Footer>
        <PostCompound.CommentsWithInput
          objectId={record.id}
          contentType={CommentContentTypes.TimelineRecord}
          showInput={Boolean(record.actor?.isActive)}
        />
      </Post.Footer>
    </Post>
  );
};
