import React from 'react';
import { Link } from 'react-router-dom';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiTypography } from 'ant/components/ui/typography';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { SearchListItem } from 'ant/types/models/search.model';

import styles from './SearchItemProfile.scss';

type Props = {
  profile: SearchListItem;
};

export const SearchItemProfile: React.FC<Props> = ({ profile }) => {
  return (
    <Link key={profile.id} to={getRoutePath(RouteNames.Profile, { id: profile.id })}>
      <div className={styles.searchItemProfile__result}>
        <UiAvatar size={40} src={profile.image} className={styles.searchItemProfile__avatar} />
        <div className={styles.searchItemProfile__description}>
          <UiTypography.Title level={4} style={{ margin: 0 }}>
            {profile.title}
          </UiTypography.Title>
          <UiTypography.Text type="secondary">{profile.subTitle}</UiTypography.Text>
          <UiTypography.Text type="secondary" className={styles.searchItemProfile__email}>
            {profile.email}
          </UiTypography.Text>
        </div>
      </div>
    </Link>
  );
};
