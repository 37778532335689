import React, { FC, useMemo } from 'react';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import CheckboxIndeterminateSvg from 'ant/components/svg/checkbox-indeterminate.svg';
import CheckboxUncheckedSvg from 'ant/components/svg/checkbox-unchecked.svg';
import CheckboxSvg from 'ant/components/svg/checkbox.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { themePalette } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';

type Props = {
  isSearching?: boolean;
  isMultiSelect?: boolean;
  isMultiSelectPermission?: boolean;
  onCreateFolderClick: () => void;
  onMultipleModeClick: () => void;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  onCancel: () => void;
  selectedCount: number;
  totalCount: number;
  foundItemsCount: number;
};

export const ControlBarLeftActions: FC<Props> = (props) => {
  const {
    onCreateFolderClick,
    onMultipleModeClick,
    isSearching,
    isMultiSelectPermission,
    selectedCount,
    totalCount,
    onCancel,
    onSelectAll,
    onDeselectAll,
    isMultiSelect,
    foundItemsCount,
  } = props;
  const [{ variables: themeVariables }] = useTheme();
  const isAllEntriesSelected = useMemo(() => {
    return selectedCount === totalCount;
  }, [selectedCount, totalCount]);

  const counterData = useMemo(() => {
    if (selectedCount === 0) {
      return {
        icon: CheckboxUncheckedSvg,
        color: themePalette.colorIcon,
      };
    }

    return {
      icon: selectedCount === totalCount ? CheckboxSvg : CheckboxIndeterminateSvg,
      color: themeVariables.colorBrand,
    };
  }, [selectedCount, totalCount]);

  return (
    <UiSpace size={16} align="center">
      {!isMultiSelect && !isSearching && (
        <UiButton
          onClick={onCreateFolderClick}
          type="link-secondary"
          icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
          label="Создать папку"
        />
      )}
      {isSearching && <UiTypography.Text strong>Найдено: {foundItemsCount}</UiTypography.Text>}
      {!isMultiSelect && totalCount > 0 && isMultiSelectPermission && (
        <UiButton
          onClick={onMultipleModeClick}
          type="link-secondary"
          icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
          label="Выбрать несколько"
        />
      )}
      {isMultiSelect && totalCount > 0 && (
        <>
          <UiIcon.Label
            type={UiTypographyTextTypes.Primary}
            color={counterData.color}
            component={counterData.icon}
            label={`${selectedCount} из ${totalCount}`}
          />
          <UiButton
            type="link"
            onClick={isAllEntriesSelected ? onDeselectAll : onSelectAll}
            label={`${isAllEntriesSelected ? 'Снять выделение' : 'Выбрать все'}`}
          />
          <UiButton onClick={onCancel} type="link-secondary" label="Отмена" />
        </>
      )}
    </UiSpace>
  );
};
