import React, { FC } from 'react';

import ErrorSvg from 'ant/components/svg/error.svg';
import HintSvg from 'ant/components/svg/hint.svg';
import SuccessSvg from 'ant/components/svg/success.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypographyTextTypes } from 'ant/components/ui/typography';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { updateUserStatusEffect } from 'ant/store/calendar/event';
import { notificationsStore } from 'ant/store/notifications';
import { themePalette } from 'ant/theme';
import { CalendarEventModel, CalendarUserStatus } from 'ant/types/models/calendar';
import { NotificationModel } from 'ant/types/models/notification.model';

type Props = {
  notification: NotificationModel<CalendarEventModel>;
};

export const CalendarEventActions: FC<Props> = (props) => {
  const { notification } = props;
  const { myStatus, id, isDeleted } = notification.content;

  const onAccept = () => {
    updateUserStatusEffect({ [CalendarUserStatus.Accepted]: [id] })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось принять приглашение'));
  };

  const onReject = () => {
    updateUserStatusEffect({ [CalendarUserStatus.Rejected]: [id] })
      .then(() => {
        message.success('Приглашение отклонено');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось отклонить приглашение'));
  };

  const onDecideMaybe = () => {
    updateUserStatusEffect({ [CalendarUserStatus.Maybe]: [id] })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось принять приглашение'));
  };

  const isCalendarEventStatus = (status: CalendarUserStatus) => status === myStatus;
  const negativeLabelProps = {
    strong: true,
    component: ErrorSvg,
    color: themePalette.colorNegative,
    type: UiTypographyTextTypes.Primary,
    style: { color: themePalette.colorNegative },
  };

  if (!isDeleted && isCalendarEventStatus(CalendarUserStatus.NotAttend)) {
    return <UiIcon.Label {...negativeLabelProps} label="Вас исключили из встречи" />;
  }

  if (isDeleted) {
    return <UiIcon.Label {...negativeLabelProps} label="Встреча отменена" />;
  }

  if (isCalendarEventStatus(CalendarUserStatus.Accepted)) {
    return (
      <UiIcon.Label
        strong
        label="Вы идете"
        component={SuccessSvg}
        color={themePalette.colorSuccess}
        type={UiTypographyTextTypes.Primary}
        style={{ color: themePalette.colorSuccess }}
      />
    );
  }

  if (isCalendarEventStatus(CalendarUserStatus.Rejected)) {
    return <UiIcon.Label {...negativeLabelProps} label="Вы не идете" />;
  }

  if (isCalendarEventStatus(CalendarUserStatus.Maybe)) {
    return (
      <UiIcon.Label
        strong
        component={HintSvg}
        label="Вы возможно пойдете"
        type={UiTypographyTextTypes.Secondary}
      />
    );
  }

  return (
    <UiSpace align="center">
      <UiButton label="Пойду" type="primary" onClick={onAccept} />
      <UiButton label="Не иду" type="tertiary" onClick={onReject} />
      <UiButton label="Может быть" type="tertiary" onClick={onDecideMaybe} />
    </UiSpace>
  );
};
