import React, { useMemo } from 'react';

import { UiIcon } from 'ant/components/ui/icon';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import Weather01dSvg from 'components-frontend/components/svg/weather-01d.svg';
import Weather01nSvg from 'components-frontend/components/svg/weather-01n.svg';
import Weather02dSvg from 'components-frontend/components/svg/weather-02d.svg';
import Weather02nSvg from 'components-frontend/components/svg/weather-02n.svg';
import Weather03dSvg from 'components-frontend/components/svg/weather-03d.svg';
import Weather03nSvg from 'components-frontend/components/svg/weather-03n.svg';
import Weather04dSvg from 'components-frontend/components/svg/weather-04d.svg';
import Weather04nSvg from 'components-frontend/components/svg/weather-04n.svg';
import Weather09dSvg from 'components-frontend/components/svg/weather-09d.svg';
import Weather09nSvg from 'components-frontend/components/svg/weather-09n.svg';
import Weather10dSvg from 'components-frontend/components/svg/weather-10d.svg';
import Weather10nSvg from 'components-frontend/components/svg/weather-10n.svg';
import Weather11dSvg from 'components-frontend/components/svg/weather-11d.svg';
import Weather11nSvg from 'components-frontend/components/svg/weather-11n.svg';
import Weather13dSvg from 'components-frontend/components/svg/weather-13d.svg';
import Weather13nSvg from 'components-frontend/components/svg/weather-13n.svg';
import Weather50dSvg from 'components-frontend/components/svg/weather-50d.svg';
import Weather50nSvg from 'components-frontend/components/svg/weather-50n.svg';

enum WeatherConditionsDay {
  ClearSky = '01d',
  FewClouds = '02d',
  ScatteredClouds = '03d',
  BrokenClouds = '04d',
  ShowerRain = '09d',
  Rain = '10d',
  Thunderstorm = '11d',
  Snow = '13d',
  Mist = '50d',
}

enum WeatherConditionsNight {
  ClearSky = '01n',
  FewClouds = '02n',
  ScatteredClouds = '03n',
  BrokenClouds = '04n',
  ShowerRain = '09n',
  Rain = '10n',
  Thunderstorm = '11n',
  Snow = '13n',
  Mist = '50n',
}

export type WeatherCondition = WeatherConditionsDay | WeatherConditionsNight;

const weatherIconMap: Record<WeatherCondition, SvgrComponent> = {
  [WeatherConditionsDay.ClearSky]: Weather01dSvg,
  [WeatherConditionsDay.FewClouds]: Weather02dSvg,
  [WeatherConditionsDay.ScatteredClouds]: Weather03dSvg,
  [WeatherConditionsDay.BrokenClouds]: Weather04dSvg,
  [WeatherConditionsDay.ShowerRain]: Weather09dSvg,
  [WeatherConditionsDay.Rain]: Weather10dSvg,
  [WeatherConditionsDay.Thunderstorm]: Weather11dSvg,
  [WeatherConditionsDay.Snow]: Weather13dSvg,
  [WeatherConditionsDay.Mist]: Weather50dSvg,

  [WeatherConditionsNight.ClearSky]: Weather01nSvg,
  [WeatherConditionsNight.FewClouds]: Weather02nSvg,
  [WeatherConditionsNight.ScatteredClouds]: Weather03nSvg,
  [WeatherConditionsNight.BrokenClouds]: Weather04nSvg,
  [WeatherConditionsNight.ShowerRain]: Weather09nSvg,
  [WeatherConditionsNight.Rain]: Weather10nSvg,
  [WeatherConditionsNight.Thunderstorm]: Weather11nSvg,
  [WeatherConditionsNight.Snow]: Weather13nSvg,
  [WeatherConditionsNight.Mist]: Weather50nSvg,
};

export const getWeatherIconSvg = (type?: WeatherCondition): SvgrComponent | null => {
  if (!type) return null;

  return weatherIconMap[type];
};

type WeatherIconProps = { type?: WeatherCondition; size: number };

export const WeatherIcon: React.FC<WeatherIconProps> = ({ type, size }) => {
  const iconProps = { width: size, height: size };
  const Icon = useMemo(() => getWeatherIconSvg(type), [type]);

  return Icon ? <UiIcon component={Icon} {...iconProps} /> : <UiSkeleton circle loading {...iconProps} />;
};
