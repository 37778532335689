import classNames from 'classnames';
import { eachDayOfInterval, format, isSameDay, isToday } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { Store } from 'effector';
import { useStore } from 'effector-react';
import React, { useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { CalendarAppearanceDates } from 'ant/store/calendar/appearance-dates';

import styles from './CalendarWidgetWeek.scss';

interface Props {
  selectedDate: Date;
  onDayClick: (selectedDate: Date) => void;
  appearanceDatesStore: Store<CalendarAppearanceDates>;
}

const CalendarWidgetWeek: React.FC<Props> = ({ selectedDate, onDayClick, appearanceDatesStore }) => {
  const { sinceDate, tillDate } = useStore(appearanceDatesStore);
  const daysOfWeek = eachDayOfInterval({ start: sinceDate, end: tillDate });

  const weekEls = useMemo(
    () =>
      daysOfWeek?.map((columnDate) => {
        const dayOfMonth = columnDate.getDate();
        const weekName = format(columnDate, 'EEEEEE', { locale: ru });
        const isSelected = !isToday(columnDate) && isSameDay(columnDate, selectedDate);

        return (
          <UiButton
            key={weekName}
            type="link"
            onClick={() => onDayClick(columnDate)}
            className={styles.calendarWidgetWeek__column}
          >
            <span
              className={classNames(styles.calendarWidgetWeek__day, {
                [styles.calendarWidgetWeek__day_today]: isToday(columnDate),
                [styles.calendarWidgetWeek__day_selected]: isSelected,
              })}
            >
              {weekName}
            </span>
            <span
              className={classNames(styles.calendarWidgetWeek__date, {
                [styles.calendarWidgetWeek__date_today]: isToday(columnDate),
                [styles.calendarWidgetWeek__date_selected]: isSelected,
              })}
            >
              {dayOfMonth}
            </span>
          </UiButton>
        );
      }),
    [daysOfWeek, selectedDate],
  );

  return <div className={styles.calendarWidgetWeek}>{weekEls}</div>;
};

export { CalendarWidgetWeek };
