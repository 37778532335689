import React, { useState } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCheckboxOptionType } from 'ant/components/ui/checkbox';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiRadio, UiRadioChangeEvent } from 'ant/components/ui/radio/UiRadio';
import { UiTypography } from 'ant/components/ui/typography';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { NotificationFilters, NotificationSortAttributes } from 'ant/types/models/notification.model';

import styles from './SettingsModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChange: (filters?: NotificationFilters) => void;
};

const sortByAttrs: UiCheckboxOptionType[] = [
  {
    label: 'По дате',
    value: NotificationSortAttributes.Date,
  },
  {
    label: 'Сперва непросмотренные',
    value: NotificationSortAttributes.UnreadFirst,
  },
];

const SettingsModal: React.FC<Props> = ({ isOpen, onClose, onChange }) => {
  const [sortBy, setSortBy] = useState<NotificationSortAttributes>(NotificationSortAttributes.Date);

  const onRadioChange = ({ target: { value } }: UiRadioChangeEvent) => {
    setSortBy(value);
  };

  const onSave = () => {
    if (!sortBy) {
      return;
    }

    onChange([sortBy]);
    onClose();
  };

  return (
    <UiModal type={UiModalTypes.Small} isOpen={isOpen} onClose={onClose}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Фильтрация уведомлений')} />
      </UiModal.Header>
      <UiModal.Content className={styles.settingsModal__content}>
        <UiTypography.Text type="secondary" strong className={styles.settingsModal__label}>
          Сортировка
        </UiTypography.Text>
        <UiRadio.Group value={sortBy} onChange={onRadioChange} className={styles.settingsModal__sort}>
          {sortByAttrs.map(({ label, value }) => (
            <UiRadio key={String(label)} value={value} className={styles.settingsModal__sortItem}>
              {label}
            </UiRadio>
          ))}
        </UiRadio.Group>
        {/* todo B2BCORE-1233 заполнить сведения по категориям уведомлений */}
        <div className={styles.settingsModal__buttonGroup}>
          <UiButton
            type="tertiary"
            label="Отмена"
            onClick={onClose}
            className={styles.settingsModal__button}
          />
          <UiButton
            type="primary"
            label="Применить"
            onClick={onSave}
            className={styles.settingsModal__button}
          />
        </div>
      </UiModal.Content>
    </UiModal>
  );
};

export { SettingsModal };
