import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiCollapse } from 'ant/components/ui/collapse';
import { UiDivider } from 'ant/components/ui/divider';
import { UiRow } from 'ant/components/ui/grid';
import { message } from 'ant/components/ui/message';
import { UiModalBase } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { UiTag } from 'ant/components/ui/tag';
import { UiTypography, UiTypographySymbolName } from 'ant/components/ui/typography';
import { Post, PostFooterAction, PostSwitchAction } from 'ant/components/widgets/Post';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getPostBlogsMap, isSomePostBlogsNegativeKey } from 'ant/plugins/get-post-blogs';
import { capitalizeFirstLetter } from 'ant/plugins/utils/capitalize-first-letter';
import { AttachmentsStorage } from 'ant/store/attachment';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { isBlogPost, isGamificationPost, isMicropost, isProfileEntry } from 'ant/types/guards/post';
import { PermissionsV2Enum } from 'ant/types/models/blog.model';
import { PostStatuses, PostTypes } from 'ant/types/models/post';
import { PostAttachmentModel, PostFormAttachmentId } from 'ant/types/models/post-attachment';
import { ReactionId } from 'ant/types/models/reactions.model';
import { getCommentsContentTypeByPost } from 'components-frontend/components/comment/helpers';
import { PostCompound, PostItemProps } from 'components-frontend/components/post/compound/PostCompound';
import { deletePostEffect } from 'components-frontend/store/post';

import { PostAttachmentsAdapter } from '../../attachments/adapter/PostAttachmentsAdapter';
import { PostGamification } from '../../gamification/PostGamification';
import { PostSkill } from '../../skill/PostSkill';

interface Props extends Pick<PostItemProps, 'blog' | 'post' | 'postsStorage' | 'onPin'> {
  switchEditMode?: () => void;
  attachments: PostAttachmentModel[];
  attachmentsStorage: AttachmentsStorage;
}

export const PostCompoundRepresentation: FC<Props> = (props) => {
  const { post, blog, postsStorage, onPin, switchEditMode, attachments, attachmentsStorage } = props;
  const { id: postId, type: postType } = post;
  const { reloadAttachmentByIdEffect } = attachmentsStorage;

  const tags = isBlogPost(post) || isMicropost(post) ? post.tags : [];

  const {
    reactOnPostEffect,
    switchFavoritePostEffect,
    switchPinPostEffect,
    changePostStatusEffect,
    updateCommentsCountEvent,
  } = postsStorage;

  const isPostRestorePending = useStore(changePostStatusEffect.pending);

  const isPostStatus = (status: PostStatuses) => post.flag === status;
  const isPremoderation = isPostStatus(PostStatuses.AwaitingApprove);
  const isDeclined = isPostStatus(PostStatuses.Decline);
  const isCommentsDisabledPostSettings = post?.settings && !post.settings.isComments;
  const isCommentsDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isComments');
  const isCommentsDisabled = isCommentsDisabledPostSettings || isCommentsDisabledPostBlogs;
  const showComments = !isDeclined && !isCommentsDisabled && !isPremoderation;
  const isDeletePostPending = useStore(deletePostEffect.pending);
  const isChangePostPending = useStore(changePostStatusEffect.pending);

  const onPostDelete = useCallback(() => {
    const postName = postType === PostTypes.Micropost ? 'микропост' : 'пост';

    UiModalBase.warning({
      title: `Удалить ${postName}`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      disabled: isDeletePostPending,
      onOk: async (confirmClose) => {
        try {
          await deletePostEffect({ postId, postType });
          message.success(`${capitalizeFirstLetter(postName)} удален`);
          confirmClose();
        } catch (error) {
          message.error(getErrorResponseMessage(error, `Ошибка удаления ${postName}`));
        }
      },
    });
  }, [deletePostEffect, postId, postType]);

  const onPostFavorite: PostSwitchAction = useCallback(
    (isFavorite) => {
      switchFavoritePostEffect({ postId, postType, favorite: isFavorite }).catch((e) =>
        message.error(getErrorResponseMessage(e, 'Не удалось добавить пост в избранное')),
      );
    },
    [postId, postType, switchFavoritePostEffect],
  );

  const onPostPin: PostSwitchAction = useCallback(
    (isPinned) => {
      const errorMessage = `Не удалось ${isPinned ? 'закрепить' : 'открепить'} пост`;

      switchPinPostEffect({ postId, isPinned })
        .then(onPin)
        .catch((error) => getErrorResponseMessage(error, errorMessage));
    },
    [postId, switchPinPostEffect],
  );

  const onPostCommentsCountUpdate = useCallback(
    (commentsCount: number) => updateCommentsCountEvent({ postId, postType, commentsCount }),
    [postId, postType, updateCommentsCountEvent],
  );

  const onPublish = useCallback(() => {
    changePostStatusEffect({ postId, postType, flag: PostStatuses.FlagPublished })
      .then(() => {
        postsStorage.resetAndRefetchEvent();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось опубликовать пост')));
  }, [postId, postType]);

  const onDecline = useCallback(() => {
    changePostStatusEffect({ postId, postType, flag: PostStatuses.Decline }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось отклонить пост')),
    );
  }, [postId, postType]);

  const onCancelDecline = useCallback(() => {
    changePostStatusEffect({ postId, postType, flag: PostStatuses.AwaitingApprove }).catch((e) =>
      message.error(getErrorResponseMessage(e, 'Не удалось восстановить пост')),
    );
  }, [postId, postType]);

  const onPostReaction = useCallback(
    (reactionId: ReactionId, isLike: boolean) => {
      reactOnPostEffect({ postId, postType, reactionId, isLike }).catch((e) =>
        message.error(getErrorResponseMessage(e, 'Не удалось поставить реакцию')),
      );
    },
    [postId, postType, reactOnPostEffect],
  );

  const onAttachmentReload = useCallback(
    (attachmentId: PostFormAttachmentId) => reloadAttachmentByIdEffect({ attachmentId }),
    [reloadAttachmentByIdEffect],
  );

  const openLongread = useCallback(() => {
    const isPostEntry = isBlogPost(post) || isMicropost(post);

    if (isPostEntry) {
      const postLongreadParams = { postId, postType: post.type };
      const postLongreadPayload = {
        ...postLongreadParams,
        onEditApply: postsStorage.storage.refetchWithLastParams,
      };

      openGlobalModal(GlobalModalNames.PostLongread, postLongreadPayload, postLongreadParams);
    }
  }, [postId, postType, postsStorage.storage.refetchWithLastParams]);

  const postActions = useMemo<PostFooterAction[]>(() => {
    const actions: PostFooterAction[] = [];

    if (isPremoderation && blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts]) {
      actions.push({
        label: 'Опубликовать',
        onClick: onPublish,
        type: 'primary',
        disabled: isChangePostPending,
      });

      actions.push({
        label: 'Отклонить',
        onClick: onDecline,
        type: 'tertiary',
        disabled: isChangePostPending,
      });
    }

    return actions;
  }, [post, onPublish, onDecline, isPremoderation, blog, isChangePostPending]);

  const postBlogs = useMemo(() => getPostBlogsMap(post), [post]);
  const commentsType = useMemo(() => getCommentsContentTypeByPost(post), [post]);
  const isPost = isBlogPost(post) || isMicropost(post);
  const isActiveAuthor = Boolean(post.author.isActive || post.author.keycloakUser?.isActive);

  return (
    <Post style={{ paddingTop: 24 }}>
      <Post.Header
        padding={[0, 24]}
        blogs={postBlogs}
        author={post.author}
        postDate={post.publishedAt || post.createdAt}
      >
        <Post.Header.Actions
          blog={blog}
          post={post}
          onEdit={switchEditMode}
          onDelete={onPostDelete}
          onFavorite={onPostFavorite}
          onPin={onPostPin}
        />
      </Post.Header>
      <Post.Body padding={[16, 24]}>
        {isDeclined && (
          <UiRow>
            <UiTypography.Text>Запись удалена.</UiTypography.Text>
            <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
            {blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts] && (
              <UiButton
                disabled={isPostRestorePending}
                type="link"
                style={{ display: 'inline' }}
                onClick={onCancelDecline}
              >
                Восстановить
              </UiButton>
            )}
          </UiRow>
        )}

        {!isDeclined && (
          <UiSpace size={24} full direction="vertical">
            <UiCollapse.Content>
              {isProfileEntry(post) && <PostSkill post={post} />}
              {isGamificationPost(post) && <PostGamification post={post} />}
              {isPost && <Post.Body.Markup post={post} onClick={openLongread} />}
            </UiCollapse.Content>

            {tags.length > 0 && <UiTag.Group tags={tags} />}

            {attachments.length > 0 && (
              <Post.Body.Attachments
                attachments={attachments}
                renderItem={(item) => (
                  <PostAttachmentsAdapter value={item} reloadAttachment={onAttachmentReload} />
                )}
              />
            )}

            <Post.Body.Reactions
              post={post}
              {...(isActiveAuthor && {
                onReaction: onPostReaction,
              })}
            />
          </UiSpace>
        )}
      </Post.Body>
      <UiDivider emptyMargin />
      <Post.Footer>
        {postActions.length > 0 && (
          <Post.Footer.Actions postType={post.type} padding={[16, 24]} actions={postActions} />
        )}
        {showComments && (
          <PostCompound.CommentsWithInput
            objectId={post.id}
            showInput={isActiveAuthor}
            contentType={commentsType}
            onCommentsCountUpdate={onPostCommentsCountUpdate}
          />
        )}
      </Post.Footer>
    </Post>
  );
};
