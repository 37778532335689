import { useStore } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiForm } from 'ant/components/ui/form';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiOptionData } from 'ant/components/ui/select';
import { UiUploadFile, UiUploadFileStatusType, UiUploadOriginFile } from 'ant/components/ui/upload';
import { UiUploadFileList } from 'ant/components/ui/upload-file-list';
import { UploadDraggerArea } from 'ant/components/widgets/UploadDraggerArea';
import { UploadFile } from 'ant/helpers/storage/abstract-files-upload-factory';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { generateTagApiOptions } from 'ant/plugins/utils/tag-helper';
import { createUpdateFileStorageEntryEffect } from 'ant/store/filestorage';
import { FileStorageCreatePayload } from 'ant/types/file-storage';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { FileStorageEntryInfoModel } from 'ant/types/models/file.model';
import { OptionModel } from 'ant/types/models/option';

import { FileStorageCreateUpdateFields } from '../fields/FileStorageCreateUpdateFields';

export type FileStorageCreateUpdateFormValues = Pick<
  FileStorageEntryInfoModel<UiOptionData>,
  'id' | 'name' | 'description' | 'tags' | 'categories'
>;

export const FileStorageCreateModal: FC<FileStorageCreatePayload> = (props) => {
  const { onClose, onSuccess: onSuccessModal, parent, uploadStore } = props;
  const { store: uploadFilesStore, uploadFilesEvent, removeFilesEvent } = uploadStore;
  const preloadedFiles = useStore(uploadFilesStore);

  const [form] = UiForm.useForm<FileStorageCreateUpdateFormValues>();

  const preloadedFile = useMemo(() => {
    if (preloadedFiles && preloadedFiles.length) {
      return {
        ...preloadedFiles[0],
        fileData: {
          ...preloadedFiles[0].fileData,
          name: preloadedFiles[0].fileData.name,
          status: preloadedFiles[0].status,
          error: preloadedFiles[0].errorMessage,
        },
      };
    }

    return null;
  }, [preloadedFiles]);

  useEffect(() => {
    if (preloadedFile) {
      form.setFieldValue('name', preloadedFile.fileData.name);
    }
  }, [preloadedFile]);

  const onFinish = () => {
    const { id, tags, categories, ...restFormValues } = form.getFieldsValue(true);
    const params = {
      ...restFormValues,
      tags: generateTagApiOptions(tags),
      categories: categories?.map(({ value }: OptionModel) => value),
      id: preloadedFile ? preloadedFile.fileData.uid : id,
      parent,
    };

    createUpdateFileStorageEntryEffect(params)
      .then((data) => {
        message.success('Файл успешно создан');
        onSuccessModal?.(data);
        onClose();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка создания файла')));
  };

  const isSaveDisabled = useMemo(() => {
    const disabledStatuses = [UiUploadFileStatusType.Uploading, UiUploadFileStatusType.Error];
    const isDisabledByStatus = preloadedFile?.status && disabledStatuses.includes(preloadedFile.status);

    return isDisabledByStatus && !form.getFieldValue('id');
  }, [preloadedFile, form]);

  const uploadFiles = useCallback(
    (filesToUpload: UiUploadOriginFile[]) => {
      const uploadFileList: UploadFile<UiUploadFile>[] = filesToUpload.map((file) => ({
        key: file.uid,
        file,
        fileData: file,
      }));

      uploadFilesEvent({ filesToUpload: uploadFileList, appendData: false });
    },
    [uploadFilesEvent],
  );

  const onPickFiles = useCallback(
    (files: UiUploadOriginFile[]) => {
      uploadFiles(files);
    },
    [uploadFiles],
  );

  const onRemoveFile = useCallback(() => {
    if (preloadedFile) {
      removeFilesEvent([preloadedFile.key]);
    }
  }, [removeFilesEvent, preloadedFile]);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Загрузить файл')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm onFinish={onFinish} form={form}>
          <FileStorageCreateUpdateFields parent={parent}>
            <UiForm.Item label="Файл">
              {!preloadedFile && (
                <UploadDraggerArea
                  multiple={false}
                  onPickFiles={onPickFiles}
                  accept={FileUploadAccepts.All}
                  description="Максимальный размер файла: 2 Гб"
                />
              )}
              {preloadedFile && <UiUploadFileList files={[preloadedFile.fileData]} onDelete={onRemoveFile} />}
            </UiForm.Item>
          </FileStorageCreateUpdateFields>
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            label="Сохранить"
            onClick={form.submit}
            size="large"
            type="primary"
            disabled={isSaveDisabled}
          />
          <UiButton label="Отмена" onClick={onClose} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
