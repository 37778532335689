import { ProfileEndpoints } from 'ant/endpoints/profile';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { ProfileListParams } from 'ant/store/profile';
import { DictPaginated } from 'ant/types/api';
import { SearchListItem } from 'ant/types/models/search.model';
import { ProfileMain, searchListResultDataMapper } from 'components-frontend/typings/search/profile';

export const getSearchProfileStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProfileMain>,
    SearchListItem[],
    SearchListItem[],
    ProfileListParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(ProfileEndpoints.v2List(), params),
    defaultValue: [],
    dataMapper: ({ items }) => searchListResultDataMapper(items),
    paginationInfoRetriever: ({ meta: { objectsTotal: count } }) => ({ count }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
