import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import React, { useMemo } from 'react';

import ClearCircleSVG from 'ant/components/svg/clear-circle.svg';
import CommunitySVG from 'ant/components/svg/community.svg';
import DownloadSVG from 'ant/components/svg/download.svg';
import PollSVG from 'ant/components/svg/poll.svg';
import TimeSVG from 'ant/components/svg/time.svg';
import { BadgeColors, UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiTypography } from 'ant/components/ui/typography';
import { PollModel } from 'ant/types/models/poll';
import { PollAnswers } from 'components-frontend/components/poll/poll-answers/PollAnswers';

import styles from './PollModal.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  saveAllToFileXLSX: (id: number, name: string) => void;
  poll: PollModel;
}

const PollModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, poll, saveAllToFileXLSX } = props;
  const { questions, title, isAnonymous = false, isAuthor, dateEnd, votedRespondentsCount } = poll;

  const pollStatus = useMemo(() => {
    if (!dateEnd) {
      return '';
    }

    return new Date(dateEnd) < new Date()
      ? 'Голосование закончилось'
      : `Голосование заканчивается ${format(new Date(dateEnd), 'd MMMM в HH:mm', { locale: ru })}`;
  }, [dateEnd]);

  return (
    <UiModal type={UiModalTypes.Large} isOpen={isOpen} onClose={onClose}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={{ 1: { title } }} />
      </UiModal.Header>
      <UiModal.Content>
        <div className={styles.pollModal__header}>
          <UiTypography.Text strong type="secondary" className={styles.pollModal__text}>
            <UiIcon component={PollSVG} height={20} width={20} className={styles.pollModal__icon} />
            {`${isAnonymous ? 'Анонимный' : 'Публичный'} опрос`}
          </UiTypography.Text>
          {dateEnd && (
            <UiTypography.Text strong type="secondary" className={styles.pollModal__text}>
              <UiIcon component={TimeSVG} height={20} width={20} className={styles.pollModal__icon} />
              {pollStatus}
            </UiTypography.Text>
          )}
          {/* todo добавить отображение количества проголосовавших и не прошедших голосование по готовности бэка */}
          <UiTypography.Text strong type="secondary" className={styles.pollModal__text}>
            <UiIcon component={CommunitySVG} height={20} width={20} className={styles.pollModal__icon} />
            {`Проголосовали ${votedRespondentsCount}`}
          </UiTypography.Text>
          <UiTypography.Text strong type="secondary" className={styles.pollModal__text}>
            <UiIcon component={ClearCircleSVG} height={20} width={20} className={styles.pollModal__icon} />
            Не прошли
          </UiTypography.Text>
        </div>

        {questions.map((question, index) => {
          const answers =
            question.options?.map((el) => ({ option: Number(el.option), question: question.id })) || [];

          return (
            <div key={question.id} className={styles.pollModal__question}>
              <div className={styles.pollModal__questionHeader}>
                <UiBadge
                  color={BadgeColors.Primary}
                  count={index + 1}
                  className={styles.pollModal__questionBadge}
                />
                <UiTypography.Title level={1} style={{ margin: 0 }}>
                  {question.text}
                </UiTypography.Title>
              </div>
              <PollAnswers
                poll={poll}
                question={question}
                answers={answers}
                disabled
                answerClassName={styles.pollModal__answer}
                isSingleQuestionPoll={questions.length === 1}
              />
              {isAuthor && (
                <UiButton
                  className={styles.pollModal__button}
                  type="link"
                  label="Выгрузить все ответы в файл"
                  icon={<UiIcon component={DownloadSVG} height={20} width={20} />}
                  onClick={() => saveAllToFileXLSX(poll.id, poll.title)}
                />
              )}
            </div>
          );
        })}
      </UiModal.Content>
    </UiModal>
  );
};

export { PollModal };
