import { BlogsEndpoints } from 'ant/endpoints/blogs';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { DictPaginated } from 'ant/types/api';
import { BlogsListItem } from 'ant/types/blogs';
import { SearchParams } from 'ant/types/models/search.model';

export const getSearchBlogStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<BlogsListItem>,
    BlogsListItem[],
    BlogsListItem[],
    SearchParams
  >({
    endpointBuilder: ({ pageNumber, pageSize }) =>
      buildEndpointWithQueryParams(BlogsEndpoints.list(), { pageNumber, pageSize }),
    defaultValue: [],
    dataBuilder: ({ searchString }) => ({ searchString }),
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
    requestMethod: 'post',
  });

  return { storage };
};
